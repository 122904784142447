@mixin wolf-m-navigation-switcher($name: '.m-navigation-switcher') {
	#{$name} {
		height: 46px;
		border-radius: 30px;
		overflow: hidden;
		border: solid 1px get-color('true-white');
		display: inline-flex;

		#root > div > & {
			margin: 24px; //Aply only for storybook
		}

		&.switcher-active {
			&-1,
			&-2 {
				.a-button {
					&.uk-active {
						&:hover {
							color: get-color('accent');
						}
					}
				}
			}
		}

		.a-button {
			height: 42px;
			border-radius: 28px;
			border: 0;
			outline: none;
			background-color: transparent;
			color: get-color('true-white');
			margin-left: -1px;
			position: relative;
			z-index: 0;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			padding: 12px 22px;
			margin-top: 2px;

			&::after {
				content: none;
			}

			&:not(.uk-active) {
				&:hover {
					background-color: rgba(255, 255, 255, 0.1);
					color: get-color('true-white');
				}
			}

			&.uk-active {
				background-color: get-color('true-white');
				color: get-color('wolf-black');
				z-index: 1;
				border-top-right-radius: 30px;
				border-bottom-right-radius: 30px;
				box-shadow: 0 0 0 2px get-color('true-white');

				&::after {
					content: none;
				}
			}

			+ .a-button {
				margin-left: -14px;
				margin-right: -1px;

				&:not(.uk-active) {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}

				&::before {
					content: '';
					background-color: transparent;
					position: absolute;
					z-index: 1;
					top: 0;
					left: -30px;
					width: 30px;
					height: 46px;
					display: flex;
				}
			}
		}

		&.only-btn {
			&-1,
			&-2 {
				border-color: get-color('accent');

				&:hover {
					border-color: get-color('true-white');
				}

				.a-button {
					background-color: get-color('accent');
					box-shadow: 0 0 0 2px get-color('accent');
					color: get-color('true-white');

					&:hover {
						background-color: get-color('true-white');
						box-shadow: 0 0 0 2px get-color('true-white');
						color: get-color('accent');
					}

					&.uk-button {
						background-color: get-color('accent');
						box-shadow: 0 0 0 2px get-color('accent');
						color: get-color('true-white');

						&:hover {
							background-color: get-color('true-white');
							box-shadow: 0 0 0 2px get-color('true-white');
							color: get-color('accent');
						}
					}
				}
			}
		}
	}
}

@include wolf-m-navigation-switcher();
