@mixin wolf-m-teaser-product($name: '.m-teaser-product') {
	#{$name} {
		width: 100%;
		position: relative;
		min-height: 680px;

		@media (max-width: $breakpoint-medium) {
			min-height: initial;
		}

		&__image-wrapper {
			height: 250px;
			width: 100%;
			background-color: map-get($colors, 'lighter-grey');
			position: relative;
		}

		&__image {
			background-repeat: no-repeat;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&__energy {
			position: absolute;
			left: 0px;
			top: 10px;
			width: 110px;

			.a-energy-badge p {
				border-bottom: 2px solid map-get($colors, 'lighter-grey');

				&::after {
					border-right-color: map-get($colors, 'lighter-grey');
					border-top-color: map-get($colors, 'lighter-grey');
					border-bottom-color: map-get($colors, 'lighter-grey');
				}
			}
		}

		&__description {
			// margin-top: 32px;
			margin-top: 24px;
			min-height: 150px;

			.a-headline__heading {
				margin-bottom: 32px;

				@media (max-width: $breakpoint-medium) {
					margin-bottom: 12px;
				}
			}

			p {
				margin: 0px;
			}

			@media (max-width: $breakpoint-medium) {
				// min-height: 170px;
			}
		}

		&__product-link {
			text-decoration: none;
			color: inherit;

			&:hover {
				text-decoration: none;
			}
		}

		&__price {
			font-weight: 600;
			margin-top: 79px;

			@media (max-width: $breakpoint-medium) {
				margin-top: 24px;
			}

			.uk-select {
				background: transparent;
				background-image: url('./../../../global/assets/icons/chevron-down-thin.svg') !important;
				background-size: 12px;
				font-size: get-fontsize(m, large);
				background-position: 25% 50% !important;
			}

			#{$name}__price-column {
				text-align: right;
				font-size: get-fontsize(m, large);

				@media (max-width: 1350px) {
					font-size: get-fontsize(m, large) - 5;
				}
			}

			.uk-select:not([multiple]):not([size]) {
				height: inherit;
			}
		}

		&__button {
			margin-top: 28px;
			margin-bottom: 12px;

			a {
				width: 100%;
				white-space: nowrap;
				justify-content: center;
				padding: 1px 0px 0px 0px !important;

				&.a-button {
					min-height: 85px;
				}

				.a-button__icon-wrapper {
					padding: 15px 15px 15px 0;
				}
			}
		}

		&__order {
			width: 100%;
			bottom: 0px;

			@media (max-width: $breakpoint-medium) {
				position: initial;
			}
		}

		&--highlighted #{$name}__description {
			p {
				color: map-get($colors, 'light-blue');
			}
		}
	}
}

@include wolf-m-teaser-product();
