.pl-0 {
	@include only-mobile {
		padding-left: 0 !important;
	}
}

.pr-0 {
	@include only-mobile {
		padding-right: 0 !important;
	}
}

.px-0 {
	@include only-mobile {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
}

.pl-md-0 {
	@include only-tablet {
		padding-left: 0 !important;
	}
}

.pr-md-0 {
	@include only-tablet {
		padding-right: 0 !important;
	}
}

.px-md-0 {
	@include only-tablet {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

.pl-lg-0 {
	@include min-lg {
		padding-left: 0 !important;
	}
}

.pr-lg-0 {
	@include min-lg {
		padding-right: 0 !important;
	}
}

.px-lg-0 {
	@include min-lg {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}
