@mixin wolf-a-headline($name: '.a-headline') {
	h1#{$name} {
		@include hook-base-h1();
		&.inter {
			@include hook-inter-h1();
		}
	}

	h2#{$name} {
		@include hook-base-h2();
		&.inter {
			@include hook-inter-h2();
		}
	}

	h3#{$name} {
		@include hook-base-h3();
		&.inter {
			@include hook-inter-h3();
		}
	}

	h4#{$name} {
		@include hook-base-h4();
		&.inter {
			@include hook-inter-h4();
		}
	}

	h5#{$name} {
		@include hook-base-h5();
		&.inter {
			@include hook-inter-h5();
		}
	}

	h6#{$name} {
		@include hook-base-h6();
		&.inter {
			@include hook-inter-h6();
		}
	}

	&#{$name} {
		&:empty {
			display: none;
		}

		&:not(.no-hypen) {
			hyphens: auto;
			word-break: break-word;
		}

		.prefix-anchor {
			color: #d2d2d2;
		}

		.anchor {
			visibility: hidden;
			display: flex;
			width: 100%;
			transform: translateY(-100px);
		}

		&.uk-h1 {
			@include hook-base-h1();
			&.inter {
				@include hook-inter-h1();
			}
		}

		&.uk-h2 {
			@include hook-base-h2();
			&.inter {
				@include hook-inter-h2();
			}
		}

		&.uk-h3 {
			@include hook-base-h3();
			&.inter {
				@include hook-inter-h3();
			}
		}

		&.uk-h4 {
			@include hook-base-h4();
			&.inter {
				@include hook-inter-h4();
			}
		}

		&.uk-h5 {
			@include hook-base-h5();
			&.inter {
				@include hook-inter-h5();
			}
		}

		&.uk-h6 {
			@include hook-base-h6();
			&.inter {
				@include hook-inter-h6();
			}
		}

		&.has-anchor {
			&.uk-heading-small,
			&.uk-heading-medium,
			&.uk-heading-large,
			&.uk-heading-xlarge,
			&.uk-heading-2xlarge {
				margin-top: 0px;
			}
		}

		&.dark {
			&-blue {
				color: get-color('dark-blue');
			}
			&-grey {
				color: get-color('dark-grey');
			}
		}

		&.light {
			&-blue {
				color: get-color('light-blue');
			}
			&-grey {
				color: get-color('light-grey');
			}
		}

		&.silver-grey {
			color: get-color('silver-grey');
		}

		&.true-white {
			color: get-color('true-white');
		}

		&.wolf {
			&-black {
				color: get-color('wolf-black');
			}

			&-red {
				color: get-color('wolf-red');
			}
		}

		&.text {
			&-left {
				text-align: left;
			}
			&-center {
				text-align: center;
			}
			&-right {
				text-align: right;
			}
		}
	}
}

@include wolf-a-headline();
