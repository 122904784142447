@mixin wolf-o-tabbed-content($name: '.o-tabbed-content') {
	#{$name} {
		user-select: none;

		&__wrapper {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 100px;

			@media (max-width: $breakpoint-medium) {
				display: flex;
				flex-direction: column;
			}

			.uk-switcher {
				&.content {
					width: 100%;
					max-width: 1514px;
				}
			}
		}

		.uk-switcher.full-width {
			overflow-x: hidden;
			width: 99vw;
			position: relative;
			left: 49.5%;
			right: 49.5%;
			margin-left: -49.5vw;
			margin-right: -49.5vw;
		}

		&__tab-section {
			> .a-headline {
				word-break: break-word;
				margin-bottom: 80px;
				@media (max-width: $breakpoint-small-max) {
					margin-bottom: 24px;
				}
			}

			> .a-copy {
				margin-bottom: 80px;
				max-width: 100%;

				@media (max-width: $breakpoint-small-max) {
					max-width: 100%;
					margin-bottom: 30px;
				}

				p {
					margin: 0px;
					font-size: 20px;
					@include font-inter-200();
				}
			}

			.tab-select-mob {
				width: 100%;
			}

			.select-tab {
				width: 100%;
				padding: 15px 10px;
				font-size: 20px;
				appearance: none;
				background: transparent;
				background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='40' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
				background-repeat: no-repeat;
				background-position-x: 100%;
				background-position-y: 15px;
			}

			.tab-content-container {
				display: flex;
				flex-direction: column;

				.uk-tab {
					margin-left: 0 !important;
				}

				.tabs-row {
					margin: 0 !important;
					display: flex;

					@media (max-width: $breakpoint-small-max) {
						margin-bottom: 0;
						margin-top: 45px;
					}

					.tab {
						padding: 0;
						margin: 0 15px 15px 0;
						border-radius: 100px;

						.tab-navigation {
							text-decoration: none;
							text-align: center;
							font-size: 12px;
							padding: calc(0.8rem + 5px) calc(1.3rem + 10px);
						}
					}
				}

				.tabs-row:not(.back-color) {
					.tab {
						.tab-navigation {
							border-radius: 100px;
							border: solid 2px #f7f7f7;
							background-color: get-color('true-white');
							color: get-color('dark-blue-30-white');
							cursor: pointer;
						}

						&.uk-active {
							.tab-navigation {
								border: solid 2px get-color('accent');
								background-color: get-color('accent');
								color: get-color('true-white');
							}
						}
					}
				}

				.tabs-row.back-color {
					.tab-navigation {
						outline: none;
						cursor: pointer;
						border-radius: 100px;
						transition: all 0.25s ease-out;
					}

					.tab:not(.uk-active) {
						background-color: transparent !important;

						.tab-navigation {
							border: 2px solid #f7f7f7;
							background-color: get-color('true-white');
							color: get-color('dark-blue-30-white');
						}
					}

					.tab.uk-active {
						.tab-navigation {
							border: 2px solid get-color('wolf-red');
							background-color: get-color('wolf-red');
							color: get-color('true-white');
						}
					}
				}

				.content {
					.o-text-image {
						height: initial;
					}

					/*Overriding paddings inside element to make it space correctly*/
					.uk-active > div {
						.padding-background-bottom,
						.padding-background-top,
						.padding-background-both {
							padding: 0px;
						}

						.m-text-features__wrapper .description p {
							margin-bottom: 0;
						}
					}
				}

				#{$name}__tab-nav {
					display: none;
				}
			}
		}

		&.oneRowTabs {
			.padding-standard {
				@media (max-width: $breakpoint-small-max) {
					padding-top: 24px;
					padding-bottom: 15px;
				}
			}

			.tab-wrapper {
				user-select: none;
				overflow-x: auto;
				scroll-behavior: smooth;

				&::-webkit-scrollbar {
					width: 0;
				}

				&.with-navigation {
					width: calc(100% - 116px);
				}

				.tab-wrapper-inner {
					position: relative;
					display: flex;
					flex-wrap: nowrap;

					.tab-navigation {
						background-color: transparent;
					}
				}
			}

			&.tab-circle {
				.tab-wrapper {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;

					.tab-wrapper-inner {
						width: 100%;
						display: contents;

						.tabs-row {
							.tab {
								margin: 0 15px 15px;
							}
						}
					}
				}

				.o-tabbed-content__wrapper .tab-content-container .tabs-row {
					border-bottom: 0;
				}
			}

			#{$name}__tab-nav {
				padding-top: 11px;
				top: 1px;
				background-color: get-color('true-white');
				z-index: 3;

				&::before {
					content: '';
					position: absolute;
					width: 120px;
					height: calc(100% + 11px);
					top: 0;
					bottom: 0;
					left: -120px;
					background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 60%);
				}

				&::after {
					content: '';
					position: absolute;
					width: 100%;
					height: 4px;
					left: 0;
					right: 0;
					bottom: -4px;
					background-color: get-color('true-white');
				}

				.o-tabbed-content--arrow {
					transform: translateY(-9px);
					cursor: pointer;
				}
			}

			@media (min-width: $breakpoint-small-max) {
				#{$name}__wrapper {
					display: inherit;

					.tab-content-container {
						position: relative;

						.tabs-row {
							flex-wrap: nowrap;
							//overflow: auto;
							-ms-overflow-style: none;
							scrollbar-width: none;
							border-bottom: solid 2px #eeeeee;
							align-items: flex-end;

							&::-webkit-scrollbar {
								display: none;
							}

							.tab {
								display: flex;
								align-items: flex-start;
								justify-content: center;
							}
						}
					}

					#{$name}__tab-nav {
						position: absolute;
						right: 0;
						top: 0;
						display: inline-flex;
						//background: inherit;

						@media (max-width: 1200px) {
							right: 0px;
						}

						#{$name}--arrow {
							height: 48px;
							width: 48px;
							border: 1px solid black;
							border-radius: 50%;
							cursor: pointer;
							z-index: 99;

							svg {
								padding: 17px;
								pointer-events: none;
							}

							&:first-child {
								margin-right: 12px;
							}

							&:not(.disabled):hover {
								background-color: #ea4c4c;
								border-color: #ea4c4c;
								color: get-color('true-white');
							}

							&.disabled {
								border: 1px solid get-color('silver-grey');
								pointer-events: none;

								&:hover {
									cursor: default;
								}

								svg {
									color: get-color('silver-grey');
								}
							}
						}
					}
				}
			}
		}

		&.oneRowTabs:not(.tab-circle) {
			.tab-wrapper {
				user-select: none;
				overflow-x: auto;
				scroll-behavior: smooth;

				&::-webkit-scrollbar {
					width: 0;
				}

				&.with-navigation {
					width: calc(100% - 116px);
				}

				.tab-wrapper-inner {
					position: relative;
					display: flex;
					flex-wrap: nowrap;
				}

				.jelly-bar {
					position: absolute;
					bottom: -1px;
					left: 0;
					z-index: 1;
					width: 4px;
					height: 4px;
					background-color: get-color('red-30-white');
					transition: all 0.6s ease-out; //cubic-bezier(0, .58, .36, .83);
				}
			}

			.tabs-row {
				.tab {
					border-radius: 0px;
					border-top: 0px;
					border-right: 0px;
					border-left: 0px;
					margin: 0px;
					width: fit-content;
					max-width: 300px;

					.tab-navigation {
						background-color: transparent;
						padding: 8px 50px;
						display: inline-flex;
						align-items: flex-end;
						justify-content: center;
						min-height: 60px;
						border: 0;
						width: auto;
						max-width: 260px;
						color: get-color('silver-grey');
						@include font-inter-500();
						font-size: 16px;
						line-height: 20px;
						transition: color 0.2s ease-in;
						cursor: pointer;
						border-radius: 0;
						border: 0;

						&:hover {
							color: get-color('true-black');
						}
					}

					&.uk-active {
						.tab-navigation {
							background-color: transparent;
							border: 0;
						}
					}
				}

				&:not(.back-color) .uk-active {
					background-color: transparent;

					.tab-navigation {
						color: get-color('red-30-white');
					}
				}
			}

			#{$name}__tab-nav {
				.o-tabbed-content--arrow {
					transform: translateY(5px);
				}
			}

			.uk-switcher {
				> li {
					&.uk-active {
						animation-name: tabIn;
						animation-duration: 0.35s;
						animation-timing-function: cubic-bezier(0, 0.61, 0.59, 1.01);
						animation-delay: 0.35s;
						animation-fill-mode: forwards;
						transform: translateY(-20px);
						opacity: 0;
						pointer-events: none;
					}
				}
			}
		}

		&.tab-circle {
			#{$name}__tab-section > .a-headline {
				text-align: center;
			}

			.jelly-bar {
				display: none;
			}

			@media (max-width: $breakpoint-small-max) {
				.tab-content-container {
					.content {
						max-width: 100%;
					}
				}
			}

			.tabs-row {
				width: 83%;

				@media (min-width: $breakpoint-large-max) {
					width: 90%;
				}

				@media (min-width: $breakpoint-medium-max) and (max-width: $breakpoint-large-max) {
					width: 87%;
				}

				@media (max-width: $breakpoint-small-max) {
					justify-content: flex-start;
					display: inline-flex;
					overflow-x: scroll;
					flex-wrap: nowrap;
					-ms-overflow-style: none;
					scrollbar-width: none;
					width: 100%;

					&::-webkit-scrollbar {
						display: none;
					}
				}

				.tab {
					/*Overriding normal tab stylings*/
					@media (max-width: $breakpoint-small-max) {
						margin-bottom: 0px;
					}

					.name {
						@media (max-width: $breakpoint-small-max) {
							display: inline-flex !important;
						}
					}

					.index {
						@media (max-width: $breakpoint-small-max) {
							display: none;
						}
					}
				}
			}

			.uk-switcher {
				/*Overriding normal tab stylings*/
				.a-headline__heading {
					@media (max-width: $breakpoint-small-max) {
						display: none !important;
					}
				}
			}
		}

		.m-gallery__thumbnails {
			.image-wrapper {
				position: relative;
			}
		}

		@media (max-width: $breakpoint-small-max) {
			.padding-small-bottom {
				padding-top: 14px;
				padding-bottom: 14px;
			}

			.o-text-image.padding-standard {
				padding-bottom: 0;
			}
		}
	}

	@keyframes tabIn {
		from {
			transform: translateY(-20px);
			opacity: 0;
			pointer-events: none;
		}

		to {
			transform: translateX(0);
			opacity: 1;
			pointer-events: all;
		}
	}
}

@include wolf-o-tabbed-content();
