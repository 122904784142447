@mixin wolf-m-heading($name: '.m-heading') {
	#{$name} {
		color: get-color('true-white');

		&__topline {
			margin: 0;
			color: get-color('true-white');
		}

		&__headline {
			margin-top: 30px;
			color: get-color('true-white');
		}

		&__subline {
			line-height: 32px;
			font-size: get-fontsize(m, default);
			font-weight: 500;
			color: get-color('true-white');
		}

		&__topline,
		&__headline,
		&__subline {
			&.uk-h1 {
				font-size: 80px;

				@media (max-width: $breakpoint-medium) {
					font-size: 40px;
				}
			}

			&.uk-h2 {
				font-size: 65px;

				@media (max-width: $breakpoint-medium) {
					font-size: 35px;
				}
			}

			&.uk-h3 {
				font-size: 50px;

				@media (max-width: $breakpoint-medium) {
					font-size: 30px;
				}
			}

			&.uk-h4 {
				font-size: 32px;

				@media (max-width: $breakpoint-medium) {
					font-size: 21px;
				}
			}

			&.uk-h5 {
				font-size: 28px;

				@media (max-width: $breakpoint-medium) {
					font-size: 21px;
				}
			}

			&.uk-h6 {
				font-size: 12px;
			}
		}

		&--light {
			#{$name}__topline,
			#{$name}__headline,
			#{$name}__subline {
				color: get-color('wolf-black');
			}
		}
	}
}

@include wolf-m-heading();
