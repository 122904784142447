@mixin wolf-o-pdf-print-header($name: '.o-pdf-print-header') {
	#{$name} {
		margin-top: 200px;
		margin-bottom: 150px;

		.a-wolf-logo {
			svg {
				width: 360px;
			}
		}

		&__date {
			border: 1px solid rgba(17, 21, 28, 0.2);
			border-radius: 100px;
			padding: 10px 20px;

			text-align: left;
			@include font-inter-500();
			font-size: 16px;
			color: #212d40;
			opacity: 0.6;
			height: fit-content;
			height: moz-fit-content;
		}
	}
}

@include wolf-o-pdf-print-header();
