//
// Component:       UIkit Variables
// Description:     Defines common values which are used across all uikit components
//

// Breakpoints
// ========================================================================

// Phone Portrait:   Galaxy (360x640), iPhone 6 (375x667), iPhone 6+ (414x736)
// Phone Landscape:  Galaxy (640x360), iPhone 6 (667x375), iPhone 6+ (736x414)
// Tablet Portrait:  iPad (768x1024), Galaxy Tab (800x1280),
// Tablet Landscape: iPad (1024x768), iPad Pro (1024x1366),
// Desktop:          Galaxy Tab (1280x800), iPad Pro (1366x1024)
$breakpoint-small: map-get($breakpoints, small); // Phone landscape
$breakpoint-medium: map-get($breakpoints, medium); // Tablet Landscape
$breakpoint-large: map-get($breakpoints, large); // Desktop
$breakpoint-xlarge: map-get($breakpoints, xlarge); // Large Screens

$breakpoint-xsmall-max: ($breakpoint-small - 1);
$breakpoint-small-max: ($breakpoint-medium - 1);
$breakpoint-medium-max: ($breakpoint-large - 1);
$breakpoint-large-max: ($breakpoint-xlarge - 1);

// Global variables
// ========================================================================

//
// Typography
//
$global-font-family: 'Inter';
$global-font-size: get-fontsize(m, default);
$global-line-height: 1.4;

$global-xxlarge-font-size: get-fontsize(m, xxlarge);
$global-xlarge-font-size: get-fontsize(m, xlarge);
$global-large-font-size: get-fontsize(m, large);
$global-medium-font-size: get-fontsize(m, medium);
$global-small-font-size: get-fontsize(m, small);
$global-xsmall-font-size: get-fontsize(m, xsmall);

//
// Colors
//
$global-color: get-color(text);
$global-emphasis-color: get-color(text);
$global-muted-color: get-color(secondary, -10%);

$global-link-color: get-color(primary);
$global-link-hover-color: get-color(primary, 20%);

$global-inverse-color: #fff;

//
// Backgrounds
//
$global-background: #fff;

$global-muted-background: get-color(secondary, 90%);
$global-primary-background: get-color(primary);
$global-secondary-background: get-color(secondary);

$global-success-background: get-color(success);
$global-warning-background: get-color(warning);
$global-danger-background: get-color(danger);

//
// Borders
//
$global-border-width: 1px;
$global-border: #e5e5e5;

//
// Box-Shadows
//
$global-small-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
$global-medium-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
$global-large-box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
$global-xlarge-box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16);

//
// Spacings
//

// Used in margin, section, list
$global-margin: map-get($spacings, default);
$global-xsmall-margin: map-get($spacings, xsmall);
$global-small-margin: map-get($spacings, small);
$global-medium-margin: map-get($spacings, medium);
$global-large-margin: map-get($spacings, large);
$global-xlarge-margin: map-get($spacings, xlarge);

//
// Container
//
$container-max-width: 1520px;
//$container-xsmall-max-width: 750px;
//$container-small-max-width: 900px;
//$container-large-max-width: 1400px;
$container-xlarge-max-width: 1520px;
//$container-padding-horizontal: 15px;
//$container-padding-horizontal-s: $global-gutter;
//$container-padding-horizontal-m: $global-medium-gutter;

// Used in grid, column, container, align, card, padding
$global-gutter: 16px;
$global-small-gutter: 15px;
$global-medium-gutter: 50px;
$global-large-gutter: 70px;

//
// Form Controls
//
$global-control-height: 48px;
$global-control-small-height: 40px;
$global-control-large-height: 64px;

//
// Z-index
//
$global-z-index: 1000;

//
// Alert
//
$alert-margin-vertical: 15px;
