@mixin wolf-a-datepicker($name: '.a-datepicker') {
	#{$name} {
		&__wrapper {
			margin-top: 33px;

			> label {
				font-size: 12px;
				color: #6b718b;

				&.uk-form-danger {
					~ .uk-input {
						border-color: get-color('danger');
						color: get-color('danger');
					}
				}
			}

			.input-wrapper {
				position: relative;

				svg {
					position: absolute;
					top: 0;
					right: 0;
					color: get-color('silver-grey');
					width: 24px;
					height: 24px;
					margin: 12px 13px 0 0;
					pointer-events: none;
					transition: all 0.25s ease-out;
				}
			}

			.uk-input[type='date'] {
				background-color: transparent;
				border: 1px solid #62677e;

				&::placeholder {
					@include font-inter-300;
					color: get-color('silver-grey');
					opacity: 0.2;
				}

				&::-webkit-calendar-picker-indicator {
					background-image: none;
					width: 26px;
					height: 44px;
					cursor: pointer;
				}

				&:hover,
				&:focus {
					+ svg {
						color: get-color('wolf-black');
					}
				}
			}

			#{$name}__required-input {
				color: get-color('primary');
			}
		}
	}

	// input[type="date"] {
	// 	border: 1px solid #ccc;
	// 	border-radius: 4px;
	// 	padding: 6px;
	// 	font-size: 16px;
	// 	-webkit-appearance: none;
	// 	background-color: white;
	// 	background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Cpath%20d%3D%22M2.42%204.48h7.16v-.96H2.42v.96zM6%207.3L3.76%205.07l-.67.67L6%208.73l3.91-3.91-.67-.67L6%207.3z%22%20fill%3D%22%23aaa%22%2F%3E%3C%2Fsvg%3E');
	// 	cursor: pointer;
	// }

	// /* Hide the default calendar dropdown arrow */
	// input[type="date"]::-webkit-calendar-picker-indicator {
	// 	display: none;
	// }

	/* Modify the style of the calendar when it is opened */
	// input[type="date"]:focus::before {
	// 	content: "";
	// 	display: block;
	// 	position: absolute;
	// 	top: 100%;
	// 	left: 0;
	// 	right: 0;
	// 	z-index: 999;
	// 	background-color: white;
	// 	border: 1px solid #ccc;
	// 	border-radius: 4px;
	// 	margin-top: 2px;
	// 	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	// 	padding: 6px;
	// }

	// input[type="date"]:focus::after {
	// 	content: "";
	// 	display: block;
	// 	position: absolute;
	// 	top: -10px;
	// 	left: 50%;
	// 	transform: translateX(-50%);
	// 	border-style: solid;
	// 	border-width: 0px 10px 10px 10px;
	// 	border-color: transparent transparent #ccc transparent;
	// }

	// /* Modify the style of the calendar days */
	// input[type="date"]:focus::before table tbody tr td {
	// 	padding: 16px;
	// 	text-align: center;
	// 	font-size: 16px;
	// 	color: #333;
	// 	background-color: #fff;
	// 	border-radius: 4px;
	// 	cursor: pointer;
	// 	transition: background-color 0.2s ease-in-out;
	// }

	// input[type="date"]:focus::before table tbody tr td:hover {
	// 	background-color: #eee;
	// }

	// input[type="date"]:focus::before table tbody tr td:active {
	// 	background-color: #ccc;
	// }

	// input[type="date"]:focus::before table tbody tr td:disabled {
	// 	color: #999;
	// 	cursor: not-allowed;
	// }
}

@include wolf-a-datepicker();
