@mixin wolf-o-product-info($name: '.o-product-info') {
	#{$name} {
		&__modal {
			padding: map-get($spacings, 'default');
		}

		&__product-stage {
			display: grid;
			grid-template-columns: 1fr 2fr;
			column-gap: 2rem;

			@media (max-width: $breakpoint-large) {
				display: flex;
				flex-direction: column;
			}
		}

		&__product-image-container {
			margin: 20px 0;
		}

		&__product-introduction {
			display: grid;
			row-gap: 2rem;
		}

		&__product-promo {
			.product-name {
				margin: 0;
				margin-bottom: 0px;
			}

			.sku {
				font-weight: 200;
				font-size: 10px;
			}

			.headline {
				margin: 20px 0;
			}
		}

		&__product-specification {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 2rem;

			@media (max-width: $breakpoint-large) {
				display: flex;
				flex-direction: column;
			}

			.short-description {
				font-size: $global-xsmall-font-size;
				line-height: 27px;
				font-weight: 300;
				margin-bottom: 20px;
			}

			.delivery-time {
				font-weight: bold;
				margin: 40px 0;

				span {
					width: 20px;
					color: get-color('wolf-red');
				}

				@media (max-width: $breakpoint-large) {
					margin: 1rem 0 4rem 0;
				}
			}

			.download {
				margin: 40px 0;
				font-weight: bold;
				white-space: nowrap;

				svg {
					width: 1rem;
					color: get-color('wolf-red');
				}

				a {
					text-decoration: none;
				}
			}

			.price {
				display: flex;
				align-items: center;
				justify-content: flex-end;

				p {
					margin: 0;
				}
			}
		}

		&__info-link {
			background-color: map-get($colors, 'true-white');
			position: absolute;
			width: 50px;
			height: 50px;
			line-height: 50px;
			right: 10px;
			top: 10px;
			z-index: 99;

			> a {
				width: inherit;
				height: inherit;
				display: block;
				text-decoration: none;
				color: map-get($colors, 'dark-grey');
				line-height: inherit;
				text-align: center;
				margin: 0 auto;
				padding: 0;
				user-select: none;
			}
		}

		&__close-button {
			display: flex;
			justify-content: flex-end;
			margin: 2rem 0;

			svg {
				visibility: hidden;
			}
		}
	}
}

@include wolf-o-product-info();
