@import './font-faces.scss';
@import './utils.scss';

// 1. Your custom variables and uikit variable overwrites.
@import './variables.scss';
@import './uikit/variables.scss';
@import '/workspace/FrontendBundle/Resources/integration/src/wolf/global/scss/uikit/components/base-variables.scss'; @import '/workspace/FrontendBundle/Resources/integration/src/wolf/global/scss/uikit/components/button-variables.scss'; @import '/workspace/FrontendBundle/Resources/integration/src/wolf/global/scss/uikit/components/card-variables.scss'; @import '/workspace/FrontendBundle/Resources/integration/src/wolf/global/scss/uikit/components/grid-variables.scss'; @import '/workspace/FrontendBundle/Resources/integration/src/wolf/global/scss/uikit/components/nav-variables.scss'; @import '/workspace/FrontendBundle/Resources/integration/src/wolf/global/scss/uikit/components/navbar-variables.scss'; @import '/workspace/FrontendBundle/Resources/integration/src/wolf/global/scss/uikit/components/section-variables.scss'; @import '/workspace/FrontendBundle/Resources/integration/src/wolf/global/scss/uikit/components/text-variables.scss';

// 2. Import default variables and available mixins.
@import '~/uikit/src/scss/variables.scss';
@import '~/uikit/src/scss/mixins.scss';

// 3. Your custom mixin overwrites.
@import '/workspace/FrontendBundle/Resources/integration/src/wolf/global/scss/uikit/components/base-mixins.scss'; @import '/workspace/FrontendBundle/Resources/integration/src/wolf/global/scss/uikit/components/button-mixins.scss'; @import '/workspace/FrontendBundle/Resources/integration/src/wolf/global/scss/uikit/components/card-mixins.scss'; @import '/workspace/FrontendBundle/Resources/integration/src/wolf/global/scss/uikit/components/nav-mixins.scss'; @import '/workspace/FrontendBundle/Resources/integration/src/wolf/global/scss/uikit/components/section-mixins.scss'; @import '/workspace/FrontendBundle/Resources/integration/src/wolf/global/scss/uikit/components/text-mixins.scss';

// 4. Import UIkit.
@import './uikit/imports.scss';

// 5. Base App Styles (for this project)
@import '@brand/global/scss/wolf';

// 6. Custom styles for atoms, molecules, organisms and bricks

// REQUIRED Atoms
@import '@brand/components/atoms/a-wolf-logo/a-wolf-logo.styles.scss';
@import '@brand/components/atoms/a-navlink/a-navlink.styles.scss';
@import '@brand/components/atoms/a-headline/a-headline.styles.scss';
@import '@brand/components/atoms/a-copy/a-copy.styles.scss';
@import '@brand/components/atoms/a-image/a-image.styles.scss';
@import '@brand/components/atoms/a-button/a-button.styles.scss';
@import '@brand/components/atoms/a-fab-button/a-fab-button.styles.scss';
@import '@brand/components/atoms/a-copyright/a-copyright.styles.scss';

// START WOLF INDEX
// // Header
@import '@brand/components/organisms/o-nav/o-nav.styles.scss';
@import '@brand/components/molecules/m-nav-bar/m-nav-bar.styles.scss';
@import '@brand/components/molecules/m-navigation-switcher/m-navigation-switcher.styles.scss';
// // Main
@import '@brand/components/molecules/m-heading/m-heading.styles.scss';
@import '@brand/components/molecules/m-ahu-stage/m-ahu-stage.styles.scss';
@import '@brand/components/organisms/o-tabbed-content/o-tabbed-content.styles';
@import '@brand/components/organisms/o-cta/o-cta.styles.scss';
@import '@brand/components/organisms/o-adviser/o-adviser.styles.scss';
@import '@brand/components/molecules/m-search-field/m-search-field.styles.scss';
@import '@brand/components/organisms/o-fab-menu/o-fab-menu.styles.scss';
// // Footer
@import '@brand/components/molecules/m-ahu-footer/m-ahu-footer.styles.scss';
@import '@brand/components/molecules/m-footnotes/m-footnotes.styles.scss';
// END WOLF INDEX

// START /BERATUNG
@import '@brand/components/organisms/o-finder/o-finder.styles.scss';
@import '@brand/components/organisms/o-finder-select-step-entrypoint/o-finder-select-step-entrypoint.styles.scss';
@import '@brand/components/molecules/m-finder-stage/m-finder-stage.styles.scss';
@import '@brand/components/organisms/o-teaser-grid/o-teaser-grid.styles.scss';
@import '@brand/components/organisms/o-teaser-grid-boxed/o-teaser-grid-boxed.styles.scss';
@import '@brand/components/organisms/o-accordion/o-accordion.styles';
// END /BERATUNG

// START /PRODUKTE
@import '@brand/components/molecules/m-ahu-hero/m-ahu-hero.styles.scss';
// END /PRODUKTE

// START /EXPERTE FINDEN
@import '@brand/components/organisms/o-text/o-text.styles';
@import '@brand/components/organisms/o-fhw-search/o-fhw-search.styles.scss';
// END /EXPERTE FINDEN

// START /PROFESSIONAL
@import '@brand/components/organisms/o-default-stage/o-default-stage.styles.scss';
@import '@brand/components/organisms/o-text-image-scrollable/o-text-image-scrollable.styles';
// END /PROFESSIONAL

// Atoms =====================================================================
@import '@brand/components/atoms/a-socialmedia/a-socialmedia.styles.scss';
@import '@brand/components/atoms/a-side-badge/a-side-badge.styles.scss';
@import '@brand/components/atoms/a-linklist/a-linklist.styles.scss';
@import '@brand/components/atoms/a-quantity-selector/a-quantity-selector.styles.scss';
@import '@brand/components/atoms/a-energy-badge/a-energy-badge.styles.scss';
@import '@brand/components/atoms/a-price/a-price.styles.scss';
@import '@brand/components/atoms/a-play-button/a-play-button.styles.scss';
@import '@brand/components/atoms/a-tag/a-tag.styles.scss';
@import '@brand/components/atoms/a-input/a-input.styles.scss';
@import '@brand/components/atoms/a-select/a-select.styles.scss';
@import '@brand/components/atoms/a-checkbox/a-checkbox.styles.scss';
@import '@brand/components/atoms/a-datepicker/a-datepicker.styles.scss';
@import '@brand/components/atoms/a-filter-btn/a-filter-btn.styles.scss';

// // Molecules =============================================================
@import '@brand/components/molecules/m-ahu-navbar/m-ahu-navbar.styles.scss';
@import '@brand/components/molecules/m-teaser-list-item/m-teaser-list-item.styles.scss';
@import '@brand/components/molecules/m-accordion-content/m-accordion-content.styles.scss';
@import '@brand/components/molecules/m-kb-footer/m-kb-footer.styles.scss';
@import '@brand/components/molecules/m-article-header/m-article-header.styles.scss';
@import '@brand/components/molecules/m-article-extra-info/m-article-extra-info.styles.scss';
@import '@brand/components/molecules/m-article-index-nav/m-article-index-nav.styles.scss';
@import '@brand/components/molecules/m-pdf-list-header/m-pdf-list-header.styles.scss';
@import '@brand/components/molecules/m-pdf-list-item/m-pdf-list-item.styles.scss';
@import '@brand/components/molecules/m-pdf-list-toolbar/m-pdf-list-toolbar.styles.scss';
@import '@brand/components/molecules/m-pagination/m-pagination.styles.scss';
@import '@brand/components/molecules/m-searchfilter/m-searchfilter.styles.scss';
@import '@brand/components/molecules/m-typeahead-results/m-typeahead-results.styles.scss';
@import '@brand/components/molecules/m-cardimage/m-cardimage.styles.scss';
@import '@brand/components/molecules/m-gallery/m-gallery.styles.scss';
@import '@brand/components/molecules/m-breadcrumb/m-breadcrumb.styles.scss';
@import '@brand/components/molecules/m-breadcrumb-dynamic/m-breadcrumb-dynamic.styles.scss';
@import '@brand/components/molecules/m-teaser-product/m-teaser-product.styles.scss';
@import '@brand/components/molecules/m-product-price/m-product-price.styles.scss';
@import '@brand/components/molecules/m-product-image/m-product-image.styles.scss';
@import '@brand/components/molecules/m-product-attributes/m-product-attributes.styles.scss';
@import '@brand/components/molecules/m-text-features/m-text-features.styles.scss';
@import '@brand/components/molecules/m-step-navigation/m-step-navigation.styles.scss';
@import '@brand/components/molecules/m-shop-footer/m-shop-footer.styles.scss';
@import '@brand/components/molecules/m-teaser-rounded/m-teaser-rounded.styles.scss';
@import '@brand/components/molecules/m-shopping-cart-item/m-shopping-cart-item.styles';
@import '@brand/components/molecules/m-teaser-default/m-teaser-default.styles';
@import '@brand/components/molecules/m-energy-info/m-energy-info.styles.scss';
@import '@brand/components/molecules/m-ahu-request/m-ahu-request.styles.scss';
@import '@brand/components/molecules/m-flipcard/m-flipcard.styles.scss';
@import '@brand/components/molecules/m-adviser-content/m-adviser-content.styles.scss';
@import '@brand/components/molecules/m-icon-link-suggestion/m-icon-link-suggestion.styles.scss';
@import '@brand/components/molecules/m-teaser-post/m-teaser-post.styles.scss';
@import '@brand/components/molecules/m-input-search-fat/m-input-search-fat.styles.scss';
@import '@brand/components/molecules/m-category-toggle-selector/m-category-toggle-selector.styles.scss';
@import '@brand/components/molecules/m-download/m-download.styles.scss';
@import '@brand/components/molecules/m-cb-checklist/m-cb-checklist.styles.scss';
@import '@brand/components/molecules/m-teaser-boxed/m-teaser-boxed.styles.scss';
@import '@brand/components/molecules/m-table-contents/m-table-contents.styles.scss';
@import '@brand/components/molecules/m-teaser-square-icon/m-teaser-square-icon.styles.scss';
@import '@brand/components/molecules/m-teaser-bg/m-teaser-bg.styles.scss';
@import '@brand/components/molecules/m-teaser-bg-portrait/m-teaser-bg-portrait.styles.scss';
@import '@brand/components/molecules/m-nav-ad/m-nav-ad.styles.scss';
@import '@brand/components/molecules/m-login-form/m-login-form.styles.scss';
@import '@brand/components/molecules/m-nav-mobile-menu/m-nav-mobile-menu.styles.scss';
@import '@brand/components/molecules/m-rounded-teaser/m-rounded-teaser.styles.scss';
@import '@brand/components/molecules/m-nav-language-switcher/m-nav-language-switcher.styles.scss';
@import '@brand/components/molecules/m-nav-offcanvas/m-nav-offcanvas.styles.scss';
@import '@brand/components/molecules/m-nav-burger-menu/m-nav-burger-menu.styles.scss';
@import '@brand/components/molecules/m-nav-flyout/m-nav-flyout.styles.scss';
@import '@brand/components/molecules/m-formstep-select-image/m-formstep-select-image.styles.scss';
@import '@brand/components/molecules/m-formstep-select-icon/m-formstep-select-icon.styles.scss';
@import '@brand/components/molecules/m-stage-fullscreen/m-stage-fullscreen.styles.scss';
@import '@brand/components/molecules/m-formstep-personal-data/m-formstep-personal-data.styles.scss';
@import '@brand/components/molecules/m-finder-result-teasers/m-finder-result-teasers.styles.scss';
@import '@brand/components/molecules/m-finder-result-project/m-finder-result-project.styles.scss';
@import '@brand/components/molecules/m-finder-contact/m-finder-contact.styles.scss';
@import '@brand/components/molecules/m-finder-pdf-footer/m-finder-pdf-footer.styles.scss';
@import '@brand/components/molecules/m-finder-results-input/m-finder-results-input.styles.scss';
@import '@brand/components/molecules/m-finder-popup-form/m-finder-popup-form.styles.scss';
@import '@brand/components/molecules/m-finder-select-step/m-finder-select-step.styles.scss';
@import '@brand/components/molecules/m-finder-select-step-static/m-finder-select-step-static.styles.scss';
@import '@brand/components/molecules/m-product-family/m-product-family.styles.scss';
@import '@brand/components/molecules/m-finder-content/m-finder-content.styles.scss';
@import '@brand/components/molecules/m-finder-result-reference-mobile/m-finder-result-reference-mobile.styles.scss';
@import '@brand/components/molecules/m-hero-contact-selector/m-hero-contact-selector.styles.scss';
@import '@brand/components/molecules/m-professional-product-table-desktop/m-professional-product-table-desktop.styles.scss';
@import '@brand/components/molecules/m-professional-product-table-mobile/m-professional-product-table-mobile.styles.scss';
@import '@brand/components/molecules/m-professional-product-benefits/m-professional-product-benefits.styles.scss';
@import '@brand/components/molecules/m-professional-anchor-navigation/m-professional-anchor-navigation.styles.scss';
@import '@brand/components/molecules/m-product-gallery/m-product-gallery.styles.scss';
@import '@brand/components/molecules/m-popup-interview/m-popup-interview.styles.scss';
@import '@brand/components/molecules/m-formstep-warranty-wolf-products/m-formstep-warranty-wolf-products.styles.scss';
@import '@brand/components/molecules/m-formstep-system-component/m-formstep-system-component.styles.scss';
@import '@brand/components/molecules/m-formstep-resume-slice/m-formstep-resume-slice.styles.scss';
@import '@brand/components/molecules/m-formstep-main-part-component/m-formstep-main-part-component.styles.scss';
@import '@brand/components/molecules/m-fhw-profile-services-list/m-fhw-profile-services-list.styles.scss';
@import '@brand/components/molecules/m-fhw-profile-contact/m-fhw-profile-contact.styles.scss';
@import '@brand/components/molecules/m-fhw-profile-header/m-fhw-profile-header.styles.scss';
@import '@brand/components/molecules/m-fhw-contact-form/m-fhw-contact-form.styles.scss';
@import '@brand/components/molecules/m-fhw-profile-jump-to-section/m-fhw-profile-jump-to-section.styles.scss';
@import '@brand/components/molecules/m-fhw-profile-about/m-fhw-profile-about.styles.scss';
@import '@brand/components/molecules/m-fhw-profile-sliders/m-fhw-profile-sliders.styles.scss';
@import '@brand/components/molecules/m-fhw-stage/m-fhw-stage.styles.scss';
@import '@brand/components/molecules/m-teaser-fhw/m-teaser-fhw.styles.scss';
@import '@brand/components/molecules/m-video-overlay/m-video-overlay.styles.scss';
@import '@brand/components/molecules/m-application-step/m-application-step.styles.scss';
@import '@brand/components/molecules/m-contact/m-contact.styles.scss';
@import '@brand/components/molecules/m-job-ad/m-job-ad.styles.scss';
@import '@brand/components/molecules/m-job-offer-item/m-job-offer-item.styles.scss';
@import '@brand/components/molecules/m-job-offer-area/m-job-offer-area.styles.scss';
@import '@brand/components/molecules/m-job-offer-area-content/m-job-offer-area-content.styles.scss';
@import '@brand/components/molecules/m-job-offer-search/m-job-offer-search.styles.scss';
@import '@brand/components/molecules/m-product-gallery-details/m-product-gallery-details.styles.scss';
@import '@brand/components/molecules/m-teaser-with-modal/m-teaser-with-modal.styles.scss';
@import '@brand/components/molecules/m-masonry/m-masonry.styles.scss';
@import '@brand/components/molecules/m-marker-image/m-marker-image.styles.scss';
@import '@brand/components/molecules/m-fab-menu-list/m-fab-menu-list.styles.scss';
@import '@brand/components/molecules/m-fab-menu-header/m-fab-menu-header.styles.scss';
@import '@brand/components/molecules/m-fab-drawer/m-fab-drawer.styles.scss';
@import '@brand/components/molecules/m-formstep-add-system-component/m-formstep-add-system-component.styles.scss';
@import '@brand/components/molecules/m-formstep-step-title/m-formstep-step-title.styles.scss';
@import '@brand/components/molecules/m-formstep-warranty-step-2/m-formstep-warranty-step-2.styles.scss';
@import '@brand/components/molecules/m-formstep-warranty-step-3/m-formstep-warranty-step-3.styles.scss';
@import '@brand/components/molecules/m-formstep-resume-step/m-formstep-resume-step.styles.scss';
@import '@brand/components/molecules/m-form/m-form.styles.scss';
@import '@brand/components/molecules/m-search-flyout/m-search-flyout.styles.scss';
@import '@brand/components/molecules/m-input-search-debounce/m-input-search-debounce.styles.scss';
@import '@brand/components/molecules/m-search-autosuggestion-hits/m-search-autosuggestion-hits.styles.scss';
@import '@brand/components/molecules/m-search-mobile/m-search-mobile.styles.scss';
@import '@brand/components/molecules/m-footnotes/m-footnotes.styles.scss';
@import '@brand/components/molecules/m-redirect-modal/m-redirect-modal.styles.scss';
@import '@brand/components/molecules/m-kb-navbar/m-kb-navbar.styles.scss';
@import '@brand/components/molecules/m-shop-navbar/m-shop-navbar.styles.scss';

// # MEdiacenter
@import '@brand/components/molecules/m-card/m-card.styles.scss';
@import '@brand/components/molecules/m-headline-stage/m-headline-stage.styles.scss';
@import '@brand/components/molecules/m-press-headline/m-press-headline.styles.scss';
@import '@brand/components/molecules/m-button-group/m-button-group.styles.scss';
@import '@brand/components/molecules/m-image-gallery/m-image-gallery.styles.scss';
@import '@brand/components/molecules/m-date-range/m-date-range.styles.scss';
@import '@brand/components/molecules/m-filter-accordion/m-filter-accordion.styles.scss';

// // Organisms
@import '@brand/components/organisms/o-teaser-list/o-teaser-list.styles.scss';
@import '@brand/components/organisms/o-pdf-list/o-pdf-list.styles.scss';
@import '@brand/components/organisms/o-search-stage/o-search-stage.styles.scss';
@import '@brand/components/organisms/o-pdf-search-header/o-pdf-search-header.styles.scss';
@import '@brand/components/organisms/o-article/o-article.styles.scss';
@import '@brand/components/organisms/o-kb-startpage-stage/o-kb-startpage-stage.styles.scss';
@import '@brand/components/organisms/o-secondary-navigation/o-secondary-navigation.styles.scss';
@import '@brand/components/organisms/o-product-list/o-product-list.styles.scss';
@import '@brand/components/organisms/o-product-details/o-product-details.styles.scss';
@import '@brand/components/organisms/o-teaser-rounded/o-teaser-rounded.styles.scss';
@import '@brand/components/organisms/o-shopping-cart/o-shopping-cart.styles';
@import '@brand/components/organisms/o-grid-container/o-grid-container.styles.scss';
@import '@brand/components/organisms/o-product-description/o-product-description.styles.scss';
@import '@brand/components/organisms/o-product-info/o-product-info.styles.scss';
@import '@brand/components/organisms/o-text-media/o-text-media.styles.scss';
@import '@brand/components/organisms/o-shop-filter-stage/o-shop-filter-stage.styles.scss';
@import '@brand/components/organisms/o-checkout-summary/o-checkout-summary.styles.scss';
@import '@brand/components/organisms/o-checkout-start/o-checkout-start.styles';
@import '@brand/components/organisms/o-cart-summary/o-cart-summary.styles';
@import '@brand/components/organisms/o-teaser-row/o-teaser-row.styles';
@import '@brand/components/organisms/o-text-image/o-text-image.styles';
@import '@brand/components/organisms/o-flipcard/o-flipcard.styles';
@import '@brand/components/organisms/o-modal/o-modal.styles';
@import '@brand/components/organisms/o-video/o-video.styles.scss';
@import '@brand/components/organisms/o-downloads/o-downloads.styles.scss';
@import '@brand/components/organisms/o-checklist/o-checklist.styles.scss';
@import '@brand/components/organisms/o-two-column-text/o-two-column-text.styles.scss';
@import '@brand/components/organisms/o-usp/o-usp.styles.scss';
@import '@brand/components/organisms/o-product-gallery-360/o-product-gallery-360.styles.scss';
@import '@brand/components/organisms/o-teaser-simple-scroll/o-teaser-simple-scroll.styles.scss';
@import '@brand/components/organisms/o-fhw-partner-profile/o-fhw-partner-profile.styles.scss';
@import '@brand/components/organisms/o-fhw-request/o-fhw-request.styles.scss';
@import '@brand/components/organisms/o-pdf-print-header/o-pdf-print-header.styles.scss';
@import '@brand/components/organisms/o-professional-product-table/o-professional-product-table.styles.scss';
@import '@brand/components/organisms/o-teaser-single-row-staggered/o-teaser-single-row-staggered.styles.scss';
@import '@brand/components/organisms/o-application-steps/o-application-steps.styles.scss';
@import '@brand/components/organisms/o-application-process/o-application-process.styles';
@import '@brand/components/organisms/o-form-stepper/o-form-stepper.styles';
@import '@brand/components/organisms/o-job-offers/o-job-offers.styles.scss';
@import '@brand/components/organisms/o-fab-menu/o-fab-menu.styles.scss';
@import '@brand/components/organisms/o-image/o-image.styles.scss';
@import '@brand/components/organisms/o-search-results/o-search-results.styles.scss';
@import '@brand/components/organisms/o-heating-form/o-heating-form.styles.scss';
@import '@brand/components/organisms/o-service-order-booking-form/o-service-order-booking-form.styles.scss';
@import '@brand/components/organisms/o-mediacenter-list/o-mediacenter-list.styles.scss';

// # MEdiacenter ======================================================
@import '@brand/components/organisms/o-download-list/o-download-list.styles.scss';

// Templates ==========================================================
@import '@brand/components/templates/t-default/t-default.styles.scss';
@import '@brand/components/templates/t-form-stepper/t-form-stepper.styles.scss';
@import '@brand/components/templates/t-nav-content/t-nav-content.styles.scss';
@import '@brand/components/pages/p-search-results/p-search-results.styles.scss';
@import '@brand/components/pages/p-standards/p-standards.styles.scss';
@import '@brand/components/pages/p-forms/p-forms.styles.scss';
@import '@brand/components/pages/p-knowledgebase/p-knowledgebase.styles.scss';
@import '@brand/components/pages/p-shop-startpage/p-shop-startpage.styles';
@import '@brand/components/pages/p-endcustomer-navigation/p-endcustomer-navigation.styles';

// Globals ============================================================
:root {
	--current-breakpoint: null;

	a {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	@media (min-width: $breakpoint-small) {
		--current-breakpoint: #{$breakpoint-small};
	}

	@media (min-width: $breakpoint-medium) {
		--current-breakpoint: #{$breakpoint-medium};
	}

	@media (min-width: $breakpoint-large) {
		--current-breakpoint: #{$breakpoint-large};
	}

	@media (min-width: $breakpoint-xlarge) {
		--current-breakpoint: #{$breakpoint-xlarge};
	}
}

// General overwrites ================================================
@import '@brand/global/scss/wolf/partials/_overwrites.scss';

// Financing form from external partner overwrites ===================
@import '@global/scss/financing-overwrites.scss';
