@mixin wolf-m-job-offer-item($name: '.m-job-offer-item') {
	#{$name} {
		&__info-wrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;

			@media (max-width: $breakpoint-xsmall-max) {
				flex-direction: column;
				align-items: flex-start;
			}

			#{$name}__info {
				& > a {
					text-decoration: none;
				}

				.a-headline {
					margin: 20px 0;

					@media (max-width: $breakpoint-xsmall-max) {
						margin-top: 20px;
					}
				}

				#{$name}__location {
					margin: 20px 0;
					line-height: 32px;

					& strong {
						color: get-color('dark-grey');
						margin-right: 10px;
					}

					& span {
						color: get-color('silver-grey');
					}

					svg {
						width: 32px;
						min-width: 32px;
					}
				}
			}

			#{$name}__link {
				& .a-button {
					border-radius: 100px;
					border-width: 2px;
					padding: 11px 21px;

					&__text {
						white-space: nowrap;
					}
				}
			}
		}

		&__divider {
			margin: 0;
			border-color: #707070;
		}
	}
}

@include wolf-m-job-offer-item();
