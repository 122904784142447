@mixin energy-color($color: rgb(0, 0, 0)) {
	background-color: $color;

	&::after {
		border-color: transparent transparent transparent $color;
	}
}

@mixin wolf-a-energy-badge($name: '.a-energy-badge') {
	#{$name} {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		padding-right: 14px;

		.a-badge {
			min-width: calc(100% - 24px);
			height: 28px;
			margin: 0px;
			color: get-color(true-white);
			padding-left: 6px;
			margin-bottom: 2px;
			font-size: 20px;
			position: relative;

			&::after {
				content: '';
				position: absolute;
				right: -12px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 14px 0 14px 12px;
			}

			sup {
				margin-left: -3px;
			}
		}

		&__icon-label {
			height: 20px;
			min-width: 20px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			transform: translateY(3px);
		}

		&__Appp {
			@include energy-color(rgb(0, 144, 54));
		}

		&__App {
			@include energy-color(rgb(7, 171, 39));
		}

		&__Ap {
			@include energy-color(rgb(201, 210, 0));
		}

		&__A {
			@include energy-color(rgb(255, 237, 0));
		}

		&__B {
			@include energy-color(rgb(250, 187, 0));
		}

		&__C {
			@include energy-color(rgb(235, 105, 11));
		}

		&__D,
		&__E,
		&__F,
		&__G {
			@include energy-color(rgb(226, 0, 26));
		}
	}
}

@include wolf-a-energy-badge();
