@mixin wolf-m-formstep-resume-step($name: '.m-formstep-resume-step') {
	#{$name} {
		overflow: auto !important;
		@media (min-width: $breakpoint-medium) {
			overflow: auto;
		}
	}
}

@include wolf-m-formstep-resume-step();
