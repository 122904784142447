@mixin wolf-o-application-process($name: '.o-application-process') {
	#{$name} {
		&__headline {
			display: flex;
			justify-content: center;
			margin-bottom: 215px;

			@media (max-width: $breakpoint-small-max) {
				justify-content: flex-start;
				margin-bottom: 90px;
			}
		}
	}
}

@include wolf-o-application-process();
