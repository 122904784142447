@mixin wolf-m-fhw-profile-jump-to-section($name: '.m-fhw-profile-jump-to-section') {
	#{$name} {
		.fhw-jump-to-item {
			background-color: transparent;
			color: get-color('text');
			opacity: 0.5;
			padding: 0;
			margin: 0 50px 0 0;

			cursor: pointer;

			border: unset;
			font-size: 16px;

			&:hover,
			&:focus {
				opacity: 1;
			}
		}

		hr {
			margin-top: 25px;
		}
	}
}

@include wolf-m-fhw-profile-jump-to-section();
