@mixin wolf-m-text-features($name: '.m-text-features') {
	#{$name} {
		&__wrapper {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 20px;

			@media (max-width: $breakpoint-medium) {
				grid-template-columns: 1fr;
			}

			.description {
				p {
					line-height: 28px;
					font-weight: map-get($fontweights, 'light');
				}
			}

			.attributes {
				> div {
					display: flex;
					justify-content: space-between;

					&:not(:last-child) {
						border-bottom: solid 1.5px get-color('light-grey');
					}
				}

				span {
					font-size: $global-xsmall-font-size;
					line-height: 2em;
					font-weight: map-get($fontweights, 'light');

					&:first-child {
						font-weight: 600;
					}
				}
			}
		}
	}
}

@include wolf-m-text-features();
