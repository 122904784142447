@mixin wolf-m-teaser-post($name: '.m-teaser-post') {
	#{$name} {
		background-color: #f8f8f8;
		padding: 32px 40px;

		@media (max-width: $breakpoint-small-max) {
			padding: 0;
		}
		a {
			text-decoration: none;
			cursor: pointer;
		}

		p {
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.breadcrumbs {
			@include font-inter-400();
			font-size: 16px;
			line-height: 26px;
			letter-spacing: -1px;
			color: #646464;
			margin-bottom: 25px;
		}

		.breadcrumbs-content {
			@media (max-width: $breakpoint-small-max) {
				order: 2;
				padding: 0px 20px 0 40px;
			}
		}

		.text-content {
			margin: 0px;
			flex-direction: column;

			@media (max-width: $breakpoint-small-max) {
				order: 3;
				padding: 0px 20px 0px 40px;
			}
		}

		.image-content {
			justify-content: flex-end;
			align-items: flex-start;
			margin-top: 0;
			@media (max-width: $breakpoint-small-max) {
				order: 1;
			}
		}

		.image-wrapper {
			position: relative;
		}

		.headline {
			@include font-inter-600();
			font-size: 20px;
			line-height: 26px;
			color: get-color('true-black');
			margin-bottom: 15px;
		}

		.text {
			color: #646464;
			font-size: 16px;
			line-height: 26px;
			letter-spacing: -1px;
			@media (max-width: $breakpoint-xsmall-max) {
				font-size: 13px;
			}
		}

		.tags {
			display: inline-flex;

			.a-tag {
				margin: 0 6px 0 0;
			}

			@media (max-width: $breakpoint-small-max) {
				padding: 20px;
				margin-top: -15px;
			}
		}
		.right-image {
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;

			@media (max-width: $breakpoint-small-max) {
				width: 100%;
			}

			img {
				border-radius: 5px;
				max-height: 140px;
				width: auto;

				@media (max-width: $breakpoint-small-max) {
					border-radius: unset;
					max-height: 100%;
					width: 100%;
				}
			}
		}
	}
}

@include wolf-m-teaser-post();
