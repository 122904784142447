@mixin line-clamp($lines: '3') {
	-webkit-line-clamp: $lines;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

// EXAMPLE
// HTML:
// <div class="o-foo o-foo--dark">
//     <div class="o-foo__bar"></div>
// </div>

// Component SCSS:
// @mixin wolf-o-foo($name: '.o-foo') {
//     #{$name} {

//         color: black;

//         // example themes
//         &--dark {

//             #{$name}__bar {
//                 color: white;
//             }
//         }
//         // ###########

//         // above is the same as...

//         @include element-modifier('&--dark') {
//             &__bar {
//                 color: white;
//             }

//         }
//     }
// }
@mixin element-modifier($selector) {
	@at-root #{$selector} #{&} {
		@content;
	}
}

// EXAMPLE
// HTML:
// <div class="o-bar o-bar--dark o-bar--small">
//     <div>Some text</div>
// </div>
// Component SCSS:
// @mixin wolf-o-foo($name: '.o-bar') {
//     #{$name} {
//         @include element-modifier-self('&--dark') {
//             // targets .o-bar.o-bar--dark
//             color: get-color(true-white);

//             &--small {
//                 // targets .o-bar.o-bar--dark.o-bar--small
//                 color: get-color(wolf-black);

//             }
//         }
//     }
// }
@mixin element-modifier-self($selector) {
	@at-root #{$selector}#{&} {
		@content;
	}
}

/** A mixin to style all <input type="submit"> buttons on forms */
@mixin wolf-submit-button() {
	@include font-inter-400();
	font-size: get-fontsize(m, default);
	line-height: normal;
	border: 0;
	padding: 12px 36px;
	text-align: center;
	margin: 0;
	background-color: get-color(wolf-red);
	color: get-color(true-white);
	outline: none;

	@media (max-width: $breakpoint-small-max) {
		padding-left: 10px;
		padding-right: 10px;
		font-size: get-fontsize(s, default);
	}

	&:hover {
		background-color: get-color(red-hover);
		color: #fff;
		border-color: #fff;
		text-decoration: none;
		cursor: pointer;
	}
}
// apply global style to submit buttons
form {
	input[type='submit'] {
		@include wolf-submit-button();
	}
}

// ASPECT RATIO MIXIN
// USAGE
// &__image {
// 	@include aspect-ratio(16, 9);
//	...
// 	& > img {
// 		opacity: 1 !important;
// 	}
// }

/**
 * Maintain aspect-ratio mixin - example: @include aspect-ratio(16, 9)
 *
 * @param   {boolean}  $width
 * @param   {boolean}  $height
 */
@mixin aspect-ratio($width, $height) {
	position: relative;
	overflow: hidden;

	&:before {
		display: block;
		content: '';
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}

	> * {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}
