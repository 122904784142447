@mixin wolf-m-date-range($name: '.m-date-range') {
	#{$name} {
		display: flex;
		flex-direction: column;

		> label {
			&:not(.uk-form-label.checkbox) {
				font-size: 12px;
				color: #6b718b;
			}

			&.uk-form-label.checkbox {
				display: flex;
				gap: 12px;
				margin-top: 12px;
				margin-bottom: 8px;

				+ div > .uk-form-label {
					font-size: 12px;
					color: #6b718b;
					margin-bottom: 6px;
					display: inline-flex;
				}

				[type='checkbox'] {
					display: none;

					+ .ico {
						min-width: 36px;
						width: 36px;
						height: 36px;
						border-radius: 4px;
						margin-top: 0;
						cursor: pointer;
						position: relative;

						&::before,
						&::after {
							content: '';
							background-color: get-color(true-white);
							height: 4px;
							position: absolute;
							transform-origin: 0 0;
							border-radius: 0;

							@include min-lg {
								height: 3px;
							}
						}

						&::before {
							width: 0;
							transform: rotate(45deg);
							top: 15px;
							left: 9px;

							@include min-lg {
								top: 10px;
								left: 7px;
							}
						}

						&::after {
							width: 0;
							transform: rotate(-45deg);
							top: 24px;
							left: 12px;

							@include min-lg {
								top: 14px;
								left: 9px;
							}
						}

						@include min-lg {
							min-width: 24px;
							width: 24px;
							height: 24px;
						}

						+ .text {
							display: inline-flex;
							flex-grow: 1;
							padding-top: 6px;
							user-select: none;

							@include min-lg {
								padding-top: 0;
							}
						}
					}

					&:checked {
						+ .ico {
							background-color: get-color(wolf-red);

							&::before,
							&::after {
								transition: all 0.2s ease-out;
							}

							&::before {
								width: 12px;

								@include min-lg {
									width: 7px;
								}
							}

							&::after {
								width: 22px;
								transition-delay: 0.2s;

								@include min-lg {
									width: 11px;
								}
							}
						}
					}
				}
			}
		}

		&-group {
			display: flex;
			align-items: flex-end;
			border: 1px solid #62677e;

			.a-datepicker__wrapper {
				position: relative;
				margin-top: 0;

				.uk-input[type='date'] {
					border-color: transparent;
				}

				+ .a-datepicker__wrapper {
					&::before {
						content: '';
						width: 1px;
						height: 24px;
						background-color: get-color('silver-grey');
						position: absolute;
						z-index: 1;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
						pointer-events: none;
					}
				}
			}
		}

		.date-range-wrapper {
			display: flex;
			flex-direction: column;
			transition: all 0.2s ease-out;

			&[data-show-dates='false'] {
				opacity: 0;
				pointer-events: none;
				margin-top: 10px;
			}

			&[data-show-dates='true'] {
				opacity: 1;
				margin-top: 0;
				pointer-events: all;
			}
		}
	}
}

@include wolf-m-date-range();
