@mixin wolf-m-article-header($name: '.m-article-header') {
	#{$name} {
		width: 100%;
		overflow: hidden;

		@media (min-width: 1935px) {
			width: 100vw;
			position: relative;
			left: 0;
			right: 0;
			margin: 0 auto;
			overflow: hidden;
		}

		&__header-wrapper {
			background-color: map-get($colors, 'light-grey');
			width: 100%;
			max-width: 1920px;

			@media (max-width: $breakpoint-medium) {
				background-color: transparent;
				margin-top: 0;
			}
		}

		&__header-grid {
			display: flex;
			justify-content: space-between;
			@media (max-width: $breakpoint-medium) {
				flex-direction: column-reverse;
				&.uk-container {
					padding-left: 0;
					padding-right: 0;
				}
			}
			.m-cardimage,
			.m-cardimage__column-text-wrapper {
				width: inherit;
			}
		}

		&__wrapper {
			padding: 3rem 0;
			display: flex;
			flex-direction: column;
			@media (max-width: $breakpoint-medium) {
				margin: 0;
				padding: 0;
				&.downloads {
					flex-direction: column;
					width: 100%;
					display: inline-flex;
				}
			}

			.text,
			.download-link,
			.download-link:hover {
				font-size: get-fontsize(m, default);
				color: map-get($colors, 'wolf-black');
				padding-top: 5px;
				margin: 0px;
				display: inline-block;
			}
			.download-link {
				font-weight: bold;
				text-decoration: none;
				@media (max-width: $breakpoint-small-max) {
					display: inline-block;
					margin: 0 auto 0 0;
					padding-left: 2rem;
				}

				&::after {
					content: '';
					height: 2px;
					background: get-color(wolf-red);
					display: block;
					margin-top: 6px;
					margin-right: 5px;
				}
			}
		}

		.text {
			@media (max-width: $breakpoint-medium) {
				margin: 0;
				padding-left: 2rem;
			}
		}

		.uk-container.uk-container-xlarge.m-article-header__header-grid {
			@media (max-width: $breakpoint-large) and (min-width: $breakpoint-medium) {
				padding-left: 7%;
			}
		}

		.m-cardimage {
			padding: 3rem 0;
			margin-left: 14px;
			@media (max-width: $breakpoint-large) {
				margin-left: 28px;
			}
			@media (max-width: $breakpoint-medium) {
				margin-left: 0;
				margin-top: 20px;
				align-items: flex-start;
				padding: 1rem 0 1rem 3rem;
				display: flex;
			}
		}

		&__title,
		& .title,
		& .m-cardimage__title {
			font-size: get-fontsize(m, small);
			color: map-get($colors, 'wolf-black');
			margin: 0px;

			@media (max-width: $breakpoint-medium) {
				font-size: get-fontsize(s, default);
			}
		}

		& .m-cardimage__text-wrapper {
			@media (max-width: $breakpoint-medium) {
				margin-left: 1.5rem;
			}
		}

		& .m-cardimage__cardimage-text {
			color: map-get($colors, 'wolf-black');
			font-size: get-fontsize(m, default);
			max-width: 100%;
			padding-top: 5px;
			@media (max-width: $breakpoint-medium) {
				font-size: get-fontsize(s, xxsmall);
				padding-top: 0.3rem !important;
			}
		}

		& .m-cardimage__contact-image {
			@media (max-width: $breakpoint-medium) {
				width: 89px;
				height: 89px;
				min-width: 89px;
				margin-left: 1rem;
			}

			@media (max-width: $breakpoint-small) {
				width: 75px;
				height: 75px;
				min-width: 75px;
			}
		}

		.p-article__author {
			background-color: map-get($colors, 'wolf-grey');
		}
	}
}

@include wolf-m-article-header();
