@mixin wolf-t-nav-content($name: '.t-nav-content') {
	#{$name} {
		max-width: $global-max-width;
		margin: auto;
		&__container {
			margin-top: 200px;

			@media (max-width: $breakpoint-small-max) {
				margin-top: 100px;
			}
		}
	}
}

@include wolf-t-nav-content();
