@mixin wolf-m-professional-product-benefits($name: '.m-professional-product-benefits') {
	#{$name} {
		&__container {
			min-height: 528px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			& a {
				text-decoration-color: transparent;
				&:hover {
					& p {
						opacity: 0.8;
					}
				}
			}
		}
		&__item {
			min-width: 300px;
			margin: 32px;
		}
		&__image {
			border-radius: 50%;
			min-width: 176px;
		}
		&__text {
			max-width: 260px;
			text-align: center;
			letter-spacing: -0.24px;
			font-weight: bold;
		}
	}
}

@include wolf-m-professional-product-benefits();
