@mixin wolf-o-fhw-request($name: '.o-fhw-request') {
	#{$name} {
		&.uk-grid-medium > *,
		&.uk-grid-column-medium > * {
			padding-left: 0;
		}

		&__headline-request {
			.a-copy {
				margin-top: 39px;

				@media (max-width: $breakpoint-small-max) {
					font-size: 14px;
					margin-top: 33px;
				}
			}
		}

		.m-fhw-contact-form {
			@media (max-width: $breakpoint-small-max) {
				display: flex !important;
			}
		}
	}
}

@include wolf-o-fhw-request();
