@mixin justifyContent($val: '-') {
	.justify-content#{$val} {
		&start {
			justify-content: flex-start !important;
		}

		&center {
			justify-content: center !important;
		}

		&end {
			justify-content: flex-end !important;
		}

		&between {
			justify-content: space-between !important;
		}

		&around {
			justify-content: space-around !important;
		}

		&unset {
			justify-content: unset !important;
		}
	}
}
