@mixin wolf-m-finder-content($name: '.m-finder-content') {
	#{$name} {
		.m-teaser-bg {
			@media (max-width: $breakpoint-small-max) {
				height: 158px;
				width: 158px;
			}

			&__outer {
				@media (max-width: $breakpoint-small-max) {
					height: 158px;
				}
			}
		}
	}
}

@include wolf-m-finder-content();
