@mixin wolf-a-checkbox($name: '.a-checkbox') {
	#{$name} {
		&__wrapper {
			display: grid;
			grid-template-areas: 'input label' 'validation validation';
			grid-template-columns: 32px auto;

			margin-top: 33px;

			> label {
				font-size: 12px;
				color: #6b718b;

				&.uk-form-danger {
					~ .uk-input {
						border-color: get-color('danger');
						color: get-color('danger');
					}
				}
			}

			#{$name}__required-input {
				color: get-color('primary');
			}
		}

		label {
			grid-area: label;
			align-self: center;
		}

		input {
			grid-area: input;
			align-self: center;
		}

		.validation-error {
			grid-area: validation;
		}
	}
}

@include wolf-a-checkbox();
