@mixin wolf-m-shop-footer($name: '.m-shop-footer') {
	#{$name} {
		background-color: get-color(darker-grey);
		padding-bottom: 38px;

		@media (max-width: $breakpoint-medium) {
			padding-bottom: 20px;
		}

		&__copy-wrapper {
			position: relative;

			@media (max-width: $breakpoint-medium) {
				position: initial;
				margin-top: 48px !important;
			}

			span {
				position: absolute;
				bottom: 0px;
				left: 50%;
				-webkit-transform: translateX(-50%);
				transform: translateX(-50%);
				font-size: 12px;

				@media (max-width: $breakpoint-medium) {
					position: initial;
					-webkit-transform: translateX(0%);
					transform: translateX(0%);
					margin-bottom: 0px;
					text-align: center;
				}
			}
		}

		.a-linklist {
			@media (max-width: $breakpoint-medium) {
				width: 100%;
			}

			li:not(.a-linklist__list-title) {
				@media (max-width: $breakpoint-medium) {
					margin-top: 8px !important;
				}
			}
		}

		.a-linklist__dark .a-linklist__list-wrapper {
			background-color: get-color(darker-grey);

			@media (max-width: $breakpoint-medium) {
				padding: 0px !important;
				margin-bottom: 0px;
				margin-top: 0px;
				text-align: center;
			}
		}

		.a-linklist__list-title {
			color: get-color(dark-blue-90-white);
			font-size: get-fontsize(m, small) - 1;

			@media (max-width: $breakpoint-medium) {
				margin-top: 48px !important;
				margin-bottom: 16px;
			}
		}

		.a-linklist__list-wrapper > li:not(.a-linklist__list-title) {
			margin-top: 8px !important;
		}

		.a-linklist__list-wrapper > li.top-list {
			margin-top: 24px !important;

			@media (max-width: $breakpoint-medium) {
				margin-top: 8px !important;
			}
		}

		&__top-list .a-linklist__list-title {
			@media (max-width: $breakpoint-medium) {
				margin-top: 40px !important;
			}
		}

		& .a-linklist__list-wrapper.uk-list {
			margin-left: 0px;
			padding-left: 0px;
		}

		.a-linklist__link {
			color: get-color(dark-blue-70-white);
			// color: get-color(dark-blue-30-white);

			width: 100%;
			display: block;
			min-width: max-content;

			@media (max-width: ($breakpoint-large + 50px)) {
				font-size: 15px;
			}

			@media (max-width: ($breakpoint-medium + 130px)) {
				font-size: 13px;
			}
		}

		&__no-title {
			margin-top: 41px;
			background-color: transparent;

			.a-linklist__dark .a-linklist__list-wrapper {
				background-color: transparent;
			}

			.a-linklist__list-title {
				display: none;
			}

			@media (max-width: $breakpoint-medium) {
				margin-top: 0px !important;
			}

			@media (max-width: ($breakpoint-large - 50px)) {
				padding-left: 5px;
			}
		}

		.uk-padding-remove-left {
			@media (max-width: $breakpoint-medium) {
				padding-left: 16px !important;
			}
		}

		.uk-grid > .uk-grid-margin {
			@media (max-width: $breakpoint-medium) {
				margin-top: 0px;
			}
		}

		&__copy-wrapper-pos .a-copyright {
			position: relative;
			top: 100%;
			right: 20%;
		}
	}
}

@include wolf-m-shop-footer();
