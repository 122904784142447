@mixin wolf-m-search-field($name: '.m-search-field') {
	#{$name} {
		&__wrapper {
			display: flex;
		}

		&__input-group {
			position: relative;

			> span {
				width: 45px;
				height: 45px;
				margin: auto;
				right: 15px;
				top: 0;
				bottom: 0;
				position: absolute;
				cursor: pointer;

				@media (max-width: $breakpoint-medium-max) {
					width: 26px;
					height: 26px;
				}
			}

			&.empty {
				> span {
					display: none;
				}
			}
		}

		&__input {
			@include font-inter-300;
			border: 1px solid get-color('silver-grey');
			padding: 0 70px 0 25px;
			font-size: 24px;
			width: 100%;
			height: 100%;

			&:focus {
				outline: none;
			}

			// Hide X from chrome
			&::-webkit-search-decoration,
			&::-webkit-search-cancel-button,
			&::-webkit-search-results-button,
			&::-webkit-search-results-decoration {
				display: none;
			}

			@media (max-width: $breakpoint-medium-max) {
				padding: 0 50px 0 15px;
				font-size: 16px;
			}
		}

		&__button {
			color: get-color('true-white');
			border: none;
			border-radius: 0;
			padding: 25px;
			background-color: get-color('primary');
			cursor: pointer;

			> span {
				width: 38px;
				display: flex;

				svg {
					min-width: 38px;
					min-height: 38px;
				}
			}

			@media (max-width: $breakpoint-medium-max) {
				.m-search-field & {
					padding: 6px;
					background-color: get-color('wolf-red');
					color: get-color('true-white');
					height: 46px;
					width: 46px;
					min-width: 46px;
					display: inline-flex;
					align-items: center;
					justify-content: center;

					svg {
						min-width: 22px;
						min-height: 22px;
						width: 22px;
					}
				}

				> span {
					width: 25px;
				}
			}
		}
	}
}

@include wolf-m-search-field();
