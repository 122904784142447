@mixin wolf-a-image($name: '.a-image') {
	#{$name} {
		&__img {
			width: 100%;
		}

		&--background-image {
			& > div {
				min-height: 800px;
			}
		}

		&--fullwidth {
			#{$name}__img {
				width: 100%;
			}
		}
	}

	.image-wrapper {
		position: relative;
		background-color: inherit;

		&::before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 2;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		&.image-loader {
			> img {
				opacity: 0;
			}

			&::before {
				content: none;
			}

			&::after {
				content: '';
				position: absolute;
				z-index: 0;
				background-color: transparent;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 30px;
				height: 30px;
				border-radius: 50%;
				border-style: solid;
				border-width: 2px;
				border-color: #666 #f9f9f9 #f9f9f9;
				animation: rotating 0.5s linear infinite;
			}
		}
	}

	img,
	.bg-image {
		display: flex;
		object-fit: cover;
		vertical-align: unset;
		background-color: inherit;
	}
}

@keyframes rotating {
	from {
		transform: translate(-50%, -50%) rotate(0deg);
	}

	to {
		transform: translate(-50%, -50%) rotate(359deg);
	}
}

@include wolf-a-image();
