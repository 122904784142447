@mixin wolf-m-rounded-teaser($name: '.m-rounded-teaser') {
	#{$name} {
		max-width: 363px;

		@media (max-width: $breakpoint-medium-max) {
			max-width: unset;
		}

		&__image-container {
			position: relative;
			width: 363px;
			height: 225px;
			box-shadow: 0 3px 20px rgba(#0e1115, 0.1);
			border-radius: 11px;

			@media (max-width: $breakpoint-large-max) {
				width: 100%;
			}

			@media (max-width: $breakpoint-medium-max) {
				width: 100%;
				height: 225px;
				border-radius: 0;
			}

			@media (max-width: $breakpoint-xsmall-max) {
				height: 155px;
			}

			#{$name}__overlay-selected {
				background-color: rgba(get-color('wolf-black'), 0.3);
				z-index: 0;
				color: get-color('true-white');
				opacity: 0;
				transition: opacity 300ms;

				> span {
					pointer-events: none;
				}
			}

			&.selected {
				#{$name}__overlay-selected {
					opacity: 1;

					svg {
						width: 72px;
					}
				}
			}
		}

		&__image {
			img.a-image {
				&__img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}

		&__overlay-info {
			display: none;
			background-color: get-color('true-white');
			font-size: 13px;
			line-height: 22px;
			padding: 54px 32px;
			z-index: 3;
		}

		&__icon {
			position: absolute;
			top: 14px;
			right: 14px;
			width: 22px;
			height: 22px;
			cursor: pointer;

			&--open {
				color: get-color('true-white');
				z-index: 2;
			}

			&--close {
				color: get-color('silver-grey');
				z-index: 3;
			}
		}

		.m-rounded-teaser__icon--open:hover ~ .m-rounded-teaser__overlay-info {
			display: block;
		}

		&__description {
			@include font-inter-700;
			padding: 18px;
			font-size: 18px;
			line-height: 22px;
			text-align: center;

			@media (max-width: $breakpoint-xsmall-max) {
				@include font-inter-500;
				font-size: 16px;

				> p {
					margin-bottom: 0;
				}
			}
		}
	}
}

@include wolf-m-rounded-teaser();
