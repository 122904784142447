@mixin wolf-m-fhw-profile-contact($name: '.m-fhw-profile-contact') {
	#{$name} {
		&__column-wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;

			@media (min-width: $breakpoint-large) {
				justify-content: center;
			}

			.column {
				padding: 15px 0;

				h5 {
					@include font-inter-600;
					font-size: 16px;
					@media (min-width: $breakpoint-small-max) {
						font-size: 20px;
					}
				}

				.a-copy {
					strong {
						@include font-inter-500;
						display: inline-block;
						min-width: 55px;
					}

					> :not(strong),
					a {
						&::after {
							content: none; // Removes the underline if there's a link in the content
						}
					}

					p {
						margin-bottom: 0;
					}
				}
			}

			hr {
				margin: 0 60px;
				@media (min-width: $breakpoint-large) {
					border-left: 1px solid #c6c6c6;
					margin: 0 100px;
				}
			}
		}

		.button-container {
			display: grid;
			place-content: center;
			margin-top: 50px;
		}
	}
}

@include wolf-m-fhw-profile-contact();
