$m-searchfilter-search-button-height: 58px;

@mixin wolf-m-searchfilter($name: '.m-searchfilter') {
	#{$name} {
		backdrop-filter: blur(15px);
		background-color: rgba(255, 255, 255, 0.1);
		opacity: 1;
		padding: 4rem;
		font-size: $global-xsmall-font-size;
		margin-bottom: 2rem;

		@media (max-width: $breakpoint-medium) {
			padding: 3rem 2rem;
		}

		&__form {
			margin-left: 0;
			margin-right: auto;
			max-width: 620px;
		}
		&__search {
			display: flex;

			> div {
				flex-direction: row;
				flex-grow: 5;
				+ div {
					flex-grow: 1;
				}
			}
		}
		&__searchbox {
		}

		&__input {
			background-color: transparent;
			border: none;
			border-bottom: 1px solid map-get($colors, 'wolf-red');
			height: $m-searchfilter-search-button-height !important; // see search-button
			font-size: get-fontsize(m, small) !important;
			line-height: normal;
			min-width: 420px;
			color: map-get($colors, 'true-white');

			&:focus {
				background-color: rgba(0, 0, 0, 0.33);
				color: map-get($colors, 'true-white');
			}
			@media (max-width: $breakpoint-medium) {
				min-width: auto;
			}
		}

		&__input::placeholder {
			color: map-get($colors, 'true-white');
		}

		// &__search-button {
		// 	height: $m-searchfilter-search-button-height !important; // see input
		// 	span {
		// 		font-size: get-fontsize(m, small);
		// 		@include font-inter-500;
		// 	}
		// 	border: 0px;
		// }

		form input[type='submit'] {
			// see: @include wolf-submit-button();
			@include font-inter-600();
			font-size: 22px;
			line-height: 22px;

			width: 100%;
			height: $m-searchfilter-search-button-height !important; // see input
			@media (max-width: $breakpoint-medium-max) {
				// padding: 1rem 1rem 4rem 1rem;
				max-width: 100%;
				min-width: 100%;
			}
		}

		&__filters {
			margin-top: 80px;
			h3 {
				color: get-color(true-white);
				margin-bottom: 5rem;

				@media (max-width: $breakpoint-medium-max) {
					margin-bottom: 3rem;
				}
			}
			p {
				color: get-color(true-white);
				margin-bottom: 1em;
			}
			.a-button {
				margin: 10px 20px 10px 0;

				@media (max-width: $breakpoint-small) {
					margin: 10px 10px 0 0;
				}
			}
		}
	}
}

@include wolf-m-searchfilter();
