@mixin wolf-p-forms($name: '.p-forms') {
	#{$name} {
		section {
			margin: 50px auto;
			padding-bottom: 50px;
			border-bottom: 1px solid map-get($colors, 'silver-grey');

			&:last-child {
				border-bottom: none;
				padding-bottom: 0;
			}

			h1 {
				font-size: get-fontsize('m', 'small');
				// text-transform: uppercase;
				color: map-get($colors, 'wolf-black');
				font-weight: 700;
				letter-spacing: normal;
				margin: 0;
				line-height: normal;
			}
		}
	}
}

@include wolf-p-forms();

// put these somewhere global?
form.wolf {
	// uk overrides for wolf

	// FORM TITLES - As seen on AHU forms
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		&.form-title {
			@include font-inter-400();
			margin: 0 auto 1em 0;

			&--underlined {
				padding-bottom: 0.25em;
				border-bottom: 1px solid map-get($colors, 'dark-blue-90-white');
			}
		}
	}

	h1.form-title {
		font-size: 50px;
		line-height: 50px;
	}

	h2.form-title {
		font-size: 36px;
		line-height: 36px;
	}

	h3.form-title {
		font-size: 28px;
		line-height: 28px;
	}

	h4.form-title {
		font-size: 24px;
		line-height: 24px;
	}

	h5.form-title {
		font-size: 20px;
		line-height: 20px;
	}

	h6.form-title {
		font-size: 16px;
		line-height: 16px;
	}

	// input[type=text]:not(.uk-form-blank),
	// input[type=email]:not(.uk-form-blank),
	// input[type=password]:not(.uk-form-blank),
	select,
	input.uk-input,
	textarea {
		&:not(.uk-form-blank)[class^='uk-'],
		&:not(.uk-form-blank)[class*=' uk-'] {
			font-size: 16px; // global?
			font-weight: 400;
			line-height: normal;
			background-color: transparent;

			border: 1px solid map-get($colors, 'wolf-black');
			outline-color: map-get($colors, 'wolf-black');

			&:focus {
				// border-width: 4px;
				outline: 1px solid;
			}

			&.uk-form-danger,
			&.uk-form-success {
				border-color: inherit;
			}

			// &.border-light {
			// 	border-color: map-get($colors, 'dark-blue-70-white') !important;
			// }
		}
	}

	textarea {
		&:not(.uk-form-blank)[class^='uk-'],
		&:not(.uk-form-blank)[class*=' uk-'] {
			min-height: 104px;
			resize: vertical;
		}
	}

	// special validation error styles, not from UIKit
	div.validation-error {
		color: map-get($colors, 'wolf-red');
		font-size: 15px;
		padding: 10px 0;
		margin-bottom: 20px;
	}

	input.uk-input,
	select.uk-select {
		padding-left: 16px;
		padding-right: 16px;

		&[disabled] {
			border-color: inherit !important;
			color: map-get($colors, 'silver-grey') !important;
			text-decoration: line-through;
		}
	}

	label {
		&.uk-form-label {
			@include font-inter-500();

			&--light {
				@include font-inter-300();
			}

			&--small {
				font-size: 87.5%;
			}
		}

		// to support controls which are disabled with a "disabled" class on the label parent - e.g. checkboxes/radios
		&.disabled {
			color: map-get($colors, 'silver-grey') !important;
			text-decoration: line-through;
		}
	}

	// themed controls - light
	&.light,
	&.matte {
		select,
		input.uk-input,
		textarea {
			&:not(.uk-form-blank)[class^='uk-'],
			&:not(.uk-form-blank)[class*=' uk-'] {
				border: 1px solid map-get($colors, 'wolf-black');
				outline-color: map-get($colors, 'wolf-black');

				&.border-light {
					border-color: map-get($colors, 'dark-blue-70-white');
				}
			}
		}

		input {
			&.uk-checkbox,
			&.uk-radio {
				border: 1px solid map-get($colors, 'wolf-black');

				&:not(:checked) {
					background-color: #fff;
				}

				&:disabled {
					border-color: map-get($colors, 'silver-grey') !important;
				}

				&.border-light {
					&:not(:disabled) {
						border-color: map-get($colors, 'dark-blue-70-white') !important;
					}
				}
			}
		}
	}

	// make form elements have a white background
	&.matte {
		select.uk-select,
		input.uk-input,
		// e.g. not radio and checkboxes
		textarea {
			background-color: #fff !important;
		}
	}

	// themed controls - dark
	&.dark {
		// make form elements display properly on a dark or black background (e.g. invert colours...)
		color: white;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p,
		a,
		span {
			color: inherit;
			text-decoration-color: inherit;
		}

		select,
		input.uk-input,
		textarea {
			&:not(.uk-form-blank)[class^='uk-'],
			&:not(.uk-form-blank)[class*=' uk-'] {
				border: 1px solid map-get($colors, 'dark-grey');
				outline-color: white;
				color: map-get($colors, 'dark-grey');

				&:focus {
					color: white;
				}

				&.uk-form-danger,
				&.uk-form-success {
					color: map-get($colors, 'wolf-red');
					border-color: inherit;
				}
			}
		}

		label {
			&.uk-form-label {
				color: map-get($colors, 'dark-grey');

				&.uk-form-danger,
				&.uk-form-success {
					color: map-get($colors, 'wolf-red');
				}
			}
		}

		input {
			&::placeholder {
				color: map-get($colors, 'dark-grey');
			}

			&.uk-checkbox {
				border: 1px solid map-get($colors, 'dark-grey');

				&:not(:checked) {
					background-color: transparent;
				}

				&:checked {
					border-color: map-get($colors, 'wolf-red');
				}
			}
		}
	}

	.my-register-link {
		color: map-get($colors, 'light-blue') !important;
	}

	.my-dark-grey-text {
		color: map-get($colors, 'dark-grey') !important;
	}
}
