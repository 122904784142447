&.pro-klima-blue {
	background-color: get-color(pro-klima-blue);

	.m-ahu-footer__sub {
		border-color: #ffffff40;

		.sitemap {
			a {
				&:hover {
					color: get-color(true-white);
				}
			}
		}
	}
}
