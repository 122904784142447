@mixin fontface($fontpath, $folder, $ffamily, $fweight, $fname, $fontExtension) {
	@font-face {
		@if $ffamily {
			font-family: $ffamily;
		}
		@if $fweight {
			font-weight: $fweight;
		}
		font-display: swap;
		font-style: normal;
		@if $folder {
			src: url('#{$fontpath}/#{$folder}/#{$fname}.#{$fontExtension}') format($fontExtension);
		} @else {
			src: url('#{$fontpath}/#{$fname}.#{$fontExtension}') format($fontExtension);
		}
		@if $ffamily == 'Saira' {
			@if $fname == 'latin' {
				unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
					U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
			} @else if $fname == 'latin-ext' {
				unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F,
					U+A720-A7FF;
			} @else {
				@warn "Unknown subset name #{$fname}";
			}
		} @else {
			@if $fname == 'latin' {
				unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
					U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
			} @else if $fname == 'latin-ext' {
				unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F,
					U+A720-A7FF;
			} @else if $fname == 'greek' {
				unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
			} @else if $fname == 'greek-ext' {
				unicode-range: U+1F00-1FFF;
			} @else if $fname == 'cyrillic' {
				unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
			} @else if $fname == 'cyrillic-ext' {
				unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
			} @else {
				@warn "Unknown subset name #{$fname}";
			}
		}
	}
}

// Saira fallback
@font-face {
	font-family: 'Saira-fallback';
	src: local(Arial);
	size-adjust: 103%;
	ascent-override: 109%;
	descent-override: 31%;
	line-gap-override: 13%;
}

// Inter fallback
@font-face {
	font-family: 'Inter-fallback';
	src: local(Arial);
	size-adjust: 107%;
	ascent-override: 90%;
	descent-override: 19%;
	line-gap-override: normal;
}
