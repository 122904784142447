$m-product-gallery-ratio: 3/4; // 4:3 aspect ratio

$m-product-gallery-responsive-width: (
	xs: 360px,
	sm: 720px,
	md: 544px,
	// this is lower than expected because of 2-column layout
	lg: 700px,
	// this is lower than expected because of 2-column layout
);
$m-product-gallery-responsive-width-fullscreen: (
	xs: 360px,
	// should not apply as no fs on mobile
	sm: 720px,
	md: 960px,
	lg: 1500px,
);

$m-product-gallery-responsive-height: (
	xs: map-get($m-product-gallery-responsive-width, xs) * $m-product-gallery-ratio,
	sm: map-get($m-product-gallery-responsive-width, sm) * $m-product-gallery-ratio,
	md: map-get($m-product-gallery-responsive-width, md) * $m-product-gallery-ratio,
	lg: map-get($m-product-gallery-responsive-width, lg) * $m-product-gallery-ratio,
);
$m-product-gallery-responsive-height-fullscreen: (
	xs: map-get($m-product-gallery-responsive-width-fullscreen, xs) * $m-product-gallery-ratio,
	sm: map-get($m-product-gallery-responsive-width-fullscreen, sm) * $m-product-gallery-ratio,
	md: map-get($m-product-gallery-responsive-width-fullscreen, md) * $m-product-gallery-ratio,
	lg: map-get($m-product-gallery-responsive-width-fullscreen, lg) * $m-product-gallery-ratio,
);

@mixin wolf-m-product-gallery($name: '.m-product-gallery') {
	#{$name} {
		&__galleryWrapper {
			display: flex;
			justify-content: center;
			align-items: stretch;

			// set height fixed, depending on fullscreen modifier
			height: map-get($m-product-gallery-responsive-height, 'xs');

			@media (min-width: $breakpoint-small) {
				height: map-get($m-product-gallery-responsive-height, 'sm');
			}

			@media (min-width: $breakpoint-medium) {
				height: map-get($m-product-gallery-responsive-height, 'md');
			}

			@media (min-width: $breakpoint-large) {
				//height: map-get($m-product-gallery-responsive-height, "lg");
				height: 560px;
			}

			@media (max-width: $breakpoint-small-max) {
				// height: map-get($m-product-gallery-responsive-height, "sm");
				height: auto;
			}

			@media (max-width: $breakpoint-xsmall-max) {
				//height: map-get($m-product-gallery-responsive-height, "xs");
				height: auto;
			}

			> div.focus {
				display: flex;
				justify-content: center;
				align-items: stretch;
				width: 100%;
				height: inherit;
				overflow: hidden;
				position: relative;
				border: solid 1px #ffffff;

				.icon360 {
					position: absolute;
					left: 0;
				}

				// images
				&--image {
					> img {
						object-fit: cover;
						width: 100%;

						@media (max-width: $breakpoint-small-max) {
							height: calc((100vw - 32px) / 4 * 3);
							object-fit: cover;
						}
					}
				}

				// videos
				&--video {
					#{$name} {
						// a video organism inside this molecule
						&__video {
							> div {
								height: inherit;

								> div {
									height: inherit;
								}
							}

							&.o-video {
								height: inherit;

								@media (max-width: $breakpoint-small-max) {
									height: calc((100vw - 32px) / 4 * 3);
								}

								.o-video {
									&__media {
										height: inherit;
										// height: map-get($m-product-gallery-responsive-height, 'md');
									}
								}
							}
						}
					}
				}

				// 360s
				&--three60 {
					@media (max-width: $breakpoint-small-max) {
						height: calc((100vw - 32px) / 4 * 3);
						object-fit: cover;
					}

					> .cloudimage-360 {
						height: inherit;

						> .cloudimage-inner-box {
							height: inherit;
							display: flex; // centre 360

							> img {
								@media (min-width: $breakpoint-medium) {
									transform: translateY(-36px);
								}
							}

							> canvas {
								margin: auto;
								//width: map-get($m-product-gallery-responsive-width, "md") !important;
								//width: auto !important;

								@media (max-width: $breakpoint-xsmall-max) {
									//width: map-get($m-product-gallery-responsive-width, "xs") !important;
									width: 100% !important;
									height: auto !important;
								}

								@media (max-width: $breakpoint-small-max) {
									//width: map-get($m-product-gallery-responsive-width, "sm") !important;
								}
							}

							> div {
								// hide the magnifier and 360 image enlarger icons
								display: none;
							}
						}
					}
				}
			}
		}

		&__energyBadge {
			position: absolute;
			top: 60px;
			left: 0px;
			width: 110px;

			@media (max-width: $breakpoint-small-max) {
				top: 16px;
			}
		}

		&__sliderWrapper {
			margin: 18px auto 0;
			position: relative;

			@media (max-width: $breakpoint-medium-max) {
				margin-top: 16px; // ipad
			}

			@media (max-width: $breakpoint-small-max) {
				margin-top: 16px; // phone
			}

			.icon360 {
				max-width: 90px;
				width: 100%;
			}

			&::before,
			&::after {
				content: '';

				@media (max-width: $breakpoint-small-max) {
					content: none;
				}

				position: absolute;
				z-index: 1;
				top: 0;

				height: 100%;
				pointer-events: none;
			}

			&::before {
				width: 90px;
				left: -1px;
				background-color: red;
				background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 100%);
				background: linear-gradient(to right, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 100%);
			}

			&::after {
				width: 90px;
				right: -1px;
				background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
				background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
			}

			&[data-position='list-center'] {
				display: flex;
				justify-content: center;
			}
		}

		&__slides {
			+ a.uk-slidenav-previous {
				margin-left: 0;

				@media (max-width: $breakpoint-small-max) {
					left: 16px;
				}

				@media (max-width: $breakpoint-xsmall-max) {
					left: 0;
				}

				+ a.uk-slidenav-next {
					margin-right: 0;

					@media (max-width: $breakpoint-small-max) {
						margin-right: 16px;
					}

					@media (max-width: $breakpoint-xsmall-max) {
						margin-right: 0;
					}
				}
			}

			> li {
				&.product-thumb {
					width: 151px;
					height: 135px;
					position: relative;
					z-index: 5;
					cursor: pointer;

					&::after {
						content: '';
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 15px;
						background-color: rgba(0, 0, 0, 0.15);
						pointer-events: none;
						transition: all 0.25s ease-out;
					}

					&:hover {
						> div {
							box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5) inset;
						}

						&::after {
							background-color: rgba(0, 0, 0, 0);
						}
					}

					@media (max-width: $breakpoint-xsmall-max) {
						width: 108px;
						height: 88px;
					}
				}

				> div {
					height: 100%;
					background-color: #f0f0f0;
				}

				a.thumb-click {
					display: block;
					height: 100%;
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					box-sizing: border-box;
					border: 1px solid transparent;
					position: relative;

					&.selected {
						border-color: get-color('dark-grey');
					}

					img {
						height: 100%;
						width: 100%;
						object-fit: cover;
						opacity: 1;
					}

					&[data-type='image'],
					&[data-type='video'] {
						&.image-loader {
							&::before {
								content: '';
								position: absolute;
								z-index: 1;
								background-color: #f9f9f9;
								top: -1px;
								right: -1px;
								bottom: -1px;
								left: -2px;
							}

							&::after {
								content: '';
								position: absolute;
								z-index: 2;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%) rotate(0deg);
								width: 20px;
								height: 20px;
								display: flex;
								border-radius: 50%;
								border-style: solid;
								border-color: transparent #11151c #11151c #11151c;
								border-width: 3px;
								opacity: 0.3;
								animation: gallery-thumb-loader 0.4s infinite linear;
							}

							img {
								opacity: 0;
							}
						}
					}
				}
			}

			~ a.uk-slidenav {
				background-position: center;
				background-size: 17px;
				background-repeat: no-repeat;
				max-width: 46px;
				max-height: 46px;
				width: 46px;
				height: 46px;
				box-sizing: border-box;
				border-radius: 50%;
				border: 1px solid #11151c;
				padding: 10px;
				background-color: #fff;
				margin: 16px 0;
				z-index: 5;

				> svg {
					// hide default svg image
					display: none;
				}

				// replace images
				&-next {
					background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA3MiA3MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNzIgNzI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBvbHlnb24gcG9pbnRzPSI0MC44LDQuNCAzMy44LDExLjMgNTMuNywzMS40IDAsMzEuNCAwLDQxLjIgNTMuMiw0MS4yIDMzLjgsNjAuNyA0MC44LDY3LjYgNzIsMzYgIi8+Cjwvc3ZnPgo=');
				}

				&-previous {
					background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA3MiA3MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNzIgNzI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBvbHlnb24gcG9pbnRzPSI3MiwzMC44IDE4LjgsMzAuOCAzOC4yLDExLjMgMzEuMiw0LjQgMCwzNiAzMS4yLDY3LjYgMzguMiw2MC43IDE4LjMsNDAuNiA3Miw0MC42ICIvPgo8L3N2Zz4K');
				}
			}

			@keyframes gallery-thumb-loader {
				from {
					transform: translate(-50%, -50%) rotate(0deg);
				}

				to {
					transform: translate(-50%, -50%) rotate(359deg);
				}
			}
		}

		// overrides for full screen layout - no sidebar to consider
		@include element-modifier-self('&--fullscreen') {
			#{$name} {
				&__galleryWrapper {
					height: 69vh;

					@media screen and (min-width: $breakpoint-small) {
						height: 69vh;
					}

					@media (min-width: $breakpoint-medium) {
						height: 64vh;
					}

					@media screen and (min-width: $breakpoint-large) {
						height: 59vh;
					}

					@media screen and (min-width: $breakpoint-large), screen and (max-height: 635px) {
						height: 52vh;
					}

					> div.focus {
						&--three60 {
							> .cloudimage-360 > .cloudimage-inner-box {
								> div {
									// show the magnifier
									display: initial !important;
									right: 20px !important; // align with the modal close cross
									opacity: 0.42; // make same grey as design
									transition: all 0.2s ease-in;

									&:hover {
										transform: scale(1.25);
										transition: all 0.2s ease-in;
									}

									&:last-child {
										// hide the full screen icon
										display: none !important;
									}
								}

								> canvas {
									width: map-get($m-product-gallery-responsive-width, 'lg') !important;
									height: auto !important;

									@media (min-width: $breakpoint-large) {
										height: inherit !important;
									}
								}
							}
						}
					}
				}

				&__thumbs {
					max-width: 890px;
				}
			}
		}
	}
}

@include wolf-m-product-gallery();
