@mixin wolf-o-teaser-single-row-staggered($name: '.o-teaser-single-row-staggered') {
	#{$name} {
		&__headline {
			margin-bottom: 145px;
			display: flex;
			justify-content: center;

			@media (max-width: $breakpoint-small-max) {
				margin-bottom: 45px;
				justify-content: flex-start;
				width: 100%;
			}
		}

		&__teasers {
			width: 100%;
			display: inline-flex;
			justify-content: space-between;

			.teaser-container {
				.image-wrapper {
					position: relative;
					border-radius: 16px;
					overflow: hidden;

					> img {
						object-fit: contain;
					}
				}
			}

			@media (max-width: $breakpoint-xlarge) {
				justify-content: flex-start;

				.teaser-container:not(:last-of-type) {
					margin-right: 100px;
				}
			}

			@media (max-width: $breakpoint-small-max) {
				max-width: 100%;
				overflow-x: scroll;
				-ms-overflow-style: none;
				scrollbar-width: none;
				justify-content: flex-start;

				&::-webkit-scrollbar {
					display: none;
				}

				.teaser-container {
					min-width: 280px;
					min-height: 700px;
					height: 100%;

					margin-right: 30px;

					img {
						width: 280px;
						height: auto;
					}
				}
			}

			&.non-spaced {
				justify-content: flex-start;

				.teaser-container {
					margin-right: 100px;

					@media (max-width: $breakpoint-small-max) {
						margin-right: 30px;
					}
				}
			}

			&.one-row {
				max-width: 100%;
				overflow-x: scroll;
				-ms-overflow-style: none;
				scrollbar-width: none;

				justify-content: flex-start;

				&::-webkit-scrollbar {
					display: none;
				}

				.teaser-container {
					min-width: 400px;
					margin-right: 140px;

					@media (max-width: $breakpoint-small-max) {
						min-width: 280px;
						margin-right: 30px;
					}
				}
			}
		}

		&__scrollbar-nav {
			display: flex;
			z-index: 0;
			justify-content: flex-end;
			margin: 0px 0px 30px 0px;

			@media (max-width: $breakpoint-small-max) {
				display: none;
			}

			span {
				height: 66px;
				width: 66px;
				border: 1px solid black;
				border-radius: 50%;
				cursor: pointer;
				z-index: 99;

				svg {
					padding: 22px;
					pointer-events: none;
				}

				&:first-child {
					margin-right: 20px;
				}

				&:not(.disabled):hover {
					background-color: #ea4c4c;
					border-color: #ea4c4c;
					color: get-color('true-white');
				}

				&.disabled {
					border: 1px solid get-color('silver-grey');
					pointer-events: none;

					&:hover {
						cursor: default;
					}

					svg {
						color: get-color('silver-grey');
					}
				}
			}
		}
	}
}

@include wolf-o-teaser-single-row-staggered();
