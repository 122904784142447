$a-fab-button-dimensions: 30px;

@mixin wolf-a-fab-button($name: '.a-fab-button') {
	#{$name} {
		display: inline-block; // fixes width
		transition: all 200ms ease-in-out;

		&__anchor {
			@include font-inter-600;
			background-color: #e21b00;
			color: white;
			padding: 12px;
			font-size: 22px;
			line-height: 22px;
			border-radius: $a-fab-button-dimensions;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			transition: all 200ms ease-in-out;
			border: 0;
			outline: none;
			cursor: pointer;

			@media (max-width: 639px) {
				width: 54px;
				box-sizing: border-box;
			}

			&:hover {
				color: white;
				text-decoration: none;
			}

			.label {
				margin: 0 0 0 18px;
			}

			.icon {
				display: none;

				margin: 0 18px 0 12px;
				width: $a-fab-button-dimensions;
				height: $a-fab-button-dimensions;

				&--closed {
					display: initial;
				}

				&--open {
					margin: 0;
				}
			}

			&:hover {
				background-color: darken(#e21b00, 5%);
				box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
			}

			&.open {
				border-radius: $a-fab-button-dimensions;

				.label {
					width: 0;
					opacity: 0;
					display: none;
				}

				.icon {
					&--open {
						display: initial;
					}

					&--closed {
						display: none;
					}
				}

				@media (max-width: $breakpoint-small-max) {
					display: none;
				}
			}

			@media (max-width: $breakpoint-xsmall-max) {
				width: 54px;

				.label {
					display: none;
				}

				.icon {
					margin: 0;
				}
			}
		}

		&__anchor.no-text {
			.label {
				margin: 0 0 0 6px;
			}
		}
	}
}

@include wolf-a-fab-button();
