@mixin wolf-a-socialmedia($name: '.a-socialmedia') {
	#{$name} {
		width: fit-content;
		width: -moz-fit-content;
		display: flex;
		align-items: center;
		gap: 1.5em;
		@media (max-width: $breakpoint-small) {
			gap: 0;
		}

		a {
			display: flex;
			align-items: center;
		}

		svg {
			color: #a1a1a1;
			width: 48px;
			min-width: 48px;
		}
	}
}

@include wolf-a-socialmedia();
