&.pro-klima-blue {
	.m-fab-menu-header--red {
		background-color: get-color(pro-klima-blue);
	}

	.a-fab-button__anchor {
		background-color: get-color(pro-klima-blue);

		&:hover {
			background-color: get-color(pro-klima-blue);
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.1), inset 0 0 0 100px rgba(0, 0, 0, 0.2);
		}
	}
}
