@mixin wolf-o-video($name: '.o-video') {
	#{$name} {
		width: 100%; // give component a width if inside another component
		@media (min-width: $breakpoint-medium) {
			box-sizing: border-box;
		}

		&__media {
			position: relative;
			// aspect-ratio: (16 / 9);
			height: calc(100vw * 0.5625); // this is 16:9, based on the youtube specs
			@media (min-width: $global-max-width) {
				// now that the video is in a uk-container, the calculation for height is different
				height: calc(#{$global-max-width} * 0.5625); // this is 16:9, based on the youtube specs
			}
		}

		&__posterWrapper {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1;

			background-size: cover;
			background-repeat: no-repeat;

			.uk-spinner {
				> svg {
					color: white;
				}
			}
		}

		&__player {
			position: relative;
			overflow: hidden;
			width: 100%;
			// height: 0;
			height: 100%;
			// padding-bottom: 56.25%;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		&__overlay {
			// position: relative;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			border: 0;
			padding: 10% 0;
			height: 100%;
			width: 100%;
			z-index: 800;
			// margin: auto auto auto 100px;
			margin: auto;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-content: center;
			background-color: rgba(38, 32, 28, 0.4);

			box-sizing: border-box;

			/*@media (max-width: 1100px){
				padding: 50px 0;
			}*/
		}

		&__cookieNotice {
			// position: relative;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			border: 0;
			height: 100%;
			width: 100%;
			z-index: 810;
			margin: auto;
			color: get-color(wolf-black);
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-content: center;

			box-sizing: border-box;
			padding: 0;

			div.inner {
				background-color: rgba(255, 255, 255, 0.8);
				box-sizing: border-box;

				.cookie-message {
					display: flex;
					flex-direction: column;
					align-content: center;
					justify-content: center;

					@media (min-width: $breakpoint-medium) {
						flex-direction: row;
						box-sizing: border-box;
						padding: 2rem;
					}
				}

				.message-wrapper,
				.button-wrapper {
					flex: auto;
					@media (min-width: $breakpoint-medium) {
						flex: 1;
						padding: 100px 0;
					}
				}

				// padding: 80px 120px;
				.message-wrapper {
					max-width: 95%;
					margin: auto;

					h1,
					h2,
					h3,
					h4,
					h5,
					h6 {
						@include font-inter-700();
						font-size: 16px;
						line-height: 20px;
						letter-spacing: normal;
						margin: 0 0 1em;
						color: get-color(wolf-black);

						@media (min-width: $breakpoint-medium) {
							font-size: 40px;
							line-height: 48px;
						}
					}

					p,
					span {
						color: get-color(wolf-black);
						line-height: normal;
						font-size: 12px;
						&:last-child {
							margin-bottom: 0.5em;
						}
						@media (min-width: $breakpoint-medium) {
							font-size: 14px;
							margin-top: 0.5em;
							margin-bottom: 0.5em;
						}
					}
				}

				.button-wrapper {
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}

	#{$name}.default-colors {
		.o-video__playButton {
			color: white;

			@media (min-width: $breakpoint-medium) {
				border-color: white;
			}
			@media (max-width: $breakpoint-small-max) {
				border-color: white;
			}

			&:hover {
				background-color: rgba(0, 0, 0, 0.66);
			}
		}
		.o-video__overlay {
			color: white;

			.content-wrapper {
				color: white;
				.inner {
					h1,
					h2,
					h3,
					h4,
					h5,
					h6 {
						color: white;
					}
				}
			}
		}

		.button.content {
			.a-button__text {
				color: white;
			}
		}
	}
}

.ot-slide-in-right,
.onetrust-pc-dark-filter[style='display: none;'] {
	display: block !important;
}

@include wolf-o-video();
