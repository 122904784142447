@mixin wolf-a-filter-btn($name: '.a-filter-btn') {
	#{$name} {
		display: inline-flex;
		min-height: 30px;
		border-radius: 15px;
		background-color: #636b7910;
		font-family: 'Inter';
		font-size: 14px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1;
		letter-spacing: -0.32px;
		color: get-color(wolf-black);
		transition: all 0.25s ease-out;
		cursor: pointer;

		@include min-md {
			min-height: 40px;
			border-radius: 20px;
			font-size: 16px;
		}

		&:hover,
		&:focus-within {
			background-color: get-color(wolf-red);

			> button {
				color: get-color(true-white);

				&:last-of-type {
					svg {
						opacity: 1;
					}
				}
			}
		}

		> button {
			border: 0;
			outline: none;
			background-color: transparent;
			display: inline-flex;
			align-items: center;
			pointer-events: none;

			&:first-of-type {
				padding-left: 24px;
				transition: all 0.25s ease-out;
				display: block;
				max-width: 190px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				@include min-md {
					padding-left: 30px;
				}
			}

			&:last-of-type {
				padding-right: 10px;

				@include min-md {
					padding-right: 16px;
				}

				svg {
					height: 12px;
					opacity: 0.5;
					transition: all 0.25s ease-out;
				}
			}
		}
	}
}

@include wolf-a-filter-btn();
