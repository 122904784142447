@mixin wolf-m-shop-navbar($name: '.m-shop-navbar') {
	#{$name} {
		width: 100%;
		margin-top: $global-navbar-offset-top;
		text-align: center;

		@media (max-width: $breakpoint-medium) {
			margin-top: $global-mobile-navbar-offset-top;
		}

		.uk-offcanvas-bar {
			background: get-colors('wolf-black');
			display: block;
			padding: 1rem;
		}

		.menu__wrap {
			height: 100%;
			width: 100%;
			display: grid;
			grid-template-rows: 2fr 5fr 1fr;
		}

		&__burger-footer {
			display: flex;
			align-items: flex-end;
			justify-content: center;
		}

		&__language-list-accordion {
			width: 100%;
			padding: 0;

			.current-lang {
				text-decoration: none;
				font-size: 21px;
			}

			.select-lang {
				line-height: 2rem;

				a {
					text-decoration: none;
				}
			}
		}

		&__wrapper {
			display: grid;
			grid-template-columns: 3fr 2fr 3fr;

			@media (max-width: $breakpoint-medium) {
				display: flex;
				flex-direction: row-reverse;
			}

			> div {
				flex: 1;
				align-items: center;

				&:first-child {
					display: flex;
					justify-content: flex-start;
				}
			}
		}

		#hamburger {
			right: $global-small-gutter + 10;
		}

		.uk-dropdown {
			max-width: 50px;
			background-color: map-get($colors, 'true-white') !important;
			-webkit-box-shadow: 0px 2px 5px 0px rgba(158, 158, 158, 1);
			-moz-box-shadow: 0px 2px 5px 0px rgba(158, 158, 158, 1);
			box-shadow: 0px 2px 5px 0px rgba(158, 158, 158, 1);
		}

		&__center {
			display: flex;
			justify-content: space-between;

			.a-wolf-logo {
				@media (max-width: $breakpoint-large) {
					width: 130px;
				}

				@media (max-width: $breakpoint-small) {
					width: 84px;
				}
			}
		}

		&__cart {
			display: flex;
			justify-content: flex-end;

			@media (max-width: $breakpoint-medium) {
				justify-content: flex-start;
			}

			a {
				width: 16px;
				height: 16px;
				padding: 1em;
				border: solid 1px map-get($colors, 'silver-grey');
				cursor: pointer;
				transition: all 0.2s ease;
				position: relative;
				display: inline-flex;
				align-items: center;
				justify-content: center;

				> span {
					position: absolute;
					width: 15px;
					right: 15px;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					display: inline-flex;
					align-items: center;
					justify-content: center;
				}

				&:hover {
					background: map-get($colors, 'silver-grey');
					transition: all 0.2s ease;
				}
			}
		}

		&__locale {
			display: inline;
			padding: 15px;
			cursor: pointer;

			.dropdown {
				max-width: fit-content;
				max-width: -moz-fit-content;
				text-align: left;
			}

			div {
				&:first-child {
					width: 46px;
				}
			}

			svg {
				width: 9px;
			}
		}

		&__navlink--grey {
			color: get-color('dark-grey');

			a {
				color: get-color('dark-grey');
			}
		}
	}
}

@include wolf-m-shop-navbar();
