@mixin wolf-m-finder-pdf-footer($name: '.m-finder-pdf-footer') {
	#{$name} {
		display: inline-flex;
		align-items: center;
		padding: 90px 200px;
		background-color: #f1f1f1;
		margin-top: 75px;

		&__image {
			min-width: 200px;
			height: 200px;
			background-size: cover;
			background-position: top center;
			border-radius: 50%;

			@media (max-width: $breakpoint-large) {
				width: 70px;
				height: 70px;
			}
		}

		&__contact-text {
			@include font-inter-600();
			font-size: 30px;
			line-height: 30px;
			max-width: fit-content;
			width: 100%;
			padding-left: 50px;

			tcxspan {
				text-decoration: none;
			}
		}

		&__text {
			@include font-inter-200();
			font-size: 20px;
			line-height: 20px;
			width: 100%;
			padding-left: 120px;
		}
	}
}

@include wolf-m-finder-pdf-footer();
