@mixin wolf-m-nav-ad($name: '.m-nav-ad') {
	#{$name} {
		border: 3px solid get-color('light-blue');
		position: relative;
		overflow: hidden;

		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		width: 464px;
		padding: 30px 34px;
		box-sizing: border-box;

		&__headline {
			color: get-color('true-white');
			margin-bottom: 20px;

			:first-of-type {
				margin: 0;
			}
		}

		&__copy {
			@include font-inter-400();
			color: get-color('true-white');
			font-size: get-fontsize(m, default);

			width: 318px;
			margin: 0;
			letter-spacing: -0.32px;
			line-height: 28px;
			z-index: 1;
		}

		&__buttons {
			display: flex;
			flex-direction: column;
			margin-top: 44px;
			z-index: 1;

			a {
				width: 120px;
				height: 47px;
				white-space: nowrap;
				justify-content: center;
				margin: 4px 0;
				font-size: 12px;

				&.uk-button {
					&-primary {
						&:first-of-type {
							color: get-color('light-blue');
							border: none;

							&:hover,
							&:focus {
								color: get-color('true-white');

								.a-button__text {
									text-decoration: none;
								}
							}
						}
					}
					&.uk-button-secondary {
						border-width: 2px;

						&:hover,
						&:focus {
							.a-button__text {
								color: get-color('wolf-black');
								text-decoration: none;
							}
						}
					}
				}
			}
		}

		&__tablet {
			position: absolute;
			bottom: -74px;
			right: -88px;
			transform: rotate(-6deg);
			z-index: 0;

			img {
				width: 350px;
			}
		}
	}
}

@include wolf-m-nav-ad();
