$offcanvas-base-size-mid: 732px;
$offcanvas-base-size-large: 1054px;

$clip-path-menu--right: polygon(0% 100%, 100% 100%, 100% 100%, 100% 0%, 20% 0%);
$clip-path-menu--left: polygon(0 0, 0 100%, 100% 100%, 80% 100%, 100% 0);

$bg-gradient-customer: transparent linear-gradient(155deg, #e84b4c 0%, get-color('secondary') 100%) 0% 0% no-repeat padding-box; // get-color('secondary')
$bg-gradient-professional: transparent linear-gradient(155deg, get-color('accent') 0%, get-color('secondary') 100%) 0% 0% no-repeat padding-box; // light-blue-seo
$bg-gradient-proklima-blue: transparent linear-gradient(155deg, get-color('pro-klima-blue') 0%, get-color('pro-klima-blue-dark') 100%) 0% 0% no-repeat
	padding-box;
$bg-gradient-proklima-grey: transparent linear-gradient(155deg, get-color('pro-klima-grey') 0%, get-color('pro-klima-grey-dark') 100%) 0% 0% no-repeat
	padding-box;

@mixin offcanvas-polygon-clipper($direction) {
	@if $direction == 'left' {
		@media (min-width: $breakpoint-medium) {
			-webkit-clip-path: $clip-path-menu--left;
			clip-path: $clip-path-menu--left;
		}
	} @else {
		@media (min-width: $breakpoint-medium) {
			-webkit-clip-path: $clip-path-menu--right;
			clip-path: $clip-path-menu--right;
		}
	}
}

@mixin nav-canvas-default($size) {
	&.uk-offcanvas-bar {
		@if $size == 'lg' {
			width: auto;
			min-width: $offcanvas-base-size-large;

			.uk-open {
				> .uk-offcanvas-reveal {
					width: $offcanvas-base-size-large;
				}
			}
		} @else {
			width: auto;
			@media (min-width: $breakpoint-medium) {
				min-width: $offcanvas-base-size-mid;
			}

			.uk-open {
				> .uk-offcanvas-reveal {
					width: $offcanvas-base-size-mid;
				}
			}
		}
	}
}

@mixin wolf-m-nav-offcanvas($name: '.m-nav-offcanvas') {
	#{$name} {
		#{$name} {
			&-left {
				.uk-offcanvas-bar {
					@include offcanvas-polygon-clipper('left');
					.close-offcanvas-button {
						left: 27px;
					}
					.m-login-form__wrapper {
						padding-left: 0;
						@media (min-width: $breakpoint-medium) {
							margin-left: -140px;
						}
					}
				}
			}
			&-right {
				.uk-offcanvas-bar {
					@include offcanvas-polygon-clipper('right');
					.close-offcanvas-button {
						right: 27px;
					}
				}
			}
		}

		button {
			border: 0;
			outline: none;
			background-color: transparent;
			padding: 0;
			color: inherit;
			opacity: 1;

			&.close-offcanvas-button {
				width: 48px;
				height: 48px;
				padding: 15px;
				opacity: 1;
				cursor: pointer;
				z-index: 1021;

				> svg {
					width: 18px;
					height: 18px;
				}

				&[data-variant='language'],
				&[data-variant='search'] {
					color: get-color('true-black');
				}

				&[data-variant='search'] {
					background: get-color('true-white');
					border-radius: 50%;
					box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
				}
			}
		}

		&__closing-icon {
			position: absolute;
			top: 26px;
			width: 18px;

			button & {
				border: 0;
				outline: none;
				background-color: transparent;
			}

			&--left {
				left: 26px;
			}

			&--right {
				right: 26px;
			}

			@media (min-width: $breakpoint-medium) {
				top: 27px;

				&--left {
					left: 27px;
				}

				&--right {
					right: 27px;
				}
			}
		}

		&__canvas {
			&--professional {
				@include nav-canvas-default('md');
				@include offcanvas-polygon-clipper('left');
				background: $bg-gradient-professional;
			}

			&--customer {
				@include nav-canvas-default('md');
				@include offcanvas-polygon-clipper('left');
				background: $bg-gradient-customer;
			}

			&--language {
				@include nav-canvas-default('md');
				@include offcanvas-polygon-clipper('left');
				background-color: get-color('true-white');
				&.uk-offcanvas-bar {
					background-color: get-color('true-white');
				}

				button[data-variant='language'] {
					color: get-color('true-black');
					transform: translate(-15px, -15px);
				}

				button[data-variant='search'] {
					color: get-color('true-black');
					transform: translate(-15px, 15px);
				}
			}

			&--search {
				@media (min-width: $breakpoint-large) {
					@include nav-canvas-default('md');
					@include offcanvas-polygon-clipper('left');
				}

				&.uk-offcanvas-bar {
					background-color: get-color('true-white');
				}

				.close-offcanvas-button {
					transform: translate(15px, -15px);
				}

				.uk-icon[uk-icon='close'] {
					svg {
						color: get-color('true-black');
					}
				}
			}

			&--login {
				@include nav-canvas-default('lg');
				@include offcanvas-polygon-clipper('right');
				background: get-color('dark-blue');
			}

			&--pro-klima-blue,
			&--proklima-blue {
				@include nav-canvas-default('md');
				@include offcanvas-polygon-clipper('left');
				background: $bg-gradient-proklima-blue;
			}

			&--pro-klima-grey,
			&--proklima-grey {
				@include nav-canvas-default('md');
				@include offcanvas-polygon-clipper('left');
				background: $bg-gradient-proklima-grey;
			}

			@media (min-width: $breakpoint-medium) {
				&::-webkit-scrollbar {
					display: none;
				}
			}

			@media (max-width: $breakpoint-medium) {
				max-width: 100vw;
				min-width: 100vw;
			}
		}
	}

	.uk-offcanvas-overlay::before {
		background-color: rgba(0, 0, 0, 0.2);
		backdrop-filter: blur(5px);
	}

	.uk-offcanvas-bar {
		left: -100%;
	}

	.uk-offcanvas-flip .uk-offcanvas-bar {
		right: -100%;
	}
}

@include wolf-m-nav-offcanvas();
