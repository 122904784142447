@mixin wolf-m-formstep-personal-data($name: '.m-formstep-personal-data') {
	#{$name} {
		--elemWidth: 768;
		--formLabelColor: #6b718b;
		--formBorderColor: #62677e;

		max-width: calc(var(--elemWidth) * 1px);
		width: 100%;
		margin: 0 auto;

		&__grid {
			margin-left: -32px;

			& > div {
				padding-left: 32px;
			}
		}

		&__label {
			@include font-inter-400();
			font-size: 12px;
			letter-spacing: -0.6px;
			line-height: 2em;
		}
		&__input {
			letter-spacing: -0.48px;
		}

		&__legal {
			label {
				margin-top: -1px;
				display: block;
			}
		}

		form {
			&:invalid {
				input[type='submit'] {
					background-color: get-color('light-grey');
					color: get-color('dark-grey');
					cursor: default;
					user-select: none;
				}
			}

			&.wolf {
				&.dark {
					& label.uk-form-label {
						color: var(--formLabelColor);
					}
					& input.uk-input:not(.uk-form-blank) {
						&[class^='uk-'],
						&[class*='uk-'] {
							border-color: var(--formBorderColor);

							//&:valid:not(:placeholder-shown),
							&:not(.uk-form-danger) {
								color: get-color('true-white');
							}
						}
					}
				}

				.validation-error {
					margin-bottom: 0;
					padding-bottom: 0;
				}

				input[type='submit'] {
					border: none;

					display: table;
					margin-left: auto;

					line-height: 1.4;
					padding: 16px calc(30px + 10px);
					font-size: 16px;
					font-weight: 500;
				}
			}
		}

		@media all and (min-width: 640px) {
			&__container {
				padding: 24px;
			}
		}
	}
}

@include wolf-m-formstep-personal-data();
