@mixin wolf-m-typeahead-results($name: '.m-typeahead-results') {
	#{$name} {
		position: relative;
		width: 100%;
		// background-color: rgba(0, 0, 0, 0.5);

		&__results {
			max-width: 620px;
			min-width: 620px;
			opacity: 1;
			backdrop-filter: blur(18px);
			-webkit-backdrop-filter: blur(18px);
			backdrop-filter: contrast(0.5);
			-webkit-backdrop-filter: contrast(0.5);

			&::before {
				content: '';
				position: absolute;
				border-left: 1px solid #fff;
				height: calc(100% - 60px); // 2 * padding
				left: 50%;
				width: 1px;
			}

			@media (max-width: $breakpoint-medium-max) {
				// padding: 1rem 1rem 4rem 1rem;
				max-width: 100%;
				min-width: auto;
			}
		}
		&__inner {
			padding: 30px;
			position: relative;
			@media (max-width: $breakpoint-medium) {
				padding: 1rem 1rem 4rem 1rem;
			}
		}
		&__results-column {
			+ #{$name}__results-column {
				// second column contents to have padding
				div {
					padding-left: 30px;
				}
			}
		}

		&__results-title {
			text-transform: uppercase;
			padding-bottom: 15px;
			border-bottom: 1px solid white;
			font-size: 12px;
			margin-bottom: 15px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;

			@media (max-width: $breakpoint-medium) {
				font-size: get-fontsize(s, xxsmall) !important;
			}
		}

		&__results-data {
			padding: 15px 0;
			font-size: get-fontsize(m, small) !important;

			@media (max-width: $breakpoint-medium) {
				font-size: get-fontsize(s, xsmall) !important;
			}

			a {
				color: get-color('true-white');
				text-decoration: none;
			}
		}
	}
}

@include wolf-m-typeahead-results();
