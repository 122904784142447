@mixin wolf-m-article-index-nav($name: '.m-article-index-nav') {
	#{$name} {
		font-size: get-fontsize(m, xxsmall);
		width: 100%;
		max-width: 1540px;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;

		@media (max-width: $breakpoint-small-max) {
			position: initial;
			left: 0;
			margin-bottom: 2rem !important;
			max-width: 100% !important;
		}

		@media (max-width: 2052px) {
			max-width: calc(100% - 70px);
			left: 68px;
		}

		.uk-nav {
			> li {
				width: 100%;
				> a {
					width: fit-content;
					width: -moz-fit-content;
				}
				&.uk-active > a {
					font-weight: bold;
					padding-left: 2px;
					&::before {
						content: '';
						position: absolute;
						margin-top: 7px;
						left: -65px;
						width: 55px;
						border-top: 1px solid map-get($colors, 'wolf-red');
					}
				}
			}
		}

		.uk-nav,
		.uk-nav ul {
			@media (max-width: 1986px) and (min-width: $breakpoint-large) {
				max-width: 105px;
			}
		}

		select {
			font-size: get-fontsize(s, default);
			@media (max-width: $breakpoint-medium) {
				background-color: transparent;
				border: 1px solid map-get($colors, 'wolf-black');
				color: map-get($colors, 'wolf-black');
				padding: 16px 32px;
				height: 58px !important;
			}
		}
	}
}

@include wolf-m-article-index-nav();
