@mixin wolf-m-teaser-default($name: '.m-teaser-default') {
	#{$name} {
		> .uk-container {
			background: inherit;
		}

		&__image-wrapper {
			margin-bottom: 16px;

			&--image {
				.image-wrapper {
					height: auto;
					max-height: 100vh;
					position: relative;

					@media (max-width: $breakpoint-xsmall-max) {
						min-height: auto;
					}

					> img {
						width: 100%;

						@media (max-width: $breakpoint-small) {
							object-fit: cover;
						}

						@media (max-width: $breakpoint-xsmall-max) {
							object-fit: contain;
						}
					}
				}
			}
		}

		&__headlink {
			text-decoration: none;
		}

		&__energy-wrapper {
			margin-bottom: 35px;
			padding: 1px 3px;
			background-color: map-get($colors, 'lighter-grey');
		}

		&__headline {
			color: map-get($colors, 'wolf-black');
			margin-bottom: 35px;

			> .a-headline * {
				margin-bottom: 0;
				margin-top: 0;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				line-clamp: 2;
				overflow: hidden;
				line-height: normal;
			}

			> .a-headline:last-child * {
				margin-top: 5px;
			}

			@media (max-width: $breakpoint-medium) {
				margin-bottom: 12px;
				min-height: unset;

				h5.a-headline {
					margin-bottom: 0;
				}
			}
		}

		&__description {
			@include font-inter-400();

			color: map-get($colors, 'wolf-black');
			margin-bottom: 35px;

			&.read-more .a-copy {
				line-height: 30px;
			}

			> * {
				margin-bottom: 0;
				margin-top: 0;
			}
		}

		&__read-more-item {
			background: inherit;
			min-height: 60px !important;
			margin-bottom: 30px !important;
			transition: min-height 0.3s;

			@media (max-width: $breakpoint-small-max) {
				min-height: auto !important;
			}

			& .a-headline {
				margin-bottom: 10px;
			}

			.readMore-button {
				margin-top: 40px;
				display: none;
			}

			.readMore-element {
				@include font-inter-300();
				margin-top: 10px;
				line-height: 30px;

				&:not(.open) {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					overflow: hidden;
					position: relative;
					background: inherit;
				}

				&.open {
					margin-bottom: 0;

					~ .readMore-button {
						display: block;
					}

					.readMore-link {
						display: none;
					}
				}

				&:not(.open) .readMore-link {
					@include font-inter-500();
					font-size: get-fontsize(m, default);
					text-decoration: underline;

					display: inline-block;
					position: absolute;
					bottom: 0px;
					right: 0px;
					padding-left: 50px;
					cursor: pointer;
				}
			}
		}

		.uk-container {
			@media (max-width: $breakpoint-small-max) {
				> :last-child {
					margin-bottom: 0 !important;
				}

				.m-teaser-default__read-more-item {
					&:last-of-type {
						.readMore-element {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}

@include wolf-m-teaser-default();
