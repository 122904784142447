@mixin wolf-o-teaser-simple-scroll($name: '.o-teaser-simple-scroll') {
	#{$name} {
		&__headline-container {
			display: flex;
			justify-content: flex-start;
			margin-left: 24px;
			@media (min-width: $breakpoint-medium) {
				margin-left: 0;
				justify-content: center;
			}
		}
		&__headline-wrapper {
			flex-direction: column;
			display: flex;
		}
		&__title {
			display: flex;
			justify-content: flex-start;
			& > h1,
			h2,
			h3,
			h4,
			h5 {
				margin-bottom: 12px;

				@media (min-width: $breakpoint-large-max) {
					margin-bottom: 20px;
				}
			}
		}
		&__description {
			margin: 8px 0 40px;
			& :first-child {
				font-size: 12px;
				letter-spacing: 0px;
				text-align: center;
				@media (min-width: $breakpoint-large-max) {
					font-size: 20px;
					letter-spacing: -0.2px;
				}
			}

			& :last-child {
				margin-bottom: 44px;
				@media (min-width: $breakpoint-large-max) {
					margin-bottom: 70px;
				}
			}
		}
		&__teasers {
			display: flex;
			max-width: 100vw;
			overflow: scroll;
			justify-content: flex-start;
			@media (min-width: $breakpoint-medium) {
				justify-content: center;
			}
			& > div:first-child {
				padding-left: 20px;
			}
			& > div {
				padding-right: 10px;
				@media (min-width: $breakpoint-large-max) {
					padding-right: 20px;
				}
			}

			/* hide scrollbar but allow scrolling */
			-ms-overflow-style: none; /* for Internet Explorer, Edge */
			scrollbar-width: none; /* for Firefox */
			overflow-y: scroll;

			&::-webkit-scrollbar {
				display: none; /* for Chrome, Safari, and Opera */
			}
		}
	}
}

@include wolf-o-teaser-simple-scroll();
