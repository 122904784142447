@mixin wolf-a-tag($name: '.a-tag') {
	#{$name} {
		width: fit-content;
		padding: 4px 12px;

		@include font-inter-400();
		font-size: 16px;
		line-height: 18px;
		letter-spacing: -1px;

		background-color: #e6e7e8;
		border-radius: 5px;
		height: fit-content;
		height: -moz-fit-content;
		color: map-get($colors, 'wolf-black');

		&.text-color {
			&-white {
				color: map-get($colors, 'true-white');
			}
		}
	}
}

@include wolf-a-tag();
