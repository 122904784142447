@mixin wolf-p-shop-startpage($name: '.p-shop-startpage') {
	#{$name} {
		&__nav {
		}
		&__teasers {
			margin: 130px auto;
			@media (max-width: $breakpoint-xsmall-max) {
				margin: 25px auto;
			}
		}
		&__contact {
			// @todo
		}
	}
}

@include wolf-p-shop-startpage();
