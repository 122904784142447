@mixin wolf-o-product-list($name: '.o-product-list') {
	#{$name} {
		margin-top: 75px;
		margin-bottom: 65px;

		// new products container
		div.fadein {
			animation: fadein 200ms;
			// Firefox
			-moz-animation: fadein 200ms;
			// Safari and Chrome
			-webkit-animation: fadein 200ms;
			// Opera
			-o-animation: fadein 200ms;
		}

		&__wrapper {
			// margin: 0px 8px 0px 0px;
			margin-bottom: 80px;
		}

		&__back-button {
			display: flex;
			justify-content: center;

			span {
				border: 1px solid #f0f0f0;
				height: 50px;
				width: 50px;
				display: flex;
				align-items: center;
				justify-content: center;

				svg {
					height: 15px;
					width: 20px;
					margin: 0 auto;
				}
			}
		}

		&__title {
			display: flex;
			align-items: center;

			h3 {
				@media (max-width: $breakpoint-medium) {
					width: 100%;
					text-align: center;
				}
			}
		}

		&__products {
			margin-top: 37px !important;

			@media (max-width: $breakpoint-medium) {
				display: flex;
				justify-content: center;
			}

			#{$name} __product-card {
				@media (max-width: $breakpoint-medium) {
					display: flex;
					justify-content: center;
				}
			}
		}

		&__product-card {
			@media (max-width: $breakpoint-medium) {
				justify-content: center;
			}
		}

		&__button-load {
			margin-top: 80px !important;
		}
	}
}

@include wolf-o-product-list();
