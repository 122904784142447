&.new-design {
	.m-nav-mobile-menu {
		&__menu-items {
			height: 60%;
			max-height: 60%;
		}

		&__canvas-header {
			.a-button {
				&[uk-toggle*='#nav-offcanvas--'] {
					position: absolute;
					top: 0;
					left: 0;
					width: 48px;
					height: 48px;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					margin: 12px 0 0 13px;
					text-indent: -9999px;
				}

				.a-button__icon-wrapper {
					padding: 0;
					svg {
						width: 24px;
						height: 24px;
						min-width: 24px;
					}
				}
			}
		}

		&__switcher-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 16px 0 10px;

			@media (max-width: 400px) {
				max-width: 100%;
				padding-left: 15px;
				padding-right: 15px;
			}

			.m-navigation-switcher {
				.a-button {
					@media (max-width: 400px) {
						font-size: 14px;
						padding: 12px 16px;
						&:first-of-type {
							&:not(.uk-active) {
								padding-right: 24px;
							}
						}
						&:last-of-type {
							&:not(.uk-active) {
								padding-left: 24px;
							}
						}
					}

					position: relative;
					padding: 12px 30px;

					&.uk-active {
						z-index: 1;
						color: get-color('wolf-black');
					}
				}
			}
		}

		&__menu-items {
			margin-bottom: 118px;

			@media (orientation: landscape) {
				min-height: calc(100vh - 260px);
			}
		}

		&__fixed-bottom {
			position: fixed;
			left: 0;
			bottom: 0;
			right: 0;
			border-top: solid 1px get-color('true-white');

			.m-nav-mobile-menu {
				&__language-switcher {
					@include font-inter-400;
					font-size: 16px;
					position: relative;
					left: 22px;
					display: flex;
					align-items: center;
					text-decoration: none;
					margin: 0;
					color: get-color('true-white');

					&:hover {
						text-decoration: none;
					}

					& .uk-icon {
						width: 12px;
						margin-left: 6px;
					}

					span {
						margin-left: 6px;
						display: inline-flex;

						svg {
							width: 12px;
						}
					}
				}
			}

			.m-nav-mobile-menu__language-switcher,
			.a-button {
				min-height: 58px;
				width: 100%;
			}

			> .a-button {
				background-color: #101620;
				display: flex;
				justify-content: center;
				align-items: center;
				box-shadow: none;
				border-radius: 0;
			}
		}
	}
}
