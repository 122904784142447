@mixin wolf-m-product-gallery-modal-content($name: '.m-product-gallery') {
	#{$name} {
		&--fullscreen {
			#{$name} {
				&__galleryWrapper {
					height: 100vh;

					@media (pointer: coarse) {
						height: calc(100vh - 30px);
					}

					@media (pointer: fine) {
						max-height: 900px;
					}

					box-sizing: border-box;

					@media (min-width: $breakpoint-small) {
						height: calc(100vh - 30px);
						padding: 0 160px 0 0;
					}

					@media (min-width: $breakpoint-medium) {
						height: calc(100vh - 30px);
						padding: 0 160px 0 0;
					}

					@media (min-width: $breakpoint-large) {
						height: calc(100vh - 30px);
						padding: 0 160px 0 0;
					}

					@media (max-width: $breakpoint-small-max) {
						height: 100vh;
					}

					@media (max-width: $breakpoint-xsmall-max) {
						height: 100vh;
					}

					> div.focus {
						&--image,
						&--video,
						&--three60 {
							@media (pointer: fine) {
								max-height: 900px;
							}

							@media (max-width: $breakpoint-small-max) {
								height: calc((100vw - 32px) / 4 * 3);
								object-fit: cover;
							}
						}

						&--video {
							.m-product-gallery__video.o-video {
								@media (pointer: fine) {
									max-height: 900px;
								}

								@media (max-width: $breakpoint-small-max) {
									height: calc((100vw - 32px) / 4 * 3);
									object-fit: cover;
								}

								.o-video__media {
									@media (pointer: fine) {
										max-height: 900px;
									}

									@media (max-width: $breakpoint-small-max) {
										height: calc((100vw - 32px) / 4 * 3);
										object-fit: cover;
									}
								}
							}
						}

						// 360s
						&--three60 {
							> .cloudimage-360 {
								height: inherit;

								@media (pointer: fine) {
									max-height: 900px;
								}

								> .cloudimage-inner-box {
									height: inherit;
									display: flex; // centre 360

									> img {
										@media (min-width: $breakpoint-medium) {
											transform: translateY(-36px);
										}
									}

									> canvas {
										margin: auto;
										height: auto !important;
										width: auto !important;
										//width: map-get($m-product-gallery-responsive-width, "md") !important;
										//width: auto !important;

										@media (min-width: $breakpoint-large) {
											height: 76vh !important;
											max-height: 740px !important;
											margin: 5vh auto auto;
										}

										@media (max-width: $breakpoint-xsmall-max) {
											//width: map-get($m-product-gallery-responsive-width, "xs") !important;
											width: 100% !important;
											height: auto !important;
										}

										@media (max-width: $breakpoint-small-max) {
											//width: map-get($m-product-gallery-responsive-width, "sm") !important;
											height: auto !important;
										}

										+ div {
											// hide the magnifier and 360 image enlarger icons
											display: none !important;
										}
									}
								}
							}
						}
					}
				}

				&__sliderWrapper {
					&.uk-position-relative {
						padding-left: 15px;
						position: absolute !important;
						background-color: get-color('true-white');
					}

					z-index: 1;
					top: 0;
					right: 0;
					bottom: 0;
					height: calc(100vh - 15px);

					@media (pointer: coarse) {
						height: calc(100vh - 30px);
					}

					@media (pointer: fine) {
						max-height: 900px;
					}

					margin: 0;

					&::before,
					&::after {
						content: none;
					}

					ul {
						&.uk-thumbnav-vertical {
							margin-top: 0;
							height: calc(100vh - 30px);

							@media (pointer: fine) {
								max-height: 900px;
							}

							+ .uk-slidenav-previous {
								position: absolute;
								top: 0;
								left: 50%;
								transform: translateY(-15px) translateX(-35%) rotate(90deg);

								+ .uk-slidenav-next[class*='uk-position-center-'] {
									position: absolute !important;
									bottom: 0;
									left: 50%;
									top: unset;
									transform: translateY(10px) translateX(-35%) rotate(90deg);
								}
							}

							&::before,
							&::after {
								content: '';
								position: absolute;
								z-index: 6;
								right: 0;
								width: 170px;
								height: 100px;
								pointer-events: none;
							}

							&::before {
								top: 0;
								background: linear-gradient(180deg, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 0) 100%);
							}

							&::after {
								bottom: 0;
								background: linear-gradient(0deg, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 0) 100%);
							}
						}
					}
				}

				&__slides {
					> li {
						&.product-thumb {
							width: 145px;
							height: 129px;

							&::after {
								left: 0;
								top: 15px;
							}
						}
					}
				}
			}
		}
	}
}

@include wolf-m-product-gallery-modal-content();
