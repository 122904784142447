@mixin wolf-m-table-contents($name: '.m-table-contents') {
	#{$name} {
		@media (max-width: $breakpoint-small-max) {
			.a-headline__heading {
				margin-bottom: 15px;
			}

			.padding-no-padding-bottom {
				padding-top: 0;
			}

			&.padding-standard {
				padding-top: 40px;
				padding-bottom: 24x;
			}
		}

		&__tocItem {
			@include font-inter-400();
			font-size: 24px;
			line-height: 43px;
			margin: 0 0 37px;

			@media (max-width: $breakpoint-small-max) {
				font-size: 20px;
				line-height: 30px;
				margin: 0 0 15px;
			}

			a,
			.prefix,
			.text {
				display: inline-flex;
				max-height: 30px;
				text-decoration: none;

				@media (max-width: $breakpoint-small-max) {
					max-height: unset;
				}

				&:hover {
					text-decoration: none;
				}
			}

			a:hover .a-headline {
				color: inherit;
			}

			p {
				@include font-inter-400();
				font-size: 24px;
				line-height: 43px;
				margin: 0px;

				@media (max-width: $breakpoint-small-max) {
					font-size: 20px;
					line-height: 30px;
				}
			}

			.prefix {
				color: get-color('silver-grey');
				margin-left: 24px;
				min-width: 48px;
			}
		}

		&__navigation.non-visible {
			visibility: hidden;
		}

		&__navigation {
			position: absolute;
			font-size: get-fontsize(m, xxsmall);
			left: 50px;
			width: fit-content !important;

			@media (max-width: $breakpoint-small-max) {
				display: none;
			}

			@media (max-width: 1985px) {
				left: 20px;
			}

			@media (min-width: 2300px) {
				left: 9%;
			}

			li {
				a {
					color: get-color('dark-blue-50-black');

					&:hover {
						color: get-color('dark-blue-50-black');
						background: rgba(255, 255, 255, 0.9);

						.title {
							visibility: visible;
							padding-right: 5px;
						}
					}

					.title {
						visibility: hidden;
					}
				}

				&.uk-active a {
					font-weight: 400;
					font-size: 16px;
					color: get-color('dark-blue-50-black');

					&::before {
						content: '';
						position: absolute;
						margin-top: 10px;
						left: -25px;
						width: 20px;
						border-top: 2px solid #5ca8ff;
					}
				}
			}

			.uk-nav,
			.uk-nav {
				ul {
					width: fit-content;
				}

				li {
					width: fit-content;
					width: -moz-fit-content;

					> a {
						width: fit-content;
						width: -moz-fit-content;
					}
				}
			}
		}

		.uk-sticky-placeholder {
			display: none;
		}
	}
}

@include wolf-m-table-contents();
