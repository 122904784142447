:is(div):has(.nfx-calculator-app) {
	.nfx-calculator-app {
		--color-primary: #f7f7f7;
		--color-secondary: #757575;
		--color-text: #11151c;
		--color-text-dark: #11151c;
		--slider-thumb-background: #e10000;
		--slider-track-background: rgb(218 218 218);
		--slider-lower-track-background: #e10000;

		.calculator-slider-title {
			letter-spacing: unset;
		}

		.button-financing-calculator {
			background-color: var(--slider-thumb-background);
			border: 0;
			font-weight: bold;
			color: var(--color-primary);
			padding: 8px 16px;
			border-radius: 4px;
			text-align: center;
		}
	}
}
