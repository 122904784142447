$brakepoints: (
	xs: 0px,
	md: 768px,
	lg: 1200px,
);

$width-xs: 0px;
$width-md: 768px;
$width-lg: 1200px;

// MIN
// Min-0
@mixin min-xs {
	@media (min-width: #{$width-xs}) {
		@content;
	}
}

// Min-769
@mixin min-md {
	@media (min-width: #{$width-md+1}) {
		@content;
	}
}

// Min-1201
@mixin min-lg {
	@media (min-width: #{$width-lg+1}) {
		@content;
	}
}

// 0px - 768px
@mixin only-mobile {
	@media (max-width: #{$width-md}) {
		@content;
	}
}

// 769px - 1200px
@mixin only-tablet {
	@media (min-width: #{$width-md+1}) and (max-width: #{$width-lg}) {
		@content;
	}
}

// 0px - 1200px
@mixin only-touch {
	@media (max-width: #{$width-lg}) {
		@content;
	}
}
