@mixin button-active-styles {
	@include font-saira-700();
	font-size: 24px;
	color: get-color('true-white');

	@media (min-width: $breakpoint-xlarge) {
		font-size: 30px;
	}
}

@mixin wolf-o-form-stepper($name: '.o-form-stepper') {
	#{$name} {
		min-width: 100%;
		height: 100vh;
		min-height: 100%;
		display: grid;
		grid-template-columns: 1fr;
		overflow: hidden;
		position: relative;

		ul:not(#{$name}__buttons-container) li {
			margin: 0;
			padding: 0;
			text-indent: 0;
			list-style-type: none;
		}

		@include container-dark-on-mobile(get-color(true-white), get-color(wolf-black));

		@media (min-width: $breakpoint-medium-max) {
			grid-template-columns: 2fr 3fr;
		}

		&__alert-message {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 10000;
		}

		form {
			width: 100%;
			background-color: get-color(wolf-black);
			display: grid;

			@media (min-width: $breakpoint-medium-max) {
				grid-template-rows: calc(100vh - 155px) 120px; // 100vh - (height of container with back and next buttons)
				background-color: get-color(true-white);
				padding: 0;
			}

			input[type='submit'] {
				line-height: 1.4;
				padding: 16px 40px;
				font-size: 16px;
				font-weight: 500;
				border: 1px solid #fff;
				margin-left: 0;
				margin-left: 32px;
			}

			&:invalid,
			& button:disabled {
				input[type='submit'] {
					border-color: get-color(dark-blue-10-white);
					color: get-color(text-grey);
					background-color: get-color(dark-blue-10-white);

					@media (min-width: $breakpoint-large) {
						color: get-color(silver-grey);
						background-color: get-color(light-grey);
					}
				}
			}

			ul {
				padding: 0 16px;
				margin: 0 auto;
				display: flex;
				align-items: center;

				@media (min-width: $breakpoint-large) {
					width: 90%;
				}

				@media (min-width: $breakpoint-xlarge) {
					width: 924px;
				}
			}

			.uk-checkbox {
				background-color: #11151c;
				border: 1px solid #62677e;

				@media (min-width: $breakpoint-large) {
					background-color: get-color(true-white);
				}

				&:checked,
				&:indeterminate {
					background-color: #e10000 !important;
					border: 1px solid #e10000;
				}
			}
		}

		&__sidebar {
			display: none;

			@media (min-width: $breakpoint-medium-max) {
				background: get-color('wolf-black');
				color: get-color('true-white');
				flex-direction: column;
				justify-content: center;
				padding: 0 5% 0 80px;
				display: flex;
			}

			.uk-button-tertiary {
				margin-top: 71px;
				max-width: max-content;
				border: unset;
				margin-bottom: 60%;
			}

			ul {
				list-style-type: none;
				margin: 0;
				padding-left: 0;
				align-items: flex-start;

				&.uk-subnav-pill {
					> li:not(.uk-active) > :first-child {
						padding: 5px 10px;
						background: transparent;
						color: get-color('dark-grey');
					}
				}

				li {
					display: flex;
					justify-content: flex-start;
					padding-left: 0;

					span.uk-badge {
						@include font-inter-600();
						transition: color 0.3s ease-in, background-color 0.3s ease-in;
						font-size: 14px;
						margin: 0 25px 0 40px;
						padding: 1px 5px 0;
						height: 25px;
						width: 25px;

						&.valid {
							padding: 0 5px;
							background-color: map-get($colors, 'success');
						}
					}

					.li-button {
						@include font-saira-400();
						transition: font-size 0.2s ease-in;
						white-space: nowrap;
						font-size: 20px;
						line-height: 30px;
						letter-spacing: -1px;
						padding: 0 30px;
						color: get-color('dark-grey');
						background-color: transparent;
						border: none;

						&:hover {
							color: get-color('stroke-grey');
						}

						&:focus,
						&:active {
							@include button-active-styles();
							font-weight: 400;
						}

						&:focus-visible {
							outline: none;
						}

						span.uk-badge {
							margin: 24px 25px 25px 0px;

							&.valid {
								margin-top: 25px;
							}
						}
					}

					&.uk-active {
						.li-button {
							@include button-active-styles();
							font-size: 30px;
							line-height: 30px;
							font-weight: 400;
							letter-spacing: normal;
							padding: 5px 30px 5px 10px;
						}
					}

					&:not(.uk-active) {
						.uk-badge {
							background-color: get-color('dark-grey');

							&:not(.valid) {
								color: #11151c !important;
							}
						}
					}
				}
			}
		}

		#wolf-form-stepper {
			margin: auto 0;
			justify-self: center;

			li {
				width: 100%;
			}
		}

		&__step-grid {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: 120px calc(100vh - 240px);
			align-items: left;
			overflow-x: hidden;

			@media (min-width: $breakpoint-large) {
				grid-template-rows: 174px auto;
				justify-items: center;
				align-items: center;
			}

			.a-checkbox label {
				padding-left: 0;
			}
		}

		&__formsteps-title {
			width: 100%;
			padding-top: 20px;

			@media (min-width: $breakpoint-large) {
				// margin: 0 auto;
				max-width: 926px;
			}
		}

		&__formsteps {
			align-self: center;
			flex-direction: column;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			@media (max-width: $breakpoint-medium-max) {
				background-color: #101620;
				color: get-color('true-white');
				width: auto;

				.a-headline,
				div.icon-selected svg {
					color: get-color('true-white') !important;
				}

				div:not(.icon-selected) svg {
					color: #707070;
				}

				.o-cta__button .a-button {
					border: 0px;
				}
			}

			@media (min-width: $breakpoint-large) {
				background: get-color('true-white');
				color: get-color('true-black');

				display: flex;
				justify-content: center;
				align-items: center;

				.a-headline {
					color: get-color('true-black');
				}
			}

			&-item {
				// min-height: 400px;
				// max-height: 600px;
				width: 100%;
				max-width: 926px;
				height: calc(100vh - 248px);
				@include right-scrollbar();
				overflow-y: hidden;

				@media (min-width: $breakpoint-large) {
					// width: 926px;
					height: 620px;

					&:not(.o-cta) {
						overflow-y: auto;
						max-height: calc(
							100vh - 155px - 174px
						); // 100vh - (height of container with back and next buttons) - (height of container with title & description text)
					}

					max-width: 926px;
				}

				@media (max-height: 740px) {
					height: 100%;
				}

				.padding-standard {
					padding-top: 70px;
					padding-bottom: 0;

					@media (max-height: 740px) {
						padding-top: 0px;
					}
				}

				.uk-grid-medium > .uk-grid-margin {
					margin-top: 33px;
				}
			}

			.m-formstep-select-icon__selection-wrapper .iconSelector .icon {
				> svg > path,
				> svg > g > path {
					fill: get-color('true-black');
				}
			}
		}

		&__buttons-container {
			display: flex;
			align-items: center;
			z-index: 10;

			width: calc(100% - 18px);
			margin: 0;

			li {
				padding-left: 2em;

				.a-button.uk-button-primary {
					border: 0;
				}
			}

			&--button-prev {
				border-color: get-color(true-white);
				background-color: transparent;
				color: get-color(true-white);

				&:hover,
				&:active {
					background-color: transparent;
				}

				@media (min-width: $breakpoint-large) {
					background-color: get-color(true-white);
					color: get-color(dark-blue);
					border-color: get-color(dark-blue);

					&:hover,
					&:active {
						color: get-color(dark-blue);
						background-color: get-color(true-white);
					}
				}
			}

			&--button-next {
				border-color: get-color(primary);
				background-color: get-color(primary);

				&:hover {
					background-color: get-color(primary);
				}
			}

			&--button-next,
			&--button-prev,
			&--button-submit {
				display: flex;
				justify-content: center;
				width: calc(100% - 20px);

				&:disabled {
					border-color: get-color(dark-blue-10-white);
					color: get-color(text-grey);
					background-color: get-color(dark-blue-10-white);

					@media (min-width: $breakpoint-large) {
						color: get-color(silver-grey);
						background-color: get-color(light-grey);
						border: none;
					}
				}
			}

			ul,
			li {
				display: flex;
				list-style-type: none;
				width: 48%;

				&:last-child {
					justify-content: flex-end;
				}
			}

			@media (min-width: $breakpoint-medium) {
				width: 100%;

				&--button-next,
				&--button-prev,
				&--button-submit {
					width: 141px;
					margin-left: 32px;
				}

				justify-content: flex-end;
				max-width: 914px;

				ul,
				li {
					list-style-type: none;
					padding-left: 2em;
					text-indent: -2em;
					width: auto;
				}
			}
		}

		.m-formstep-resume-step__terms {
			padding-bottom: 32px;
		}

		&__top-element {
			position: absolute;
			width: 100%;
			max-width: 100%;
			padding-top: 32px;
			padding-left: 70px;
			height: 55px;

			display: inline-flex;
			justify-content: flex-end;
			right: 15px;

			@media (min-width: $breakpoint-large) {
				display: block;
				right: auto;
			}

			.back {
				color: white;
				@include font-inter-500();
				font-size: 16px;
				line-height: 20px;
				display: none;
				text-decoration: none;

				@media (min-width: $breakpoint-large) {
					height: 100%;
					display: inline-flex;
					align-items: center;
				}

				svg {
					height: 15px;
					width: auto;
					color: white;
					margin-right: 3px;
				}
			}

			.a-wolf-logo {
				position: absolute;
				bottom: 24px;
				left: calc(50% - 86px);
				display: none;

				@media (min-width: $breakpoint-large) {
					display: inline;
				}
			}

			.close {
				display: inline;

				svg {
					height: 21px;
					width: auto;
					color: white;
					margin-right: 5px;
				}

				@media (min-width: $breakpoint-large) {
					display: none;
				}
			}
		}
	}
}

@include wolf-o-form-stepper();
