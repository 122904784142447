@mixin wolf-m-job-offer-area($name: '.m-job-offer-area') {
	#{$name} {
		&__headline {
			> .a-headline {
				> .a-headline {
					margin-bottom: 80px;

					@media (max-width: $breakpoint-xsmall-max) {
						margin-bottom: 30px;
					}
				}
			}
		}
	}
}

@include wolf-m-job-offer-area();
