@mixin wolf-m-fab-menu-list($name: '.m-fab-menu-list') {
	#{$name} {
		.a-headline {
			letter-spacing: -1px;
			margin-bottom: 20px;
		}

		&__links {
			margin-bottom: 35px;

			a.linkitem {
				text-decoration: none;
				transition: all 0.5s cubic-bezier(0.16, 0.08, 0.355, 1);
				display: block;
				margin: 0;

				&:hover {
					color: get-color(true-black);
					text-decoration: underline;

					.linkbody {
						.arrow-hover {
							left: 10px;
							opacity: 1;
						}
					}
				}

				.linkbody {
					display: flex;
					align-items: center;

					@include font-inter-400();
					font-size: 18px;
					line-height: 21px;
					padding: 8px 0 2px;

					span.big-icon:not(.arrow-hover) {
						margin-right: 15px;
						display: inline-flex;
						align-items: center;

						svg {
							height: 25px;
							width: 25px;
						}

						&.stroke-width-4 {
							svg {
								stroke-width: 4;
							}
						}
					}

					span.small-icon:not(.arrow-hover) {
						margin-right: 15px;
						display: inline-flex;

						svg {
							height: 30px;
							width: 13px;
						}
					}

					&.line-spacer {
						border-bottom: 1px solid #d3d3d3;
					}

					.arrow-hover {
						left: -15px;
						position: relative;
						opacity: 0;
						transition: all 0.5s cubic-bezier(0.16, 0.08, 0.355, 1);

						svg {
							width: auto;
							height: 13px;
						}
					}
				}
			}
		}

		&--spacers {
			#{$name}__links {
				&.with-header {
					a.linkitem:not(:first-child) {
						.linkbody {
							padding: 15px 0;
						}
					}

					a.linkitem:first-child {
						.linkbody {
							padding: 0 0 15px 0;
						}
					}
				}

				&:not(.with-header) {
					a.linkitem {
						.linkbody {
							padding: 17px 0;
						}
					}
				}
			}
		}
	}
}

@include wolf-m-fab-menu-list();
