@import '../../../../global/assets/fonts/font-mixin.scss';

$fontpath: '../../../global/assets/fonts';
$fontFolder: 'saira';
$fontFamily: 'Saira';
$fontExtension: 'woff2';
$fontWeights: 100, 200, 300, 400, 500, 600, 700, 800, 900;

@each $fweight in $fontWeights {
	@include fontface($fontpath, $fontFolder, $fontFamily, $fweight, 'latin', $fontExtension);
}
@each $fweight in $fontWeights {
	@include fontface($fontpath, $fontFolder, $fontFamily, $fweight, 'latin-ext', $fontExtension);
}

@mixin font-saira-100() {
	font-family: 'Saira', 'Saira-fallback', sans-serif;
	font-weight: 100;
}

@mixin font-saira-200() {
	font-family: 'Saira', 'Saira-fallback', sans-serif;
	font-weight: 200;
}

@mixin font-saira-300() {
	font-family: 'Saira', 'Saira-fallback', sans-serif;
	font-weight: 300;
}

@mixin font-saira-400() {
	font-family: 'Saira', 'Saira-fallback', sans-serif;
	font-weight: 400;
}

@mixin font-saira-500() {
	font-family: 'Saira', 'Saira-fallback', sans-serif;
	font-weight: 500;
}

@mixin font-saira-600() {
	font-family: 'Saira', 'Saira-fallback', sans-serif;
	font-weight: 600;
}

@mixin font-saira-700() {
	font-family: 'Saira', 'Saira-fallback', sans-serif;
	font-weight: 700;
}

@mixin font-saira-800() {
	font-family: 'Saira', 'Saira-fallback', sans-serif;
	font-weight: 900;
}

// Orginals
//src: url(https://fonts.gstatic.com/s/saira/v14/memjYa2wxmKQyPMrZX79wwYZQMhsyuSLh4vSZSk.woff2) format('woff2');
//src: url(https://fonts.gstatic.com/s/saira/v14/memjYa2wxmKQyPMrZX79wwYZQMhsyuSLhovSZSk.woff2) format('woff2');
//src: url(https://fonts.gstatic.com/s/saira/v14/memjYa2wxmKQyPMrZX79wwYZQMhsyuSLiIvS.woff2) format('woff2');
