@mixin position($val: '') {
	.absolute#{$val} {
		position: absolute !important;
	}

	.relative#{$val} {
		position: relative !important;
	}

	.fixed#{$val} {
		position: fixed !important;
	}

	.static#{$val} {
		position: static !important;
	}

	.sticky#{$val} {
		position: sticky !important;
	}
}
