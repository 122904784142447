@mixin wolf-m-fhw-profile-sliders($name: '.m-fhw-profile-sliders') {
	#{$name} {
		margin-bottom: 70px;

		@media (max-width: $breakpoint-small-max) {
			margin-bottom: 50px;
		}

		&__head {
			display: inline-flex;
			justify-content: space-between;
			width: 100%;
			margin-bottom: 35px;
			align-items: center;
			padding-left: 16px;
			max-width: calc(100% - 32px);

			@media (max-width: $breakpoint-small-max) {
				margin-bottom: 16px;
			}

			@media (min-width: $breakpoint-small-max) {
				padding-left: 32px;
				max-width: calc(100% - 64px);
			}

			.title {
				display: inline-flex;
				@include font-inter-600();
				font-size: 20px;
				line-height: 30px;
				margin: 0px;

				color: get-color('wolf-black');

				@media (max-width: $breakpoint-small-max) {
					font-size: 16px;
				}
			}
		}

		&__nav {
			display: inline-flex;
			z-index: 0;
			margin: 0px;

			&.hide-button {
				opacity: 0;
			}

			a.scroller {
				width: 47px;
				height: 47px;
				border-radius: 50%;
				border: 1px solid get-color('wolf-black');
				cursor: pointer;
				margin-right: 10px;
				z-index: 2;
				color: get-color('wolf-black');

				&:active,
				&:focus {
					color: get-color('wolf-black');
				}

				svg {
					z-index: 0;
					padding: 17px;
				}

				&--disabled {
					opacity: 0.25;
					pointer-events: none;
				}
			}
		}

		&__image-container {
			display: inline-flex;
			flex-wrap: nowrap;
			overflow-x: scroll;
			max-width: 100%;
			width: 100%;
			scroll-behavior: smooth;

			.image-wrapper {
				&:first-child {
					padding-left: 16px;

					@media (min-width: $breakpoint-small-max) {
						padding-left: 32px;
					}
				}
			}

			img {
				height: 340px;
				width: auto;
				border-radius: 5px;
				margin-right: 15px;

				@media (max-width: $breakpoint-small-max) {
					height: 165px;
					margin-right: 10px;
				}
			}

			& picture:first-child {
				padding-left: 16px;

				@media (min-width: $breakpoint-small-max) {
					padding-left: 32px;
				}
			}

			picture {
				min-width: fit-content;
				min-width: -moz-fit-content;
				user-select: none;

				img {
					height: 340px;
					width: auto;
					border-radius: 5px;
					margin-right: 15px;

					@media (max-width: $breakpoint-small-max) {
						height: 165px;
						margin-right: 10px;
					}
				}
			}

			-ms-overflow-style: none;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		&__content {
			.image-wrapper {
				position: relative;
				width: auto;

				> img {
					opacity: 1;
					min-height: 340px;
					max-height: 340px;
					object-fit: cover;
					width: auto;
					max-width: none;

					@media (max-width: $breakpoint-medium-max) {
						min-height: 250px;
						max-height: 250px;
					}

					@media (max-width: $breakpoint-small-max) {
						min-height: 169px;
						max-height: 169px;
					}
				}
			}

			.image-wrapper.image-loader {
				position: relative;
				width: auto;
				height: auto;

				&::after {
					content: none;
				}

				> img {
					opacity: 1;
					min-height: 340px;
					max-height: 340px;
					object-fit: cover;
					width: auto;
					max-width: none;

					@media (max-width: $breakpoint-medium-max) {
						min-height: 250px;
						max-height: 250px;
					}

					@media (max-width: $breakpoint-small-max) {
						min-height: 169px;
						max-height: 169px;
					}
				}
			}
		}
	}
}

@include wolf-m-fhw-profile-sliders();
