@mixin wolf-o-teaser-list($name: '.o-teaser-list') {
	#{$name} {
		margin-top: 50px;

		@media (max-width: $breakpoint-medium) {
			margin-top: 30px;
		}

		&__title {
			margin-bottom: 70px;
			font-size: $global-xlarge-font-size;

			@media (max-width: $breakpoint-medium) {
				margin-top: 48px;
				margin-bottom: 22px;
				font-size: $global-large-font-size;
				letter-spacing: 0;
			}
		}
	}
}

@include wolf-o-teaser-list();
