@mixin wolf-m-icon-link-suggestion($name: '.m-icon-link-suggestion') {
	#{$name} {
		max-width: 835px;
		width: 100%;

		&__suggestion {
			a {
				display: flex;
				flex-direction: column;
				align-items: center;
				text-decoration: none;

				&:hover {
					text-decoration: none;

					.uk-icon {
						color: get-color(true-white);
					}
				}

				.icon {
					color: get-color(true-white);
					background-color: #81adc8;
					height: 60px;
					width: 60px;
					margin-bottom: 25px;
					border-radius: 50%;

					@media (max-width: $breakpoint-small-max) {
						margin-bottom: 14px;
					}

					svg {
						padding: 17px;
					}
				}

				.text {
					@include font-inter-500();
					font-size: 16px;
					line-height: 21px;
					color: get-color('true-black');

					@media (max-width: $breakpoint-small-max) {
						font-size: 14px;
					}
				}
			}
		}
	}
}

@include wolf-m-icon-link-suggestion();
