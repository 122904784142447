@mixin wolf-m-redirect-modal($name: '.m-redirect-modal') {
	#{$name} {
		position: fixed;
		z-index: 1002;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		&-backdrop {
			position: absolute;
			display: block;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: all 0.15s ease-out 0s;
			z-index: 2;
			background-color: rgba(17, 21, 28, 0.596);
			backdrop-filter: blur(4px);
		}

		&-container {
			position: relative;
			background-color: white;
			border-radius: 15px;
			padding: 30px;
			z-index: 3;
			gap: 20px;
			max-width: unquote('min(500px, 95vw)');
			box-sizing: border-box;
			font-size: 18px;
			font-family: Inter;
			font-weight: 400;
			line-height: 1.5em;
			letter-spacing: 0.4px;

			.m-redirect-modal-close {
				color: #0d5055;
				opacity: 0.5;
				background: transparent;
				border: 0;
				outline: none;
				cursor: pointer;
				width: 30px;
				height: 30px;
				position: absolute;
				right: 30px;
				top: 30px;
				min-width: 20px;
			}

			.redirect-title {
				font-weight: bolder;
				margin: 30px 0;
				width: 100%;
				@media (max-width: $breakpoint-small-max) {
					margin-bottom: 0;
				}
			}

			.redirect-description {
				width: 100%;
			}
		}
	}
}

@include wolf-m-redirect-modal();
