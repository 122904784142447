@mixin wolf-o-downloads($name: '.o-downloads') {
	#{$name} {
		.a-headline > * {
			margin-bottom: 65px;

			@media (max-width: $breakpoint-small-max) {
				margin-bottom: 35px;
			}
		}

		&.no-styling .m-download li .m-download__title {
			color: inherit;
		}
	}
}

@include wolf-o-downloads();
