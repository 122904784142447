@mixin wolf-m-gallery($name: '.m-gallery') {
	#{$name} {
		&__highlighted {
			width: fit-content;
			width: -moz-fit-content;
			position: relative;

			.image-wrapper {
				position: relative;
			}

			.energy-label {
				position: absolute;
				left: 0;
				top: 10px;
				width: 110px;
				height: fit-content;
				height: -moz-fit-content;
			}
		}

		&__thumbnails {
			margin-top: 1em;

			div {
				img {
					max-width: 135px;
				}
			}
		}
	}
}

@include wolf-m-gallery();
