@mixin wolf-o-text-image($name: '.o-text-image') {
	#{$name} {
		&__wrapper.padding-background-bottom {
			padding-top: 160px;

			@media (max-width: $breakpoint-small-max) {
				padding-top: 50px;
			}
		}

		&__wrapper.padding-background-top {
			padding-bottom: 160px;

			@media (max-width: $breakpoint-small-max) {
				padding-bottom: 50px;
			}
		}

		&__wrapper.padding-background-both {
			padding-bottom: 160px;
			padding-top: 160px;

			@media (max-width: $breakpoint-small-max) {
				padding-bottom: 50px;
				padding-top: 50px;
			}
		}

		&__text:not(.text-top):not(.text-bottom),
		&__text.text-middle {
			flex-direction: column;
			justify-content: center;
		}

		&__text.text-top {
			flex-direction: column;
			justify-content: flex-start;
		}

		&__text.text-bottom {
			flex-direction: column;
			justify-content: flex-end;
		}

		&__headline {
			margin-bottom: 64px;
			width: 100%;

			@media (max-width: $breakpoint-small-max) {
				margin-bottom: 24px;
			}

			* {
				margin: 0px;
			}
		}

		&__subheadline {
			margin-bottom: 42px;
			width: 80%;

			@media (max-width: $breakpoint-small-max) {
				margin-bottom: 24px;
			}

			* {
				margin: 0px;
			}
		}

		&__copytext {
			margin-bottom: 42px;
			max-width: 600px;

			@media (max-width: $breakpoint-small-max) {
				max-width: 100%;
				margin-bottom: 0;
			}

			[contenteditable='true'] {
				color: inherit;
				a {
					color: inherit;
					text-decoration-color: inherit;
				}
			}

			.a-copy {
				b,
				strong {
					font-weight: bold;
				}
			}
		}

		&__alertbox {
			padding: 20px;
			box-sizing: border-box;
		}

		&__image picture {
			height: auto;

			@media (max-width: $breakpoint-small-max) {
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
			}

			img {
				width: 100%;

				@media (min-width: $breakpoint-medium) {
					max-width: 750px;
				}
			}
		}

		&__image:not(.leftImg) {
			justify-content: flex-end;

			@media (min-width: $breakpoint-medium) {
				padding-left: 120px;
			}
		}

		&__image.leftImg {
			justify-content: flex-start;

			@media (min-width: $breakpoint-medium) {
				padding-right: 120px;
			}
		}

		&--left {
			#{$name}__text {
				@media (max-width: $breakpoint-small-max) {
					align-items: flex-start;
				}
			}

			#{$name}__headline,
			#{$name}__copytext,
			#{$name}__button {
				display: flex;
				width: 100%;
				max-width: none;
				align-items: flex-start;

				@media (max-width: $breakpoint-small-max) {
					width: 100%;
				}
			}

			#{$name}__copytext .a-copy {
				margin: 0;
				max-width: 600px;

				@media (max-width: $breakpoint-small-max) {
					max-width: 100%;
				}
			}
		}

		&__image .image-wrapper {
			position: relative;

			img {
				max-width: 750px;
				object-fit: contain;

				@media (max-width: $breakpoint-medium) {
					max-width: 100%;
				}
			}
		}

		&__image {
			@media (max-width: $breakpoint-small-max) {
				margin-top: 30px !important;
			}
		}

		&.padding-small-top {
			@media (max-width: $breakpoint-small-max) {
				padding-top: 15px;
				padding-bottom: 15px;
			}
		}

		.padding-standard .m-gallery__thumbnails {
			margin-bottom: 0px;
		}

		@media (max-width: $breakpoint-small-max) {
			.a-copy {
				margin-bottom: 0;
			}

			&.padding-standard {
				padding-top: 15px;
				padding-bottom: 15px;
			}

			&.padding-no-padding-bottom {
				padding-top: 24px;
			}
		}

		.a-copy {
			color: inherit;
			a {
				color: inherit;
				text-decoration-color: inherit;
			}
		}
	}
}

@include wolf-o-text-image();
