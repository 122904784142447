@mixin wolf-o-modal($name: '.o-modal') {
	#{$name} {
		&__body:not(.customWidth) {
			width: 746px !important;
		}

		&__close {
			margin-bottom: 1rem;
			text-align: end;

			.uk-modal-close {
				display: inline-flex;
				align-items: center;
			}

			svg {
				@media (min-width: $breakpoint-medium) {
					margin: 1rem;
				}
			}
		}

		&__content {
			@media (min-width: $breakpoint-medium) {
				text-align: center;
			}
			padding: 1rem 0;

			/* Replicating uikit*/
			table {
				text-align: left;
				border-collapse: collapse;
				border-spacing: 0;
				width: 100%;
				margin-bottom: 30px;
				display: table;
				box-sizing: border-box;
				text-indent: initial;
				border-color: grey;

				caption {
					font-size: 21px;
					text-align: left;
					color: #1e293a;
					display: table-caption;
				}

				thead {
					display: table-header-group;
					vertical-align: middle;
					border-color: inherit;
				}

				tfoot {
					font-size: 21px;

					tr {
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;

						td {
							padding: 16px 12px;
							vertical-align: top;
						}
					}
				}

				tbody {
					display: table-row-group;
					vertical-align: middle;
					border-color: inherit;

					tr {
						display: table-row;
						vertical-align: inherit;
						border-color: inherit;

						td {
							padding: 16px 12px;
							vertical-align: top;
						}
					}
				}
			}
		}

		&__body.customWidth {
			&--full {
				width: 100%;
				max-width: 1920px !important;
			}

			&--two-thirds {
				width: 66%;
				max-width: 1280px !important;
			}

			&--half {
				width: 50%;
				max-width: 960px !important;
			}
		}
	}
}
@mixin flex-o-modal($name: '.flex-modal') {
	#{$name} {
		padding-left: 0;
		padding-right: 0;

		&.uk-modal.uk-open {
			display: flex !important;

			.uk-modal-dialog.uk-modal-body {
				height: fit-content;
				height: moz-fit-content;
			}
		}

		&--center {
			justify-content: center;
		}

		&--right {
			justify-content: flex-end;
			padding-right: 80px;

			@media (max-width: $breakpoint-small-max) {
				padding-right: 20px;
			}

			.uk-modal-dialog.uk-modal-body {
				margin: 0;
			}
		}

		&--left {
			justify-content: flex-start;
			padding-left: 80px;

			@media (max-width: $breakpoint-small-max) {
				padding-left: 20px;
			}
			.uk-modal-dialog.uk-modal-body {
				margin: 0;
			}
		}
	}
}

@include wolf-o-modal();
@include flex-o-modal();
