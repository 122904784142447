@mixin wolf-p-standards($name: '.p-standards') {
	#{$name} {
		&__hero {
			background-position: center;
			background-repeat: no-repeat;
		}
	}
}

@include wolf-p-standards();
