@mixin wolf-m-formstep-add-system-component($name: '.m-formstep-add-system-component') {
	#{$name} {
		max-width: 869px;
		border-radius: 20px;
		@include container-dark-on-mobile(get-color(true-white), rgba(98, 103, 126, 0.12));
		@include text-clear-on-mobile(#62677e);

		&__text {
			font-size: 14px;
			line-height: 22px;
			margin: 0;

			@media (min-width: $breakpoint-medium-max) {
				font-size: 22px;
				font-weight: 700;
			}
		}

		&__gray-circle-button {
			width: 22px;
			height: 22px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			background-color: #62677e;
			margin-right: 20px;

			cursor: pointer;
			border: unset;
			transition: background-color 0.3s ease-in;

			&:hover:not(:disabled) {
				background-color: get-color(silver-grey);
			}

			&:disabled {
				pointer-events: none;
			}

			@media (min-width: $breakpoint-medium-max) {
				width: 48px;
				height: 48px;
				background-color: #f7f5f5;
			}
		}

		&__icon {
			width: 10px;
			height: 10px;
			display: inline-flex;
			align-items: center;
			justify-content: center;

			svg {
				color: #11151c !important;
				min-width: 10px;
			}

			@media (min-width: $breakpoint-medium-max) {
				width: 20px;
				height: 20px;

				svg {
					min-width: 20px;
				}
			}
		}

		&__dashed-box {
			border: 2px dashed #62677e;
			border-radius: 20px;
			padding: 16px;

			@media (min-width: $breakpoint-medium-max) {
				border: 2px dashed #efefef;
				padding: 32px;
			}
		}
	}
}

@include wolf-m-formstep-add-system-component();
