@mixin wolf-m-finder-select-step-static($name: '.m-finder-select-step-static') {
	#{$name} {
		display: flex;
		margin: auto;
		width: 100%;
		max-width: 1682px;
		border-radius: 5px;
		-webkit-box-shadow: 0px 0px 20px 10px rgba(183, 183, 183, 0.4);
		-moz-box-shadow: 0px 0px 20px 10px rgba(183, 183, 183, 0.4);
		box-shadow: 0px 0px 20px 10px rgba(183, 183, 183, 0.4);
		background-color: get-color('true-white');

		& > .uk-container {
			display: inline-flex;
			width: 100%;
			max-width: none;
			padding: 0;
		}

		@media (max-width: $breakpoint-small-max) {
			flex-direction: column;
			min-height: fit-content;
			height: 100%;
			width: 90%;
			padding: 0px 5%;
			-webkit-box-shadow: none;
			box-shadow: none;
			background-color: transparent;
		}

		&__header {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 30%;
			border-right: 1px solid #e8e8e8;

			@media (max-width: 1450px) {
				width: 40%;
			}

			#{$name}__headerElements {
				max-width: 60%;

				#{$name}__stepTitle {
					@include font-inter-600();
					font-size: 34px;
					line-height: 40px;

					color: get-color('wolf-black');
					margin-bottom: 10px;
				}

				#{$name}__stepSubtitle {
					@include font-inter-200();
					font-size: 20px;
					line-height: 26px;

					color: #0d5055;
					margin: 0px;
				}
			}
		}

		&__step-container {
			width: 100%;
			display: inline-flex;
			align-items: center;
			justify-content: center;

			@media (max-width: $breakpoint-small-max) {
				height: 100%;
				flex-direction: column;
			}

			#{$name}__options {
				display: inline-flex;
				align-items: space-around;
				justify-content: center;
				// width: 100%;
				margin: 50px 10px;

				@media (max-width: $breakpoint-medium-max) {
					flex-wrap: wrap;
					justify-content: flex-start;
				}

				@media (max-width: $breakpoint-small-max) {
					justify-content: center;
					padding-left: 0px;
					flex-direction: column;
					width: 100%;
					padding: 0px;
					margin: 24px 10px 0;
				}

				> div {
					width: 100%;
					justify-content: center;

					@media (max-width: $breakpoint-medium-max) {
						width: 50%;

						&:nth-of-type(n + 3) {
							margin-top: 30px;
						}
					}
					@media (max-width: $breakpoint-small-max) {
						width: 100%;
						margin: 10px 0px;
					}
				}

				.a-button {
					@include font-inter-600();
					font-size: 24px;
					line-height: 34px;
					width: 360px;
					padding: 35px 0px;
					display: flex;
					justify-content: center;
					margin-right: 10px;
					margin-left: 10px;
					background-color: transparent;
					color: get-color('wolf-black');
					border: 1px solid #efefef;

					@media (max-width: 1450px) {
						width: min-content;
						min-width: 250px;
						padding: 25px 0px;
					}

					@media (max-width: $breakpoint-small-max) {
						width: 100%;
						@include font-inter-600();
						font-size: 14px;
						line-height: 20px;
						padding: 20px 0px;
						margin-right: 0px;
						margin-left: 0px;
					}

					.a-button__text {
						@extend %hyphenate;
					}

					.a-button__icon-wrapper {
						border-radius: 50%;
						border: 2px solid #cbcbcb;
						padding: 5px;
						margin-right: 10px;
						min-width: 15px;

						@media (max-width: $breakpoint-small-max) {
							display: none;
						}

						svg {
							color: #cbcbcb;
							width: 15px;
							height: 15px;
						}
					}
				}

				.a-button:hover {
					border: 1px solid #efefef;
					background-color: get-color('wolf-red');
					color: get-color('true-white');
				}
			}
		}
	}
}

@include wolf-m-finder-select-step-static();
