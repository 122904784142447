@mixin wolf-o-heating-title($name: '.title-section') {
	#{$name} {
		width: 100%;
		margin-bottom: 30px;
		display: flex;
		justify-content: space-between;

		.a-headline {
			font-weight: 400;
			font-family: 'Saira';
			font-size: 30px;
			line-height: 30px;

			@media (max-width: 1279px) {
				font-weight: 500;
				font-size: 24px;
			}
		}

		.a-copy {
			font-weight: 400;
			font-family: 'Saira';
			margin: 8px 0 0;
			font-size: 20px;
			line-height: 20px;

			@media (max-width: 1279px) {
				margin: 20px 0 0;
				font-size: 14px;
				line-height: 14px;
			}
		}

		.headline {
			.title {
				font-family: 'Saira';
				font-weight: 500;
				font-size: 30px;
				line-height: 1;
				margin: 0;
				word-wrap: break-word;

				@media (max-width: 1279px) {
					font-size: 24px;
					color: get-color('true-white');
				}
			}

			.step {
				font-size: 18px;
			}

			.copy {
				line-height: 20px;
				font-size: 20px;
				font-family: 'Saira';
				font-weight: 400;
				margin-top: 20px;
			}
		}
	}
}

@mixin wolf-o-heating-cutomer-address($name: '.customer-address') {
	#{$name} {
		&-fieldset {
			border: solid 1px get-color('silver-grey');
			padding: 1.5em;

			@media (max-width: 1279px) {
				border-color: get-color('text-grey');
			}

			&-title {
				font-family: 'Inter';
				font-weight: 400;
				font-size: 24px;
				margin: 1em 0;
				line-height: normal;

				@media (max-width: 1279px) {
					color: get-color('true-white');
				}
			}

			.checkbox-holder {
				width: 50%;
				display: flex;

				@media (max-width: 767px) {
					width: 100%;

					+ .checkbox-holder {
						margin-top: -20px;
					}
				}

				> div {
					width: 100%;

					p {
						&:empty {
							display: none;
						}
					}

					@media (max-width: 767px) {
						margin: 30px 0 0;
					}
				}
			}
		}
	}
}

@mixin wolf-o-heating-dl-list($name: '.description-list') {
	#{$name} {
		display: flex;
		gap: 40px;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin: 24px 0 0 !important;
		font-size: 14px;

		@media (max-width: 1279px) {
			flex-direction: column;
			gap: 10px;
		}

		dt,
		dd {
			max-width: 200px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

@mixin wolf-o-heating-attachment($name: '.file-attachment') {
	#{$name} {
		user-select: none;
		margin-bottom: 32px;
		font-family: 'Inter';

		.file {
			&-wrapper {
				border: solid 1px #d2d2d2;
				padding: 1.5em;
				margin-bottom: 32px;
				border-radius: 5px;

				@media (max-width: 1279px) {
					border-color: get-color('text-grey');
				}
			}

			&-box {
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				button:not(:disabled) {
					.MuiButton-label {
						color: get-color('true-white');
					}
				}
			}

			&-choose {
				background-color: get-color('wolf-red');

				&:disabled {
					color: get-color('silver-grey');
					border-color: get-color('silver-grey');
					cursor: default;
					background-color: transparent;
				}
			}
		}

		.add-btn {
			background-color: get-color('true-white');
			color: get-color('wolf-red');
			border: solid 1px get-color('wolf-red');
			min-width: 140px;
			padding: 16px 30px;
			font-family: 'Inter';
			font-size: 16px;
			line-height: normal;
			font-weight: 400;
			cursor: pointer;

			&:disabled {
				color: get-color('silver-grey');
				border-color: get-color('silver-grey');
				cursor: default;
			}
		}
	}
}

@mixin wolf-o-heating-summary($name: '.resume') {
	#{$name} {
		background-color: #f8f8f8;
		border-radius: 20px;
		padding: 30px;
		width: 100%;
		max-width: calc(869px - 60px);
		margin-bottom: 24px;

		@media (max-width: 1279px) {
			background-color: #333942;
		}

		@media (max-width: 404px) {
			width: calc(100% - 30px);
		}

		&-title {
			font-size: 20px;
			font-weight: 700;
			line-height: 25px;
			margin: 0;

			+ a {
				display: inline-flex;
				align-items: center;
				justify-content: center;

				svg {
					width: 24px !important;
					height: 24px !important;
				}
			}
		}

		&-content {
			&-title {
				margin: 32px 0 0;
				font-size: 17px;
				line-height: 22px;
				font-weight: 500;
			}

			&-label {
				color: get-color('text-grey');
				font-size: 14px;
				margin: 20px 0 0;

				@media (max-width: 1279px) {
					color: get-color('true-white');
				}
			}

			&-detail {
				font-size: 16px;
				margin-top: 4px;

				@media (min-width: 1280px) {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}
		}

		+ .uk-grid-medium.uk-grid.uk-grid-stack {
			.MuiBox-root {
				margin-top: 15px !important;

				~ .MuiBox-root {
					margin-top: 0 !important;
				}
			}
		}
	}
}

@mixin wolf-o-heating-complete($name: '.complete-screen') {
	#{$name} {
		display: grid;
		place-content: center;

		&-wrapper {
			&-container,
			&-icon {
				display: flex;
				margin: 0 auto 48px;
				align-items: center;
				justify-content: center;
			}

			&-container {
				max-width: 926px;
				flex-direction: column;
			}

			&-icon {
				border-radius: 50%;
				border: solid 5px get-color('wolf-red');

				.icon {
					width: 80px;
					height: 80px;

					path {
						fill: none;
						stroke: get-color('wolf-red');
						stroke-width: 1.5;
						stroke-dasharray: 23;
						stroke-dashoffset: 23;
						animation: draw 0.5s linear forwards;
						animation-fill-mode: forwards;
					}
				}
			}
		}

		.complete {
			&-title,
			&-text {
				text-align: center;

				@media (max-width: 1279px) {
					color: get-color('true-white');
				}
			}

			&-title {
				margin: 0;
			}

			&-text {
				width: 60%;
				font-size: 21px;
				margin: 25px auto 0;
			}

			&-button {
				margin-top: 100px;
				background-color: get-color('wolf-red');
				color: get-color('true-white');
				padding: 16px 30px;
				font-weight: 500;
				text-decoration: none;

				@media (max-width: 1279px) {
					background-color: #212d40;
				}

				&:hover {
					background-color: #b70406;
					color: get-color('true-white');
					text-decoration: none;

					@media (max-width: 1279px) {
						background-color: #212d40;
					}
				}
			}
		}
	}
}

@mixin wolf-o-heating-form($name: '.o-heating-form') {
	#{$name} {
		.MuiSelect-select {
			&:not([multiple]) {
				option,
				optgroup {
					background-color: get-color('true-white');

					@media (max-width: 1279px) {
						background-color: get-color('wolf-black');
					}
				}
			}
		}

		.MuiOutlinedInput-input {
			@media (max-width: 1279px) {
				color: get-color('true-white');
			}
		}

		.MuiOutlinedInput-multiline {
			// for some reason, the multiline textarea doesn't have a proper height?!
			height: auto;
		}

		.m-formstep-step-title__more-info {
			margin-top: 8px;
			display: flex;
			align-items: flex-end;

			.a-button__extraInfo {
				left: -114px;
				top: 35px;
				width: 300px;
				text-align: center;
			}
		}

		&-grid-container {
			min-width: 100%;
			min-height: 100vh;
			height: auto;
			//overflow: hidden;
			position: relative;
			display: grid;
			grid-template-columns: 2fr 3fr;
			grid-column-gap: 0;

			@media (max-width: 1279px) {
				grid-template-columns: 1fr;
			}
		}

		&-sidebar {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			padding: 50px 5% 150px 50px;
			color: get-color('true-white');
			background-color: get-color('wolf-black');
			position: relative;

			@media (max-width: 1279px) {
				display: none;
			}

			> ul {
				position: sticky;
				font-family: 'Saira';
				list-style: none;
				margin: 0;
				padding-left: 0;
				align-items: center;
				top: 210px;

				@media (max-height: 685px) {
					top: 110px;
				}

				> li {
					display: 'flex';
					justify-content: flex-start;
					padding-left: 0;

					.list-btn {
						font-family: 'Saira';
						font-weight: 400;
						transition: font-size 0.2s ease-in;
						white-space: nowrap;
						font-size: 20px;
						line-height: 30px;
						letter-spacing: -1px;
						user-select: none;
						padding: 0 25px 0 0;
						color: get-color('dark-grey');
						background-color: transparent;
						border: 0;
						outline: none;

						&:hover {
							color: get-color('stroke-grey');
						}

						&:active {
							font-family: inherit;
							font-weight: 700;
							font-size: 30px;
							color: get-color('true-white');

							@media (max-width: 1279px) {
								font-size: 24px;
							}
						}

						&:focus-visible {
							outline: none;
						}

						.uk-badge {
							margin: 25px 25px 25px 40px;

							&.badge {
								font-family: 'Inter';
								font-weight: 600;
								font-size: 14px;
								transition: color 0.3s ease-in, background-color 0.3s ease-in;
								width: 25px;
								height: 25px;
							}

							&.badge-success {
								background-color: get-color('success') !important;

								svg {
									transform: translate(-1px, -1px);
								}
							}
						}
					}

					&.uk-active {
						.list-btn {
							font-size: 30px;
							color: get-color('true-white');
							line-height: 30px;
							font-weight: 400;
							letter-spacing: normal;

							@media (max-width: 1279px) {
								font-size: 24px;
							}
						}
					}

					&:not(.uk-active) {
						.uk-badge {
							background-color: get-color('dark-grey');
							color: get-color('wolf-black') !important;
						}

						&:first-child {
							background-color: transparent;
							color: get-color('dark-grey');
						}
					}
				}
			}
		}

		&-content {
			display: flex;
			// overflow: hidden;
			min-height: 100vh;
			max-height: none;
			align-self: center;
			flex-direction: column;
			justify-content: flex-start;

			&-inner {
				width: 100%;
				height: auto;
				margin: 0 auto;
				position: relative;

				@media (max-width: 1279px) {
					background-color: get-color('wolf-black');
				}

				.inner-wrap {
					margin: 0 auto;
					display: flex;
					justify-content: space-between;
					flex-direction: column;

					.logo {
						height: 130px;
						flex-grow: 0;
						padding: 32px 0 0 45px;
						position: absolute;
						top: 0;
						width: 100%;
						background-color: get-color('true-white');
						z-index: 10;
						overflow-y: auto;

						@media (max-width: 1279px) {
							background-color: #11151c;
							display: none;
						}
					}

					.completed {
						padding: 200px 0;

						@media (max-width: 1279px) {
							padding: 30px 0 100px;
						}
					}
				}
			}

			&-steps {
				flex-grow: 1;
				padding: 0;
				min-height: 80vh;
				height: auto;
				width: 90%;
				margin: 0 auto;
				max-width: 800px;
				padding-top: 30px;
				padding-bottom: 30px;
				// overflow-y: auto;

				&::-webkit-scrollbar {
					width: 14px;
					appearance: none;
					background-color: transparent;
				}

				&::-webkit-scrollbar-track,
				&::-webkit-scrollbar-thumb {
					background-clip: padding-box;
					border-left: 5px solid get-color('true-white');
					border-right: 5px solid get-color('true-white');
				}

				&::-webkit-scrollbar-track {
					background-color: rgb(247, 247, 247);
				}

				&::-webkit-scrollbar-thumb {
					background-color: #6a6e70;
				}

				@media (max-width: 1279px) {
					padding: 30px 0 0;
					background-color: get-color('wolf-black');
					color: get-color('true-white');

					&::-webkit-scrollbar-track,
					&::-webkit-scrollbar-thumb {
						border-left: 5px solid get-color('wolf-black');
						border-right: 5px solid get-color('wolf-black');
					}
				}

				@include wolf-o-heating-title();
			}
		}

		@include wolf-o-heating-cutomer-address();
		@include wolf-o-heating-dl-list();
		@include wolf-o-heating-attachment();
		@include wolf-o-heating-summary();
		@include wolf-o-heating-complete();

		.nav-button-wrapper {
			height: 180px;
			flex-grow: 0;
			position: sticky;
			bottom: 0;
			background-color: #fff;
			z-index: 10;
			width: 90%;
			max-width: 800px;
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
			flex-direction: row;
			flex-wrap: wrap;
			min-height: 10vh;
			gap: 32px;
			margin: auto;
			border-top: solid 1px #0001;
			padding-block: 30px;

			@media (max-width: 1279px) {
				background-color: #11151c;
				height: 120px;
				position: relative;
			}

			@media (max-width: 767px) {
				height: auto;
			}

			@media (max-width: 460px) {
				justify-content: space-around;
			}

			.btn-step {
				line-height: 1.4;
				padding: 16px 30px;
				font-size: 16px;
				font-weight: 500;
				border: solid 1px transparent;
				min-width: 140px;
				color: get-color('true-white');
				cursor: pointer;

				&.btn-prev,
				&.btn-next {
					&:disabled {
						background-color: get-color('light-grey');
						border-color: get-color('light-grey');
						color: get-color('silver-grey');

						@media (max-width: 1279px) {
							background-color: #11151c;
							border-color: rgba(255, 255, 255, 0.2);
							color: rgba(255, 255, 255, 0.2);
						}
					}
				}

				&:hover,
				&:focus {
					outline: 1px solid #81adc8;

					&:disabled {
						background-color: get-color('light-grey');
						color: get-color('silver-grey');

						@media (max-width: 1279px) {
							outline: none;
							background-color: #11151c;
							border-color: rgba(255, 255, 255, 0.2);
							color: rgba(255, 255, 255, 0.2);
						}
					}
				}

				&.btn-prev:not(:disabled) {
					background-color: get-color('true-white');
					border-color: get-color('wolf-black');
					color: get-color('wolf-black');
				}

				&.btn-next:not(:disabled) {
					background-color: get-color('wolf-red');
					border-color: get-color('wolf-red');
					color: get-color('true-white');
				}
			}
		}

		.user-none {
			user-select: none;
		}

		.hidden-file {
			color: transparent;
			width: 0;
			height: 0;
			line-height: 0;
			margin: 0;
			padding: 0;
			border: 0;
			background: none;
			pointer-events: none;
			cursor: default;
			outline: none;

			&::-webkit-file-upload-button {
				visibility: hidden;
			}

			&:active {
				outline: none;
			}
		}
	}
}

@include wolf-o-heating-form();
