$o-pdf-search-header-search-button-height: 58px;
@mixin wolf-o-pdf-search-header($name: '.o-pdf-search-header') {
	#{$name} {
		display: grid;
		grid-template-rows: 210px 7fr 2fr;
		padding: $global-navbar-offset-top 1em;

		@media (max-width: $breakpoint-small-max) {
			padding-top: 0;
			padding-bottom: 0;
		}
		@media (max-width: $breakpoint-medium-max) {
			// grid-template-rows: 2fr 4fr 0fr;
			grid-template-rows: 50px 4fr 0fr;

			& .uk-container {
				padding-left: 0;
			}
		}

		&__heading {
			text-align: center;
			max-width: 615px;
			margin: auto;
		}
		&__intro {
			line-height: 32px;
			font-size: $global-xsmall-font-size;
		}

		&__title--graphic {
			background-image: url('./../../../global/assets/images/einzelnormen-bg.svg');
			background-repeat: no-repeat;
			background-position: center;
			@media (max-width: $breakpoint-medium-max) {
				background-size: 140px;
			}
		}
		&__input {
			background-color: white;
			border: none;
			border-bottom: 1px solid map-get($colors, 'wolf-red');
			height: ($o-pdf-search-header-search-button-height - 1) !important; // see search-button
			line-height: normal;

			&::placeholder {
				font-size: 21px;

				@media (max-width: $breakpoint-medium-max) {
					font-size: 16px;
				}
			}
		}
		&__form {
			margin-left: auto;
			margin-right: auto;
			max-width: 780px;
		}
		&__search {
			display: flex;
			margin-bottom: 25px;
			> div {
				flex-direction: row;
				flex-grow: 5;
				+ div {
					flex-grow: 1;
				}
			}
		}
		&__searchbox {
			width: 100%;
		}

		// &__submitButton
		form input[type='submit'] {
			// height: ($o-pdf-search-header-search-button-height - 1) !important; // see input
			@include font-inter-600();

			font-size: 22px;
			line-height: 22px;

			// @media (max-width: $breakpoint-medium-max) {
			// 	max-width: 100%;
			// 	min-width: 100%;
			// }

			// span {
			// 	font-size: 22px;
			// 	@include font-inter-500;
			// }
		}
		&__advanced-text {
			color: map-get($colors, 'silver-grey');
			font-size: $global-xsmall-font-size;
			line-height: $global-xsmall-font-size;
			margin: 0;
		}
		&__advanced-toggle {
			text-align: center;

			@media (min-width: $breakpoint-small) {
				text-align: right;
			}

			> a {
				@include font-inter-500;

				display: inline-block;
				font-size: $global-xsmall-font-size;
				line-height: $global-xsmall-font-size;
				color: map-get($colors, 'wolf-red');
				text-decoration: none;

				&::before {
					// some svg?
					content: '>';
					display: inline-block;
					margin-right: 12px;
					font-weight: bold;
				}
				&:hover {
					// text-decoration: none;
				}
			}
		}
		&__toggle-search {
			margin-top: 40px;
			p.filter-label {
				margin: 0 0 5px;
				color: map-get($colors, 'dark-grey');
			}
			select {
				&.wolf-select {
					background-color: #fff;
					outline: 1px solid;
					outline-color: map-get($colors, 'silver-grey');

					&:focus {
						// border-width: 4px;
						outline-color: map-get($colors, 'wolf-black');
					}
				}
			}
			.results-count {
				span {
					display: inline-block;
					&:last-child {
						margin-left: 5px;
					}
				}
				&__total {
					color: map-get($colors, 'wolf-red');
				}
			}
		}
	}
}

@include wolf-o-pdf-search-header();
