@mixin wolf-o-accordion($name: '.o-accordion') {
	#{$name} {
		&[class*='text-color-'] {
			.o-accordion__navlist-desktop a,
			.uk-accordion-title {
				color: inherit;
			}

			.o-accordion__navlist-desktop .uk-active a {
				color: inherit;
				border-color: currentColor;
			}

			.uk-accordion-content .text-content {
				color: initial;
			}

			.uk-accordion-content.a-copy a:after {
				background: currentColor;
			}

			&:not([class*='headline-color-']) {
				.a-headline {
					color: initial;
				}
			}
		}

		&[class*='copy-color-'] {
			.uk-accordion-content.a-copy {
				.m-download {
					color: currentColor;

					a.download,
					&__number,
					&__title,
					&__underText,
					&__typing,
					&__icon .uk-icon {
						color: currentColor;
					}

					&__icon span {
						background: transparent;

						backdrop-filter: saturate(0.5);
						-webkit-backdrop-filter: saturate(0.5);
					}
				}

				.text-content {
					color: currentColor;
				}

				a:after {
					background: currentColor;
				}
			}
		}

		.uk-accordion-content.a-copy a:after {
			background: currentColor;
		}

		&__nav-accordion-wrapper {
			@media (max-width: $breakpoint-small-max) {
				display: flex;
				flex-direction: column !important;
			}
		}

		&__navlist-desktop {
			display: flex;
			flex-direction: column;
			padding: 0 !important;

			.uk-active {
				a {
					color: #141313;
					font-weight: 600;
					transition: all 100ms ease-in-out;
					border-bottom: solid 2px get-color('accent');

					&:hover {
						color: #141313;
					}
				}
			}

			li {
				margin-bottom: 1.2em;
				list-style: none;

				a {
					text-decoration: none;
					color: get-color('dark-blue-50-white');
					font-size: 20px;
					letter-spacing: -0.4px;
					font-weight: 300;
					border-bottom: solid 2px transparent;
					padding-bottom: 0.2em;
					transition: all 0.1s ease-in-out;

					&:hover {
						color: #141313a9;
						font-weight: 500;
						transition: all 100ms ease-in-out;
					}
				}
			}
		}

		&__navlist-mobile {
			padding: 0;
			width: 100%;
			background-color: transparent;

			.selector {
				width: 100%;
				padding: 15px 10px;
				font-size: 20px;

				min-width: 164px;
				margin-bottom: 2rem;

				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				background: transparent;
				background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='40' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
				background-repeat: no-repeat;
				background-position-x: 100%;
				background-position-y: 15px;
			}
		}

		.uk-grid {
			margin-left: 0 !important;
		}

		.uk-grid > * {
			padding-left: 0 !important;
		}

		.a-headline {
			@media (min-width: $breakpoint-medium) {
				padding-top: 70px;
				margin-bottom: 100px;
				background-color: transparent;
				+ .a-copy {
					margin-top: -45px;
					margin-bottom: 100px;
					padding: 30px 1rem;
				}
			}
			@media (max-width: $breakpoint-small-max) {
				+ .a-copy {
					margin: 0;

					padding: 0 0 14px;
				}
			}
		}

		.a-copy {
			@include font-inter-200();
			font-size: 24px;
			line-height: 46px;
			width: 90%;
			margin: 55px 0 0 0;
			background-color: transparent;

			@media (max-width: $breakpoint-medium) {
				font-size: 16px;
				line-height: 24px;
				width: 100%;
				margin: 0 0 50px 0;
				&:has(+ .a-headline) {
					margin-bottom: 100px;
				}
			}
		}

		&.padding-small-top {
			@media (max-width: $breakpoint-small-max) {
				padding-top: 24px;
				padding-bottom: 24px;
			}
		}
	}
}

@include wolf-o-accordion();
