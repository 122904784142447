@mixin wolf-o-search-stage($name: '.o-search-stage') {
	#{$name} {
		.section__background--image {
			// padding: $global-navbar-offset-top;
			height: fit-content;
			height: -moz-fit-content;
			background-image: url('./../../../global/assets/images/library-wolf.jpg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			padding-top: 3rem;
			width: 100%;
			max-width: 1920px;
			overflow: hidden;
			height: calc(100vh - 3rem);

			@media (min-width: 1935px) {
				width: 100vw;
				position: relative;
				left: 0;
				right: 0;
				margin: 0 auto;
				overflow: hidden;
			}
		}

		&__container {
			width: 100%;
			max-width: calc(100% - 40px);
			margin: auto;
			@media (min-width: 1720px) {
				max-width: 1640px;
			}
		}
		.m-kb-navbar {
			width: 100%;
		}

		.section__header--align {
			margin-top: 144px;
			margin-bottom: 181px;
			display: flex;
			align-items: center;
			justify-content: center;

			div {
				@media (max-width: $breakpoint-medium) {
					display: none;
				}
			}

			@media (max-width: $breakpoint-large) {
				margin: 3rem 0;
				flex-direction: column-reverse;
			}

			div {
				height: fit-content;
				flex: 1;
			}

			h2 {
				font-weight: 200;
				color: get-color(true-white);
				margin: 2rem;
			}
		}
	}
}

@include wolf-o-search-stage();
