html,
body {
	// set the font size so that rem measurements are easy to calculate
	// e.g. 1rem = 10px, 2rem = 20px...
	// font-size: 62.5%;
	//font-size: 100%;
	@include font-inter-400;
	// -webkit-font-smoothing: antialiased;

	//@media (max-width: map-get($breakpoints, 'small') - 1px) {
	//    font-size: 87.5%; // 14px
	//}
}

a {
	color: map-get($colors, 'wolf-black');
	text-decoration: underline solid;
	text-decoration-color: map-get($colors, 'wolf-red');
}

.ahu-section-gradient {
	background: linear-gradient(180deg, #ececec 0%, #ffffff 15%, white 100%);
}

.ahu-section-gradient-alt {
	background: linear-gradient(180deg, #f1f1f1 0%, white 50%, #f1f1f1 100%);
	box-shadow: 0px 0px 99px #f0f0f0;
}

.ahu-section-gradient-text {
	background: linear-gradient(0deg, white 0%, #fdfdfd 25%, #f7f7f7 100%);
}

.ahu-section-gradient-text-bottom {
	background: linear-gradient(180deg, white 0%, #fdfdfd 75%, #f7f7f7 100%);
}

.ahu-bottom-margin-bottom {
	margin-bottom: 160px;

	@media (max-width: $breakpoint-small-max) {
		margin-bottom: 50px;
	}
}

.cb-ltr-gradient-background {
	background: rgb(111, 111, 116);
	background: linear-gradient(
		90deg,
		rgba(111, 111, 116, 0.9051995798319328) 0%,
		rgba(192, 192, 195, 0.9023984593837535) 40%,
		rgba(255, 255, 255, 1) 100%
	);
}
