@mixin wolf-m-nav-bar($name: '.m-nav-bar') {
	#{$name} {
		padding: 0;
		transition: background-color 200ms ease;
		transition-delay: 1000ms;
		width: 100%;
		margin: auto;
		z-index: 998;

		@media (max-width: $breakpoint-xsmall-max) {
			height: 75px;
			box-sizing: border-box;
			padding: 21px 22px;
		}

		&:hover,
		&:focus {
			transition-delay: 0s;
			background-color: get-color('wolf-black');
		}

		@media (max-width: $breakpoint-small-max) {
			background-color: get-color('wolf-black');
			z-index: 10;
		}

		&.light {
			.a-button {
				color: get-color('wolf-black');
			}
			#{$name}__container-center {
				a#{$name}__nav-link {
					color: get-color('wolf-black');
				}
			}

			#{$name}__container-left {
				.o-nav__burger-icon {
					color: get-color('wolf-black');
				}
			}

			&:hover,
			&:focus {
				background-color: get-color('true-white');
			}

			@media (max-width: $breakpoint-small-max) {
				background-color: get-color('true-white');
			}

			#{$name}__container-right {
				.o-nav__burger-icon {
					color: #85a6a9;
				}
			}
		}

		&.back-transparent {
			transition: none;
			background-color: transparent;

			#{$name}__container-center {
				a#{$name}__nav-link {
					color: get-color('wolf-black');
				}
			}

			&:hover,
			&:focus {
				background-color: transparent;
			}

			@media (max-width: $breakpoint-small-max) {
				background-color: transparent;

				.o-nav__burger-icon {
					color: #85a6a9;
				}
			}
		}

		&__wrapper.uk-navbar-container {
			background-color: unset;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			min-height: 110px;

			@media (max-width: $breakpoint-large-max) {
				min-height: 100px;
			}

			@media (max-width: $breakpoint-medium) {
				min-height: 40px;
			}

			@media (max-width: $breakpoint-xsmall-max) {
				min-height: 28px;
			}

			&:hover {
				#{$name}__container-right {
					.a-button {
						&.uk-button-clear-blue {
							background-color: get-color('accent');

							&:hover,
							&:focus {
								@include font-inter-500;
								background-color: get-color('true-white');
								color: get-color('accent');
							}
						}
					}
				}
			}

			+ .o-nav__burger-icon {
				@media (min-width: $breakpoint-medium-max) {
					display: none;
				}
			}
		}

		&__container-center {
			ul.uk-navbar-nav > li:hover {
				> a#{$name}__nav-link {
					color: get-color('true-white');
				}
			}

			#{$name}__nav-link {
				color: get-color('true-white');
				padding: 16px 0;

				&:hover,
				&:focus,
				&.a-button--active {
					opacity: 1;
					outline: none;

					.a-button__text {
						text-decoration: underline;
						text-underline-offset: 5px;
						text-decoration-color: get-color('primary');
					}
				}

				&:not(:last-of-type) {
					margin-right: 30px;

					@media (min-width: $breakpoint-medium) and (max-width: 1440px) {
						margin-right: 5px;
					}
				}

				&.has-icon {
					padding: 0;

					& .uk-icon {
						@media (min-width: $breakpoint-medium) and (max-width: 1440px) {
							padding-right: 0;
						}
					}
				}
			}

			@media (min-width: $breakpoint-medium-max) {
				width: 100% !important;

				.navbar-center {
					&-left,
					&-right {
						width: calc(50% - (74px / 2));
					}
				}
			}
		}

		&__logo {
			margin: auto 55px;
			min-width: 175px;

			@media (max-width: 1600px) {
				margin: auto 20px;
			}

			@media (min-width: $breakpoint-medium) and (max-width: 1440px) {
				margin: auto 10px;
			}

			@media (max-width: $breakpoint-medium) {
				width: 120px;
				min-width: 120px;

				> svg {
					width: 120px;
				}
			}

			@media (max-width: $breakpoint-xsmall-max) {
				margin: 4px 0 0;
				width: 78px;
				min-width: 78px;

				> svg {
					width: 78px;
				}
			}
		}

		&__back-link {
			border: none;
			background-color: transparent;
			box-shadow: none;

			.uk-icon {
				padding: 0;
			}
		}

		.uk-navbar-nav {
			display: flex;
			margin: 0;
			padding: 0;
			list-style: none;
			min-width: 400px;
			align-items: center;

			li {
				padding: 0 10px;

				&.m-nav-flyout__column-list-item {
					padding-left: 0;
				}
			}

			> li {
				a {
					@media (min-width: 1200px) and (max-width: 1320px) {
						padding: 0 5px;
					}
				}
				> .a-button {
					font-family: 'Inter', 'Inter-fallback', sans-serif;

					&:focus,
					&:focus-visible,
					&:active {
						color: white;
					}
				}
			}
		}

		.navbar-center {
			display: flex;
			margin: auto 0;
			align-items: center;
			justify-content: center;

			&-right {
				.uk-navbar-nav {
					> li {
						a[aria-expanded='true'] {
							color: get-color('true-white');
						}
					}
				}
			}
		}

		.navbar-center-left {
			.uk-navbar-nav {
				justify-content: flex-end;
			}
		}

		&__container-left {
			padding-left: 48px;
			display: inline-flex;
			position: absolute;
			z-index: 991;

			.a-button {
				color: get-color('lighter-grey');
			}

			@media (min-width: $breakpoint-medium) and (max-width: 1440px) {
				padding-left: 34px;
			}

			@media (max-width: $breakpoint-xsmall-max) {
				padding-left: 0;

				.a-button {
					&:hover,
					&:focus {
						background-color: transparent;
					}

					&__icon-wrapper.uk-icon {
						padding: 15px 0;
					}
				}
			}
		}

		&__container-right {
			padding-right: 48px;
			position: absolute;
			z-index: 991;
			right: 0;

			:not(.m-navigation-switcher) {
				.a-button {
					padding: 12px 30px;
					border-radius: 30px;

					&.uk-button-clear-blue {
						background-color: get-color('accent');
						border-color: get-color('accent');
						color: get-color('true-white');
						z-index: 2;

						&:hover,
						&:focus {
							@include font-inter-500;
							background-color: get-color('true-white');
							border-color: get-color('accent');
							color: get-color('secondary');
						}
					}

					&.uk-button-primary-blue {
						border-color: get-color('accent');
						color: get-color('true-white');

						&:hover {
							background-color: get-color('true-white');
							color: get-color('accent');
						}

						&:focus {
							background-color: transparent;
							color: get-color('true-white');
						}
					}
				}
			}

			.m-navigation-switcher {
				&.uk-button-clear-blue {
					background-color: get-color('accent');
					border-color: get-color('accent');
					color: get-color('true-white');
					z-index: 2;

					&:hover,
					&:focus {
						@include font-inter-500;
						background-color: get-color('true-white');
						border-color: get-color('accent');
						color: get-color('secondary');
					}
				}

				&.uk-button-primary-blue {
					border-color: get-color('accent');
					color: get-color('true-white');

					&:hover {
						background-color: get-color('true-white');
						color: get-color('accent');
					}

					&:focus {
						background-color: get-color('true-white');
						color: get-color('true-white');
					}
				}
			}

			> .a-button {
				@media (max-width: $breakpoint-medium-max) {
					display: none;
				}
			}

			&.only-right-button {
				.a-button.uk-button-clear-blue {
					background-color: #81adc8;
					border-color: #81adc8;
					color: #fff;
					z-index: 2;
					padding: 12px 30px;
					border-radius: 30px;
					min-height: 48px;

					&:hover,
					&:focus {
						background-color: #fff;
						color: #81adc8;
					}
				}
			}

			@media (min-width: $breakpoint-medium) and (max-width: 1440px) {
				padding-right: 10px;
			}

			@media (max-width: $breakpoint-xsmall-max) {
				padding-right: 0;
			}

			#{$name}__close-button {
				color: get-color('true-white');
			}
		}

		&__dropdown {
			padding: 0;
			min-width: 1233px;
			left: 50% !important;
			transform: translateX(-50%);
			display: none;

			.m-nav-flyout {
				opacity: 1;
				transition: opacity 10ms ease;
			}

			&.uk-open {
				.m-nav-flyout {
					opacity: 1;
				}
			}
		}

		button.a-button {
			background-color: transparent;
		}

		.a-button {
			color: get-color('true-white');
		}

		@import './templates/new-design.scss';
	}
}

@include wolf-m-nav-bar();
