@mixin wolf-o-fhw-partner-profile($name: '.o-fhw-partner-profile') {
	#{$name} {
		margin: 0 auto;

		&__block {
			padding: 16px;

			@media (min-width: $breakpoint-medium) {
				padding: 32px;
			}
		}

		&__top-gradient {
			padding: 32px 16px;

			@media (min-width: $breakpoint-medium) {
				padding: 64px 32px;
				background: #f0f0f0;
				background: linear-gradient(180deg, #f0f0f0 0%, get-color(true-white) 12%);
			}
		}

		&__bottom-gradient {
			padding: 32px 0;

			.a-headline {
				padding-left: 16px;
				padding-right: 16px;

				@media (min-width: $breakpoint-medium) {
				}
			}

			@media (min-width: $breakpoint-medium) {
				padding: 64px 32px;
				background: get-color(true-white);
				background: linear-gradient(180deg, get-color(true-white) 88%, #f0f0f0 100%);
			}
		}

		&__rounded {
			border-radius: 32px;
			background-color: get-color(light-grey);
			color: get-color(primary);
			min-width: calc(50% - 2px);
			border: unset;
			font-size: 12px;
			outline: unset !important;

			& .uk-button {
				color: get-color(primary);
				background-color: get-color(light-grey);
			}

			& .uk-active {
				color: get-color(light-grey);
				background-color: get-color(primary);
			}

			&:hover:focus:active {
				& .uk-active {
					color: get-color(light-grey);
					background-color: get-color(primary);
				}

				background-color: transparent;
				color: get-color(primary);
			}
		}

		&__block-switcher {
			margin: 16px 16px 0;
			padding: 2px;
			background-color: get-color(light-grey);

			@media (min-width: $breakpoint-medium) {
				margin: 32px 32px 0;
			}
		}

		.m-fhw-profile {
			&-header__company-content {
				position: relative;

				.image-wrapper {
					position: relative;
					width: 200px;
					height: 200px;
					display: flex;
					align-items: center;
					justify-content: center;

					@media (min-width: $breakpoint-medium) {
						margin-right: 40px;
						width: 152px;
						height: 152px;
					}

					&.image-loader {
						opacity: 1;

						> img {
							opacity: 1;
						}
					}

					&::after {
						content: none;
					}

					> img {
						object-fit: contain;
					}
				}
			}

			&-header__text-container {
				> .a-headline {
					h1,
					h2,
					h3,
					h4,
					h5,
					h6 {
						@media (max-width: $breakpoint-xsmall-max) {
							font-size: 6vw;
						}
					}
				}
			}
		}
	}
}

@include wolf-o-fhw-partner-profile();
