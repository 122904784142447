@mixin wolf-m-finder-results-input($name: '.m-finder-results-input') {
	#{$name} {
		&__headline {
			.a-headline {
				color: #bfbfbf;

				@media (max-width: $breakpoint-small-max) {
					color: #727272;
					margin-bottom: 10px;
				}
			}
		}

		&__step-container {
			@media (max-width: $breakpoint-small-max) {
				display: inline-flex;
			}

			@media (max-width: 450px) {
				// flex-direction: column;
				width: 100%;
			}
		}

		&__step-list {
			display: inline-flex;
			width: 100%;
			border: 2px solid #e8e8e8;
			border-radius: 15px;
			margin-bottom: 20px;

			@media (max-width: $breakpoint-small-max) {
				flex-direction: column;
				padding: 10px;
				margin-right: 10px;
				border-radius: 0px;
				border-color: #707070;
				width: 130px;
			}

			.stepHead {
				display: inline-flex;
				align-items: center;
				padding: 38px 0px;

				width: 100%;

				border-right: 1px solid #e8e8e8;

				@media (max-width: $breakpoint-small-max) {
					flex-direction: column;
					border-right: 0px;
					max-width: 100% !important;
					padding: 0px;
				}

				.label {
					@include font-inter-400();
					font-size: 11px;
					line-height: 11px;
					color: #636b79;

					border: 1px solid #e8e9eb;
					border-radius: 15px;

					padding: 8px 22px;
					margin-left: 20px;

					height: fit-content;
					height: -moz-fit-content;
					min-width: fit-content;
					min-width: -moz-fit-content;

					@media (max-width: $breakpoint-small-max) {
						margin: 0px;
						align-items: center;
						padding: 8px 15px;
					}
				}

				.a-headline .a-headline {
					margin-left: 43px;
					margin-right: 60px;
					margin-bottom: 0px;
					word-break: break-all;

					@media (max-width: $breakpoint-small-max) {
						align-items: center;
						margin: 24px 0px 0px;
					}
				}
			}

			.options {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				align-content: space-between;
				margin: 30px auto;

				@media (max-width: $breakpoint-small-max) {
					margin: 0px;
				}

				.a-button {
					color: get-color('true-white');
					@include font-inter-600();

					margin: 0px 5px 10px 0px;

					background-color: #e10000;
					border-radius: 10px;

					width: fit-content;
					width: -moz-fit-content;

					margin-bottom: 0px;

					@media (max-width: $breakpoint-small-max) {
						padding: 20px 15px;
						font-size: 12px;
						line-height: 12px;
						margin: 16px 0px 0px;
					}
				}
			}
		}

		&__step-list.longtext {
			.stepHead {
				max-width: 60%;
				@media (max-width: $breakpoint-small-max) {
					max-width: 100%;
				}

				.a-headline .a-headline {
					display: none;

					@media (max-width: $breakpoint-small-max) {
						display: flex;
					}
				}

				.a-copy {
					margin-left: 25px;
					margin-right: 50px;
					margin-top: 0px;

					@media (max-width: $breakpoint-small-max) {
						display: none;
					}
				}
			}
		}

		&__step-list:not(.longtext) {
			.stepHead {
				max-width: 35%;

				@media (max-width: $breakpoint-small-max) {
					max-width: 100%;
				}
			}

			.options {
				width: 100%;
			}
		}
		@media (max-width: $breakpoint-small-max) {
			max-width: 100%;
			overflow-x: scroll;
			margin-bottom: 15px;
		}

		&::-webkit-scrollbar {
			@media (max-width: $breakpoint-small-max) {
				height: 40px;
				-webkit-appearance: none;
				background-color: rgba(255, 255, 255, 0);
			}
		}

		&::-webkit-scrollbar-track,
		&::-webkit-scrollbar-thumb {
			@media (max-width: $breakpoint-small-max) {
				background-clip: padding-box;
			}
		}

		&::-webkit-scrollbar-track {
			@media (max-width: $breakpoint-small-max) {
				border-top: 25px solid rgba(255, 255, 255, 0);
				border-bottom: 5px solid rgba(255, 255, 255, 0);
				border-left: 0px solid rgba(255, 255, 255, 0);
				border-right: 16px solid rgba(255, 255, 255, 0);
				background-color: #f5f5f5;
				width: 100%;
			}
		}

		&::-webkit-scrollbar-thumb {
			@media (max-width: $breakpoint-small-max) {
				border-top: 25px solid rgba(255, 255, 255, 0);
				border-bottom: 5px solid rgba(255, 255, 255, 0);
				border-right: 5px solid rgba(255, 255, 255, 0);
				border-left: 0px solid rgba(255, 255, 255, 0);
				background-color: rgba(0, 0, 0, 0.4);
			}
		}
	}
}

@include wolf-m-finder-results-input();
