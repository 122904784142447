@mixin wolf-m-ahu-footer($name: '.m-ahu-footer') {
	#{$name} {
		* {
			color: white;
		}
		.uk-icon [fill='#11151c'] {
			fill: #11151c !important;
		}

		&__outer-wrap {
			position: relative;
			background-color: get-color('wolf-black');
			overflow-x: hidden;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			@media (max-width: $breakpoint-medium-max) {
				height: initial;
				margin-top: 0;
			}

			@import './color-themes/pro-klima-blue.scss';
		}

		&__inner-wrap {
			padding: 0 24px;
			max-width: 1568px;
			width: 100%;
			box-sizing: border-box;
			margin: 0 auto;

			@media (max-width: $breakpoint-medium-max) {
				padding: 25px;
			}

			@media (max-width: $breakpoint-xsmall-max) {
				padding: 24px 24px 16px;
			}
		}

		&__main {
			max-width: 100vw;
			height: 200px;
			display: grid;
			grid-template-columns: 1fr 2fr 1fr;

			@media (max-width: $breakpoint-medium-max) {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				height: 70px;
			}

			@media (max-width: $breakpoint-xsmall-max) {
				flex-direction: column;
				height: auto;
			}

			.a-wolf-logo {
				width: 157px;
				margin: auto auto auto 0;

				@media (max-width: $breakpoint-medium-max) {
					width: 60px;
				}

				@media (max-width: $breakpoint-xsmall-max) {
					width: 90px;
					margin: auto;
				}

				svg {
					width: 174px;
				}
			}

			.navlinks {
				justify-self: center;
				align-self: center;
				a {
					font-size: 20px;
					margin-right: 2rem;
					padding: 15px 0;
				}
			}

			.a-socialmedia {
				justify-self: center;
				align-self: center;
				gap: 20px;
				@media (max-width: $breakpoint-small-max) {
					flex-wrap: wrap;
					justify-content: center;
					padding: 15px 0;
					gap: 24px;
					padding: 30px 0;
				}

				svg {
					width: 32px;
					min-width: 32px;
				}
			}
		}

		&__sub {
			max-width: 100vw;
			height: 60px;
			display: grid;
			grid-template-columns: 2fr 1fr 2fr;
			border-top: solid 1px get-color('dark-grey');
			padding: 24px 0;
			column-gap: 30px;
			padding-top: 0;
			@media (max-width: $breakpoint-medium-max) {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 47px;
				padding: 16px 0 0;
			}

			@media (max-width: $breakpoint-small) {
				flex-wrap: wrap;
				padding: 10px 0 0 0;
				padding-top: 0;
			}

			@media (max-width: $breakpoint-xsmall-max) {
				height: auto;
			}

			@media (max-width: 359px) {
				flex-direction: column;
				row-gap: 24px;
			}

			.copyright {
				align-self: center;
				font-size: 16px;
				letter-spacing: -0.32px;

				@media (max-width: $breakpoint-medium-max) {
					font-size: 10px;
					letter-spacing: -0.16px;
				}

				@media (max-width: $breakpoint-small) {
					max-width: calc(50% - 15px);
				}

				@media (max-width: $breakpoint-xsmall-max) {
					font-size: 14px;
					letter-spacing: -0.16px;
					order: 2;
					white-space: nowrap;
				}

				@media (max-width: 359px) {
					width: 100%;
					order: 3;
					margin-bottom: 16px;
				}

				span {
					color: get-color('true-white');

					a {
						color: get-color('true-white');
						text-decoration-color: get-color('true-white');
					}
				}
			}

			.a-socialmedia {
				justify-self: center;
				align-self: center;

				@media (max-width: $breakpoint-small) {
					max-width: calc(50% - 15px);
				}

				@media (max-width: $breakpoint-xsmall-max) {
					order: 3;
					display: flex;
					justify-content: flex-end;
					gap: 10px;
				}

				@media (max-width: 359px) {
					width: 100%;
					order: 2;
				}

				a {
					display: inline-flex;
				}

				svg {
					width: 32px;
					min-width: 32px;
				}
			}

			.sitemap {
				align-self: center;
				justify-self: flex-end;
				height: 26px !important;
				display: flex;
				gap: 0.25em 2em;

				@media (max-width: $breakpoint-medium-max) {
					height: auto !important;
					flex-wrap: wrap;
				}

				@media (max-width: $breakpoint-small) {
					width: 100%;
					max-width: 100% !important;
					justify-content: center !important;
					margin: 0;
					flex-flow: column wrap;
				}

				@media (max-width: $breakpoint-xsmall-max) {
					order: 1;
					height: auto !important;
					margin: 5px 0;
					display: flex;
					flex-flow: row wrap;
					align-items: flex-start;
					max-width: 30%;
					gap: 0px 16px;
				}

				a {
					text-decoration: none;
					@media (max-width: $breakpoint-medium-max) {
						font-size: 14px;
						letter-spacing: -0.16px;
					}
					@media (max-width: $breakpoint-small) {
						padding: 15px 14px;
					}
					@media (max-width: $breakpoint-xsmall-max) {
						font-size: 14px;
						padding: 14px 8px;
					}
				}
			}
		}
	}
}

@include wolf-m-ahu-footer();
