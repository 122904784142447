@mixin wolf-m-video-overlay($name: '.m-video-overlay') {
	#{$name} {
		// common to all layouts
		&__layout {
			display: flex;
			justify-content: stretch;
			flex-direction: column;

			// @media (min-width: $breakpoint-medium) {
			// 	flex-direction: row;
			// }
		}

		.layout-0 {
			#{$name} {
				&__playButtonWrapper {
					flex: auto;
					display: flex;
					@media (min-width: $breakpoint-medium) {
						flex: 1; // make equal width
						// padding: 100px 0;
					}
				}
				// @todo - copied styles from layout 1!
				&__playButton {
					background: transparent;
					border-radius: 50%;

					margin: auto;
					outline: none;
					user-select: none;

					@media (min-width: $breakpoint-medium) {
						border: 3px solid;
						width: 250px;
						height: 250px;
						> svg {
							width: 80px;
							height: auto;
							margin: auto;
							transform: translateX(8px); // this fixes the play button being off-centre
						}
					}
					@media (max-width: $breakpoint-small-max) {
						border: 2px solid;
						width: 80px;
						height: 80px;
						> svg {
							width: 30px;
							height: auto;
							margin: auto;
							transform: translateX(2px); // this fixes the play button being off-centre
						}
					}

					&:active,
					&:focus {
						outline: none;
					}

					&:hover {
						background-color: rgba(255, 255, 255, 0.4);
					}
				}
			}
		}
		.layout-1 {
			#{$name} {
				&__contentWrapper,
				&__playButtonWrapper {
					flex: auto;
					display: flex;
					@media (min-width: $breakpoint-medium) {
						flex: 1; // make equal width
						// padding: 100px 0;
					}
				}
				&__contentWrapper {
					@media (max-width: $breakpoint-small-max) {
						display: none;
					}
					.inner {
						padding: 0 40px;
						@media (min-width: $breakpoint-large) {
							padding: 0 100px;
						}

						h1,
						h2,
						h3,
						h4,
						h5,
						h6 {
							text-align: left;

							font-size: 32px;
							line-height: 1.1;
							margin: 0 0 0.75em 0;

							@media (min-width: $breakpoint-medium) {
								font-size: 75px;
								line-height: 80px;
								margin: 0 0 70px 0;
							}
						}

						.ext-content {
							@include font-inter-300();
							text-align: left;
							margin-top: 0;
							font-size: 13px;
							line-height: 1.1;

							&:last-child {
								margin-bottom: 0;
							}

							@media (min-width: $breakpoint-medium) {
								font-size: 16px;
								line-height: 28px;
							}
						}

						.button-content {
							@include font-inter-400();
							text-align: left;
							margin-top: 55px;
							font-size: 16px;
							line-height: 1.1;

							@media (min-width: $breakpoint-medium) {
								font-size: 14px;
								line-height: 14px;
							}
						}
					}
				}

				&__playButton {
					background: transparent;
					border-radius: 50%;

					margin: auto;
					outline: none;
					user-select: none;

					@media (min-width: $breakpoint-medium) {
						border: 3px solid;
						width: 250px;
						height: 250px;
						> svg {
							width: 80px;
							height: auto;
							margin: auto;
							transform: translateX(8px); // this fixes the play button being off-centre
						}
					}
					@media (max-width: $breakpoint-small-max) {
						border: 2px solid;
						width: 80px;
						height: 80px;
						> svg {
							width: 30px;
							height: auto;
							margin: auto;
							transform: translateX(2px); // this fixes the play button being off-centre
						}
					}

					&:active,
					&:focus {
						outline: none;
					}

					&:hover {
						background-color: rgba(255, 255, 255, 0.4);
					}
				}
			}
		}
		.layout-2 {
			#{$name} {
				&__playButtonWrapper {
					display: flex;
					position: absolute;
					z-index: 110;
					left: 50%;
					top: 50%;
					margin-left: -52px;
					margin-top: -52px;

					width: 104px;
					height: 104px;
					> button {
						width: 104px;
						height: 104px;
						border-width: 1px;
						> svg {
							width: 23px;
							height: auto;
							transform: translateX(2px);
						}
					}
				}
				&__contentWrapper {
					position: absolute;
					z-index: 109;
					bottom: 40px;
					left: 40px;

					#{$name}__headline {
						&.a-headline {
							> .a-headline {
								font-size: 28px;
								@include font-inter-300();
								margin-bottom: 18px;
								line-height: normal;
							}
						}
					}
					#{$name}__subheadline {
						&.a-headline {
							> .a-headline {
								font-size: 32px;
								@include font-inter-300();
								margin-bottom: 18px;
								line-height: normal;
							}
						}
					}
					#{$name}__copy {
						&.a-copy {
							> * {
								&:nth-child(1) {
									margin-bottom: 1em;
								}
								&:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
		.layout-3 {
			#{$name} {
				&__contentWrapper,
				&__playButtonWrapper {
					flex: auto;
					display: flex;
					@media (min-width: $breakpoint-medium) {
						flex: 1; // make equal width
						// padding: 100px 0;
					}
				}
				&__contentWrapper {
					@media (max-width: $breakpoint-small-max) {
						display: none;
					}
					.inner {
						padding: 0 20px;
						@media (min-width: $breakpoint-medium) {
							padding: 0 50px;
						}

						h1,
						h2,
						h3,
						h4,
						h5,
						h6 {
							text-align: left;

							font-size: 32px;
							line-height: 1.1;
							margin: 0 0 0.75em 0;

							@media (min-width: $breakpoint-medium) {
								font-size: 75px;
								line-height: 80px;
								margin: 0 0 50px 0;
							}
						}

						.ext-content {
							@include font-inter-300();
							text-align: left;
							margin-top: 0;
							font-size: 13px;
							line-height: 1.1;

							&:last-child {
								margin-bottom: 0;
							}

							@media (min-width: $breakpoint-medium) {
								font-size: 16px;
								line-height: 28px;
							}
						}

						.button-content {
							@include font-inter-400();
							text-align: left;
							margin-top: 55px;
							font-size: 16px;
							line-height: 1.1;

							@media (min-width: $breakpoint-medium) {
								font-size: 14px;
								line-height: 14px;
							}
						}
					}
				}
				&__playButton {
					background: transparent;
					border-radius: 50%;

					margin: auto;
					outline: none;
					user-select: none;

					@media (min-width: $breakpoint-medium) {
						border: 3px solid;
						width: 250px;
						height: 250px;
						> svg {
							width: 80px;
							height: auto;
							margin: auto;
							transform: translateX(8px); // this fixes the play button being off-centre
						}
					}
					@media (max-width: calc($breakpoint-small-max + 1)) {
						border: 2px solid;
						width: 80px;
						height: 80px;
						> svg {
							width: 30px;
							height: auto;
							margin: auto;
							transform: translateX(2px); // this fixes the play button being off-centre
						}
					}

					&:active,
					&:focus {
						outline: none;
					}

					&:hover {
						background-color: rgba(255, 255, 255, 0.4);
					}
				}
			}
		}
		.layout-4 {
			#{$name} {
				&__playButtonWrapper {
					display: flex;
					position: absolute;
					z-index: 110;
					left: 50%;
					top: 50%;
					margin-left: -52px;
					margin-top: -52px;

					width: 104px;
					height: 104px;
					> button {
						border-width: 1px;
						width: 104px;
						height: 104px;
						> svg {
							width: 24px;
							height: 24px;
							transform: translateX(2px);
						}
					}
				}
				&__contentWrapper {
					position: absolute;
					z-index: 109;
					bottom: 40px;
					left: 40px;

					#{$name}__headline {
						&.a-headline {
							> .a-headline {
								font-size: 32px;
								@include font-inter-600();
								margin-bottom: 4px;
								line-height: normal;
							}
						}
					}
					#{$name}__subheadline {
						&.a-headline {
							> .a-headline {
								font-size: 32px;
								@include font-inter-300();
								margin-bottom: 25px;
								line-height: normal;
							}
						}
					}
					#{$name}__copy {
						&.a-copy {
							> * {
								&:nth-child(1) {
									margin-bottom: 1em;
								}
								&:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}

@include wolf-m-video-overlay();
