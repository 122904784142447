@mixin wolf-m-product-attributes($name: '.m-product-attributes') {
	#{$name} {
		&__attribute {
			display: flex;
			justify-content: space-between;

			span {
				font-size: $global-xsmall-font-size;
				line-height: 2em;
				font-weight: 200;

				&:first-child {
					font-weight: 600;
				}
			}
		}
	}
}

@include wolf-m-product-attributes();
