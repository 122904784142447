@mixin alignItems($val: '-') {
	.align-items#{$val} {
		&start {
			align-items: flex-start !important;
		}

		&center {
			align-items: center !important;
		}

		&end {
			align-items: flex-end !important;
		}

		&stretch {
			align-items: stretch !important;
		}

		&baseline {
			align-items: baseline !important;
		}
	}
}

@mixin alignContent($val: '-') {
	.align-content#{$val} {
		&start {
			align-content: flex-start !important;
		}

		&center {
			align-content: center !important;
		}

		&end {
			align-content: flex-end !important;
		}

		&between {
			align-content: space-between !important;
		}

		&around {
			align-content: space-around !important;
		}
	}
}

@mixin alignSelf($val: '-') {
	.align-self#{$val} {
		&start {
			align-self: flex-start !important;
		}

		&center {
			align-self: center !important;
		}

		&end {
			align-self: flex-end !important;
		}
	}
}
