@mixin wolf-o-teaser-grid-boxed($name: '.o-teaser-grid-boxed') {
	#{$name} {
		&__header {
			width: 100%;
			margin-bottom: 10px;

			.header-headline {
				color: get-color('true-black');
				@include font-inter-400();
				font-size: 40px;
				line-height: 40px;
				letter-spacing: -3px;
				word-break: break-all;
				margin: 0px;

				width: 40%;
				display: inline-flex;
				justify-content: flex-start;

				@media (max-width: 650px) {
					width: 100%;
					margin-bottom: 20px;
					font-size: 30px;
					line-height: 30px;
					letter-spacing: -1px;
				}
			}
			.header-text-link {
				@include font-inter-400();
				font-size: 24px;
				line-height: 24px;
				letter-spacing: -1px;
				word-break: break-all;
				margin: 0px;

				width: 60%;
				justify-content: flex-end;
				display: inline-flex;

				@media (max-width: 650px) {
					width: 100%;
					font-size: 20px;
					line-height: 20px;
					letter-spacing: -1px;
					justify-content: flex-start;
				}

				a {
					padding: 0px 30px;
					font-weight: 600;
					line-height: 24px;

					@media (max-width: 650px) {
						padding: 0px 10px;
						font-weight: 600;
						line-height: 20px;
					}
				}
			}
		}

		&__button-load {
			margin-top: 30px;
		}

		&__text {
			@include font-inter-200();
			font-size: 24px;
			line-height: 35px;
			letter-spacing: -1px;
			word-break: word-break;

			width: 80%;
			margin-top: 80px;

			@media (max-width: 650px) {
				width: 100%;
			}
		}

		.label.teaser-container .m-teaser-boxed div:first-child.m-teaser-boxed__image-container {
			margin-top: 95px;
		}
	}
}

@include wolf-o-teaser-grid-boxed();
