@mixin wolf-m-search-flyout($name: '.m-search-flyout') {
	#{$name} {
		height: 100vh;
		max-width: 1920px !important;
		overflow-x: hidden;
		background-color: transparent;
		width: 100vw;
		left: 0 !important;
		display: none;

		&__container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 624px;
			background-color: get-color(true-white);
		}

		&__search-input {
			margin: 40px 0 120px;

			@media (min-width: $breakpoint-medium-max) {
				margin: 40px 0 160px;
			}
		}

		&__close-button {
			position: absolute;
			right: 50px;
			top: 40px;

			.m-nav-bar #{$name} & {
				color: get-color(secondary);
			}
		}

		&__mobile--title {
			display: none;

			@media (max-width: $breakpoint-medium-max) {
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				font-family: 'Saira', sans-serif;
				font-weight: 500;
				color: get-color('wolf-black');
				margin-bottom: 14px;
				font-size: 22px;
				width: 50%;
				min-width: 748px;
				margin-inline: auto;
			}

			@media (max-width: $breakpoint-small-max) {
				width: 100%;
				min-width: 100%;
			}
		}
	}
}

@include wolf-m-search-flyout();
