@mixin wolf-m-finder-result($name: '.m-finder-result') {
	#{$name} {
		border: 1px solid get-color('stroke-grey');
		padding: 40px;
		display: flex;
		position: relative;

		@media (max-width: $breakpoint-small-max) {
			flex-direction: column;
			padding: 8px 8px 0;
		}

		&__image {
			.a-image {
				&__img {
					margin-right: 80px;
					height: 100%;
					max-width: 281px;
					object-fit: cover;
				}

				@media (max-width: $breakpoint-small-max) {
					&__img {
						margin-right: 0;
						width: 100%;
						height: 132px;
						max-width: none;
						margin-bottom: 15px;
					}
				}
			}
		}

		&__content-wrapper {
			display: flex;
			flex-direction: column;
			width: 610px;

			@media (max-width: $breakpoint-small-max) {
				max-width: 160px;
			}

			#{$name}__headline-link {
				text-decoration: none;

				.a-headline {
					margin-bottom: 20px;

					@media (min-width: $breakpoint-medium) {
						@include font-saira-500;
					}
				}
			}

			.a-button {
				border-width: 2px;
				width: max-content;
				@media (max-width: $breakpoint-small-max) {
					margin-bottom: 8px;
					padding: 8px 24px;
				}
			}

			@media (max-width: $breakpoint-small-max) {
				width: 100%;

				& #{$name}__headline-link {
					position: absolute;
					top: 15px;
					left: 20px;
					width: calc(100% - 40px);

					& .a-headline {
						color: get-color('true-white') !important;
					}
				}
			}
		}

		.uk-divider-vertical {
			margin: 0 40px;
			height: 100%;

			@media (max-width: 1199px) {
				height: 100%;
			}
		}

		&__teaser-grid {
			flex-shrink: 0;
			max-width: 410px;
			width: 100%;

			@media (max-width: $breakpoint-small-max) {
				width: 100%;
				max-width: 100%;

				.a-image {
					&__img {
						width: 90px;
					}
				}
			}

			@media screen and (min-width: $breakpoint-small-max) and (max-width: $breakpoint-medium-max) {
				max-width: 220px;

				.m-finder-result-teasers__item-container {
					width: 100%;
				}
			}
		}
	}
}

@include wolf-m-finder-result();
