@mixin wolf-m-finder-stage($name: '.m-finder-stage') {
	#{$name} {
		&.order-right {
			#{$name}__left-container {
				order: 2;
				width: 40%;
				padding-left: 5%;
			}

			#{$name}__right-container {
				width: 55%;
			}
		}

		&.default-gradient {
			position: relative;
		}

		.image-wrapper {
			&::before {
				background: linear-gradient(-180deg, rgba(0, 0, 0, 0.226), rgba(0, 0, 0, 0.637));
			}
		}

		&.default-gradient .uk-container {
			position: relative;
			padding-left: 0px;
			padding-right: 0px;
			height: 100%;
		}

		&.fit-height .uk-container {
			height: fit-content;
			height: moz-fit-content;

			margin: 0px;
			padding: 300px 0px 0px 0px;
			width: 100%;
		}

		&.small-height .uk-container {
			height: fit-content;
			height: moz-fit-content;

			margin: 0px;
			padding: 0px;
			width: 100%;
		}

		&:not(.fit-height):not(.small-height) .uk-container {
			height: 535px;
			padding: 300px 50px 0px 50px;

			@media (max-width: $breakpoint-small-max) {
				height: fit-content;
				padding: 150px 0px 0px 0px;
			}

			* {
				@media (max-width: $breakpoint-small-max) {
					width: 100%;
				}
			}
		}

		.uk-container {
			z-index: 1;
			display: flex;

			@media (max-width: 1450px) {
				padding: 300px 0px 0px 0px;
				margin: 0px 0px 0px 5%;
			}

			@media (max-width: $breakpoint-small-max) {
				display: flex;
				flex-direction: column;
				padding: 165px 0px 0px 0px;
				height: fit-content;
				height: moz-fit-content;
				margin: 0px;
				width: 100%;
			}

			#{$name}__left-container {
				display: flex;
				width: 45%;
				justify-content: flex-start;
				align-items: flex-start;

				@media (max-width: $breakpoint-small-max) {
					width: 90%;
					padding: 0px 5%;
				}

				h1 {
					font-size: 100px;
					line-height: 100px;
					margin-bottom: 90px;

					@media (max-width: $breakpoint-small-max) {
						font-size: 40px;
						line-height: 40px;
						margin-bottom: 20px;
					}
				}
			}

			#{$name}__right-container {
				display: flex;
				align-items: flex-end;
				flex-direction: column;
				width: 55%;

				@media (max-width: $breakpoint-small-max) {
					display: flex;
					justify-content: flex-start;
					align-items: flex-end;
					width: 90%;
					padding: 0px 5%;
					margin-top: 20px;
				}

				.a-headline {
					width: 100%;
				}

				.a-copy {
					margin-top: 50px;

					@media (max-width: $breakpoint-small-max) {
						margin-top: 30px;
					}

					* {
						@media (max-width: $breakpoint-small-max) {
							font-size: 12px;
							line-height: 24px;
						}
					}
				}

				&#{$name}__buttons {
					.a-copy {
						margin-bottom: 50px;

						@media (max-width: $breakpoint-medium-max) {
							margin-top: 20px;
							margin-bottom: 20px;
						}
					}

					.buttons {
						width: 100%;
						display: flex;
						justify-content: flex-start;
						margin-bottom: 110px;

						.a-button {
							margin-right: 30px;
						}
					}
				}
			}

			#{$name}__full-width {
				display: flex;
				align-items: flex-end;
				flex-direction: column;
				width: 100%;

				@media (max-width: $breakpoint-small-max) {
					display: flex;
					justify-content: flex-start;
					align-items: flex-end;
					width: 100%;
					padding: 0px 5%;
				}

				.a-headline {
					width: 100%;
				}

				.a-copy {
					margin-top: 20px;

					* {
						@media (max-width: $breakpoint-small-max) {
							font-size: 12px;
							line-height: 24px;
						}
					}
				}
			}
		}
	}
}

@include wolf-m-finder-stage();
