@mixin wolf-m-image-gallery($name: '.m-image-gallery') {
	#{$name} {
		&.container-fluid {
			@include min-md {
				padding-left: 0;
				padding-right: 0;
			}
		}

		margin-bottom: 60px;

		@include min-md {
			margin-bottom: 80px;
		}

		@include min-lg {
			margin-bottom: 110px;
		}

		&-top {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 30px;
			gap: $gutter * 2;

			@include min-lg {
				margin-bottom: 40px;
			}

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				&.a-headline {
					font-family: 'Saira';
					font-size: 30px;
					font-weight: 500;
					line-height: 1;
					letter-spacing: -2px;
					text-align: left;
					color: get-color(wolf-black);
					margin: 0 !important;

					@include min-md {
						font-size: 40px;
					}

					@include min-lg {
						font-size: 50px;
					}
				}
			}
		}

		.uk-slider {
			&-items {
				gap: 16px;

				> li {
					position: relative;

					> .lightbox-cover {
						width: 100%;
						height: 100%;
						display: flex;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 1;
					}
				}
			}
		}

		.image-wrapper {
			position: relative;
			> img {
				object-fit: contain;
				height: 50vw;
				max-height: 554px;
				width: auto;
				@include min-lg {
					height: 54vh;
				}
			}
		}

		.a-headline:empty {
			display: flex;
		}

		&-buttons {
			display: flex;
			margin: auto 0;
			gap: $gutter;
			margin-right: $gutter * 2;

			@media (min-width: 1490px) {
				margin-right: 0;
			}

			.uk-slidenav {
				background-color: get-color(true-white);
				color: get-color(wolf-black);
				border: solid 1px get-color(wolf-black);
				width: 40px;
				height: 40px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				padding: 0;
				cursor: pointer;

				&.uk-invisible {
					visibility: visible !important;
					opacity: 0.3;
					cursor: default;
				}

				@include min-lg {
					width: 60px;
					height: 60px;
				}

				svg {
					height: 16px;

					@include min-lg {
						height: 22px;
					}
				}
			}
		}

		&-modal {
			position: fixed;
			z-index: 100;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			pointer-events: none;
			transition: all 0.25s ease-out;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			opacity: 0;
			padding: 0 $gutter * 2;

			&-close {
				position: absolute;
				z-index: 101;
				background-color: transparent;
				color: get-color('true-white');
				width: 64px;
				height: 64px;
				top: 0;
				right: 0;
				margin: 0;
				padding: 0;
				border: 0;
				outline: none;
				cursor: pointer;
				opacity: 0;
				transition: all 0.25s ease-out;
				transition-delay: 0;

				@include only-mobile {
					margin-right: -$gutter;
					width: 44px;
					height: 44px;
				}

				svg {
					width: 20px;

					@include only-mobile {
						width: 16px;
					}
				}
			}

			&-bg {
				background-color: rgba(0, 0, 0, 0);
				position: fixed;
				z-index: 99;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				pointer-events: none;
				transition: all 0.25s ease-out;
				opacity: 0;
				backdrop-filter: blur(0px);

				&::after {
					content: '';
					position: fixed;
					z-index: 1;
					top: 50%;
					left: 50%;
					margin: -15px 0 0 -15px;
					width: 30px;
					height: 30px;
					display: flex;
					border-radius: 50%;
					overflow: hidden;
					background-color: transparent;
					border-color: get-color(true-white) get-color(true-white) transparent;
					border-style: solid;
					border-width: 3px;
					opacity: 0.5;
					animation: galleryloader 0.8s linear 0s infinite;

					@include min-lg {
						margin: -20px 0 0 -20px;
						width: 40px;
						height: 40px;
					}
				}

				&.opened {
					pointer-events: all;
					background-color: rgba(0, 0, 0, 0.9);
					opacity: 1;
					backdrop-filter: blur(5px);

					+ #{$name}-modal {
						opacity: 1;

						#{$name}-modal {
							&-close,
							&-image {
								opacity: 1;
							}

							&-image {
								transition-delay: 1s;
							}

							&-close {
								transition-delay: 1.2s;
							}

							&-image-wrapper {
								.modal-image-wrapper {
									&::before,
									&::after {
										opacity: 1;
										transition-delay: 1s;
									}
								}
							}
						}
					}
				}
			}

			&-image-wrapper {
				position: relative;
				pointer-events: all;

				.modal-image-wrapper {
					position: relative;
					overflow: hidden;
					padding: 0;
					border-radius: 10px;
					box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);

					@include only-mobile {
						width: calc(100% + (#{$gutter}* 2)) !important;
						margin-left: -$gutter;
					}

					@include min-md {
						border-radius: 15px;
					}

					@include min-lg {
						border-radius: 20px;
					}

					&::before,
					&::after {
						content: '';
						position: absolute;
						z-index: 1;
						left: 0;
						right: 0;
						display: flex;
						height: 110px;
						opacity: 0;
						transition: all 0.25s ease-out;

						@include min-md {
							height: 160px;
						}

						@include min-lg {
							height: 265px;
						}
					}

					&::before {
						background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
						top: 0;
					}

					&::after {
						background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
						bottom: 0;
					}
				}

				#{$name}-modal-image {
					width: auto;
					max-height: calc(100vh - 130px);
					object-fit: contain;
					opacity: 0;
					transition: all 0.25s ease-out;
				}
			}
		}

		+ .o-text {
			padding-top: 0 !important;
		}
	}

	@keyframes galleryloader {
		form {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(359deg);
		}
	}
}

@include wolf-m-image-gallery();
