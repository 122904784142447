// $wolf-o-shopping-cart-width: 660px;
$wolf-o-shopping-cart-width: 890px;
// body.uk-offcanvas-flip.uk-offcanvas-container-animation {
//     // left: -$wolf-o-shopping-cart-width;
//     left: -$wolf-o-shopping-cart-width + 50;
// }
@mixin wolf-o-shopping-cart($name: '.o-shopping-cart') {
	#{$name} {
		color: white;

		#{$name}__spinner {
			display: none;
			position: absolute;
		}

		&--loading {
			#{$name}__outer {
				opacity: 0.4;
			}
			#{$name}__spinner {
				display: block;
			}
		}

		&.uk-offcanvas-overlay {
			&::before {
				background-color: rgba(0, 0, 0, 0.2);
				backdrop-filter: blur(5px);
			}
		}
		&.uk-open {
			@media (min-width: 960px) {
				> .uk-offcanvas-reveal {
					width: $wolf-o-shopping-cart-width;
				}
			}
		}

		.uk-offcanvas-reveal {
			// width: $wolf-o-shopping-cart-width;
			.uk-offcanvas-bar {
				width: $wolf-o-shopping-cart-width - 50;
				background-color: map-get($colors, 'dark-blue'); // override UIkit
				color: white !important;
				@media (min-width: 960px) {
					padding: 100px 100px;
				}
			}
			// the menu close button
			a.uk-offcanvas-close {
				width: 50px;
				height: 50px;
				display: block;
				border: 1px solid #f0f0f0;
				display: flex;
				top: 30px;
				right: 65px;
				padding: 0;
				> span {
					width: 21px;
					margin: auto;
				}
			}
		}

		// background-color: #212D40; // for testing

		&__outer {
			min-width: 100%;
		}
		&__items {
			> .m-shopping-cart-item {
				margin-bottom: 32px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		&__subtotals {
			border-top: 1px solid;
			border-bottom: 1px solid;
			padding: 30px 0;
			margin: 30px 0;

			> div {
				margin-bottom: 20px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		&__shipping,
		&__vat,
		&__modifiers {
			font-size: 21px;
		}

		&__modifiers {
			.modifier-subtract {
				color: get-color(wolf-red);
			}
		}

		&__total {
			@include font-inter-600();
			font-size: 30px;
			line-height: 40px;
			margin: 30px 0;
		}

		&__checkout {
			text-align: right;
			// checkout button is bigger than normal
			a.uk-button {
				min-width: 368px;
				max-width: 100%;
				padding: 30px 30px;
				span {
					@include font-inter-500();
					margin: auto;
					font-size: 20px;
					line-height: normal;
				}
			}
		}

		// light theme blocks
		@include element-modifier('&--light') {
			&__checkout {
				a.uk-button {
					border-color: map-get($colors, 'wolf-black');
					color: map-get($colors, 'wolf-black');
				}
			}
		}

		// dark theme blocks
		@include element-modifier('&--dark') {
			&__subtotals {
				border-color: rgba(255, 255, 255, 0.2);
			}
		}
	}
}

@include wolf-o-shopping-cart();
