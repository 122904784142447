@mixin wolf-o-secondary-navigation($name: '.o-secondary-navigation') {
	#{$name} {
		&__nav {
			@media (max-width: $breakpoint-small-max) {
				padding: 0;
			}
		}

		div.uk-dropdown {
			// position: inherit;
			margin-top: 0px;
			background-color: transparent;
			// top: -25px !important;
			min-width: 0;

			a,
			a:hover {
				color: map-get($colors, 'true-white');
			}
		}

		&__nav-element {
			vertical-align: top;
			margin: 1rem 1rem 1rem 0;
			// top-level anchors
			> a {
				padding: 16px;
			}

			@media (max-width: $breakpoint-small-max) {
				margin: 0 16px 16px 0;
				> a {
					border: 1px solid rgba(255, 255, 255, 0.43) !important;
					padding: 16px 6px;
					outline: none !important;
					&:active,
					&:focus {
						outline: none !important;
					}
				}
				// container for the children
				div.uk-dropdown {
					position: relative;
					padding: 16px 12px;
					margin-right: -16px; // this is a hack to account for the parent margin
					ul {
						display: flex;
						flex-wrap: wrap;
						li {
							width: 50%;
							box-sizing: border-box;
							a {
								box-sizing: border-box;
								padding: 6px 6px;
								border: none;
							}
						}
					}
				}
			}
		}
	}
}

@include wolf-o-secondary-navigation();
