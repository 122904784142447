@mixin wolf-m-contact($name: '.m-contact') {
	#{$name} {
		&__title {
			padding: 0 20px 20px;

			.a-headline {
				margin-bottom: 0;
			}
		}

		&:not(.use-small) {
			#{$name} {
				&__contact-wrapper {
					display: flex;
					flex-direction: column;
					align-items: center;
					padding: 32px 0;
					margin: 0 16px;
					background-color: #d2d2d233;
					border-radius: 10px;

					@media (min-width: $breakpoint-medium) {
						padding: 32px;
						margin: auto;
						background-color: transparent;
						flex-direction: row;
						justify-content: space-around;
						align-items: center;
						max-width: 748px;
					}
				}
			}
		}

		&__contact-image {
			height: 142px;
			width: 142px;
			min-width: 142px;
			border-radius: 50%;

			@media (min-width: $breakpoint-medium) {
				height: 270px;
				width: 270px;
				min-width: 270px;
				margin-right: 124px;
			}
		}

		&__contact-details {
			display: flex;
			flex-direction: column;
		}

		&__contact-image {
			margin-bottom: 20px;

			@media (min-width: $breakpoint-medium) {
				margin-bottom: 0;
			}
		}

		&__contact {
			font-size: 14px;
			margin: 0 0 4px;
			white-space: nowrap;

			&--email {
				color: get-color(light-blue-m-application-seo);
				text-decoration-color: get-color(light-blue-m-application-seo);
			}

			&--phone {
				color: get-color(light-blue-m-application-seo);
				text-decoration-color: get-color(light-blue-m-application-seo);
			}

			&--name {
				font-weight: bold;
			}

			&--text-description {
				color: get-color(dark-gray);
				display: none;

				@media (min-width: $breakpoint-medium) {
					display: block;
					min-width: 110px;
				}
			}

			&:not(&--name) {
				font-weight: 300;
			}

			@media (min-width: $breakpoint-medium) {
				font-size: 22px;
				margin: 4px 0;
			}
		}

		&__contact-clickable-info {
			margin-top: 16px;

			@media (min-width: $breakpoint-medium) {
				margin-top: 24px;
			}
		}

		.a-button {
			display: flex;
			margin-top: 32px;
		}

		&.text-color-true-white {
			#{$name}__contact {
				&--email {
					color: get-color(true-white);
					text-decoration-color: get-color(true-white);
				}

				&--phone {
					color: get-color(true-white);
					text-decoration-color: get-color(true-white);
				}
			}
		}

		&.use-small {
			.padding-small,
			.padding-standard,
			.padding-large {
				padding: 0;
				background: transparent !important;

				> .uk-container {
					padding-left: 0;
					padding-right: 0;

					@media (min-width: $breakpoint-medium) {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}

			#{$name} {
				&__contact {
					&-wrapper {
						margin: 0 0 20px;
						padding: 0;
						justify-content: flex-start;
						align-items: center;
						display: flex;
						flex-wrap: wrap;

						@media (min-width: $breakpoint-medium) {
							margin: 0 0 50px;
						}
					}

					&-image {
						height: 54px;
						width: 54px;
						min-width: 54px;
						margin-right: 20px;
						margin-bottom: 0;

						@media (min-width: $breakpoint-medium) {
							height: 74px;
							width: 74px;
							min-width: 74px;
						}
					}

					&-details {
						display: inline-flex;

						#{$name} {
							&__contact {
								&--name,
								&--position {
									font-family: 'Inter';
									margin: 0;
									font-size: 14px;
									line-height: 1.5;

									@media (min-width: $breakpoint-medium) {
										font-size: 16px;
									}
								}

								&--position {
									color: #636b79;
								}
							}
						}
					}
				}
			}
		}
	}
}

@include wolf-m-contact();
