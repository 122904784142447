@mixin wolf-m-kb-navbar($name: '.m-kb-navbar') {
	#{$name} {
		position: absolute;

		padding-top: 49px;
		z-index: 99;

		max-width: 1920px;
		width: 100%;

		margin: 0 auto;

		left: 0;
		right: 0;

		@media (max-width: $breakpoint-small-max) {
			width: 90%;
		}

		&__wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			/*Width changes*/
			padding-left: 10%;
			padding-right: 10%;
			//color: get-color($colors, 'true-white');

			div {
				flex: 1;
			}

			.a-navlink {
				&--isLightTheme {
					color: map-get($colors, 'wolf-black');
				}
			}
		}

		#hamburger {
			right: $global-small-gutter + 10;
		}

		&__hamburger-badge {
			background-color: map-get($colors, 'wolf-red');
			color: map-get($colors, 'true-white');
			font-size: 12px;
			position: absolute;
			top: -9px;
			right: -10px;
			min-width: 16px;
			height: 16px;
			z-index: 1;

			&:hover {
				color: map-get($colors, 'true-white');
			}
		}

		&__logo {
			a {
				@media (max-width: $breakpoint-small-max) {
					width: 130px;
				}

				@media (max-width: $breakpoint-small) {
					width: 84px;
				}
			}
		}

		.light {
			color: map-get($colors, 'true-black');
		}

		.dark {
			color: map-get($colors, 'true-white');
		}

		&__links {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			.a-navlink--isButton.a-navlink--isLightTheme {
				color: map-get($colors, 'true-white');
			}
		}
	}

	.uk-offcanvas-bar {
		background-color: map-get($colors, 'wolf-black');
		display: grid;
		width: 300px;
		place-items: center;
		grid-template-rows: 2fr 5fr 1fr;
	}

	.menu__wrap {
		display: flex;
		flex-direction: column;
		height: 550px;
	}
}

@include wolf-m-kb-navbar();
