@mixin wolf-m-pdf-list-header($name: '.m-pdf-list-header') {
	#{$name} {
		border-bottom: 1px solid map-get($colors, 'silver-grey');
		padding-bottom: 12px;
		margin-bottom: 50px;
		margin-top: 50px;

		@media (max-width: $breakpoint-medium-max) {
			margin-bottom: 0px;
		}

		&__label {
			color: map-get($colors, 'dark-grey');
			font-size: 21px;
			line-height: 40px;
			height: 40px;
			@media (max-width: $breakpoint-medium-max) {
				font-size: 16px;
			}
		}
	}
}

@include wolf-m-pdf-list-header();
