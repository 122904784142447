@mixin wolf-m-fhw-contact-form($name: '.m-fhw-contact-form') {
	#{$name} {
		&__head {
			.title {
				@include font-inter-600();
				font-size: 30px;
				line-height: 30px;
				margin-bottom: 0;
			}

			.subtitle {
				@include font-inter-400();
				font-size: 20px;
				line-height: 30px;
				margin-top: 0;
				margin-bottom: 40px;
			}
		}

		&__form {
			label {
				@include font-inter-400();
				font-size: 16px;
				line-height: 20px;
				color: #727272;
				display: block;
				margin-bottom: 7px;

				@media (max-width: $breakpoint-xsmall-max) {
					font-size: 14px;
				}

				@media (max-width: $breakpoint-xsmall-max) {
					font-size: 14px;
				}

				.mandatory {
					color: get-color('wolf-red');
				}
			}

			input,
			textarea,
			select {
				@include font-inter-400();
				font-size: 16px;
				line-height: 25px;
				border: 1px solid #62677e;
				background-color: transparent;

				&:focus {
					&:not([type='submit']) {
						@include font-inter-400();
						font-size: 16px;
						line-height: 25px;

						border: 1px solid #62677e;
						background-color: transparent;
					}
				}
				&:not([type='checkbox']) {
					margin-bottom: 40px;

					@media (max-width: $breakpoint-xsmall-max) {
						margin-bottom: 20px;
					}
				}
			}
			textarea {
				margin-bottom: 20px;
			}
			input[type='submit'] {
				margin-bottom: 0;
				&[disabled] {
					cursor: default;
					pointer-events: none;
					background-color: #ccc;
				}
			}

			.uk-dropdown.uk-open {
				display: block;
				margin-top: 0px;
				width: 100%;
				border-top: 1px solid get-color('wolf-red');
				background-color: white;
				max-height: 250px;
				overflow: auto;

				p {
					padding: 15px;
					margin: 0px;

					@include font-inter-400();
					font-size: 16px;
					line-height: 25px;
					color: #727272;

					&:hover {
						cursor: pointer;
						background-color: #f5f5f5;
					}
				}
			}

			select {
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;

				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='35' viewBox='0 0 24 24' fill='none' stroke='rgb(98,103,126)' stroke-width='1' stroke-linecap='butt' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E") !important;
			}

			.uk-select:not([multiple]):not([size]) {
				background-position: 99% 50%;
			}

			textarea {
				min-height: 200px;
			}

			.mandatoryLabel {
				margin: 0px;
				@include font-inter-400();
				font-size: 15px;
				line-height: 25px;
				color: #727272;
			}

			#{$name}__checkbox {
				margin-top: 30px;

				div {
					display: inline-flex;
					max-width: 80%;
					p {
						margin: 0px;
					}
				}
				.uk-checkbox {
					background-color: transparent;
					border: 1px solid #62677e;
					margin-right: 25px;

					@media (max-width: $breakpoint-small-max) {
						margin-right: 15px;
					}

					&:checked {
						background-color: get-color('wolf-red');
						border: 0px;
					}
				}
			}
		}

		&__button {
			margin: 20px 0;
		}
		&__alert {
			margin: 20px 0 0;
		}
	}
}

@include wolf-m-fhw-contact-form();
