@mixin wolf-m-adviser-content($name: '.m-adviser-content') {
	#{$name} {
		&__grid-wrapper {
			margin-top: 78px;

			@media (max-width: $breakpoint-small-max) {
				margin-top: 24px;
			}

			.uk-container {
				margin: 0;
				padding: 0;
			}
		}

		.m-teaser-bg {
			width: 368px;
			height: 368px;

			@media (max-width: $breakpoint-small-max) {
				width: 100%;
				height: auto;
			}

			.m-teaser-bg__headline {
				height: 40%;

				@media (max-width: $breakpoint-small-max) {
					height: auto;
				}

				.a-headline {
					min-height: 60px;
					word-break: break-word;

					@media (max-width: $breakpoint-medium-max) {
						width: 100%;

						.a-headline {
							max-width: 100%;
							word-break: break-word;
						}
					}
				}

				@media (max-width: $breakpoint-xsmall-max) {
					.a-headline {
						min-height: 0;
					}
				}
			}
		}

		.m-pagination {
			margin-top: 100px;
			max-width: 1520px;

			@media (max-width: $breakpoint-small-max) {
				margin-top: 35px;
			}

			.uk-pagination {
				justify-content: center;
			}
		}
	}
}

@include wolf-m-adviser-content();
