@import './variables';

@mixin flex-system() {
	.container {
		width: 100%;
		max-width: $containerMaxWidth;
		margin-right: auto;
		margin-left: auto;
		padding-right: $mobileGutter;
		padding-left: $mobileGutter;
		box-sizing: border-box;
		//display: flex;
		//flex-direction: column;

		&.no-padding {
			&-xs {
				@include only-mobile {
					padding-right: 0;
					padding-left: 0;
				}
			}
		}

		// @include min-md {
		// 	padding-right: $gutter;
		// 	padding-left: $gutter;
		// }

		max-width: calc(#{$containerMaxWidth} + #{$mobileGutter}* 2);
		padding-left: $mobileGutter;
		padding-right: $mobileGutter;

		@media (min-width: 640px) {
			max-width: calc(#{$containerMaxWidth} + 32px);
			padding-left: 16px;
			padding-right: 16px;
		}

		@media (min-width: 960px) {
			max-width: calc(#{$containerMaxWidth} + 100px);
			padding-left: 50px;
			padding-right: 50px;
		}

		&-fluid {
			width: 100%;
			max-width: 1920px;
			padding-right: $mobileGutter;
			padding-left: $mobileGutter;
			display: flex;
			flex-direction: column;

			&.no-padding {
				padding-right: 0;
				padding-left: 0;

				@include min-md {
					padding-right: 0;
					padding-left: 0;
				}
			}

			@include min-md {
				padding-right: $gutter;
				padding-left: $gutter;
			}
		}
	}

	.row {
		display: flex;
		flex-wrap: wrap;
		margin-right: -$mobileGutter;
		margin-left: -$mobileGutter;

		@include min-md {
			margin-right: -$gutter;
			margin-left: -$gutter;
		}
	}

	[class^='col-'] {
		box-sizing: border-box;
	}

	.col {
		@for $i from 0 through $colCount {
			&-#{$i} {
				padding-right: $mobileGutter;
				padding-left: $mobileGutter;
				flex: 0 0 100% / $colCount * $i;
				max-width: 100% / $colCount * $i;
				position: relative;
			}
		}
	}

	@include min-md {
		.col-md {
			@for $i from 0 through $colCount {
				&-#{$i} {
					padding-right: $gutter;
					padding-left: $gutter;
					flex: 0 0 100% / $colCount * $i;
					max-width: 100% / $colCount * $i;
				}
			}
		}
	}

	@include min-lg {
		.col-lg {
			@for $i from 0 through $colCount {
				&-#{$i} {
					flex: 0 0 100% / $colCount * $i;
					max-width: 100% / $colCount * $i;
				}
			}
		}
	}
}
