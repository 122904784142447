@mixin wolf-m-nav-flyout($name: '.m-nav-flyout') {
	#{$name} {
		padding: 32px 30px;
		min-height: 340px;
		margin-left: 0px;

		&__column-title {
			@include font-inter-600;
			color: get-color('true-white');
			font-size: 24px;
			line-height: 21px;
			padding: 0 0 24px;
			margin-bottom: 0;
			letter-spacing: 0;
		}

		&__column-links {
			padding: 0;
			margin-top: 0;

			li {
				list-style: none;
			}
		}

		&__final-link {
			color: get-color('true-white');
			line-height: 21px;
			padding: 0;

			&.a-button.has-icon {
				padding: 1px 0 5px 0;
			}

			& .a-button__text {
				margin: 0;
			}
		}

		&__child-link {
			&.a-button {
				@include font-inter-300;
				color: get-color('true-white');
				font-size: 16px;
				line-height: 21px;
				padding: 0 0 24px;

				& .a-button__text {
					margin: 0;
				}
			}
		}

		&__final-link:focus,
		&__final-link:hover,
		&__child-link:focus,
		&__child-link:hover {
			outline: none !important;
			text-decoration: underline;
			text-underline-offset: 5px;
			text-decoration-color: get-color('primary');
		}

		&__ad-wrapper {
			padding-left: 100px;

			&:not(.uk-first-column) {
				&::before {
					height: 340px;
					border-color: get-color('dark-grey');
				}
			}

			&.no-content {
				&:not(.uk-first-column) {
					&::before {
						height: 0;
					}
				}
			}

			@media (max-width: $breakpoint-medium-max) {
				padding-left: 60px;
			}
		}
	}
}

@include wolf-m-nav-flyout();
