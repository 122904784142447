@mixin wolf-m-teaser-rounded($name: '.m-teaser-rounded') {
	#{$name} {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&__box {
			position: relative;
			display: block;
			cursor: pointer;
			user-select: none;
			text-decoration: none;
			color: map-get($colors, 'wolf-black');

			width: 280px;
			height: 280px;

			&:hover {
				text-decoration: none;
			}

			@media (min-width: $breakpoint-small) {
				width: 335px;
				height: 335px;
			}
			@media (min-width: $breakpoint-large) {
				width: 620px;
				height: 620px;
			}
		}

		&__image {
			position: absolute;
			z-index: 10;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;

			background: center center no-repeat scroll;
			background-size: cover;
			width: inherit;
			height: inherit;
			border-radius: 50%;
			overflow: hidden;
		}

		&__overlay {
			position: relative;
			z-index: 20;
			width: 100%;
			height: 100%;
			text-align: center;

			.inner {
				display: flex;
				flex-direction: column;
				justify-content: center;

				.a-headline {
					padding: 0 15px;
					margin-left: auto;
					margin-right: auto;
					margin-top: 40%;
					@media (max-width: $breakpoint-large) {
						margin-bottom: 0px;
						margin-top: 25%;
					}
					+ .description {
						margin-top: 0;
						transform: translateY(0);
					}
				}
			}

			.description {
				font-size: get-fontsize(s, xsmall);
				line-height: 32px;
				padding: 0 15px;
				margin-left: auto;
				margin-right: auto;
				margin-top: 50%;
				transform: translateY(-50%);
				@media (max-width: $breakpoint-large) {
					max-width: 280px;
				}
			}
		}
	}
}

@include wolf-m-teaser-rounded();
