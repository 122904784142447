@mixin wolf-m-nav-language-switcher($name: '.m-nav-language-switcher') {
	#{$name} {
		&__lang-list {
			margin-top: 160px;

			@media (max-width: $breakpoint-small) {
				margin-top: 100px;
			}

			.a-headline {
				margin-bottom: 42px;

				color: black;
			}

			ul {
				list-style: none;
				padding-left: 0px;
				color: black;

				li {
					@include font-inter-200();
					font-size: 16px;
					line-height: 23px;
					margin-bottom: 24px;
					color: black;

					a {
						text-decoration: none;
						color: black;
					}

					a.active,
					a:hover {
						font-weight: 400;
						color: red;
					}
				}
			}

			ul.twoColumn {
				display: grid;
				grid-auto-flow: column;
				column-gap: 24px;
			}
		}

		&__bottom-links {
			margin-top: 200px;

			@media (max-width: $breakpoint-small) {
				//margin-top: 50px;
				display: none;
			}

			.a-headline {
				margin-bottom: 20px;
				color: black;
			}

			.a-button {
				width: 100%;
				margin-bottom: 20px;

				.a-button__text {
					margin-left: 0px;
					@include font-inter-400();
					line-height: 20px;
					font-size: 20px;
				}

				.a-button__icon-wrapper {
					padding: 0px 15px;
				}
			}
		}
	}
}

@include wolf-m-nav-language-switcher();
