@mixin wolf-m-press-headline($name: '.m-press-headline') {
	#{$name} {
		display: flex;
		flex-direction: column;

		> .uk-container {
			width: 100%;
			box-sizing: border-box;

			@media (min-width: 960px) {
				max-width: 1620px;
			}
		}

		.o-video {
			position: relative;
			z-index: 0;

			&.padding {
				&-small,
				&-standard,
				&-large {
					padding-top: 0;
					padding-bottom: 0;
				}
			}

			&__media {
				position: relative;
				height: 0;
				padding-top: 56.25%;
				width: 100%;
			}

			.message-wrapper {
				display: none;

				@media (min-width: 412px) {
					display: block;
				}
			}
		}

		> .image-wrapper {
			width: 100%;
			max-width: 1920px;
			margin: 0 auto;
			overflow: hidden;
			position: relative;
			max-height: 70vh;

			@include only-mobile {
				max-height: 65vh;
				max-width: 100%;

				> img {
					min-height: 70vh;
					max-width: 100%;
				}
			}

			@media (min-width: 1921px) and (-webkit-max-device-pixel-ratio: 1) {
				max-height: 700px;
			}
		}

		&.no-img {
			margin-top: 0;
		}

		.a-button.m-card-btn {
			background-color: get-color('wolf-red');
			color: get-color('true-white');

			@include min-md {
				background-color: get-color('true-white');
				color: get-color('wolf-black');
			}
		}

		@include only-mobile {
			padding: 0;

			> .container {
				padding-inline: $mobileGutter * 2;
			}
		}

		@include only-tablet {
			padding: 0;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			&.a-headline {
				font-family: 'Saira';
				font-size: 30px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.2;
				letter-spacing: -2px;
				text-align: left;
				color: get-color('text');
				margin: 0;
				padding-top: 15px;
				padding-bottom: 0;

				@include min-md {
					font-size: 40px;
					padding-top: 0;
					padding-bottom: 30px;
				}

				@include min-lg {
					font-size: 50px;
				}
			}
		}

		.a-copy:not(.styled) p {
			font-family: 'Inter';
			font-weight: 400;
			font-size: 18px;
			line-height: 1.5em;
			letter-spacing: 0.4px;

			@include min-md {
				font-size: 18px;
			}
		}

		.m-card-filtertags-date {
			margin-top: 24px;
			margin-bottom: 8px;

			> * {
				margin-bottom: 16px;
			}

			@include min-md {
				margin-top: 50px;
				margin-bottom: 20px;
			}
		}

		.hash-wrapper {
			display: inline-flex;
			flex-wrap: wrap;
			border-left: solid 1px get-color('black');
			padding-inline: 12px;
			margin-left: 12px;

			&.no-date {
				border-left: 0;
			}

			.hash {
				color: get-color('wolf-red');
				font-size: 14px;
				margin-right: 14px;

				@include min-md {
					font-size: 16px;
				}
			}
		}

		.m-card-btn-group {
			width: 100%;

			.m-button-group {
				position: relative;
				z-index: 3;

				> .container {
					.a-button {
						@include only-mobile {
							background-color: map-get($colors, 'wolf-red');
							color: map-get($colors, 'true-white');
						}
					}
				}
			}

			@include min-md {
				justify-content: flex-end;

				.m-button-group {
					position: relative;
					z-index: 3;

					> .container {
						height: 0;
						margin-top: -21px;
						padding: 0;

						.a-button {
							background-color: map-get($colors, 'true-white');
							color: map-get($colors, 'true-black');
						}
					}
				}
			}

			@include min-lg {
				padding-right: 116px;
			}

			&.under-headline,
			&.no-img {
				height: auto;
				justify-content: flex-start;
				margin-bottom: 24px;

				@include min-md {
					margin-bottom: 45px;
					margin-top: 8px;
				}

				.a-button.m-card-btn {
					margin-top: 0;
				}
			}

			.a-button.m-card-btn.share-btn {
				> svg {
					height: 20px;
					margin-left: -2px;
				}
			}
		}

		.divider {
			width: 100%;
			height: 1px;
			display: flex;
			background-color: #dddddd;
			margin: 0 0 34px;

			@include min-md {
				margin: 0 0 50px;
			}
		}
	}

	section#{$name} {
		> .container {
			padding-inline: $gutter * 2;

			@media (min-width: 960px) {
				padding-inline: 35px;
			}
		}
	}
}

@include wolf-m-press-headline();
