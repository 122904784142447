@mixin wolf-m-hero-contact-selector($name: '.m-hero-contact-selector') {
	#{$name} {
		display: flex;
		justify-content: center;
		background: linear-gradient(180deg, transparent 200px, #11151c 200px, #11151c 100%);

		&__container {
			display: flex;
			flex-direction: column;
			padding: 32px 16px;
			max-width: 1520px;
			box-sizing: border-box;

			@media (min-width: $breakpoint-medium) {
				flex-direction: row;
			}
			@media (min-width: $breakpoint-medium) {
				padding: 96px;
			}
		}

		&__right-column {
			width: 100%;
			@media (min-width: $breakpoint-medium) {
				max-width: 55%;
			}
		}

		&__left-column {
			width: 100%;
			display: flex;
			align-items: center;
			@media (min-width: $breakpoint-medium) {
				justify-content: flex-start;
				max-width: 45%;
			}
		}

		&__headline {
			.a-headline__heading {
				@media (min-width: $breakpoint-small) {
					max-width: 70%;
				}
			}
		}

		&__description {
			margin: 0;
			font-size: 14px;
			font-weight: 100;
			@media (min-width: $breakpoint-small) {
				max-width: 90%;
				font-size: 24px;
			}
		}

		&__select-container {
			display: flex;
			margin: 24px 0 0 0;
		}

		&__select {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;

			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='35' viewBox='0 0 24 24' fill='none' stroke='rgb(98,103,126)' stroke-width='1' stroke-linecap='butt' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E") !important;
			background-position: 95% 50% !important;

			background-color: get-color(true-white);
			font-size: 12px;
			margin: 16px 0 8px 0;
			padding-right: 3em !important;
			max-width: 312px;
			@media (min-width: $breakpoint-small) {
				font-size: 16px;
				margin: 32px 32px 32px 0;
			}

			&:focus {
				background-color: get-color(true-white);
			}
			&:first-child {
				margin-right: 8px;
			}
			&:last-child {
				margin-left: 8px;
			}
		}

		&__poc-container {
			margin: 32px 0;
			display: flex;
			align-items: center;
			& .a-copy {
				margin: 0 32px;
			}
		}

		&__poc-data-container {
			display: flex;
			flex-direction: column;

			& > a.a-copy {
				text-decoration-color: get-color('true-white');

				&:hover {
					text-decoration-color: get-color('wolf-red');
				}
			}
		}

		&__poc-image {
			width: 108px;
			height: 108px;
			border-radius: 50%;
			@media (min-width: $breakpoint-small) {
				width: 236px;
				height: 236px;
			}
		}

		&__poc-description {
			border-radius: 50%;

			&.a-copy {
				margin: 0 0 4px 24px;

				@media (min-width: $breakpoint-small) {
					font-size: 24px;
					margin: 0 0 4px 32px;
				}
			}

			&[data-ref='m-hero-contact-selector__poc-mainline'] {
				opacity: 0.6;
			}
		}

		&__poc-position {
			&.a-copy {
				margin: 0 0 16px 24px;
				@media (min-width: $breakpoint-small) {
					margin: 0 0 24px 32px;
				}
			}
		}

		&__poc-phoneNumber {
			font-weight: 100;
		}

		&__poc-email {
			font-weight: 100;
			overflow-wrap: anywhere;
		}
	}
}

@include wolf-m-hero-contact-selector();
