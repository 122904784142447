@mixin wolf-m-formstep-warranty-wolf-products($name: '.m-formstep-warranty-wolf-products') {
	#{$name} {
		fieldset {
			margin-bottom: 24px;
		}

		&__systemComponents-container {
			fieldset {
				margin-bottom: 0;
			}
			@media (min-width: $breakpoint-medium) {
				fieldset {
					margin-bottom: 24px;
				}
			}
		}

		.uk-input,
		.uk-select {
			&:focus,
			&:active {
				background-color: get-color(dark-blue);
				@media (min-width: $breakpoint-medium) {
					background-color: transparent;
				}
			}
		}
	}
}

@include wolf-m-formstep-warranty-wolf-products();
