@mixin wolf-m-teaser-fhw($name: '.m-teaser-fhw') {
	#{$name} {
		&__container {
			width: 315px;
			height: 437px;
			background-color: #fcfcfc;
			border: 1px solid get-color('silver-grey');
			@media (min-width: $breakpoint-large-max) {
				width: 432px;
				height: 590px;
			}
		}
		&__image {
			height: 166px;
			width: 100%;
			@media (min-width: $breakpoint-large-max) {
				height: 284px;
			}
		}
		&__information {
			padding: 20px;
			@media (min-width: $breakpoint-large-max) {
				padding: 32px;
			}
		}
		&__title {
			font-size: 20px;
			letter-spacing: -0.4px;
			font-weight: normal;
			margin-bottom: 0;
			@media (min-width: $breakpoint-large-max) {
				font-size: 24px;
				margin-bottom: 16px;
				letter-spacing: -0.48px;
			}
		}
		&__description {
			font-size: 14px;
			letter-spacing: 0px;
			margin-top: 0px;
			margin-bottom: 16px;
			line-height: 28px;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			@media (min-width: $breakpoint-large-max) {
				font-size: 16px;
				margin-bottom: 32px;
				line-height: 32px;
				-webkit-line-clamp: 3;
			}
		}
		&__button {
			& .a-button {
				width: 100%;
				text-align: center;
				&__text {
					margin: 0 auto;
				}
			}
		}
	}
}

@include wolf-m-teaser-fhw();
