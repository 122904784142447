@mixin wolf-m-input-search-debounce($name: '.m-input-search-debounce') {
	#{$name} {
		max-width: 100%;

		@media (max-width: $breakpoint-xsmall-max) {
			margin: 0 0 75px;
		}

		&__input-group {
			padding: 16px;
			position: relative;
			width: calc(100% - 64px);

			@media (min-width: $breakpoint-xsmall-max) {
				width: 90%;
			}

			@media (min-width: $breakpoint-medium) {
				max-width: 50%;
				min-width: 748px;
			}

			@media (max-width: $breakpoint-medium-max) {
				margin-inline: auto;
			}
		}

		input {
			font-size: 50px;
			width: 100%;

			@media (max-width: $breakpoint-xsmall-max) {
				font-size: 16px;
			}

			&::placeholder {
				color: get-color(stroke-grey);
			}

			&:focus-visible {
				outline-color: transparent;
			}

			@media (min-width: $breakpoint-medium-max) {
				width: 50%;
				min-width: 748px;
			}
		}

		&__input-icon {
			height: 32px;
			width: 32px;
			position: absolute;
			right: 24px;
			top: 20px;
			margin: 20px 0;
		}

		&__results {
			position: absolute;
			width: 100vw;
			left: 50%;
			transform: translate(-50%, 0);
			display: flex;
			justify-content: center;
			z-index: 5;
			padding: 0 32px;
			background-color: get-color(true-white);
			padding-bottom: 90px;
			margin-top: 1px;
		}
	}
}

@include wolf-m-input-search-debounce();
