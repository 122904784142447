@mixin wolf-o-checkout-start($name: '.o-checkout-start') {
	#{$name} {
		margin-bottom: 800px !important;

		@media (max-width: $breakpoint-medium) {
			text-align: center;
			margin-bottom: 90px !important;
		}

		& .uk-grid-margin.uk-first-column {
			@media (max-width: $breakpoint-medium) {
				margin-top: 60px;
			}
		}

		&__back-button {
			display: flex;

			span {
				border: 1px solid #f0f0f0;
				height: 50px;
				width: 50px;
				display: flex;
				align-items: center;
				justify-content: center;

				svg {
					height: 15px;
					width: 20px;
					margin: 0 auto;
				}
			}
		}

		&__register,
		&__guest {
			margin-top: 67px !important;
			@media (max-width: $breakpoint-medium) {
				margin-top: inherit;
			}
		}
	}
}

@include wolf-o-checkout-start();
