@mixin wolf-m-breadcrumb-dynamic($name: '.m-breadcrumb-dynamic') {
	#{$name} {
		padding: 32px 0 32px 90px;
		box-sizing: border-box;

		@media (max-width: $breakpoint-medium) {
			padding: 20px 0px;
		}

		&__breadcrumb {
			position: relative;

			div.uk-navbar-dropdown {
				background-color: map-get($colors, 'true-white') !important;
				box-shadow: 0px 2px 5px 0px rgba(158, 158, 158, 1);
				max-width: 250px;
				top: 45px !important;
				left: -15px !important;
				font-size: get-fontsize(m, small) !important;
				z-index: 999;

				@media (max-width: $breakpoint-medium) {
					max-width: 150px;
				}

				&.uk-open a {
					font-size: get-fontsize(m, default);
				}
			}

			a,
			.uk-navbar-dropdown {
				color: map-get($colors, 'dark-grey') !important;
				font-size: get-fontsize(m, small) !important;
				@media (max-width: $breakpoint-medium) {
					font-size: 12px !important;
				}
			}
			.icon-wrapper {
				margin-bottom: 0px;
				display: flex;
				justify-content: center;
				align-items: center;
				box-sizing: border-box;
				text-decoration: none;
			}

			.uk-icon {
				color: map-get($colors, 'dark-grey') !important;
				margin-top: 2px;
				svg {
					height: 15px;
					width: 15px;
					margin-left: 15px;
					@media (max-width: $breakpoint-medium) {
						height: 10px !important;
						width: 10px !important;
					}
				}
			}
		}

		&__separator {
			a {
				color: map-get($colors, 'wolf-red') !important;
				font-size: get-fontsize(m, small) !important;

				@media (max-width: $breakpoint-medium) {
					font-size: 12px !important;
				}
			}
		}

		.uk-navbar-nav {
			display: flex;
			flex-wrap: wrap;
			row-gap: 20px;
			> li {
				> a {
					position: relative;
					padding: 0 45px 0 35px;
					min-height: auto;
				}

				&:first-child a {
					padding-left: 0;
					padding-right: 0;
				}

				&:not(:first-child) {
					> a {
						&::before,
						&::after {
							position: absolute;
							width: 35px;
							text-align: center;
						}
						&::before {
							content: '/';
							left: 0px;
							right: initial;
						}
						&::after {
							height: 100%;
							content: '';
							background-image: url('./../../../global/assets/icons/chevron-down-thin.svg') !important;
							background-size: 15px;
							background-repeat: no-repeat;
							background-position: center !important;
							right: 0;
						}
					}
				}
			}
		}
	}
}

@include wolf-m-breadcrumb-dynamic();
