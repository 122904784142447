// Name:            Card
// Description:     Component to create boxed content containers
//
// Component:       `uk-card`
//
// Sub-objects:     `uk-card-body`
//                  `uk-card-header`
//                  `uk-card-footer`
//                  `uk-card-media-*`
//                  `uk-card-title`
//                  `uk-card-badge`
//
// Modifiers:       `uk-card-hover`
//                  `uk-card-default`
//                  `uk-card-primary`
//                  `uk-card-secondary`
//                  `uk-card-small`
//                  `uk-card-large`
//
// Uses:            `uk-grid-stack`
//
// ========================================================================

// @mixin hook-card() {}
// @mixin hook-card-body(){}
// @mixin hook-card-header(){}
// @mixin hook-card-footer(){}
// @mixin hook-card-media(){}
// @mixin hook-card-media-top(){}
// @mixin hook-card-media-bottom(){}
// @mixin hook-card-media-left(){}
// @mixin hook-card-media-right(){}
// @mixin hook-card-title(){}
// @mixin hook-card-badge(){}
// @mixin hook-card-hover(){}
// @mixin hook-card-default(){}
// @mixin hook-card-default-title(){}
// @mixin hook-card-default-hover(){}
// @mixin hook-card-default-header(){}
@mixin hook-card-default-footer() {
	border: none;
}
// @mixin hook-card-primary(){}
// @mixin hook-card-primary-title(){}
// @mixin hook-card-primary-hover(){}
// @mixin hook-card-secondary(){}
// @mixin hook-card-secondary-title(){}
// @mixin hook-card-secondary-hover(){}
// @mixin hook-card-misc(){}
