$o-fab-menu-border-radius: 25px;
$o-fab-menu-padding: 24px;

@mixin wolf-o-fab-menu($name: '.o-fab-menu') {
	#{$name} {
		height: 100vh;
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
		transition: all 300ms ease-in-out;
		opacity: 0;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: flex-end;
		margin: auto 0 30px auto;
		pointer-events: none;

		&.open {
			opacity: 0;
			//animation: fadeIn 0.25s ease-out 0.5s forwards;
			margin: auto 0 0 auto;
			pointer-events: all;

			@media (max-width: $breakpoint-xsmall-max) {
				background-color: rgba(0, 0, 0, 0.25);
			}
		}

		&-section {
			position: relative;

			@import './themes/index';
		}

		&__wrapper {
			width: 100%;
			height: 880px;

			@media (max-width: $breakpoint-xsmall-max) {
				height: 70vh;
			}
		}

		&__inner {
			overflow: hidden;
			overflow-y: auto; // for now, create an overflow just in case
			position: relative;
			background-color: #fff;
			height: 100%;
			box-sizing: border-box;
			border-radius: $o-fab-menu-border-radius $o-fab-menu-border-radius 0 0;
			display: flex;
			flex-direction: column;
			-webkit-box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
			box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.15);
		}

		&__header {
			box-sizing: border-box;
			position: relative;
			min-height: 40px;

			&-wrapper.m-fab-menu-header {
				padding-left: $o-fab-menu-padding;
				padding-right: $o-fab-menu-padding;
			}
		}

		&__closeMenu {
			position: absolute;
			top: $o-fab-menu-padding;
			right: $o-fab-menu-padding;
			width: 16px;
			height: 16px;
			// color: #101620;
			color: #62677e;

			&:hover {
				color: darken(#62677e, 5%);
			}
		}

		&__body {
			box-sizing: border-box;
			padding: $o-fab-menu-padding;
			flex-grow: 1;
		}

		&__drawer {
			height: 100%;

			.drawer {
				height: 100%;
				padding: $o-fab-menu-padding;
				box-sizing: border-box;

				&__header {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;

					> a {
						width: 16px;
						height: 16px;
						color: #62677e;
					}

					.o-fab-menu__closeDrawer {
						@media (max-width: $breakpoint-small-max) {
							display: block;
						}
					}
				}

				&__body {
					height: 100%;
				}
			}
		}

		.slide {
			position: relative;

			+ .slide {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				width: 100%;
				background-color: #fff;

				transition: all 300ms ease-in-out;
				transform: translateX(100%);

				&.overlay {
					transform: translateX(0);
					transition: all 400ms ease-in-out;
				}
			}
		}

		@media (min-width: $breakpoint-small) {
			&__wrapper {
				max-width: 375px;
				margin: 0 30px 0 auto;
				max-height: 720px;
			}

			&__inner {
				height: calc(100% - 100px);
				border-radius: $o-fab-menu-border-radius;
			}
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
			margin: auto 0 30px auto;
		}

		to {
			opacity: 1;
			margin: auto 0 0 auto;
		}
	}

	@keyframes fadeOut {
		from {
			opacity: 1;
			margin: auto 0 0 auto;
		}

		to {
			opacity: 0;
			margin: auto 0 30px auto;
		}
	}
}

@include wolf-o-fab-menu();
