@mixin wolf-o-search-results($name: '.o-search-results') {
	#{$name} {
		max-width: 100%;
		overflow-x: hidden;

		&__headline {
			display: inline-flex;
			margin-bottom: 40px;

			@include only-mobile {
				margin-bottom: 0;
			}

			.total-items {
				@include font-saira-500;

				margin-right: 15px;
				color: get-color('accent');

				&.uk-h {
					&1 {
						font-size: 90px;
						line-height: 1em;
						letter-spacing: -2px;
					}

					&2 {
						font-size: 75px;
						line-height: 1em;
						letter-spacing: -3px;
					}

					&3 {
						font-size: 50px;
						line-height: 1em;
						letter-spacing: -1.5px;
						@media (max-width: $breakpoint-small-max) {
							font-size: 30px;
							line-height: 1.17em;
						}
					}

					&4 {
						font-size: 32px;
						line-height: 1em;
						letter-spacing: -1.28px;
					}

					&5 {
						font-size: 28px;
						line-height: 1em;
						letter-spacing: -1.12px;
					}
				}

				+ .a-headline {
					margin-top: 0;
				}
			}

			&-mobile {
				.a-headline {
					font-size: 26px;
					margin-top: 10px;
				}
			}
		}

		& .search-bar {
			margin-bottom: 40px;
		}

		&__categories {
			margin-bottom: 60px;
		}

		&__results-wrapper {
			.m-teaser-post {
				margin-bottom: 30px;
			}
		}

		.m-pagination {
			margin-top: 62px;

			.uk-pagination {
				justify-content: center;
			}

			&__current-page {
				a {
					background-color: get-color('primary') !important;
				}
			}
		}
	}
}

@include wolf-o-search-results();
