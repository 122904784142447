@mixin wolf-m-article-extra-info($name: '.m-article-extra-info') {
	#{$name} {
		padding: 0 1rem;
		margin-top: 140px;
		width: 85%;
		@media (max-width: $breakpoint-small) {
			width: 100%;
		}

		&__border-top {
			border-top: 1px solid map-get($colors, 'silver-grey');
			padding-top: 35px;
		}

		&__contact-image {
			width: 97px;
			height: 97px;
			background-size: cover;
			background-position: top center;
			border-radius: 50%;
		}

		&__extra-image {
			max-width: 170px;
			height: auto;
		}

		&__offset {
			margin-left: calc(50% - 16px);
		}

		&__icon-wrapper {
			border-radius: 50%;
			border: 2px solid map-get($colors, 'true-black');
			width: 90px;
			height: 90px;
			display: flex;

			span {
				max-width: 40px;
				height: auto;
				color: map-get($colors, 'wolf-red');
				display: flex;
				align-items: center;
				margin: 0 auto;

				> svg {
					min-width: 32px;
					width: 32px;
				}
			}
		}

		&__title {
			font-size: get-fontsize(m, small) !important;
			margin-bottom: 0;
		}

		&__text {
			font-size: get-fontsize(m, default);
			margin-top: 8px;
		}

		&__text p {
			margin: 0 0 8px 0;
		}

		.a-button--anchor-style {
			border: 0;
			margin: 8px 0 0 !important;
			&::after {
				margin-right: 0;
			}
			&:hover,
			&:focus,
			&:focus-within,
			&:active {
				border: 0;
				background-color: transparent;
				box-shadow: none;
				color: inherit;
			}
		}

		.a-button {
			margin-top: 16px;
			white-space: nowrap;
		}

		.uk-grid-stack {
			div[class^='uk-width-'] {
				padding-left: 0px !important;
			}
		}
	}
}

@include wolf-m-article-extra-info();
