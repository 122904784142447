@mixin wolf-a-input($name: '.a-input') {
	#{$name} {
		&__wrapper {
			margin-top: 33px;

			> label {
				font-size: 12px;
				color: #6b718b;

				&.uk-form-danger {
					~ .uk-input {
						border-color: get-color('danger');
						color: get-color('danger');
					}
				}
			}

			.uk-input,
			.uk-select {
				background-color: transparent;
				border: 1px solid #62677e;

				&::placeholder {
					@include font-inter-300;
					color: get-color('silver-grey');
					opacity: 0.2;
				}
			}

			#{$name}__required-input {
				color: get-color('primary');
			}
		}
	}
}

@include wolf-a-input();
