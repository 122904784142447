@mixin wolf-m-teaser-boxed($name: '.m-teaser-boxed') {
	#{$name} {
		background-color: #fcfcfc;
		border: 1px solid #d2d2d2;
		padding: 24px 18px;
		display: flex;
		flex-direction: column;
		position: relative;
		&__label-container {
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			#{$name}__label {
				background-color: get-color('true-white');
				border: 1px solid #e8e9eb;
				border-radius: 36px;
				margin: 0;
				padding: 10px 20px;
				@include font-inter-400();
				font-size: 14px;
				letter-spacing: -0.2px;
				@media (max-width: 767px) {
					padding: 5px 8px;
					font-size: 12px;
					line-height: 1;
				}
			}

			+ #{$name}__content-wrapper {
				#{$name}__image-container {
					margin-top: 24px;
				}
			}
		}

		&__content-wrapper {
			height: 100%;
			display: flex;
			flex-direction: column;
			.button {
				display: flex;
				flex: 1;
				align-items: flex-end;
				padding: 0 29px;
				@media (max-width: $breakpoint-small-max) {
					padding: 0;
				}
			}
		}

		&__image-container {
			display: flex;
			justify-content: center;
			margin-top: 4px;
			margin-bottom: 20px;
			padding-inline: 28px;
			@media (max-width: $breakpoint-small-max) {
				margin-top: 0;
				padding-inline: 0;
			}
			.image-wrapper {
				position: relative;
				height: 100%;
				max-height: 396px;

				> img {
					object-fit: contain;
					width: 100%;
				}
			}
		}

		&__textblock {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			height: auto;
			padding: 0 29px;
			@media (max-width: $breakpoint-small-max) {
				padding: 0;
			}
		}

		.a-headline {
			margin-bottom: 10px;
			min-height: 100px;
			@media (min-width: 960px) {
				font-size: 22px;
				line-height: 24px;
				min-height: auto;
			}
			@media (max-width: $breakpoint-small-max) {
				margin-bottom: 30px;
				min-height: auto;
			}
		}

		.a-copy {
			margin-bottom: 20px;
			flex: 1;
			word-break: break-word;
			> * {
				margin-bottom: 0;
			}
		}
	}
}

@include wolf-m-teaser-boxed();
