@mixin wolf-o-download-list($name: '.o-download-list') {
	#{$name} {
		box-sizing: border-box;
		padding-block: 0;
		position: sticky;
		top: -1px;
		z-index: 980;
		animation-duration: 0.5s;
		animation-timing-function: ease-out;
		animation-fill-mode: both;
		transition-delay: 0s;
		transition: top 0.2s ease;
		user-select: none;

		.container-fluid {
			box-sizing: border-box;
		}

		.basket-row {
			box-sizing: border-box;
			padding-block: 8px;
			min-height: 54px;

			&[data-basket-show='false'] {
				display: none;
			}

			@media (max-width: 450px) {
				padding-bottom: 12px;
			}

			@include min-lg {
				min-height: 64px;
				padding-block: 14px;
			}
		}

		body.mediacenter-opened & {
			z-index: 1002;
		}

		.button-wrapper {
			position: relative;

			.download-counter {
				background-color: get-color(wolf-red);
				transform: translateX(20%);
				border-radius: 4px;
				font-family: Inter;
				font-size: 14px;
				font-weight: 500;
				line-height: 1.29;
				letter-spacing: -0.42px;
				color: get-color(true-white);
				padding: 3px 5px;
				margin-top: -6px;
				min-height: 24px;
				min-width: 24px;
				box-sizing: border-box;
				text-align: center;
				box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);

				&:empty {
					display: none;
				}
			}

			.a-button {
				border: 0;
				outline: none;
				height: 38px;
				border-radius: 19px;
				color: get-color(wolf-black);
				box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
				transition: all 0.25s ease-out;
				background-color: get-color(true-white);

				&:hover,
				&:focus {
					background-color: get-color(true-white);
					box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
				}

				&:active {
					background-color: get-color(true-white);
					box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5) inset;
				}

				.a-button__text {
					color: inherit;
					margin: 0;
				}

				&.uk-flex-row-reverse {
					.a-button__icon-wrapper {
						margin-right: 8px;
						padding-left: 28px;

						@include only-mobile {
							margin-right: 0px;

							svg {
								display: none;
							}
						}
					}
				}
			}

			.download-counter {
				position: absolute;
				top: 0;
				right: 0;
				background-color: get-color(wolf-red);
			}
		}

		&-container-wrapper {
			position: fixed;
			z-index: 1000;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;

			> * {
				pointer-events: all;
			}

			&[data-open='false'] {
				#{$name} {
					&-backdrop {
						opacity: 0;
						pointer-events: none;
						backdrop-filter: blur(0);
					}

					&-container {
						opacity: 0;
						pointer-events: none;
						transform: translateY(100%);

						@media (min-width: 691px) {
							transform: translateX(100%);
						}
					}
				}

				.m-card.list-view {
					opacity: 0;
					margin-bottom: 20px;
					transition: all 0.25s ease-out;
				}
			}

			&[data-open='true'] {
				.m-card.list-view {
					opacity: 1;
					margin-bottom: 0;
					transition: all 0.25s ease-out;
				}
			}
		}

		&-backdrop {
			position: absolute;
			z-index: 2;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #11151c98;
			backdrop-filter: blur(4px);
			transition: all 0.15s ease-out;
		}

		&-container {
			position: absolute;
			z-index: 3;
			top: 50px;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			max-width: 100%;
			height: calc(100% - 50px);
			background-color: get-color(true-white);
			padding: 0;
			display: flex;
			flex-direction: column;
			border-radius: 10px 10px 0 0;
			transform: translateY(0);
			transition: all 0.15s ease-out;

			@media (min-width: 691px) {
				transform: translateX(0);
				top: 0;
				left: unset;
				height: 100%;
				max-width: 690px;
				border-radius: 0;
			}
		}

		&-header {
			display: flex;
			flex-wrap: wrap;
			gap: 16px;
			justify-content: space-between;
			align-items: center;
			padding: 18px 24px;
			flex-direction: row-reverse;

			@media (min-width: 691px) {
				padding: 30px;
				flex-direction: row;
			}

			.a-headline {
				display: flex;

				flex-grow: 1;
				justify-content: center;
				padding-right: 20px;

				@media (min-width: 691px) {
					justify-content: flex-start;
					padding-right: 0;
				}

				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					font-family: 'Inter';
					font-size: 20px;
					color: #0d505575;
					font-weight: bold;
					line-height: 1;
					letter-spacing: normal;
					text-align: left;
					margin: 0;
					padding: 0;

					@media (min-width: 691px) {
						padding: 4px 0 4px 30px;
						font-family: 'Saira';
						font-size: 35px;
						color: get-color(wolf-black);
						letter-spacing: -1.4px;
						font-weight: 500;
					}
				}
			}

			#{$name}-close {
				background-color: transparent;
				border: 0;
				outline: none;
				cursor: pointer;
				width: 46px;
				height: 46px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: -9px;

				@media (min-width: 691px) {
					width: 62px;
					height: 62px;
				}

				svg {
					width: 20px;
					height: 20px;
					display: flex;
					color: #85a6a9;
					margin: 0;

					@media (min-width: 691px) {
						color: #85a6a9;
					}
				}
			}
		}

		&-items {
			display: flex;
			flex-grow: 1;
			flex-direction: column;
			padding: 20px 24px;
			overflow: hidden auto;
			gap: 20px;

			&:empty() {
				&::after {
					content: '';
					width: 36px;
					height: 36px;
					display: flex;
					align-self: center;
					background-color: transparent;
					position: absolute;
					top: 50%;
					left: 50%;
					margin: -36px 0 0 -18px;
					border-radius: 50%;
					border-style: solid;
					border-width: 3px;
					border-color: get-color(wolf-black) get-color(wolf-black) transparent;
					animation: loader-rotate 0.6s linear infinite;
					opacity: 0.7;
				}
			}

			@media (min-width: 691px) {
				padding: 34px 60px;
			}

			&::-webkit-scrollbar {
				width: 8px;
			}

			&::-webkit-scrollbar-track {
				background-color: get-color(true-white);

				@media (min-width: 691px) {
					background-color: #f0f0f0;
				}
			}

			&::-webkit-scrollbar-thumb {
				background-color: get-color(true-white);
				outline: none;

				@media (min-width: 691px) {
					background-color: get-color(wolf-black);
				}
			}

			.m-card.list-view {
				min-height: auto;
				max-height: 122px;
				overflow: hidden;
				position: relative;

				@media (min-width: 691px) {
					max-height: 150px;
				}

				> a > .image-wrapper {
					position: absolute;
					padding-top: 0;
					width: 80px;
					height: 122px;

					> img {
						margin-top: 0;
					}

					@media (min-width: 691px) {
						width: 150px;
						height: 150px;
					}
				}

				> a {
					+ .m-card-content-wrapper {
						max-width: calc(100% - 80px);
						width: calc(100% - 80px);
						margin-left: 80px;
						flex: 0 0 100%;
						padding: 0 20px 34px;
						position: relative;

						@media (min-width: 691px) {
							max-width: calc(100% - 150px);
							width: calc(100% - 150px);
							margin-left: 150px;
						}
					}
				}

				> .image-wrapper {
					position: absolute;
					padding-top: 0;
					width: 80px;
					height: 122px;

					> img {
						margin-top: 0;
					}

					@media (min-width: 691px) {
						width: 150px;
						height: 150px;
					}

					+ .m-card-content-wrapper {
						max-width: calc(100% - 80px);
						width: calc(100% - 80px);
						margin-left: 80px;
						flex: 0 0 100%;
						padding: 0 20px 34px;

						@media (min-width: 691px) {
							max-width: calc(100% - 150px);
							width: calc(100% - 150px);
							margin-left: 150px;
						}
					}
				}

				.m-card-filtertags-date {
					margin-top: 14px;

					@media (min-width: 691px) {
						margin-top: 24px;
					}

					.tag:not(:first-of-type) {
						display: none;
					}

					.tag {
						font-family: 'Inter';
						@media (max-width: 690px) {
							min-height: 20px;
							align-items: center;
							justify-content: center;
						}
					}
				}

				.m-card-content {
					font-weight: 600;
					padding-right: 30px;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					margin-top: 18px;

					@media (max-width: 690px) {
						font-size: 12px;
						line-height: 1.5;
						letter-spacing: -0.36px;
						margin-top: 10px;
					}

					@media (min-width: 691px) {
						-webkit-line-clamp: 3;
					}

					p {
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
						overflow: hidden;

						@media (min-width: 691px) {
							-webkit-line-clamp: 2;
						}
					}
				}

				.m-card-hash,
				.show-on-listview.m-button-group {
					display: none;
				}

				.show-on-mobile.m-button-group {
					@include only-mobile {
						display: none;
					}
				}
			}

			.m-card:not(.list-view) {
				display: none;
			}
		}

		&-buttons {
			box-sizing: border-box;
			background-color: #f0f0f0;
			display: flex;
			flex-wrap: wrap;
			gap: 12px;
			justify-content: flex-end;
			align-items: center;
			padding: 20px 24px;
			width: 100%;

			@include min-lg {
				padding: 30px 60px;
			}

			@media (max-width: 530px) {
				flex-direction: column-reverse;
				gap: 1px;
			}

			.a-button {
				@media (max-width: 530px) {
					width: 100%;

					&.uk-button-secondary {
						flex-grow: 1;

						.a-button__text {
							margin: auto;
						}
					}
				}

				&.uk-button-default {
					flex-grow: 1;
					justify-content: center;

					.a-button__text {
						margin: auto;
					}
				}
			}
		}

		.counter-container {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			flex-direction: row;
			color: get-color(true-white);
			gap: 20px;
			font-size: 14px;
			line-height: 1;

			@media (max-width: 450px) {
				flex-direction: column-reverse;
				margin: auto;
				position: relative;
				gap: 0;
				width: calc(100% + #{$mobileGutter}* 2);
				align-items: flex-end;
			}

			.counter {
				&-wrapper {
					min-width: 110px;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					min-height: 36px;

					@media (max-width: 450px) {
						min-height: 20px;
						position: absolute;
						bottom: -11px;
						left: -#{$mobileGutter};
						right: -#{$mobileGutter};
						top: auto;
						width: calc(100% + #{$mobileGutter}* 2);
					}
				}

				&-top {
					display: flex;
					justify-content: flex-end;
					align-items: flex-end;
					margin-top: 0;
					margin-bottom: 4px;
					color: get-color(wolf-black);

					@media (max-width: 450px) {
						pointer-events: none;
						display: none;
					}
				}

				&-added {
					position: relative;
					font-weight: 500;
				}

				&-total {
					font-weight: 500;

					&::before {
						content: '/';
						margin: 0 6px;
					}
				}

				&-bottom {
					margin-top: 3px;
					display: flex;
					align-items: flex-end;

					@media (max-width: 450px) {
						background-color: inherit;
					}

					.counter-progress {
						width: 100%;
						display: flex;
						align-self: flex-end;
						height: 8px;
						background-color: transparent;
						box-shadow: none;
						border-radius: 0;
						overflow: hidden;
						position: relative;
						box-shadow: 0 0 0 1px get-color(true-white);

						@media (max-width: 450px) {
							height: 4px;
							box-shadow: none;
						}

						.counter-bar {
							position: absolute;
							z-index: 2;
							top: 0;
							left: 0;
							bottom: 0;
							background-color: get-color(true-white);
							transition: all 1s ease-out;
							transition-delay: 0.5s;

							&-last {
								position: absolute;
								z-index: 1;
								top: 0;
								left: 0;
								bottom: 0;
								background-color: #fff5;
							}
						}
					}
				}
			}
		}

		.message-row {
			background-color: get-color(wolf-red);
			color: get-color(true-white);

			@media (max-width: 450px) {
				margin-top: -1px;
			}

			&[data-show-message='false'] {
				display: none;
			}

			&[data-show-message='true'] {
				display: flex;
			}

			> .container {
				padding-block: 10px;
				gap: 10px;

				p {
					margin: 0 0 2px;
					font-family: 'Inter';
					font-size: 16px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.3;
					letter-spacing: -0.32px;
					text-align: left;
				}

				.message-close {
					margin-bottom: auto;
					background-color: transparent;
					border: 0;
					outline: none;
					user-select: none;
					color: get-color(true-white);
					width: 16px;
					height: 16px;
					padding: 0;
					cursor: pointer;
					margin-top: 3px;

					svg {
						width: 16px;
						min-width: 16px;
					}
				}
			}
		}

		+ .m-headline-stage {
			@include only-mobile {
				padding-top: 75px;
			}
		}
	}

	@keyframes loader-rotate {
		from {
			transform: rotateZ(0deg);
		}

		to {
			transform: rotateZ(359deg);
		}
	}

	body.mediacenter-opened {
		overflow: hidden;
		padding-right: 17px;
	}
}

@include wolf-o-download-list();
