@mixin wolf-m-formstep-system-component($name: '.m-formstep-system-component') {
	#{$name} {
		border-radius: 20px;
		margin: 0;
		border: unset;
		padding: 0;
		max-width: calc(869px - 60px);

		@media (min-width: $breakpoint-medium-max) {
			padding: 30px;
			background-color: #f8f8f8;
		}

		&__componentName {
			font-size: 17px;
			font-weight: 700;
			line-height: 22px;
			margin: 0;

			@media (min-width: $breakpoint-medium-max) {
				font-size: 22px;
			}
		}

		.input-disabled {
			pointer-events: none;
			border-color: get-color(stroke-grey);
			color: get-color(stroke-grey);
		}

		&__label {
			font-size: 12px;
			color: get-color(form-grey);
			margin: 0;
			margin-top: 20px;

			&.uk-form-danger {
				color: get-color(wolf-red);
				font-size: 12px !important;
				margin: 0;
				margin-top: 20px;
				padding: 0 !important;
			}
		}

		&__input {
			background-color: transparent;
			border: 1px solid get-color(form-grey);
			font-size: 16px;

			@media (min-width: $breakpoint-medium-max) {
				background-color: #f8f8f8;
			}

			&.uk-form-danger {
				padding: 0 10px !important;
				font-size: 16px !important;
			}
		}

		&__removal-button {
			border: unset;
			background-color: unset;
			cursor: pointer;
		}

		&__icon {
			width: 24px;
			height: 24px;

			svg {
				color: get-color(silver-grey);
			}
		}

		&__input-groups {
			flex-direction: column;

			@media (min-width: $breakpoint-medium-max) {
				flex-direction: row;

				&--first-line > div:first-child {
					margin-right: 16px;
				}
			}
		}

		.a-button {
			@media (max-width: $breakpoint-medium-max) {
				border-color: get-color(true-white);
				color: get-color(true-white);
			}
		}

		& .uk-select {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;

			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='35' viewBox='0 0 24 24' fill='none' stroke='rgb(98,103,126)' stroke-width='1' stroke-linecap='butt' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E") !important;
			background-position: 95% 50% !important;
		}

		.uk-form-danger {
			color: get-color(wolf-red);
			font-size: 15px;
			padding: 10px 0;
		}
	}
}

@include wolf-m-formstep-system-component();
