@mixin wolf-m-pdf-list-item($name: '.m-pdf-list-item') {
	#{$name} {
		color: map-get($colors, 'wolf-black');
		font-size: $global-small-font-size;

		@media (min-width: $breakpoint-medium) {
			margin-bottom: 91px;
		}

		@media (max-width: $breakpoint-medium) {
			border-top: 1px solid map-get($colors, 'stroke-grey');
			padding: 23px 0 33px 0;
		}

		&__name {
			@media (max-width: $breakpoint-medium-max) {
				font-size: $global-xsmall-font-size;
				font-weight: 500;
			}
		}

		&__ref {
			color: map-get($colors, 'dark-grey');

			@media (max-width: $breakpoint-medium-max) {
				font-size: $global-xsmall-font-size;
			}
		}

		&__download {
			a {
				display: inline-flex;
				text-decoration: none;

				&:hover {
					text-decoration: inherit;
				}
			}

			.label {
				@media (max-width: $breakpoint-medium-max) {
					font-size: $global-xsmall-font-size;
					font-weight: 700;
				}

				position: relative;

				span {
					width: 1em;
					position: absolute;
					top: 7px;
					left: -45px;
					height: 1em;

					svg {
						height: 15px;
					}
				}
			}

			#{$name}__pdf {
				width: 72px;

				svg {
					margin-left: 10px;
					min-width: 72px;
					width: 72px;
				}
			}
		}

		&__link {
			width: 32px;
		}

		&__valid-in {
			max-width: 230px;
		}
	}
}

@include wolf-m-pdf-list-item();
