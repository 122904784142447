@mixin wolf-m-professional-product-table-align-cell {
	&--left {
		// text-align: left;
		justify-content: flex-start !important;
	}

	&--center {
		// text-align: center;
		justify-content: center !important;
	}

	&--right {
		// text-align: right;
		justify-content: flex-end !important;
	}
}

$wolf-m-professional-product-table-desktop-gridlines-color: #ddd;

@mixin wolf-m-professional-product-table-desktop($name: '.m-professional-product-table-desktop') {
	#{$name} {
		&__headline {
			&.a-headline {
				> *.a-headline {
					margin: 0 0 32px;
				}
			}
		}

		&__controls {
			margin-bottom: 32px;

			#{$name}__icon {
				box-sizing: border-box;
				display: inline-block;
				width: 66px;
				height: 66px;
				line-height: 64px;
				padding: 0;
				border: 1px solid get-color('wolf-black');
				border-radius: 50%;
				margin: 0 10px;

				text-align: center; // center the icon
				transition: opacity 150ms ease-in-out;
				display: inline-flex;
				align-items: center;
				justify-content: center;

				&:hover {
					text-decoration: none;
					border-color: get-color('dark-blue');
					color: get-color('dark-blue');
				}

				&[disabled] {
					opacity: 0.25;
					user-select: none;
					pointer-events: none;
					cursor: default;
					transition: opacity 150ms ease-in-out;
				}

				span {
					width: 24px;
					min-height: 24px;
					display: inline-flex;

					svg {
						min-width: 24px;
						width: 24px;
					}
				}
			}
		}

		&__table {
			// support for the table white gradients
			width: 100%;
			position: relative;
			overflow: hidden;

			&.truncated-y {
				&::before {
					// add a blur to the bottom of the table
					background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 40%, rgba(255, 255, 255, 1) 100%);

					position: absolute;
					top: initial;
					left: 0;
					right: 0;
					bottom: 0;
					height: 100px;
					content: '';
					z-index: 1;
				}
			}

			&.truncated-x {
				// add a blur to the right of the table
				&::after {
					// background: rgb(255,255,255);
					background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 40%, rgba(255, 255, 255, 1) 100%);

					position: absolute;
					top: 0;
					left: initial;
					right: 0;
					bottom: 0;
					width: 100px;
					content: '';
					z-index: 1;
				}
			}
		}

		&__tableInner {
			overflow-x: scroll;
			width: 100%;
			position: relative;

			* {
				box-sizing: border-box;
			}

			&::-webkit-scrollbar {
				height: 36px;
				-webkit-appearance: none;
				background-color: rgba(255, 255, 255, 0);
			}

			&::-webkit-scrollbar-track,
			&::-webkit-scrollbar-thumb {
				background-clip: padding-box;
			}

			&::-webkit-scrollbar-track {
				border-top: 25px solid rgba(255, 255, 255, 0);
				border-bottom: 5px solid rgba(255, 255, 255, 0);
				border-left: 0px solid rgba(255, 255, 255, 0);
				border-right: 16px solid rgba(255, 255, 255, 0);
				background-color: #f5f5f5;
				width: 100%;
			}

			&::-webkit-scrollbar-thumb {
				border-top: 25px solid rgba(255, 255, 255, 0);
				border-bottom: 5px solid rgba(255, 255, 255, 0);
				border-right: 5px solid rgba(255, 255, 255, 0);
				border-left: 0px solid rgba(255, 255, 255, 0);
				background-color: rgba(0, 0, 0, 0.4);
			}

			.table {
				&__thead {
				}

				&__tbody {
					display: table;
					width: 100%;

					:not(.col__separator) {
						&.col.col__body:nth-child(odd) {
							> .cell-body {
								background-color: #f7f7f7;
							}
						}
					}

					& > .col__separator {
						& > .cell {
							& > .ci {
								padding-top: 55px !important;
								padding-bottom: 20px !important;
							}
						}
					}

					.sticky-col-1 {
						position: -webkit-sticky;
						position: sticky !important;
						z-index: 1 !important;
						left: 0 !important;
						background-color: white;
					}

					.sticky-col-2 {
						position: -webkit-sticky;
						position: sticky !important;
						z-index: 1 !important;
						background-color: white;
					}
				}

				&--outlines {
					.col {
						.cell-header {
							border-top-width: 1px;
							border-top-style: solid;
						}

						.cell:last-of-type {
							border-right-width: 1px;
							border-right-style: solid;
						}
					}

					.cell--left {
						border-left-width: 1px;
						border-left-style: solid;
					}

					.table__tbody {
						.col {
							&:last-of-type {
								.cell {
									border-bottom-width: 1px;
									border-bottom-style: solid;
								}
							}
						}
					}
				}

				&--column-lines {
					.cell:not(:last-of-type) {
						border-right-width: 1px;
						border-right-style: solid;
					}
				}

				&--row-lines {
					.col {
						.cell-header {
							border-bottom-width: 1px;
							border-bottom-style: solid;
						}

						&:not(:last-of-type) {
							.cell {
								border-bottom-width: 1px;
								border-bottom-style: solid;
							}
						}
					}
				}

				&--gridlines {
					.table__tbody {
						.col {
							&:first-child {
								.cell {
									border-top-color: $wolf-m-professional-product-table-desktop-gridlines-color;

									&:first-child {
										border-top-color: transparent;
									}
								}
							}
						}
					}

					.col {
						&__body {
							&:nth-child(2) {
								.cell {
									.ci {
										border-top-color: $wolf-m-professional-product-table-desktop-gridlines-color;
									}

									&:first-child {
										.ci {
											// remove the horizontal borders from the first row, first col
											border-top-color: transparent;
										}
									}
								}
							}

							&:last-child {
								.cell {
									&:first-child {
										.ci {
											// remove the horizontal borders from the last row, first col
											border-bottom-color: transparent;
										}
									}
								}
							}

							&:last-of-type .cell:not(:first-child) .ci::after {
								content: '';
								position: absolute;
								bottom: 0;
								left: 0;
								right: 0;
								margin: 0 11px;
								border-bottom: 1px solid $wolf-m-professional-product-table-desktop-gridlines-color;
							}

							.cell {
								&:first-child {
									// remove the vertical borders from the first col
									&::before,
									&::after {
										display: none;
									}
								}

								&-body {
									// cell bodies (not header)
									&::before,
									&::after {
										content: '';
										position: absolute;
										// z-index: -1;
										pointer-events: none;
										top: 0;
										bottom: 0;
									}

									&::before {
										left: 11px;
										border-right: 1px solid $wolf-m-professional-product-table-desktop-gridlines-color;
									}

									&::after {
										right: 11px;
										border-left: 1px solid $wolf-m-professional-product-table-desktop-gridlines-color;
									}
								}
							}
						}
					}
				}
			}

			.col {
				&__header,
				&__body {
					display: flex;
					flex-direction: row;
					display: table-row;
				}

				// hide rows when there's a row limit
				&--hidden {
					display: none;
				}

				// override - only hide cols which are not marked as shown
				&--shown {
					display: flex;
					display: table-row;
				}

				.cell {
					@include wolf-m-professional-product-table-align-cell();
					flex: 1 0 auto;
					padding: 0px 11px;

					// margin-left: 10px;
					// margin-right: 10px;
					position: relative;

					> .ci {
						// padding: 12px;
						border: 1px solid transparent;
						display: flex;
						align-items: center;
						justify-content: inherit; // take from cell
						min-width: 100%; // make border be full width
					}

					&-header {
						@include font-inter-600();
						font-size: 24px;
						line-height: 32px;
						padding-top: 16px;
						padding-bottom: 16px;
						display: table-cell;

						& > .ci {
							white-space: nowrap;
						}
					}

					&-body {
						font-size: 20px;
						line-height: normal;
						// display: flex;
						display: table-cell;
						justify-content: center;
					}

					&.separator {
						// add space above the row
						// padding-top: 55px;
						// add more space below the separator
						// padding-bottom: 20px;

						font-weight: 600;
					}
				}
			}
		}

		&__tableFooter {
			margin: 40px auto;

			#{$name} {
				&__button {
					&.a-button {
						@include font-inter-500();
						font-size: 16px;
						border-radius: 60px;
						border-width: 2px;
						padding: 11px 38px;

						transition: opacity 150ms ease-in-out;

						&[disabled] {
							opacity: 0.25;
							user-select: none;
							pointer-events: none;
							cursor: default;
							transition: opacity 150ms ease-in-out;
						}
					}
				}
			}
		}
	}
}

@include wolf-m-professional-product-table-desktop();
