@mixin wolf-m-masonry($name: '.m-masonry') {
	#{$name} {
		display: flex;
		justify-content: center;

		.uk-container,
		> div:not(.uk-container) {
			width: 100%;
			display: flex;
			justify-content: center;
		}

		&__column {
			height: 713px;

			a {
				text-decoration: none;
			}
		}

		#{$name}__column {
			padding-left: 16px;
			width: 100%;
		}

		&--five-elements {
			#{$name}__column {
				&:nth-child(1),
				&:nth-child(3) {
					#{$name}__tile {
						//height: 261px;
						height: 366px;
					}
				}

				&:nth-child(1) {
					max-width: 363px;
				}

				&:nth-child(2) {
					max-width: 489px;

					#{$name}__tile {
						//height: 633px;
						height: 748px;
					}
				}

				&:nth-child(3) {
					max-width: 615px;
				}
			}
		}

		&--four-elements {
			#{$name}__column {
				max-width: 741px;
				display: flex;
				flex-wrap: wrap;

				> a,
				.a-button {
					width: 100%;
				}

				#{$name}__tile {
					min-height: 400px;
					width: 100%;
				}
			}
		}

		&--three-elements {
			#{$name}__column {
				&:nth-child(1) {
					max-width: 867px;

					#{$name}__tile {
						//height: 309px;
						height: 342px;
					}
				}

				&:nth-child(2) {
					max-width: 615px;
					overflow: hidden;

					#{$name}__tile {
						//height: 633px;
						height: 700px;
					}
				}
			}
		}

		&--two-elements {
			#{$name}__column {
				#{$name}__tile {
					height: 633px;
				}
			}
		}

		&__tile {
			position: relative;
			margin-bottom: 15px;
			padding: 0;
			background-color: get-color('silver-grey');
			color: get-color('true-white');

			// .image-wrapper {
			// 	position: sticky;
			// }

			.gradient-fade {
				width: 100%;
				height: 100%;
				display: flex;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 1;
			}

			.content-wrapper {
				padding: 48px 34px;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 2;
				overflow-y: auto;

				&::-webkit-scrollbar {
					width: 8px;
				}

				&::-webkit-scrollbar-track {
					background-color: transparent;
				}

				&::-webkit-scrollbar-thumb {
					background-color: get-color('true-white');
					width: 9px;
					border-radius: 4px;
					border-style: solid;
					border-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0);
					border-width: 1px 1px 1px 0;
				}
			}

			.a-headline {
				color: get-color('true-white');
				margin-bottom: 14px;
			}

			.a-copy {
				@include font-inter-200();
			}
		}

		&__oneRow {
			overflow-x: scroll;
			display: inline-flex;

			-ms-overflow-style: none;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				display: none;
			}

			& a {
				text-decoration: none;
			}

			#{$name}__tile {
				height: 190px;
				min-width: 278px;
				max-width: 278px;
				margin-right: 20px;

				//padding: 25px 20px;
				box-sizing: border-box;
				overflow-x: hidden;
				overflow-y: auto;

				.image-wrapper {
					position: sticky;
					top: 0;

					&::before {
						background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.65));
					}
				}

				.content-wrapper {
					overflow-x: hidden;
					padding: 25px 20px;
					position: absolute;
					z-index: 1;
					display: flex;
					flex-direction: column;
				}

				.a-headline,
				.a-copy {
					display: flex;
				}

				.a-headline {
					width: 100%;
					max-width: 90%;

					> .a-headline {
						padding: 0;
						margin: 0;
					}
				}

				.a-copy {
					width: 100%;
				}
			}
		}
	}
}

@include wolf-m-masonry();
