@mixin wolf-o-checkout-summary($name: '.o-cart-summary') {
	#{$name} {
		margin-bottom: 80px !important;
		margin-top: 67px;

		@media (max-width: $breakpoint-medium) {
			margin-bottom: 48px !important;
		}

		&__price-details {
			.uk-grid {
				margin-left: 0px !important;
			}

			.uk-grid > * {
				padding-left: 0px !important;
			}
		}

		&__price-details-modifiers,
		&__price-details-body {
			.a-copy {
				margin: 0;
			}
		}

		&__price-header-right {
			.a-copy {
				margin: 0;
			}
		}

		&__price-details-body {
			margin-top: 32px;
		}

		&__product {
			padding-top: 20px;
			padding-bottom: 20px;
		}

		&__image-product {
			margin: auto;
			.a-image {
				margin-left: 33%;

				@media (max-width: $breakpoint-medium) {
					margin-left: 15%;
				}
			}
		}

		&__bold-text {
			font-weight: bold;
		}

		&__p-title,
		&__total-price {
			font-size: get-fontsize(m, medium);
			@media (max-width: $breakpoint-medium) {
				font-size: get-fontsize(m, small);
				margin-bottom: 8px;
			}
		}

		&__p-sku {
			font-size: 20px;
		}

		&__p-desc {
			color: map-get($colors, 'dark-grey');
			.a-copy {
				max-width: 70%;
				@media (max-width: $breakpoint-medium) {
					max-width: 95%;
					font-size: 12px;
					padding: 5px 0;
				}
			}
		}

		&__amounts {
			.a-copy,
			.a-price,
			.a-quantity-selector {
				display: flex;
				align-items: center;
			}
		}

		&__price-details-modifiers-container {
			padding-top: 20px;
			padding-bottom: 20px;

			#{$name}--price-modifier-bold {
				font-weight: bold;
			}
		}

		&__price-details-column {
			padding-right: 16px;
		}

		&__totals-wrapper {
			padding-top: 20px;

			@media (max-width: $breakpoint-medium) {
				padding-top: 0px;
			}
		}

		&__cb-container {
			padding-right: 16px;
			margin-bottom: 20px !important;

			a {
				padding-left: 6px;
				color: map-get($colors, 'wolf-black');
				text-decoration-color: map-get($colors, 'wolf-black');
				@include font-inter-600();
			}
		}

		.uk-checkbox {
			margin-top: 0px;
			margin-right: 20px;
			height: 24px;
			width: 24px;
		}

		.border-top-summary {
			border-top: 1px solid #d2d2d2;
		}

		&__submit-wrapper {
			display: flex;
			align-items: flex-end;

			a {
				margin-right: 10px;
				max-width: 154px;
				padding: 16px 19px;
			}
		}

		form input[type='submit'] {
			// see: @include wolf-submit-button();

			@include font-inter-500();
			height: 54px;
			padding: 16px 40px;
			margin: 0 10px !important;
		}
		// &__submitButton{
		//     border:0px;

		//     line-height: 1.4;
		//     padding: 16px 40px;
		//     font-size: get-fontsize(m, default);
		//     font-weight: 500;

		//     color: #fff;
		//     border: 1px solid #fff;
		//     text-align: center;
		//     margin: 0 10px;
		//     background-color: get-color(wolf-red);
		//     color: get-color(true-white);

		//     height: 54px;
		// }

		// &__submitButton:hover{
		//     background-color: #b70406;
		//     color: #fff;
		//     border-color: #fff;
		//     text-decoration: none;
		//     cursor:pointer;
		// }

		&__code-text {
			max-width: initial;
			width: initial;
			background-color: transparent;
			border: 1px solid get-color(silver-grey);
			margin-right: 16px;
			height: 54px;
		}

		&__code-label {
			margin-right: 10px;
			color: get-color(wolf-black);
			@include font-inter-400();
			padding-right: 185px;
		}

		&__code-input-wrapper {
			margin-top: 8px !important;
			a {
				margin-right: 10px;
				min-width: 154px;

				span {
					margin: 0 auto;
				}
			}
		}

		.a-quantity-selector--large {
			@media (max-width: $breakpoint-medium) {
				.a-quantity-selector__subtract,
				.a-quantity-selector__add,
				.a-quantity-selector__quantity {
					line-height: 40px;
					min-width: 40px;
					height: 40px;

					@media (max-width: ($breakpoint-medium - 160px)) {
						line-height: 30px;
						min-width: 30px;
						height: 30px;
					}
				}
				.a-quantity-selector__subtract,
				.a-quantity-selector__add {
					flex: 0 0 40px;

					@media (max-width: ($breakpoint-medium - 160px)) {
						flex: 0 0 30px;
					}
				}
				.a-quantity-selector__quantity {
					font-size: 24px;

					@media (max-width: ($breakpoint-medium - 160px)) {
						font-size: 18px;
					}
				}
				> a.uk-button {
					line-height: 40px;
					height: 40px;
					width: 40px;
					font-size: 24px;

					@media (max-width: 800px) {
						line-height: 30px;
						height: 30px;
						width: 30px;
						font-size: 18px;
					}
				}
			}
		}

		&__total-price {
			max-width: 50%;
			width: fit-content;
			width: -moz-fit-content;
			display: inline;
			padding-right: 10px;

			@media (max-width: $breakpoint-medium) {
				padding-left: 17px;
			}
		}

		&__label-price {
			padding-right: 45px;
		}

		&__modifier-price {
			max-width: 50%;
			width: fit-content;
			width: -moz-fit-content;
			display: inline;
			padding-right: 10px;

			.a-price__price {
				min-width: 90px;
				text-align: right;
			}
		}

		&__modifier-price-label {
			padding-right: 62px;
		}

		.uk-flex-right-small {
			@media (max-width: $breakpoint-medium) {
				justify-content: flex-end;
			}

			.a-button {
				@media (max-width: $breakpoint-medium) {
					padding-right: 10px;
				}
			}
		}

		.uk-flex-last-small {
			@media (max-width: $breakpoint-medium) {
				order: 99;
			}
		}

		&__price-details-totals {
			@media (max-width: $breakpoint-medium) {
				margin-top: 0px !important;
			}

			.border-top-summary {
				@media (max-width: $breakpoint-medium) {
					border: 0px;
				}
			}
		}

		&__amount-element {
			@media (max-width: $breakpoint-medium) {
				margin-top: 5px !important;
			}
		}

		&__amounts {
			@media (max-width: $breakpoint-medium) {
				padding: 30px 0;
			}
		}
	}
}

@include wolf-o-checkout-summary();
