@mixin wolf-m-energy-info($name: '.m-energy-info') {
	#{$name} {
		position: relative;
		overflow: hidden;

		& span {
			display: block;
			position: absolute;
			right: 0;
			background: #000;
			width: 70px;
			height: 30px;

			.selector--line {
				background: #000;
				content: '';
				height: 2px;
				width: 100vw;
				position: absolute;
				top: 12px;
				right: 45px;
				z-index: 1;

				@media (max-width: 840px) and (min-width: 641px) {
					width: 120px;
				}
			}
		}

		//

		//Selector position
		& span.appp {
			top: -3px;
		}

		& span.app {
			top: 20px;
		}

		& span.ap {
			top: 43px;
		}

		& span.a {
			top: 66px;
		}

		& span.b {
			top: 89px;
		}

		& span.c {
			top: 112px;
		}

		& span.d {
			top: 135px;
		}

		& span.e {
			top: 158px;
		}

		& span.f {
			top: 181px;
		}

		& span.g {
			top: 204px;
		}

		//Selector styling

		& span:before {
			content: '';
			position: absolute;
			top: 0;
			right: 100%;
			width: 0;
			height: 0;
			border-top: 15px solid transparent;
			border-right: 15px solid #000;
			border-bottom: 15px solid transparent;
		}

		& span {
			width: 50px;
			line-height: 30px;
			text-align: center;
			color: #fff;
			font-size: 16px;
			font-weight: bold;
			text-transform: uppercase;
		}

		& sub {
			bottom: 0em;
		}

		// Energy badge and content position
		& div {
			position: relative;
			height: 21px;
			margin: 2px 0;
			z-index: 30;
			padding-left: 5px;
			color: #fff;
		}

		& div:before {
			content: '';
			position: absolute;
			top: 0;
			left: 100%;
			background: transparent;
			width: 0;
			height: 0;
			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent;
		}

		// Energy badge color and text definition

		$dappp: 50px;

		& div.appp {
			background-color: rgb(0, 144, 54);
			width: $dappp;

			@media (max-width: 1240px) and (min-width: 841px) {
				width: calc(#{$dappp} - 10px);
			}

			@media (max-width: 840px) and (min-width: 641px) {
				width: 55px;
			}
		}

		& div.appp:before {
			border-left: 8px solid rgb(0, 144, 54);
		}

		$dapp: 66px;

		& div.app {
			background-color: rgb(87, 171, 39);
			width: $dapp;

			@media (max-width: 1240px) and (min-width: 841px) {
				width: calc(#{$dapp} - 10px);
			}

			@media (max-width: 840px) and (min-width: 641px) {
				width: 60px;
			}
		}

		& div.app:before {
			border-left: 8px solid rgb(87, 171, 39);
		}

		$dap: 80px;

		& div.ap {
			background-color: rgb(201, 210, 0);
			width: $dap;

			@media (max-width: 1240px) and (min-width: 841px) {
				width: calc(#{$dap} - 10px);
			}

			@media (max-width: 840px) and (min-width: 641px) {
				width: 65px;
			}
		}

		& div.ap:before {
			border-left: 8px solid rgb(201, 210, 0);
		}

		$da: 96px;

		& div.a {
			background-color: rgb(255, 237, 0);
			width: $da;

			@media (max-width: 1240px) and (min-width: 841px) {
				width: calc(#{$da} - 10px);
			}

			@media (max-width: 840px) and (min-width: 641px) {
				width: 70px;
			}
		}

		& div.a:before {
			border-left: 8px solid rgb(255, 237, 0);
		}

		$db: 110px;

		& div.b {
			background-color: rgb(250, 187, 0);
			width: $db;

			@media (max-width: 1240px) and (min-width: 841px) {
				width: calc(#{$db} - 10px);
			}

			@media (max-width: 840px) and (min-width: 641px) {
				width: 75px;
			}
		}

		& div.b:before {
			border-left: 8px solid rgb(250, 187, 0);
		}

		$dc: 126px;

		& div.c {
			background-color: rgb(235, 105, 11);
			width: $dc;

			@media (max-width: 1240px) and (min-width: 841px) {
				width: calc(#{$dc} - 10px);
			}

			@media (max-width: 840px) and (min-width: 641px) {
				width: 80px;
			}
		}

		& div.c:before {
			border-left: 8px solid rgb(235, 105, 11);
		}

		$dd: 140px;

		& div.d {
			background-color: rgb(226, 0, 26);
			width: $dd;

			@media (max-width: 1240px) and (min-width: 841px) {
				width: calc(#{$dd} - 10px);
			}

			@media (max-width: 840px) and (min-width: 641px) {
				width: 85px;
			}
		}

		& div.d:before {
			border-left: 8px solid rgb(226, 0, 26);
		}

		$de: 156px;

		& div.e {
			background-color: rgb(226, 0, 26);
			width: $de;

			@media (max-width: 1240px) and (min-width: 841px) {
				width: calc(#{$de} - 10px);
			}

			@media (max-width: 840px) and (min-width: 641px) {
				width: 90px;
			}
		}

		& div.e:before {
			border-left: 8px solid rgb(226, 0, 26);
		}

		$df: 170px;

		& div.f {
			background-color: rgb(226, 0, 26);
			width: $df;

			@media (max-width: 1240px) and (min-width: 841px) {
				width: calc(#{$df} - 10px);
			}

			@media (max-width: 840px) and (min-width: 641px) {
				width: 95px;
			}
		}

		& div.f:before {
			border-left: 8px solid rgb(226, 0, 26);
		}

		$dg: 186px;

		& div.g {
			background-color: rgb(226, 0, 26);
			width: $dg;

			@media (max-width: 1240px) and (min-width: 841px) {
				width: calc(#{$dg} - 10px);
			}

			@media (max-width: 840px) and (min-width: 641px) {
				width: 100px;
			}
		}

		& div.g:before {
			border-left: 8px solid rgb(226, 0, 26);
		}
	}
}

@include wolf-m-energy-info();
