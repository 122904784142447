@mixin wolf-m-finder-popup-form($name: '.m-finder-popup-form') {
	#{$name} {
		&__head {
			#{$name}__headline {
				display: inline-flex;
				width: 100%;

				&.headline-no-subline {
					margin-bottom: 25px;
					@media (max-width: $breakpoint-small-max) {
						margin-bottom: 0;
					}
				}

				.a-headline {
					display: flex;
					width: 100%;
					margin: 0 0 0 -5px;
					@media (max-width: $breakpoint-small-max) {
						color: #0d5055;
						text-align: center;
						opacity: 0.5;
						justify-content: center;
						align-items: center;
						align-items: center;
						margin-top: 12px;
						padding-inline: 45px;
					}
				}

				.uk-modal-close {
					position: absolute;
					z-index: 1;
					top: 40px;
					left: 40px;
					@media (min-width: $breakpoint-small-max) and (max-width: 1200px) {
						top: 20px;
						left: 20px;
					}

					@media (max-width: $breakpoint-small-max) {
						display: flex;
						width: fit-content;
						width: -moz-fit-content;
						align-items: center;
						justify-content: center;
						left: 24px;
					}

					svg {
						width: 24px;
						height: 24px;
						@media (max-width: $breakpoint-small-max) {
							width: 20px;
							height: 20px;
						}
					}

					line,
					path {
						opacity: 0.75;
						stroke-width: 2;
						color: #0d5055;
					}
				}
			}

			.a-copy {
				@include font-inter-200();
				font-size: 20px;
				line-height: 25px;
				margin-top: 25px;
				margin-bottom: 25px;

				@media (max-width: $breakpoint-small-max) {
					@include font-inter-400();
					font-size: 14px;
					color: #11151c;
				}
			}
		}

		&__modal {
			padding: 112px 0px 80px;
			@media (max-width: $breakpoint-small-max) {
				padding: 88px 0px 0px;
			}
		}

		&__body.uk-modal-dialog {
			width: 100%;
			max-width: 1200px !important;
			margin: 0px auto;
			padding: 0px;
			border-radius: 15px;
			font-family: Saira !important;

			#{$name}__content {
				padding: 100px 185px;

				@media (max-width: 1200px) {
					padding: 50px 60px;
				}

				@media (max-width: $breakpoint-small-max) {
					padding: 20px;
				}

				.submitted-message {
					font-family: 'Inter' !important;
				}

				#{$name}__form {
					margin-top: 100px;

					@media (max-width: $breakpoint-small-max) {
						margin-top: 35px;
					}

					.uk-form-label {
						@include font-inter-400();
						font-size: 16px;
						line-height: 16px;
						color: #727272;

						@media (max-width: $breakpoint-small-max) {
							font-size: 12px;
						}
					}
					input.uk-input {
						margin-top: 8px;
						margin-bottom: 40px;
						background-color: transparent;
						border: 1px solid #62677e;

						@media (max-width: $breakpoint-small-max) {
							margin-bottom: 15px;
						}
					}

					.m-finder-results-input {
						margin-bottom: 40px;
					}

					.m-finder-results-input__headline .a-headline,
					.o-teaser-grid__headline .a-headline {
						margin-bottom: 0px;
						.a-headline {
							color: #727272;
							margin-bottom: 10px;
						}
					}

					.m-finder-result {
						padding: 20px 10px;
						.m-finder-result__image {
							display: none;
						}

						.m-finder-result__content-wrapper {
							max-width: 48%;
							margin-right: 40px;

							@media (max-width: $breakpoint-small-max) {
								max-width: 100%;
							}
							.a-copy {
								font-size: 16px;
								line-height: 30px;
							}
						}

						.m-finder-result__teaser-grid {
							width: 50%;
							@media (max-width: $breakpoint-small-max) {
								width: 100%;
							}

							.m-finder-result-teasers {
								width: 100%;
								max-width: 100%;
								margin-left: 0;

								.m-finder-result-teasers__item-container {
									max-width: 45%;

									@media (max-width: $breakpoint-small-max) {
										max-width: 100%;
										padding-left: 0;
									}

									.content {
										height: fit-content;
										height: moz-fit-content;

										.a-headline {
											* {
												margin-bottom: 0px;
												margin-top: 20px;
											}
										}
									}
								}
							}
						}

						hr {
							display: none;
						}
					}

					& .m-teaser-bg {
						&__link {
							margin-bottom: 20px;

							& .a-button {
								text-align: left !important;
							}
						}
					}

					#{$name}__required-input {
						color: get-color('primary');
					}

					#{$name}__textarea {
						margin-top: 50px;

						@media (max-width: $breakpoint-small-max) {
							margin-top: 15px;
						}

						textarea {
							margin-top: 10px;
							background-color: transparent;
							border: 1px solid #62677e;
							min-height: 200px;
							margin-bottom: 20px;
						}
					}

					#{$name}__checkbox {
						margin-top: 20px;

						.uk-checkbox {
							background-color: transparent;
							border: 1px solid #62677e;
							margin-right: 25px;
							min-width: 16px;
							margin-top: 2px;

							&:checked {
								background-color: #e10000;
								background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A');
							}
						}

						label {
							display: inline-flex;
							font-size: 15px;
							@include font-inter-400();
							color: #6a6e70;

							@media (max-width: $breakpoint-small-max) {
								font-size: 12px;
							}
							a {
								text-decoration-color: #6a6e70;
							}
						}
					}

					#{$name}__submit {
						margin-top: 60px;
						display: flex;
						justify-content: flex-end;
						.uk-button {
							border: 1px solid get-color(true-white);
							background-color: get-color(wolf-red);
							color: get-color(true-white);
							border-radius: 0;
							outline: none;
							padding: 0 24px;
							min-width: 107px;

							div {
								vertical-align: middle;
								display: none;
							}
							.done-span {
								display: none;
								svg {
									width: 24px;
									vertical-align: middle;
								}
							}

							@media (max-width: $breakpoint-small-max) {
								padding: 0;
								min-width: 50px;
							}
						}
						@media (max-width: $breakpoint-small-max) {
							width: 100%;

							button {
								width: 100%;
								padding: 20px 0px;

								@include font-inter-600();
								font-size: 14px;
							}
						}
					}

					#{$name}__alert {
						margin-top: 20px;
					}
				}
			}
		}
	}
}

@include wolf-m-finder-popup-form();
