@mixin wolf-m-kb-footer($name: '.m-kb-footer') {
	#{$name} {
		margin-top: 4rem;
		border-bottom: 6px solid map-get($colors, 'wolf-red');
		background: linear-gradient(90deg, map-get($colors, 'wolf-black') 65%, map-get($colors, 'true-black') 10%);

		&.no-margin {
			margin-top: 0px;
		}

		&__logo-column {
			margin: 1rem 0 0 0;
			padding: 1rem;
		}

		&__logo-text {
			margin: 12px 0;
			color: map-get($colors, 'true-white');
			font-size: get-fontsize(m, default);

			@media (max-width: $breakpoint-medium) {
				text-align: right;
				padding-right: 20px;
				font-size: get-fontsize(s, xxsmall) !important;
				margin-bottom: 30px !important;
				margin-top: 42px !important;
			}
		}

		& .a-button {
			padding: 16px !important;
		}

		&__contact-column {
			padding: 2rem 0 2rem 2rem;
			background-color: map-get($colors, 'true-black');

			@media (max-width: $breakpoint-medium) {
				margin-top: 0px !important;
				padding: 62px 0 0 0 !important;
			}

			.m-cardimage {
				padding: 1rem 0 1rem 1rem;
			}

			.m-cardimage__wrapper.uk-grid {
				padding-right: 0;
			}

			.m-cardimage__wrapper.uk-first-column {
				padding-left: 0;
			}

			.m-cardimage__title {
				font-size: get-fontsize(m, default);
			}
		}

		@media (max-width: $breakpoint-small-max) {
			margin-top: 0;

			.uk-grid {
				margin-left: 0px !important;
			}

			.a-linklist__list-wrapper {
				margin: 0px !important;
				padding: 0px !important;
				background-color: get-color('darker-grey');
			}

			&__links {
				background-color: get-color('darker-grey');
				padding: 0;
				padding-bottom: 40px;

				#{$name}__links-column {
					display: flex;
					justify-content: center;
					padding: 0;

					.a-linklist {
						padding: 0;
						text-align: center;
					}

					.a-linklist__link {
						color: get-color('dark-blue-30-white');
						font-size: 13px;
					}

					.a-linklist__list-title {
						color: get-color('dark-blue-70-white');
						font-size: 20px;
						margin-top: 40px !important;
						margin-bottom: 16px;
					}

					.a-linklist__list-wrapper > li:not(.a-linklist__list-title) {
						margin-top: 8px !important;
					}
				}
			}

			& .uk-container {
				width: 100%;
				padding: 0;
			}

			&__contact-column .m-cardimage {
				padding: 0 !important;
			}

			& .m-cardimage--right .m-cardimage__column-text-wrapper {
				padding-left: 32px;
			}

			& .m-cardimage--right .m-cardimage__wrapper {
				justify-content: center;
			}

			& .m-cardimage__cardimage-text {
				padding-top: 22px;
				font-size: get-fontsize(s, default) !important;
			}

			& .m-cardimage__contact-image {
				width: 88px;
				height: 88px;
			}

			& .a-wolf-logo svg {
				width: 84px;
				height: auto;
			}

			& .a-button.has-icon {
				padding: 0 8px !important;
				font-weight: bold;
				white-space: nowrap;
				margin-top: 35px;
			}

			& .a-button__icon-wrapper.uk-icon svg {
				fill: map-get($colors, 'true-white');
			}
		}
	}
}
@include wolf-m-kb-footer();
