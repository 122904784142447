@mixin wolf-m-pdf-list-toolbar($name: '.m-pdf-list-toolbar') {
	#{$name} {
		background-color: map-get($colors, 'light-grey');
		margin: 0 auto;
		padding: 100px 0 50px;

		@media (max-width: $breakpoint-medium-max) {
			padding: 67px 0 72px;
		}

		&__search-title {
			margin-bottom: 30px !important;
			@media (min-width: $breakpoint-medium) {
				margin-bottom: 60px !important;
			}

			span.query {
				color: map-get($colors, 'wolf-red');
			}
		}

		.filter-wr {
			display: inline-block;
			margin-right: 50px;

			@media (max-width: $breakpoint-medium-max) {
				margin-right: 20px;
			}

			.select-label {
				color: map-get($colors, 'wolf-black');
				display: inline-block;
				margin-bottom: 12px;
				font-size: $global-xsmall-font-size;
			}
			select.uk-select {
				min-width: 80px;
				background-color: white;
				border: 1px solid map-get($colors, 'silver-grey');
				height: auto;
				padding: 20px 40px 20px 20px;
				font-size: 16px;
				color: map-get($colors, 'dark-grey');
			}
		}
	}
}

@include wolf-m-pdf-list-toolbar();
