@mixin wolf-o-fhw-search($name: '.o-fhw-search') {
	#{$name} {
		&__app {
			max-width: $global-max-width;
			margin: 0 auto;
		}
		&__scrollbars {
			&::-webkit-scrollbar {
				width: 20px;
				-webkit-appearance: none;
				background-color: rgba(255, 255, 255, 0);
			}

			&::-webkit-scrollbar-track,
			&::-webkit-scrollbar-thumb {
				background-clip: padding-box;
			}

			&::-webkit-scrollbar-track {
				border-top: 18px solid rgba(255, 255, 255, 0);
				border-bottom: 18px solid rgba(255, 255, 255, 0);
				border-left: 5px solid rgba(255, 255, 255, 0);
				border-right: 5px solid rgba(255, 255, 255, 0);
				background-color: #f5f5f5;
			}

			&::-webkit-scrollbar-thumb {
				border-top: 18px solid rgba(255, 255, 255, 0);
				border-bottom: 18px solid rgba(255, 255, 255, 0);
				border-right: 5px solid rgba(255, 255, 255, 0);
				border-left: 5px solid rgba(255, 255, 255, 0);
				background-color: #dedede;
			}
		}
	}
}

@include wolf-o-fhw-search();
