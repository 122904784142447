@mixin wolf-m-footnotes($name: '.m-footnotes') {
	#{$name} {
		margin: auto;
		box-sizing: border-box;

		&__content .a-copy {
			p {
				margin: 0 auto;
				padding: 20px 150px;
				@media (max-width: $breakpoint-medium-max) {
					padding: 25px;
				}

				@media (max-width: $breakpoint-xsmall-max) {
					padding: 24px 24px 16px;
				}
			}
		}
	}
}

@include wolf-m-footnotes();
