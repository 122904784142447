@mixin wolf-m-product-price($name: '.m-product-price') {
	#{$name} {
		text-align: end;
		max-width: fit-content;
		max-width: -moz-fit-content;

		&__old-price span.a-price {
			font-size: 10px;
			text-decoration: line-through red solid;
		}

		&__price span.a-price {
			font-size: $global-large-font-size;
			font-weight: 600;
			white-space: nowrap;
		}

		&__sub-text {
			font-size: 10px;
			margin: 6px 0;
		}

		&__legal {
			font-size: 10px;
			margin-top: 12px;
			color: get-color('wolf-black');
		}

		&__info-text {
			margin: 0 5px;
			cursor: pointer;
		}
	}
}

@include wolf-m-product-price();
