// Name:            Button
// Description:     Styles for buttons
//
// Component:       `uk-button`
//
// Sub-objects:     `uk-button-group`
//
// Modifiers:       `uk-button-default`
//                  `uk-button-primary`
//                  `uk-button-secondary`
//                  `uk-button-danger`
//                  `uk-button-text`
//                  `uk-button-link`
//                  `uk-button-small`
//                  `uk-button-large`
//
// States:          `uk-active`
//
// ========================================================================

// Variables
// ========================================================================

// $button-line-height:                             $global-control-height;
// $button-small-line-height:                       $global-control-small-height;
// $button-large-line-height:                       $global-control-large-height;

$button-font-size: 16px;
$button-small-font-size: 10px;
$button-large-font-size: 22px;

// $button-padding-horizontal:                      $global-gutter;
// $button-small-padding-horizontal:                $global-small-gutter;
// $button-large-padding-horizontal:                $global-medium-gutter;

$button-default-background: get-color(wolf-red);
$button-default-color: get-color(true-white);
$button-default-hover-background: get-color(red-hover);
$button-default-hover-color: get-color(true-white);
$button-default-active-background: get-color(red-hover);
$button-default-active-color: get-color(true-white);

$button-primary-background: get-color(true-white);
$button-primary-color: get-color(wolf-red);
$button-primary-hover-background: get-color(wolf-red);
$button-primary-hover-color: get-color(true-white);
$button-primary-active-background: get-color(red-hover);
$button-primary-active-color: get-color(true-white);

$button-secondary-background: transparent;
$button-secondary-color: get-color(wolf-red);
$button-secondary-hover-background: transparent;
$button-secondary-hover-color: get-color(wolf-red);
$button-secondary-active-background: transparent;
$button-secondary-active-color: get-color(wolf-red);

$button-default-blue-background: get-color(dark-blue);
$button-default-blue-color: get-color(true-white);
$button-default-blue-hover-background: get-color(wolf-black);
$button-default-blue-hover-color: get-color(true-white);
$button-default-blue-active-background: get-color(wolf-black);
$button-default-blue-active-color: get-color(true-white);

$button-primary-blue-background: transparent;
$button-primary-blue-color: get-color(dark-blue);
$button-primary-blue-hover-background: get-color(dark-blue);
$button-primary-blue-hover-color: get-color(true-white);
$button-primary-blue-active-background: get-color(dark-blue);
$button-primary-blue-active-color: get-color(true-white);

$button-secondary-blue-background: transparent;
$button-secondary-blue-color: get-color(dark-blue);
$button-secondary-blue-hover-background: transparent;
$button-secondary-blue-hover-color: get-color(dark-blue);
$button-secondary-blue-active-background: transparent;
$button-secondary-blue-active-color: get-color(dark-blue);

// $button-danger-background:                       $global-danger-background;
// $button-danger-color:                            $global-inverse-color;
// $button-danger-hover-background:                 darken($button-danger-background, 5%);
// $button-danger-hover-color:                      $global-inverse-color;
// $button-danger-active-background:                darken($button-danger-background, 10%);
// $button-danger-active-color:                     $global-inverse-color;

$button-disabled-background: #f7f7f7;
$button-disabled-color: get-color(silver-grey);

// $button-text-line-height:                        $global-line-height;
// $button-text-color:                              $global-muted-color;
// $button-text-hover-color:                        $global-color;
// $button-text-disabled-color:                     $global-muted-color;

// $button-link-line-height:                        $global-line-height;
// $button-link-color:                              $global-link-color;
// $button-link-hover-color:                        $global-link-hover-color;
// $button-link-hover-text-decoration:              underline;
// $button-link-disabled-color:                     $global-muted-color;
