@mixin wolf-m-fab-menu-header($name: '.m-fab-menu-header') {
	#{$name} {
		padding: 25px 0 0 25px;

		.a-headline {
			margin-bottom: 10px;
			margin-left: -2px;
		}

		&__question {
			margin: 0px;
			@include font-inter-300();
			font-size: 16px;
			line-height: 20px;
		}

		&--red {
			background-color: #e21b00;
			color: get-color(true-white);

			padding: 15px 0;

			.a-headline {
				color: get-color(true-white);
			}

			+ .o-fab-menu__closeMenu {
				color: get-color(true-white);
			}
		}
	}
}

@include wolf-m-fab-menu-header();
