@mixin wolf-m-card($name: '.m-card') {
	$beakpoint: 768px;

	#{$name} {
		.image-wrapper {
			width: 100%;
			height: 0;
			padding-top: 56.25%;
			overflow: hidden;
			position: relative;
			display: flex;

			> img {
				position: absolute;
				z-index: 1;
				left: 0;
				right: 0;
				margin-top: -56.25%;
				width: 100%;
				height: 100%;
				display: flex;
			}
		}

		.m-button-group {
			position: relative;
			z-index: 1;
			height: 0;
			width: 100%;
			display: flex;
			justify-content: flex-end;

			> .container {
				margin-top: -21px;
				display: flex;
				justify-content: flex-end;
				padding: 0;
			}

			&.show-on-listview {
				display: none;
			}

			.a-button.m-card-btn {
				background-color: white;
				color: black;
				width: 41px;
				height: 41px;
				display: inline-flex;
				border-radius: 50%;
				overflow: hidden;
				padding: 5px;
				margin-right: 10px;
				box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
				align-items: center;
				justify-content: center;
				margin-top: -21px;
				text-decoration: none;
				box-sizing: border-box;
				font-weight: normal;
				cursor: pointer;

				> svg {
					height: 26px;
				}

				&:disabled {
					background-color: red;
					color: white;
					cursor: default;

					> svg {
						width: 20px;
					}
				}
			}
		}

		&-filtertags-date {
			margin-top: 30px;

			.tag {
				background-color: rgba(128, 158, 158, 0.15);
				color: black;
				margin-right: 2px;
				min-height: 27px;
				border-radius: 4px;
				padding-inline: 12px;
				padding-bottom: 2px;
				margin-bottom: 2px;
				line-height: 1;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				text-decoration: none;
				border: 0;
				outline: none;
				cursor: pointer;

				@media (min-width: 768px) {
					font-size: 16px;
				}

				+ #{$name}-date {
					margin-left: 16px;
				}
			}

			#{$name}-date {
				min-height: 27px;
				display: inline-flex;
				margin-left: 0;
				font-size: 14px;
				margin-bottom: 2px;
			}
		}

		&:not(.list-view) {
			max-width: 768px;
			display: flex;
			flex-direction: column;
			padding-left: 8px;
			padding-right: 8px;
			align-items: stretch;
			height: 100%;

			#{$name} {
				&-content-wrapper {
					padding: 0 20px 20px;

					@include min-md {
						padding: 0 46px 42px;
					}
				}
			}
		}

		&-content {
			margin-top: 30px;
		}

		&-content-wrapper {
			background-color: #f8f8f8;
			display: flex;
			flex-direction: column;
			padding: 0 20px 20px;
			flex-grow: 1;
			transition: all 0.25s ease-out;

			@include min-md {
				padding: 0 46px 42px;
			}

			a {
				color: get-color(wolf-black);
				text-decoration: none;
				text-decoration-color: transparent;
			}

			p {
				margin: 0;
			}
		}

		&-hash {
			margin-top: auto;
			padding-top: 15px;
			display: flex;
			flex-wrap: wrap;

			.hash {
				color: get-color(wolf-red);
				font-size: 16px;
				margin-right: 14px;
				text-decoration: none;

				@media (min-width: $beakpoint) {
					font-size: 14px;
				}
			}
		}

		&-delete {
			background-color: transparent;
			width: 26px;
			height: 26px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #838589;
			border: 0;
			outline: none;
			cursor: pointer;
			position: absolute;
			z-index: 2;
			top: 0;
			right: 0;
			margin: 11px 11px 0 0;

			svg {
				width: 16px;
				height: 16px;
			}
		}

		&.list-view {
			flex: 0 0 100%;
			max-width: 100%;
			flex-wrap: wrap;
			align-items: stretch;
			transition: all 0.25s ease-out;

			&:hover {
				background-color: #efefef;
			}

			@media (min-width: $beakpoint) {
				position: relative;
				display: flex;
				flex-direction: row;
				background-color: #f8f8f8;
				min-height: 320px;
			}

			> .image-wrapper {
				@media (min-width: $beakpoint) {
					flex: 0 0 50%;
					max-width: 50%;
					background-color: #f8f8f8;
					box-sizing: border-box;
					height: 100%;
					flex-grow: 1;
					padding: 0;
					position: absolute;

					> img {
						position: relative;
						margin-top: 0;
					}
				}

				@media (min-width: 1136px) {
					flex: unset;
					max-width: 568px;
					width: 100%;
				}

				+ .m-card-content-wrapper {
					box-sizing: border-box;

					@media (min-width: $beakpoint) {
						flex: 0 0 50%;
						max-width: 50%;
						margin-left: 50%;
						display: flex;
					}

					@media (min-width: 1136px) {
						flex: unset;
						max-width: calc(100% - 568px);
						width: calc(100% - 568px);
						margin-left: 568px;
					}
				}
			}

			> a {
				@media (min-width: $beakpoint) {
					flex: unset;
					max-width: 50%;
					width: 100%;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;

					> .image-wrapper {
						position: relative;
						height: 100%;
						padding-top: 0;
						overflow: hidden;

						> img {
							margin-top: 0;
						}
					}
				}

				@media (min-width: 1136px) {
					max-width: 568px;
				}

				+ .m-card-content-wrapper {
					box-sizing: border-box;

					@media (min-width: $beakpoint) {
						flex: 0 0 50%;
						max-width: 50%;
						margin-left: 50%;
						display: flex;
					}

					@media (min-width: 1136px) {
						flex: unset;
						max-width: calc(100% - 568px);
						width: calc(100% - 568px);
						margin-left: 568px;
					}
				}
			}

			@media (min-width: $beakpoint) {
				.a-image {
					object-fit: contain;
					object-position: top;
					display: flex;
					height: 100%;
					width: 100%;
					margin-top: 0;
					margin-bottom: auto;
				}
			}

			#{$name}-content-wrapper {
				padding: 0 30px 30px;

				@media (min-width: $beakpoint) {
					padding: 0 30px 20px 30px;
				}
			}

			#{$name}-content {
				@media (min-width: $beakpoint) {
					padding-right: 10vw;
				}
			}

			.m-card-hash {
				+ .show-on-listview.m-button-group {
					@media (min-width: $beakpoint) {
						margin-top: 0;
					}
				}
			}

			.m-button-group {
				@media (min-width: $beakpoint) {
					height: auto;

					> .container {
						height: auto;
						margin-top: 0;
						padding: 0;
					}
				}

				&.show-on-mobile {
					display: flex;

					@media (min-width: $beakpoint) {
						display: none;
					}
				}

				&.show-on-listview {
					display: none;

					@media (min-width: $beakpoint) {
						display: flex;
						margin-top: auto;
					}
				}

				.a-button.m-card-btn {
					@media (min-width: $beakpoint) {
						margin-top: unset;
					}
				}
			}
		}

		&:hover {
			#{$name} {
				&-content-wrapper {
					background-color: #efefef;
				}
			}
		}
	}
}

@include wolf-m-card();
