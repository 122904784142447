@mixin wolf-m-ahu-stage($name: '.m-ahu-stage') {
	#{$name} {
		hyphens: auto;
		display: flex;
		align-items: flex-start;

		&__stage-wrapper {
			background-color: map-get($colors, 'wolf-black');
			position: relative;
			width: 100%;
			height: 100vh;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			overflow: hidden;
			align-items: center;
			min-height: 929px;

			body.w-editmode & {
				background-color: white;
			}

			@media (min-width: 1921px) and (hover: hover) {
				max-height: 929px;
			}

			@media (min-width: 1921px) and (hover: coarse) {
				max-height: 929px;
			}

			.image-wrapper {
				vertical-align: unset;
				right: 0;
				bottom: 0;
				max-height: fit-content;
				&::before {
					background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.65));
				}

				> img {
					object-fit: cover;
					max-height: 100%;
					@media (max-width: $breakpoint-medium-max) {
						height: 100%;
						max-width: none;
					}
				}
			}

			@media (max-width: $breakpoint-medium-max) {
				margin-bottom: 0px !important;
			}

			@media (max-width: $breakpoint-small-max) {
				min-height: 496px; // quoting XD
				height: 100%;
			}

			@media (max-height: 400px) {
				height: fit-content;
				height: -moz-fit-content;
				min-height: fit-content;
				min-height: -moz-fit-content;
			}
		}

		&__background-slope {
			position: absolute;
			top: 0;
			right: 0px;
			height: inherit;
			width: 40%;
			z-index: 0;

			> div {
				position: relative;
				right: -74px;
				height: inherit;
				width: 100%;
				opacity: 0.75;
				transform: skew(-10deg);
				background-color: get-color('secondary');
			}
		}

		.uk-container {
			width: 100%;
			z-index: 1;

			@media (max-width: $breakpoint-small-max) {
				display: flex;
				flex-direction: column;
				box-sizing: border-box;
			}

			@media (max-height: 400px) {
				margin-top: 75px !important;
				height: fit-content;
				height: -moz-fit-content;
			}

			&.background {
				height: auto;
				margin-top: 0px;
				display: flex;
				align-items: center;

				#{$name}__left-container {
					@media (max-width: $breakpoint-medium-max) {
						padding-right: 24px;
					}

					@media (max-width: $breakpoint-small-max) {
						max-width: 100% !important;
					}

					p {
						max-width: 620px;

						@media (max-width: $breakpoint-small-max) {
							display: none;
						}
					}

					> .a-headline {
						&:last-child {
							> .a-headline {
								margin-bottom: 15px;
							}
						}

						> h1,
						> h2,
						> h3,
						> h4,
						> h5,
						> h6 {
							@media (max-width: $breakpoint-medium-max) {
								letter-spacing: normal;
							}

							@media (max-width: $breakpoint-small-max) {
								text-align: center;
							}
						}

						> h1 {
							@media (max-width: $breakpoint-medium-max) {
								font-size: 60px;
							}

							@media (max-width: $breakpoint-small-max) {
								text-align: center;
								font-size: $base-h1-font-size-m;
							}
						}
					}
				}

				#{$name}__right-container {
					justify-content: center;
				}
			}

			#{$name}__left-container {
				display: flex;
				flex: 1.5;
				justify-content: flex-start;
				flex-direction: column;

				> * {
					color: get-color('true-white');
				}

				@media (max-width: $breakpoint-small-max) {
					flex: 0;
					margin-top: 0;
					padding-top: 96px;
					padding-left: 7px;
					padding-right: 7px;
					box-sizing: border-box;
				}

				@media (max-height: 400px) {
					flex: none;
				}

				* {
					color: get-color('true-white');
				}

				.a-headline {
					max-width: 835px;
					color: get-color('true-white');
					+ .a-headline {
						margin-top: 0;
					}
				}

				h1 {
					margin-bottom: 90px;
					@media (max-width: $breakpoint-small-max) {
						margin-bottom: 20px;
					}
				}

				.a-copy {
					margin-left: 0;
					p {
						color: get-color('true-white');
						@media (max-width: $breakpoint-small-max) {
							font-size: 14px;
							line-height: 24px;
							margin-bottom: 14px;

							&:last-child {
								margin-bottom: 14px;
							}
						}
					}
				}

				p {
					font-size: 18px;
					font-weight: 200;
					line-height: 1.5em;
					letter-spacing: 0.4px;

					&:empty {
						display: none;
					}

					@media (max-width: $breakpoint-small-max) {
						font-size: 14px;
						line-height: 24px;
						margin-bottom: 14px;

						&:last-child {
							margin-bottom: 30px;
						}
					}
				}

				&.longtext {
					max-width: 1066px;

					.a-headline {
						max-width: 100%;
					}

					@media (max-width: $breakpoint-small-max) {
						display: flex;
						justify-content: center;
						align-items: flex-start;
						flex-direction: column;

						h1 {
							margin-bottom: 20px;
						}

						p {
							font-weight: 200;
							margin-top: 0;
							margin-bottom: 16px;
							font-size: 14px;
							line-height: 1.5em;
							letter-spacing: 0.4px;

							&:empty {
								display: none;
							}
						}
					}
				}
			}

			#{$name}__right-container {
				display: flex;
				justify-content: flex-end;
				flex: 1;
				align-items: center;

				@media (max-width: $breakpoint-small-max) {
					width: 100%;
					display: flex;
					justify-content: flex-start;
					align-items: flex-end;
					padding-bottom: 48px;
					padding-left: 7px;
					padding-right: 7px;
					box-sizing: border-box;
				}

				@media (max-height: 400px) {
					flex: none;
					align-items: flex-start;
				}

				.buttons {
					max-width: 368px;
					display: flex;
					flex-direction: column;
					justify-content: center;

					@media (max-width: $breakpoint-medium-max) {
						max-width: 100%;
					}

					@media (max-width: $breakpoint-small-max) {
						align-items: stretch;
					}

					.title {
						margin-bottom: 15px;
						width: 100%;
						@include font-inter-600();
						font-size: 34px;
						line-height: 34px;
						color: get-color('true-white');

						@media (max-width: $breakpoint-small-max) {
							font-size: 20px;
							line-height: 20px;
						}
					}

					.subtitle {
						margin-top: 0;
						margin-bottom: 55px;
						width: 100%;
						@include font-inter-200();
						font-size: 20px;
						line-height: 20px;
						color: get-color('true-white');

						@media (max-width: $breakpoint-small-max) {
							font-size: 16px;
							line-height: 16px;
							margin-top: 0;
						}
					}

					a,
					.a-button {
						padding-left: 15px;
						justify-content: flex-end;
						width: 368px;
						height: 68px;
						margin: 12px 0;
						white-space: nowrap;

						@media (max-width: $breakpoint-medium-max) {
							padding-left: 5px;
							width: auto;
							height: 48px;
							font-size: 16px;
							margin: 7px 0;
						}

						// Overruling main download-button styles
						&:last-child {
							margin-bottom: 0;
						}

						svg {
							min-width: 18px;
							height: 18px;
						}

						.a-button__icon-wrapper {
							padding: 0 15px;
						}

						&.uk-button-default {
							border: solid 1px get-color('true-white');
						}
					}

					.uk-button.uk-button-secondary-blue {
						color: white;
					}

					.uk-button.uk-button-tertiary {
						&.uk-active,
						&:active,
						&:focus,
						&:hover {
							background-color: get-color('true-white');
							color: get-color('wolf-black');
						}
					}

					&.background {
						background-color: rgba(255, 255, 255, 0.2);
						-webkit-backdrop-filter: blur(10px) brightness(115%);
						backdrop-filter: blur(10px) brightness(115%);
						height: fit-content;
						padding: 60px 40px 40px;
						border-radius: 10px;
						max-width: 523px;
						width: 100%;

						@media (max-width: $breakpoint-small-max) {
							padding: 30px 20px;
						}

						.a-button {
							width: 100%;
							max-width: 524px;
							height: 105px;
							justify-content: center;
							@include font-inter-600();
							font-size: 24px;
							padding: 0px;
							align-items: center;

							@media (max-width: $breakpoint-medium-max) {
								font-size: 20px;
							}

							@media (max-width: $breakpoint-medium-max) {
								max-width: 410px;
							}

							@media (max-width: $breakpoint-small-max) {
								width: 100%;
								height: 67px;
								font-size: 20px;
								line-height: 20px;
								max-width: unset;
							}

							.a-button__icon-wrapper {
								padding: 5px;

								@media (max-width: $breakpoint-small-max) {
									display: none;
								}

								svg {
									color: get-color('true-white');
									width: 25px;
									height: 25px;

									@media (max-width: $breakpoint-small-max) {
										width: 13px;
										height: 13px;
									}
								}
							}

							.a-button__extraInfo {
								min-width: 230px;
								max-width: 230px;
								width: 100%;
								top: 20%;
								left: 85%;

								@media (max-width: 1785px) {
									min-width: 180px;
									max-width: 180px;
								}

								@media (max-width: 1680px) {
									top: 80%;
									left: 25%;
									min-width: 280px;
									max-width: 280px;
								}

								&:before {
									border-left: 10px solid transparent;
									border-right: 10px solid #ea4c4c;
									border-top: 10px solid transparent;
									border-bottom: 10px solid transparent;
									right: 100%;
									top: 20px;

									@media (max-width: 1680px) {
										border-left: 10px solid transparent;
										border-right: 10px solid transparent;
										border-top: 10px solid transparent;
										border-bottom: 10px solid #ea4c4c;
										right: 64px;
										top: -20px;
									}
								}

								p {
									white-space: normal;
								}
							}

							&:hover {
								.a-button__icon-wrapper {
									svg {
										color: get-color('true-black');
									}
								}
							}
						}
					}

					button[data-download='share'] {
						@media (max-width: $breakpoint-medium-max) {
							font-size: 16px;
						}
					}
				}

				&.accordion-container {
					@media (max-width: $breakpoint-small-max) {
						align-items: flex-start;
					}
				}

				.accordion {
					color: get-color('true-white');

					&.accordion-container {
						@media (max-width: $breakpoint-small-max) {
							align-items: flex-start;
						}
					}

					.m-accordion-content__accordion-wrapper.uk-accordion {
						width: 368px;

						@media (max-width: $breakpoint-small-max) {
							width: 100%;
						}

						.uk-open {
							.uk-accordion-title {
								.uk-icon:not(.titleElement) {
									svg {
										height: 16.5px !important;
									}
								}
							}
						}
					}

					.uk-accordion li:first-child a.uk-accordion-title {
						margin-top: 0px !important;
					}

					li {
						margin: 0px;
					}

					.uk-accordion-title {
						width: 368px;
						height: 68px;
						margin: 12px 0;
						font-size: 24px;
						color: get-color('true-white');

						@media (max-width: $breakpoint-small-max) {
							padding: 0 0 5px 5px;
							height: 40px;
							width: 100%;
							background: none;
							font-size: 16px;
							line-height: 1.5em;
							letter-spacing: 0.4px;
						}

						svg {
							height: 15px;
							width: 15px;
							min-width: 15px;
							transition-duration: 0.1s;
							transition-property: transform;
						}
					}

					li:not(:last-child) {
						border-bottom: 1px solid white;
					}

					li {
						@media (max-width: $breakpoint-small-max) {
							border-top: 0px;
						}
					}

					.uk-accordion-content {
						background: transparent;
						margin-bottom: 30px;

						@media (max-width: $breakpoint-small-max) {
							margin-bottom: 5px;
							margin-top: 5px;
						}

						.a-button {
							margin-top: 15px;

							@media (max-width: $breakpoint-small-max) {
								font-size: 16px;
							}

							svg {
								@media (max-width: $breakpoint-small-max) {
									width: 12px;
									height: 12px;
								}
							}
						}
					}
				}

				.linksRight {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;

					@media (max-width: $breakpoint-small-max) {
						width: 100%;
					}

					a {
						@include font-inter-400();
						color: get-color('true-white');
						width: 100%;
						display: block;
						font-size: 24px;
						line-height: 25px;
						text-decoration: none;
						width: 300px;

						@media (max-width: $breakpoint-small-max) {
							font-size: 16px;
							width: 100%;
						}
					}

					a:after {
						content: '';
						height: 1px;
						background: white;
						display: block;
						margin-top: 27.5px;
						margin-bottom: 27.5px;
					}
				}
			}
		}
	}
}

@include wolf-m-ahu-stage();
