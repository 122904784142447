@mixin wolf-a-button($name: '.a-button') {
	#{$name} {
		line-height: $global-line-height;
		padding: 16px 30px;
		font-size: $global-xsmall-font-size;
		font-weight: 500;
		border: 0;
		outline: none;
		align-items: center;

		&:focus:not(:active) {
			outline: none;
		}

		&__text {
			margin: 0;
			display: inline-flex;
		}

		&__icon-wrapper {
			z-index: 1;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			:not(.only-icon) & {
				padding: 15px 25px 15px 15px;
				margin-left: $global-xsmall-margin;
				margin-right: 0;
			}
			.only-icon & {
				padding: 15px;
			}
			.uk-flex-row-reverse:not(.only-icon) & {
				padding: 15px 5px 15px 30px;
				margin-right: $global-xsmall-margin;
				margin-left: 0;
			}
			& > svg {
				width: 20px;
				height: 20px;
				stroke-width: 5px;
			}
		}

		&.uk-button-primary {
			border: 1px get-color(wolf-red) solid;
			&:hover {
				background-color: get-color(wolf-red);
			}

			&[data-is-spinning='true'] {
				> .spinner {
					border-color: transparent get-color('wolf-red') get-color('wolf-red');
				}
			}

			&-blue {
				&[data-is-spinning='true'] {
					> .spinner {
						border-color: transparent get-color('wolf-black') get-color('wolf-black');
					}
				}
			}
		}

		&.uk-button-default {
			&:hover {
				background-color: get-color(danger);
			}
		}

		&.uk-button-secondary,
		&.uk-button-secondary-blue,
		&.uk-button-clear-blue {
			&.uk-disabled {
				background-color: transparent;
			}
			&[data-is-spinning='true'] {
				> .spinner {
					border-color: transparent get-color('wolf-black') get-color('wolf-black');
				}
			}
		}

		&.uk-button-secondary-blue:hover,
		&.uk-button-clear-blue:hover {
			font-weight: 700;
		}

		&.uk-button-tertiary {
			&[data-is-spinning='true'] {
				> .spinner {
					border-color: transparent get-color('true-white') get-color('true-white');
				}
			}
		}

		&.has-icon {
			padding: 1px 0 0 30px;
			&.uk-flex-row-reverse:not(.only-icon) {
				padding: 1px 30px 0 0;
			}
		}

		&.has-no-text {
			padding: 1px 0px 0px 0px;
			&.only-icon {
				.a-button__icon-wrapper {
					margin: 0;
				}

				&[data-is-spinning='true'] {
					> .spinner {
						background-color: get-color('wolf-red');
						box-shadow: 0 0 0 2px get-color('wolf-red');
						border-color: transparent get-color('true-white') get-color('true-white');
						width: 8px;
						height: 8px;
						border-width: 2px;
					}
				}
			}
		}

		&.has-icon-highlighted {
			&.uk-button-default {
				background-color: get-color(red-30-white);
				#{$name}__icon-wrapper {
					background-color: get-color(wolf-red);
				}
			}

			&.uk-flex-row-reverse {
				#{$name}__icon-wrapper {
					padding: 15px 15px 15px 25px;
				}
			}

			#{$name}__icon-wrapper {
				padding: 15px 25px 15px 15px;
			}

			&.uk-button-primary {
				background-color: transparent;
				#{$name}__icon-wrapper {
					background-color: get-color(red-90-white);
					color: get-color(wolf-red);
				}
				&:hover {
					background-color: get-color(wolf-red);
					#{$name}__icon-wrapper {
						background-color: get-color(wolf-red);
						color: get-color(true-white);
					}
				}
			}

			&.uk-button-default-blue {
				background-color: get-color(dark-blue-30-white);
				color: get-color(true-white);
				#{$name}__icon-wrapper {
					background-color: get-color(dark-blue);
					color: get-color(true-white);
				}
				&:hover,
				&.selected {
					background-color: get-color(dark-blue);
				}
			}

			&.uk-button-primary-blue {
				background-color: transparent;
				color: get-color(wolf-black);
				border: 1px solid get-color(dark-blue);
				#{$name}__icon-wrapper {
					background-color: get-color(silver-grey);
					color: get-color(wolf-black);
				}

				&:hover,
				&.selected {
					background-color: get-color(dark-blue);
					color: get-color(true-white);
					#{$name}__icon-wrapper {
						background-color: get-color(dark-blue);
						color: get-color(true-white);
					}
				}
			}

			&.uk-button-secondary-blue {
				background-color: transparent;
				color: get-color(wolf-black);
				border: 1px solid get-color(silver-grey);
				#{$name}__icon-wrapper {
					background-color: get-color(silver-grey);
					color: get-color(wolf-black);
				}
				&:hover {
					background-color: transparent;
					color: get-color(wolf-black);
					font-weight: bold;
				}
			}

			&.uk-button-default:hover,
			&.uk-button-primary:hover,
			&.uk-button-default.selected &.uk-button-primary.selected {
				background-color: get-color(wolf-red);
				color: get-color(true-white);
				#{$name}__icon-wrapper {
					background-color: get-color(wolf-red);
					color: get-color(true-white);
				}
			}
		}

		&.uk-button-clear-blue {
			background-color: transparent;
			color: #81adc8;
			padding: 0px;
			#{$name}__icon-wrapper {
				background-color: transparent;
				color: #81adc8;
				padding: 0px 15px 0px 0px;
			}
			span {
				margin: 0px;
			}
			&:hover {
				background-color: transparent;
				color: #81adc8;
				font-weight: bold;
			}
		}

		&.uk-button-transparent-white {
			background-color: get-color(true-white);
			color: #963f47;
			#{$name}__icon-wrapper {
				background-color: get-color(true-white);
				color: #963f47;
			}
			span {
				margin: 0px;
				@include font-inter-400();
				font-size: 16px;
			}
			&:hover,
			&.selected {
				background-color: get-color(true-white);
				color: #963f47;
				font-weight: bold;
			}
		}

		&.uk-button-white-back {
			background-color: get-color(true-white);
			color: get-color(true-black);
			#{$name}__icon-wrapper {
				background-color: get-color(true-white);
				color: get-color(true-black);
			}
			span {
				margin: 0px;
				@include font-inter-500();
				font-size: 16px;

				.o-grid-container.oneRow__column-width--4 & {
					margin: 0px 5px;
				}
			}
			&:hover,
			&.selected {
				background-color: get-color(true-white);
				color: get-color(true-black);
				font-weight: bold;
			}
		}

		&.uk-button-ghost {
			background-color: transparent;
			color: inherit;
			padding: 0px !important;
			text-decoration: none;
			#{$name}__icon-wrapper {
				background-color: transparent;
				color: inherit;
				padding: 0px 0px 0px 15px;
			}
			span {
				margin: 0px;
				@include font-inter-400();
				font-size: inherit;
			}
			&:hover,
			&.selected {
				background-color: transparent;
				color: inherit;
				font-weight: bold;
			}
			&:after {
				content: '';
				height: 1px;
				display: block;
				margin-top: 6px;
				margin-right: 0px;
				background: white;
			}
		}

		&.uk-button-icon-small {
			background-color: transparent;
			color: inherit;
			padding: 0px !important;
			#{$name}__icon-wrapper {
				background-color: transparent;
				color: get-color(silver-grey);
				padding: 0px;
			}
			span {
				margin: 0px;
				padding: 0;
				@include font-inter-400();
				font-size: inherit;
			}
			&:hover,
			&.selected {
				background-color: transparent;
				color: inherit;
				font-weight: bold;
				#{$name}__icon-wrapper {
					color: get-color(mid-grey);
				}
			}

			&:focus {
				outline: unset;
			}
		}

		&.uk-button.uk-disabled {
			color: $button-disabled-color;
			background-color: $button-disabled-background;
			border: none;
			#{$name}__text {
				text-decoration: line-through;
			}
		}

		&.has-icon.uk-disabled {
			background-color: transparent;
			border: 1px solid get-color(silver-grey);
		}

		&.has-icon-highlighted.uk-disabled {
			background-color: transparent;
			border: 1px solid get-color(silver-grey);
			#{$name}__icon-wrapper {
				background-color: get-color(silver-grey);
				color: get-color(true-white);
			}
		}

		&.uk-button {
			vertical-align: unset;
			align-items: center;
		}

		&--shop-back {
			display: flex;
			height: 50px;
			width: 52px;
			background-color: transparent;
			color: get-color(wolf-black);
			margin-top: 75px;
			margin-left: 90px;
			&:hover {
				background-color: get-color(true-white);
				color: get-color(wolf-red);
			}
			span {
				border: 1px solid #f0f0f0;
				height: 50px;
				width: 52px;
				padding: 0px;
				display: flex;
				align-items: center;
				justify-content: center;
				svg {
					height: 25px;
					width: 30px;
					margin: 0 auto;
				}
			}
		}

		&--black-text {
			color: get-color(wolf-black);
		}

		&--anchor-style {
			color: get-color(wolf-black);
			border: 0px;
			font-weight: bold;
			padding: 0px;
			display: inline-block;
			&:after {
				content: '';
				height: 2px;
				background: get-color(wolf-red);
				display: block;
				margin-top: 6px;
				margin-right: 5px;
			}
			&:hover,
			&.selected {
				background-color: transparent;
				color: get-color(wolf-black);
			}
			span {
				margin: 0px;
			}
		}

		&--white-anchor-style {
			color: get-color('true-white');
			border: 0px;
			font-weight: bold;
			padding: 0px;
			display: inline-block;
			background: transparent;
			align-items: flex-start;
			text-align: left !important;
			font-size: 18px;
			font-weight: 600;
			&:hover,
			&.selected {
				background-color: transparent;
				color: get-color('true-white');
			}

			span {
				margin: 0px;
			}
		}

		&__extraInfo {
			display: none;
			position: absolute;
			z-index: 2;
			top: 80%;
			left: 25%;
			color: get-color('true-white');
			background-color: #ea4c4c;
			border-radius: 5px;
			padding: 20px;
			min-width: 300px;
			font-size: 13px;
			line-height: 24px;
			@include font-inter-400();
			text-align: left;

			&::before {
				content: '';
				width: 0px;
				height: 0px;
				position: absolute;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-top: 10px solid transparent;
				border-bottom: 10px solid #ea4c4c;
				right: 50%;
				top: -20px;
			}

			* {
				margin: 0px;
			}
		}

		&.extra-info {
			position: relative;

			.a-button__icon-wrapper:hover + .a-button__extraInfo {
				display: flex;
			}
		}

		&[data-is-spinning='false'] {
			.spinner {
				opacity: 0;
			}
		}

		&[data-is-spinning='true'] {
			position: relative;
			pointer-events: none;
			> * {
				opacity: 0;
			}
			> .spinner {
				opacity: 1;
				width: 24px;
				height: 24px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) rotate(0deg);
				border-radius: 50%;
				border-style: solid;
				border-width: 3px;
				border-color: transparent get-color('true-white') get-color('true-white');
				animation: spinning infinite 0.5s linear;
			}
		}
	}

	@keyframes spinning {
		0% {
			transform: translate(-50%, -50%) rotate(0deg);
		}
		99% {
			transform: translate(-50%, -50%) rotate(359deg);
		}
	}
}

@include wolf-a-button();
