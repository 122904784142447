@mixin wolf-o-grid-container($name: '.o-grid-container') {
	#{$name} {
		hyphens: auto;
		overflow-x: auto;
		scroll-behavior: smooth;

		&.arrowScroll {
			-ms-overflow-style: none;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		&__button-loadMore {
			text-align: center;
			margin-top: 40px;
		}

		&.oneRow {
			&__column-width {
				&--default {
					.oneRow > div {
						min-width: 300px;

						@media (min-width: $breakpoint-small-max) {
							min-width: 496px;
						}
					}

					.m-teaser-default {
						max-width: 496px;
					}
				}

				&--3 {
					.oneRow > div {
						min-width: 490px;

						@media (max-width: $breakpoint-small-max) {
							min-width: calc(100% - 0px);
						}
					}
				}

				&--4 {
					.oneRow > div {
						min-width: 360px;
					}
				}

				&--5 {
					.oneRow > div {
						min-width: 285px;
					}
				}
			}
		}

		.oneRow {
			display: inline-flex;
			flex-wrap: nowrap;
			max-width: 100%;

			@media (max-width: $breakpoint-medium-max) {
				max-width: calc(100vw - 30px);
			}

			&:not(.arrowScroll) {
				&::-webkit-scrollbar {
					height: 40px;
					appearance: none;
					background-color: rgba(255, 255, 255, 0);

					&-track,
					&-thumb {
						background-clip: padding-box;
					}

					&-track {
						border-top: 25px solid rgba(255, 255, 255, 0);
						border-bottom: 5px solid rgba(255, 255, 255, 0);
						border-left: 16px solid rgba(255, 255, 255, 0);
						border-right: 16px solid rgba(255, 255, 255, 0);
						background-color: #f5f5f5;
						width: 100%;
					}

					&-thumb {
						border-top: 25px solid rgba(255, 255, 255, 0);
						border-bottom: 5px solid rgba(255, 255, 255, 0);
						border-right: 5px solid rgba(255, 255, 255, 0);
						border-left: 16px solid rgba(255, 255, 255, 0);
						background-color: rgba(0, 0, 0, 0.4);
					}
				}
			}

			&.arrowScroll {
				-ms-overflow-style: none;
				scrollbar-width: none;

				&::-webkit-scrollbar {
					display: none;
				}
			}

			.m-teaser-bg {
				&__link {
					height: 20%;
				}

				.image-wrapper {
					&::before {
						background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.65));
					}
				}
			}
		}

		ul.uk-pagination {
			justify-content: center;

			li {
				margin-left: 8px;
				margin-right: 8px;
				width: 50px;
				height: 50px;

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}

				a {
					text-decoration: none;
					color: map-get($colors, 'wolf-black');
					font-size: 25px;
					line-height: 50px;
					padding: 0;
					text-align: center;
					border-radius: 50%;

					&:hover {
						text-decoration: none;
						background-color: rgba(0, 0, 0, 0.08);
					}
				}

				&#{$name} {
					&__current-page {
						a {
							background-color: map-get($colors, 'wolf-black');
							color: white;
						}
					}

					&__chevron {
						&-next,
						&-prev {
							&--nolink {
								pointer-events: none;

								a {
									svg {
										color: #c3c3c3;
									}
								}
							}
						}
					}
				}

				.uk-pagination {
					&-next,
					&-previous {
						> svg {
							width: 12px;
						}
					}
				}
			}

			li {
				@media (max-width: $breakpoint-medium-max) {
					width: 32px;
					height: 32px;
					margin: 0 4px !important;

					a {
						line-height: 32px;
						font-size: 16px;
					}
				}

				&.uk-active {
					a {
						text-decoration: none;
						background-color: red;
						color: get-color('true-white');
					}
				}
			}

			svg,
			span {
				pointer-events: none;
			}
		}

		.m-finder-result {
			&__image {
				display: flex;

				@media (max-width: 959px) {
					margin-bottom: 10px;
				}

				> .image-wrapper {
					position: relative;
					min-width: 200px;
					max-width: 280px;
					height: 100%;
					margin-right: 80px;
					max-height: 400px;
					overflow: hidden;

					> img {
						object-fit: cover;
					}

					@media (max-width: 1440px) {
						margin-right: 60px;
					}

					@media (max-width: 959px) {
						margin-right: 0;
						max-width: 100%;
					}
				}
			}

			&-teasers__item-container {
				.a-headline {
					font-size: 25px;
					@media (max-width: $breakpoint-small-max) {
						font-size: 3vw;
						margin-top: 10px;
						margin-bottom: 0;
					}
				}
			}

			&__content-wrapper {
				.m-finder-result__headline-link {
					.a-headline {
						.a-headline__heading {
							@media (max-width: 1440px) {
								font-size: 36px;
							}
						}
					}
				}

				.a-copy:not(.styled) p {
					font-size: 16px;
				}
			}
		}

		.uk-grid > div {
			display: flex;
			overflow: hidden;

			@media (max-width: 959px) {
				display: flex;
				margin-bottom: 8px;
			}
		}
	}
}

@include wolf-o-grid-container();
