@mixin wolf-o-product-gallery-360($name: '.o-product-gallery-360') {
	#{$name} {
		&__layout {
			display: flex;
			flex-direction: column;

			@media (min-width: $breakpoint-medium) {
				flex-direction: row;
				justify-content: space-between;
			}

			.gallery,
			.details {
				@media (min-width: $breakpoint-medium) {
					// padding: 0 $global-gutter;
				}
			}

			@media (min-width: $breakpoint-medium) {
				.gallery {
					width: 60%;
				}

				.details {
					width: 35%;
				}
			}

			@media (max-width: $breakpoint-small-max) {
				.details {
					margin-top: 50px;
				}
			}
		}

		&__galleryWrapper {
			display: flex;
			flex-direction: column;
			position: relative;

			// overlay the full screen button
			> .fullscreen {
				align-self: flex-end;
				width: 50px;
				height: 50px;
				position: absolute;
				top: 20px;
				right: 16px;
				z-index: 811;
				background-color: get-color(true-white);
				box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
				border-radius: 50%;
				padding: 10px;
				box-sizing: border-box;
				border: solid 1px get-color(true-white);
				transition: all 0.25s ease-out;
				cursor: pointer;
				outline: none;

				&:hover {
					box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
				}
			}
		}

		&__modalFullWrapper {
			z-index: 10010; // need to be higher than the website nav, apparently
		}

		.modal-line {
			border-top: 1px solid #d2d2d2;
		}

		button.uk-modal-close-full {
			color: #141313;
		}

		.uk-modal-full {
			&[class*='o-product-gallery-360'] {
				[class*='uk-padding-'] {
					max-width: 1550px;

					box-sizing: border-box;
					padding: 15px 0;
					margin: 0 auto;

					@media (max-width: 1560px) {
						padding: 15px;
						box-sizing: border-box;
						max-height: calc(100vh - 30px);
					}

					@media (pointer: fine) {
						max-height: 900px;
					}
				}
			}

			.modal-content {
				position: relative;
				@import './templates/m-product-gallery.scss';
			}

			.uk-first-column {
				> .uk-container {
					background-color: #f9f9f9;
					position: relative;

					> .modal-content {
						position: relative;
						z-index: 1;
					}

					&::after {
						content: none;
						background-color: transparent;
						width: 40px;
						height: 40px;
						display: flex;
						align-self: center;
						position: absolute;
						top: 50%;
						left: 46%;
						transform: translate(-50%, -50%);
						z-index: 0;
						border-radius: 50%;
						border-color: transparent #00000075 #00000075;
						border-style: solid;
						border-width: 3px;
						animation: big-loader 0.4s linear infinite;

						@media (min-width: $breakpoint-large) {
							content: '';
						}
					}
				}
			}

			.uk-modal-close-full {
				background-color: get-color('true-white');
				width: 50px;
				height: 50px;
				box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
				border-radius: 50%;
				top: 30px;
				right: 192px;
				transition: all 0.25s ease-out;

				@media (min-width: 1530px) {
					right: 180px;
				}

				&:hover {
					background-color: get-color('wolf-black');
					color: get-color('true-white');
				}
			}
		}

		.m-product-gallery__sliderWrapper {
			&[data-position='list-centered'] {
				> .uk-slider-items {
					justify-content: center;
				}
			}

			&[data-vertical-position='list-middle'] {
				> .uk-slider-items {
					justify-content: center;

					~ .uk-slidenav {
						display: none;
					}
				}
			}
		}
	}

	@keyframes big-loader {
		from {
			transform: translate(-50%, -50%) rotate(0deg);
		}

		to {
			transform: translate(-50%, -50%) rotate(359deg);
		}
	}
}

@include wolf-o-product-gallery-360();
