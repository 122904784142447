@mixin wolf-m-formstep-resume-slice($name: '.m-formstep-resume-slice') {
	#{$name} {
		background-color: #f8f8f8;
		border-radius: 20px;
		padding: 30px;
		max-width: calc(869px - 60px);
		margin-bottom: 24px;

		@include container-dark-on-mobile(get-color(light-grey));

		@include text-clear-on-mobile();

		&__part-name {
			font-size: 17px;
			font-weight: 700;
			line-height: 22px;
			margin: 0;

			@media (min-width: $breakpoint-medium) {
				font-size: 22px;
			}
		}

		&__label {
			font-size: 12px;
			color: #6b718b;
			background-color: get-color(form-grey);
			margin: 0;
			margin-top: 20px;
		}

		&__part-detail {
			font-size: 16px;
			color: get-color(true-black);
			margin-top: 4px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			@include text-clear-on-mobile();
		}

		&__icon {
			width: 24px;
			height: 24px;
			svg {
				color: get-color(silver-grey);
			}
		}

		&__mobile-enlarger {
			@media (max-width: $breakpoint-medium) {
				font-size: 22px;
				span {
					transform: scale(1.5);
				}
			}
		}
	}
}

@include wolf-m-formstep-resume-slice();
