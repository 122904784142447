@mixin wolf-a-play-button($name: '.a-play-button') {
	#{$name} {
		background: transparent;
		border-radius: 50%;

		margin: auto;
		outline: none;
		user-select: none;

		@media (min-width: $breakpoint-medium) {
			border: 3px solid;
			width: 250px;
			height: 250px;
			> svg {
				width: 80px;
				height: auto;
				margin: auto;
				transform: translateX(8px); // this fixes the play button being off-centre
			}
		}
		@media (max-width: $breakpoint-small-max) {
			border: 2px solid;
			width: 80px;
			height: 80px;
			> svg {
				width: 30px;
				height: auto;
				margin: auto;
				transform: translateX(2px); // this fixes the play button being off-centre
			}
		}

		&:active,
		&:focus {
			outline: none;
		}

		&:hover {
			background-color: rgba(255, 255, 255, 0.4);
		}

		// button modifiers
		@include element-modifier-self('&--white') {
			color: white;
		}
	}
}

@include wolf-a-play-button();
