@mixin wolf-m-filter-accordion($name: '.m-filter-accordion') {
	#{$name} {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: auto;
		gap: 24px;
		box-sizing: border-box;

		&-label {
			margin: 0 0 8px;
			font-family: 'Inter', sans-serif;
			font-size: 12px;
			font-weight: 500;
			line-height: 1;
			letter-spacing: -0.24px;
			color: #727272;
		}

		&-filters-wrapper {
			display: flex;
			flex-direction: column;
			border: solid 1px #62677e;
			width: 100%;
			height: auto;
			box-sizing: border-box;
		}

		&-wrapper {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: auto;

			&[data-opened='true'] {
				#{$name} {
					&-head {
						svg {
							transform: rotate(180deg);
						}
					}

					&-content {
						height: auto;
						max-height: 80vh;
						overflow-x: hidden;
						overflow-y: auto;
						padding: 20px;

						&::before {
							height: 1px;
							background-color: #00000020;
						}
					}
				}
			}

			#{$name} {
				&-head {
					min-height: 46px;
					display: flex;
					flex-direction: row;
					position: relative;
					background-color: transparent;
					border: 0;
					outline: none;
					justify-content: space-between;
					align-items: center;
					padding: 0 20px;
					color: #62677e;
					cursor: pointer;

					.left-content {
						font-size: 16px;
						font-family: 'Inter', sans-serif;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						padding-right: 60px;

						&:empty {
							&::before {
								content: attr(data-filter-label);
								opacity: 0.75;
							}
						}
					}

					.right-content {
						position: relative;

						.checked-filters {
							background-color: get-color(wolf-red);
							color: get-color(true-white);
							position: absolute;
							z-index: 1;
							left: -100%;
							transform: translateX(-140%);
							margin-right: 24px;
							height: 24px;
							border-radius: 5px;
							padding: 5px 6px;
							font-family: 'Inter', sans-serif;
							font-size: 14px;
							font-weight: 500;
							line-height: 14px;
							letter-spacing: -0.42px;
							box-sizing: border-box;
							min-width: 34px;
							display: inline-flex;
							align-items: center;
							justify-content: center;
							pointer-events: none;
							opacity: 0;
							transition: all 0.25s ease-out;

							&.active {
								transition-delay: 0.8s;
								opacity: 1;
								transform: translateX(-100%);
							}
						}
					}

					svg {
						width: 20px;
						min-width: 20px;
					}
				}

				&-content {
					display: flex;
					flex-direction: column;
					padding: 0 20px;
					gap: 20px;
					position: relative;
					max-height: 0;
					height: 0;
					overflow: hidden;
					transition: all 0.2s ease-out;

					&::before {
						content: '';
						position: absolute;
						top: 0;
						width: calc(100% - 40px);
						height: 0;
						background-color: #00000000;
						transition: all 0.2s ease-out;
					}

					.uk-form-label {
						display: flex;
						gap: 12px;

						[type='checkbox'] {
							display: none;

							+ .ico {
								min-width: 24px;
								width: 24px;
								height: 24px;
								border-radius: 3px;
								margin-top: 0;
								cursor: pointer;
								position: relative;

								&::before,
								&::after {
									content: '';
									background-color: get-color(true-white);
									height: 3px;
									position: absolute;
									transform-origin: 0 0;
									border-radius: 0;
								}

								&::before {
									width: 0;
									transform: rotate(45deg);
									top: 10px;
									left: 7px;
								}

								&::after {
									width: 0;
									transform: rotate(-45deg);
									top: 14px;
									left: 9px;
								}

								+ .text {
									display: inline-flex;
									flex-grow: 1;
									user-select: none;
									padding-top: 0;
								}
							}

							&:checked {
								+ .ico {
									background-color: get-color(wolf-red);

									&::before,
									&::after {
										transition: all 0.2s ease-out;
									}

									&::before {
										width: 7px;
									}

									&::after {
										width: 11px;
										transition-delay: 0.2s;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@include wolf-m-filter-accordion();
