@mixin wolf-m-search-autosuggestion-hits($name: '.m-search-autosuggestion-hits') {
	#{$name} {
		display: flex;
		flex-direction: column;
		// width: 100%;
		width: 100%;
		margin: 10px;

		@media (min-width: $breakpoint-xsmall-max) {
			width: 90%;
		}

		@media (min-width: $breakpoint-medium) {
			max-width: 748px;
		}

		&__results-by-category {
			display: flex;
			flex-direction: column;

			&:first-of-type {
				#{$name}__result-row {
					padding-top: 10px;
				}
			}
		}

		&__category-label {
			font-size: 16px;
			color: get-color(dark-blue-70-white);
			margin-bottom: 20px;

			@media (max-width: $breakpoint-small-max) {
				display: none;
			}
		}

		&__result-row {
			@media (max-width: $breakpoint-small-max) {
				flex-direction: column;
				padding-top: 15px;
				padding-bottom: 0;
				border-bottom: solid 1px #ddd;

				.a-tag {
					margin-bottom: 5px;
					font-size: 14px;
				}
			}
		}

		&__result-title {
			width: 100%;
			margin: 0 0 10px 0;
			font-size: 14px;
			line-height: 21px;
			text-decoration: none;

			&:hover {
				text-decoration: none;
			}

			a & {
				cursor: pointer;
			}

			@media (min-width: $breakpoint-medium) {
				font-size: 24px;
				margin: 0 0 10px 8px;
				line-height: 26px;
			}
		}

		&__summary-link {
			font-size: 16px;
			color: get-color(dark-blue-70-white);
			text-decoration: none;
			margin-top: 0;

			@media (max-width: $breakpoint-small-max) {
				margin-top: 30px;
				margin-bottom: 15px;
				padding-left: 10px;
			}

			&:hover {
				text-decoration: none;
			}

			@media (min-width: $breakpoint-medium) {
				font-size: 24px;
			}
		}
	}
}

@include wolf-m-search-autosuggestion-hits();
