@mixin wolf-m-product-image($name: '.m-product-image') {
	#{$name} {
		&__highlighted {
			width: fit-content;
			width: -moz-fit-content;
			position: relative;
			border: dashed 1px green; // just visually emphasizing image dimensions, TO BE REMOVED

			.energy-label {
				position: absolute;
				left: 0;
				top: 10px;
				width: 110px;
				height: fit-content;
				height: -moz-fit-content;
			}
		}

		&__thumbnails {
			margin-top: 1em;
			margin-bottom: 0px;

			div {
				img {
					width: 65px;
				}
			}
		}
	}
}

@include wolf-m-product-image();
