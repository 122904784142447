$atom-copy-boxed-width: 500px;

@mixin wolf-a-copy($name: '.a-copy') {
	#{$name} {
		margin: auto;
		box-sizing: border-box;

		@include font-inter-400();
		font-size: 18px;
		line-height: 1.5em;
		letter-spacing: 0.4px;

		&:not(.styled) p,
		&:not(.styled) li,
		&:not(.styled) ul,
		&:not(.styled) ol {
			@include font-inter-400();
			font-size: 18px;
			line-height: 1.5em;
			letter-spacing: 0.4px;

			@media (max-width: $breakpoint-small-max) {
				font-size: 16px;
			}
		}

		&--show-container {
			background-color: #eee;
		}

		&__boxed {
			max-width: $atom-copy-boxed-width;
		}

		&__well {
			padding: 2rem;
		}

		&__well-boxed {
			padding: 2rem;
			max-width: $atom-copy-boxed-width;
		}

		&:last-child {
			margin-bottom: 0;
		}

		& a {
			border: 0px;
			font-weight: bold;
			padding: 0px;
			text-decoration: underline;
			display: inline-block;

			&:hover {
				background-color: transparent;
				color: get-color(wolf-black);
			}
		}

		&.styledOl {
			ol {
				list-style: inherit;
			}
		}

		&.styledUl {
			ul {
				list-style: inherit;
			}
		}
	}
}

@include wolf-a-copy();
