@mixin wolf-m-formstep-select-image($name: '.m-formstep-select-image') {
	#{$name} {
		width: 924px;

		// @media (max-width: $breakpoint-large-max) {
		// 	width: calc(100% - 97px);
		// }
		// @media (max-width: $breakpoint-medium-max) {
		// 	width: calc(100% - 64px)
		// }
		@media (max-width: $breakpoint-medium-max) {
		}

		&__selection-wrapper {
			max-height: 594px;
			// max-width: 809px;
			width: 100%;
			overflow-y: scroll;
			display: grid;
			grid-template-columns: repeat(2, minmax(307px, 404px));
			cursor: pointer;

			@media (max-width: $breakpoint-large-max) {
				grid-template-columns: repeat(2, minmax(100px, 1fr));
				column-gap: 30px;
				box-sizing: border-box;
				padding-right: 97px;
			}

			@media (max-width: $breakpoint-medium-max) {
				max-width: 872px;
				overflow: -moz-scrollbars-none;
				-ms-overflow-style: none;
				column-gap: 10px;
				padding-right: 0;
			}

			.image-selector {
				max-height: 307px;
			}

			.m-rounded-teaser__icon--open {
				@media (max-width: $breakpoint-medium-max) {
					display: none;
				}
			}

			.m-rounded-teaser__description {
				margin-bottom: 24px;
			}

			&::-webkit-scrollbar {
				width: 14px;
				-webkit-appearance: none;
				background-color: transparent;

				@media (max-width: $breakpoint-medium-max) {
					width: 0;
				}
			}

			&::-webkit-scrollbar-track,
			&::-webkit-scrollbar-thumb {
				background-clip: padding-box;
			}

			&::-webkit-scrollbar-track {
				border-top: 18px solid transparent;
				border-bottom: 18px solid transparent;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				background-color: rgb(247, 247, 247);
			}

			&::-webkit-scrollbar-thumb {
				border-top: 18px solid transparent;
				border-bottom: 18px solid transparent;
				border-right: 5px solid transparent;
				border-left: 5px solid transparent;
				background-color: rgb(106, 110, 112);
			}
		}
	}
}

@include wolf-m-formstep-select-image();
