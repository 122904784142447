@mixin wolf-m-job-ad($name: '.m-job-ad') {
	#{$name} {
		display: flex;
		max-width: 1246px;
		flex-direction: column;
		align-items: center;
		margin: 0 auto;
		hyphens: auto;

		.a-headline__heading {
			text-align: center;
			margin: 12px 20px 0;
			text-align: left;

			@media (min-width: $breakpoint-medium) {
				margin: 32px;
				text-align: center;
			}
		}

		.a-copy {
			margin: 36px 12px 24px;

			@media (min-width: $breakpoint-medium) {
				margin: 48px 24px 24px;
				margin: -5px 24px 0;
			}

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				margin-top: 0;
			}

			p:empty {
				display: none;
			}

			&:not(.styled) {
				ul {
					margin-bottom: 15px;
					padding-left: 24px;

					@media (min-width: $breakpoint-medium) {
						margin-bottom: 48px;
						padding-left: 40px;
					}
				}

				li {
					margin-bottom: 15px;

					p {
						margin: 0;

						~ p {
							margin-top: 15px;
						}
					}
				}
			}
		}

		&__cta-button {
			display: grid;
			place-content: center;

			.a-button {
				margin: 20px;
			}
		}

		&__metadata-wrapper {
			display: flex;
			justify-content: center;
			align-items: baseline;
			padding: 20px 0;
			width: 100%;

			@media (min-width: $breakpoint-medium) {
				padding: 48px 0;
			}
		}

		&__metadata-block {
			font-size: 14px;
			color: get-color(dark-grey);
			padding: 14px 24px;
			width: 30%;

			@media (min-width: $breakpoint-medium) {
				font-size: 16px;
				width: 15%;
				padding: 32px;
			}
		}

		&__metadata-label {
			margin: 0;
			white-space: nowrap;
		}

		&__metadata-value {
			margin: 8px 0;
			text-align: center;
		}
	}
}

@include wolf-m-job-ad();
