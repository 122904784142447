@mixin wolf-m-search-mobile($name: '.m-search-mobile') {
	#{$name} {
		display: none;

		@media (max-width: $breakpoint-medium-max) {
			display: block;

			.uk-offcanvas-bar.m-nav-offcanvas__canvas--search {
				padding: 0;
				min-width: 100vw;
				-webkit-clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 100% 0%, 0% 0%);
				clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 100% 0%, 0% 0%);
			}

			.m-search-flyout.uk-visible\@l {
				display: block !important;
			}

			.m-search-flyout {
				display: block;
				box-shadow: none;
				left: unset !important;
				width: 100% !important;
				margin-top: 0 !important;
				padding-top: 73px !important;
				top: 0 !important;
				height: 100%;
				overflow-x: hidden;
				overflow-y: auto;

				&::-webkit-scrollbar {
					display: none;
				}

				&__container {
					background-color: transparent;
					justify-content: flex-start;
					width: calc(100% - 48px);
					margin: 0 auto auto;
					height: auto;
					min-height: 68vh;
					padding-bottom: 48px;

					& > .a-headline {
						width: 100%;

						.a-headline {
							font-size: 24px;
							margin-bottom: 0;
						}
					}

					.m-input-search-debounce {
						margin: 0 0 75px !important;
						width: 100%;

						&__input-group {
							width: 100%;
							padding: 16px 0;

							.m-search-field {
								&__wrapper {
									width: 100%;
								}

								&__input-group {
									width: 100%;

									& input {
										font-size: 16px;
										box-sizing: border-box;
										height: 46px;
									}
								}
							}
						}

						&__results {
							width: 100%;
							box-sizing: border-box;
							padding: 0;

							&::-webkit-scrollbar {
								display: none;
							}

							.m-search-autosuggestion-hits {
								margin: 10px 0;
								background-color: get-color('true-white');
								width: 100%;

								&__result-title {
									color: get-color('wolf-black');
									hyphens: auto;
									margin-right: 20px;
								}

								&__summary-link {
									color: get-color('dark-blue-70-white');
								}
							}
						}
					}

					.m-icon-link-suggestion {
						width: 100%;

						.uk-grid {
							margin-left: 0px;
							gap: 16px;

							> * {
								padding-left: 0;
								width: calc(50% - 8px);
							}
						}

						.text {
							text-align: center;
						}
					}
				}

				.m-search-flyout__close-button {
					display: none;
				}
			}
		}
	}

	body.uk-offcanvas-flip {
		.o-fab-menu-section {
			display: none;
		}
	}

	#nav-offcanvas--search {
		&.uk-offcanvas-overlay {
			touch-action: pan-y;
		}
	}
}

@include wolf-m-search-mobile();
