@mixin wolf-m-job-offer-search($name: '.m-job-offer-search') {
	#{$name} {
		&__headline {
			& .a-headline {
				text-align: center;
				margin-bottom: 90px;

				@media (max-width: $breakpoint-xsmall-max) {
					text-align: left;
					margin-bottom: 50px;
				}
			}
		}

		&__select-container {
			display: grid;
			grid-template-columns: repeat(3, 1fr) auto;
			align-items: end;
			gap: 20px;

			& .uk-select {
				margin-top: 0 !important;
			}

			& .a-button {
				height: 48px;
			}

			@media (max-width: $breakpoint-small-max) {
				grid-template-columns: repeat(2, 1fr) auto;

				& .a-button {
					grid-column: 3 / 4;
				}
			}

			@media (max-width: $breakpoint-xsmall-max) {
				grid-template-columns: 1fr;

				& .a-button {
					grid-column: 1 / 2;

					& > span {
						margin: auto;
					}
				}
			}
		}

		&__input-wrapper {
			.uk-input,
			.uk-select {
				background-color: transparent;
				border: thin solid get-color('dark-grey');
			}

			.uk-form-label {
				@include font-inter-500;
				color: #727272;
				margin-bottom: 10px !important;
			}

			#{$name}__text-search {
				position: relative;
				margin-top: 10px !important;

				& .uk-icon,
				span[data-ref='searchButton'] {
					position: absolute;
					z-index: 1;
					right: 10px;
					top: 10px;
					width: 28px;
					cursor: pointer;
				}
			}
		}
	}
}

@include wolf-m-job-offer-search();
