@mixin wolf-m-teaser-bg-portrait($name: '.m-teaser-bg-portrait') {
	#{$name} {
		&.default-gradient {
			position: relative;
		}

		&.styleSettings {
			//height: 661px;
			//max-width: 496px;

			@media (max-width: $breakpoint-small-max) {
				//height: 450px;
				//max-width: 300px;
			}

			> div {
				height: 100%;
				position: relative;
			}
		}

		.image-wrapper {
			&::before {
				background: linear-gradient(90deg, rgba(33, 33, 44, 0.78), rgba(33, 33, 44, 0.68) 30%, hsla(0, 0%, 100%, 0) 70%);
			}
		}

		.uk-container {
			height: 100%;
			position: relative;
			z-index: 3;
			top: 0;
		}

		&__content-wrapper {
			padding: 75px 34px 70px 34px;
			height: calc(100% - 145px);
			display: flex;
			flex-direction: column;

			@media (max-width: $breakpoint-xsmall-max) {
				padding: 30px 15px 24px;
				height: calc(100% - 100px);

				.a-headline {
					.a-headline__heading {
						margin-bottom: 24px;
					}
				}
			}

			.text {
				width: 80%;
				margin: 0 0 70px;

				@include font-inter-300;

				@media (max-width: $breakpoint-xsmall-max) {
					width: 90%;

					&.a-copy {
						p {
							&:empty {
								display: none;
							}

							margin-top: 0;
							margin-bottom: 24px;
						}

						margin: 0;
					}
				}
			}

			.button {
				display: flex;
				flex: 1;
				align-items: flex-end;

				.a-button {
					border-width: 2px;
					@include font-inter-600;
				}
			}
		}
	}
}

@include wolf-m-teaser-bg-portrait();
