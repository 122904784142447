$global-max-width: 1920px;

/**
*
*/
$global-navbar-offset-top: 49px;
$global-mobile-navbar-offset-top: 35px;

/**
 * Breakpoints
 */
$breakpoints: (
	small: 640px,
	medium: 960px,
	large: 1200px,
	xlarge: 1520px,
);

em {
	color: unset !important;
}

/**
 * Colors
 */
$colors: (
	primary: #e10000,
	secondary: #212d40,
	accent: #81adc8,
	success: #00af00,
	warning: #d36e02,
	danger: #bb0000,
	text: #11151c,
	// ####################
	// wolf specific colors
	// ####################
	// primary palette
	wolf-red: #e10000,
	// also primary
	dark-blue: #212d40,
	// also secondary
	light-blue: #81adc8,
	// also accent
	true-white: #fff,
	wolf-black: #11151c,
	true-black: #000,
	dark-grey: #6a6e70,
	silver-grey: #d2d2d2,
	light-grey: #f7f7f7,
	mid-grey: #a5a5a5,
	stroke-grey: #d2d2d2,
	lighter-grey: #f5f5f5,
	darker-grey: #272734,
	faded-grey: #d2d2d226,
	// secondary palette
	red-30-white: #ea4c4c,
	red-50-white: #f07f7f,
	red-70-white: #f6b2b2,
	red-90-white: #fce5e5,
	dark-blue-10-white: #404652,
	dark-blue-30-white: #636b79,
	dark-blue-50-white: #8f959f,
	dark-blue-70-white: #bcbfc5,
	dark-blue-90-white: #e8e9eb,
	light-blue-30-white: #a6c5d8,
	light-blue-50-white: #c0d6e3,
	light-blue-70-white: #d9e6ee,
	light-blue-90-white: #f2f7fa,
	red-80-black: #ea4c4c,
	red-50-black: #f07f7f,
	// dark-blue-80-black: #EA4,
	dark-blue-50-black: #8f959f,
	light-blue-80-black: #a6c5d8,
	light-blue-50-black: #c0d6e3,
	//additional colors
	red-hover: #b70406,
	// Seo optimization =======
	dark-grey-seo: #90989c,
	dark-grey-seo-light: #56595b,
	transparent: #0000,
	// This is set for google seo contrsat ratio improvement
	// Used in a-linklist
	light-blue-seo: #335d76,
	footer-copyright-seo: #b5b8c1,
	silver-grey-seo: #585858,
	dark-grey-m-step-seo: #505456,
	light-blue-m-application-seo: #045b97,
	// ProKlima
	pro-klima-blue: #005faa,
	pro-klima-blue-dark: #1f2d38,
	pro-klima-grey: #6a6e70,
	pro-klima-grey-dark: #2c3134,
	// DHB colors
	dhb-red: #ab1252,
	dhb-black: #232f3f,
	dhb-light-grey: #edeff2,
	dhb-dark-grey: #313d4a,
);

/**
 * Typography
 */
$fontsizes: (
	s: (
		xxsmall: 10px,
		xsmall: 16px,
		default: 16px,
		small: 21px,
		medium: 28px,
		large: 30px,
		xlarge: 32px,
		xxlarge: 32px,
	),
	m: (
		xxsmall: 10px,
		xsmall: 16px,
		default: 16px,
		small: 21px,
		medium: 28px,
		large: 30px,
		xlarge: 50px,
		xxlarge: 50px,
	),
);

/**
 * Fontweights
 */
$fontweights: (
	light: 200,
	// book: 300,
	regular: 400,
	medium: 500,
	bold: 700,
	black: 900,
);

/**
 * Spacings
 */
$spacings: (
	xsmall: 10px,
	small: 20px,
	default: 30px,
	medium: 50px,
	large: 70px,
	xlarge: 100px,
);
