@mixin wolf-m-ahu-request($name: '.m-ahu-request') {
	#{$name} {
		max-width: 1920px;
		margin: 0 auto;

		&__contact {
			display: flex;
			flex-direction: column;

			@media (min-width: $breakpoint-large) {
				flex-direction: row;
			}
		}

		&__visual {
			padding: 170px 90px;
			box-sizing: border-box;
			position: relative;
			display: flex;
			flex-direction: column;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;

			@media (max-width: $breakpoint-medium-max) {
				flex: 1;
				min-height: 450px;
			}

			@media (max-width: $breakpoint-small-max) {
				padding: 170px 60px;
			}

			@media (max-width: $breakpoint-xsmall-max) {
				padding: 170px 30px;
			}

			@media (min-width: $breakpoint-large) {
				flex: 0 0 660px;
			}

			&::after {
				content: '';
				position: absolute;
				z-index: 0;
				inset: 0;
				background-color: rgba(38, 32, 28, 0.4);
			}

			> * {
				z-index: 1;
				position: relative;
			}

			.a-headline {
				margin: 0 auto 60px;

				@media (max-width: $breakpoint-medium-max) {
					margin: 0 auto 50px;
				}
			}

			.a-copy {
				margin-top: 0;
				> * {
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		&__formWrapper {
			flex: 1;
		}

		&__formInner {
			padding: 80px 120px;
		}

		&__visualInner,
		&__formInner {
			@media (max-width: $breakpoint-medium-max) {
				padding: 60px 20px;
				flex: 1;
			}
		}

		button[type='submit'] {
			background-color: get-color(dark-blue);
			color: get-color(true-white);
			height: 56px;
			min-width: 200px;

			&:hover {
				background-color: get-color(dark-blue-30-white);
			}

			div {
				vertical-align: middle;
				display: none;
			}

			.done-span {
				display: none;

				svg {
					width: 24px;
					vertical-align: middle;
				}
			}

			@media (max-width: $breakpoint-small-max) {
				min-width: 50px;
			}
		}

		div[data-counter='true'] {
			position: relative;

			&::after {
				content: attr(char-counter);
				position: absolute;
				z-index: 1;
				right: 0;
				bottom: -22px;
				color: get-color(wolf-black);
				font-weight: 600;
			}

			&.text {
				&-warning {
					&::after {
						color: get-color(warning);
					}
				}

				&-danger {
					&::after {
						color: get-color(wolf-red);
					}
				}
			}
		}
	}
}

@include wolf-m-ahu-request();
