@mixin wolf-m-input-search-fat($name: '.m-input-search-fat') {
	#{$name} {
		&__wrapper {
			display: flex;
		}

		&__input-group {
			position: relative;
			width: 100%;

			> span {
				width: 45px;
				height: 45px;
				margin: auto;
				right: 15px;
				top: 0;
				bottom: 0;
				position: absolute;
				cursor: pointer;

				@media (max-width: $breakpoint-xsmall-max) {
					width: 25px;
					height: 25px;
				}
			}

			&.empty {
				> span {
					display: none;
				}
			}
		}

		&__input {
			@include font-inter-300;
			border: 1px solid get-color('silver-grey');
			padding: 0 70px 0 25px;
			font-size: 24px;
			width: 100%;
			height: 100%;

			&:focus {
				outline: none;
			}

			// Hide X from chrome
			&::-webkit-search-decoration,
			&::-webkit-search-cancel-button,
			&::-webkit-search-results-button,
			&::-webkit-search-results-decoration {
				display: none;
			}

			@media (max-width: $breakpoint-xsmall-max) {
				padding: 0 50px 0 15px;
				font-size: 16px;
			}
		}

		&__button {
			color: get-color('true-white');
			border: none;
			border-radius: 0;
			padding: 25px;
			background-color: get-color('primary');
			cursor: pointer;

			> span {
				width: 38px;
				height: 38px;
				display: inline-flex;

				> svg {
					height: 38px;
					min-width: 38px;
				}
			}

			@media (max-width: $breakpoint-xsmall-max) {
				padding: 15px;

				> span {
					width: 25px;

					> svg {
						min-width: 25px;
					}
				}
			}
		}
	}
}

@include wolf-m-input-search-fat();
