@mixin wolf-m-finder-select-step($name: '.m-finder-select-step') {
	#{$name} {
		display: inline-flex;
		width: 100%;
		border-radius: 5px;
		-webkit-box-shadow: 0px 0px 20px 10px rgba(183, 183, 183, 0.4);
		-moz-box-shadow: 0px 0px 20px 10px rgba(183, 183, 183, 0.4);
		box-shadow: 0px 0px 20px 10px rgba(183, 183, 183, 0.4);
		background-color: get-color('true-white');

		@media (max-width: $breakpoint-small-max) {
			flex-direction: column;
			min-height: fit-content;
			min-height: calc(85vh - 50px);
			width: 100%;
			padding: 25px 20px 0;
			box-sizing: border-box;
			box-shadow: none;
			margin-top: 0;
			justify-content: space-between;

			&__back-mobile {
				z-index: 90;
				position: fixed;
				left: 0;
				top: 0;

				.a-button,
				.a-button:hover,
				.a-button:focus,
				.a-button:active {
					background-color: unset;
					color: #85a6a9;
					border: unset;
				}
			}
		}

		&__header {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 30%;
			border-right: 1px solid #e8e8e8;

			@media (max-width: $breakpoint-small-max) {
				margin-top: 0;
				width: 100%;
				border: 0;
				text-align: center;
			}

			#{$name}__headerElements {
				max-width: 60%;

				@media (max-width: $breakpoint-small-max) {
					max-width: 100%;
				}

				#{$name}__stepTitle {
					@include font-inter-600();
					font-size: 34px;
					line-height: 40px;

					color: get-color('wolf-black');
					margin-bottom: 10px;

					@media (max-width: $breakpoint-small-max) {
						font-size: 20px;
						line-height: 24px;
						margin-bottom: 20px;
						font-weight: bold;
					}
				}

				#{$name}__stepSubtitle {
					@include font-inter-200();
					font-size: 20px;
					line-height: 26px;

					color: #0d5055;
					margin: 0px;

					@media (max-width: $breakpoint-small-max) {
						@include font-inter-600();
						flex-direction: column;
						font-size: 14px;
						line-height: 20px;
						margin-bottom: 20px;
					}
				}
			}
		}

		&__step-container {
			width: 100%;

			display: inline-flex;
			align-items: center;
			justify-content: center;

			@media (max-width: $breakpoint-small-max) {
				height: 100%;
				max-width: 80%;
				margin-inline: auto;
				flex-direction: column;
				justify-content: center;
			}
		}

		#{$name}__options {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			max-width: 80%;
			width: 100%;

			@media (max-width: $breakpoint-small-max) {
				flex-direction: column;
				max-width: 100%;
				width: 100%;
			}

			.uk-grid {
				margin: 50px 10px;

				@media (max-width: $breakpoint-small-max) {
					width: 100%;
					margin: 10px 10px;
				}

				> div.uk-first-column {
					justify-content: flex-end;

					@media (max-width: $breakpoint-small-max) {
						justify-content: center;
						margin: 8px 0px;
						padding-left: 0;
					}
				}

				> div:not(.uk-first-column) {
					justify-content: flex-start;

					@media (max-width: $breakpoint-small-max) {
						justify-content: center;
						margin: 8px 0px;

						padding-left: 0px;
					}
				}
			}

			.a-button {
				@include font-inter-600();
				color: get-color('wolf-black');
				font-size: 24px;
				line-height: 34px;

				background-color: transparent;
				border: 1px solid #efefef;
				border-radius: 5px;

				width: 360px;
				padding: 35px 0px;
				display: flex;
				justify-content: center;

				@media (max-width: $breakpoint-small-max) {
					border: 2px solid #86a7aa;
					padding: 25px 35px;
					color: #11151c50;
					font-size: 14px;
					font-weight: bold;
					line-height: 20px;
				}

				.a-button__icon-wrapper {
					border-radius: 50%;
					border: 2px solid #cbcbcb;
					padding: 5px;

					@media (max-width: $breakpoint-small-max) {
						padding: 4px;
						width: 10px;
						height: 10px;
					}

					svg {
						color: #cbcbcb;
						width: 15px;
						height: 15px;

						@media (max-width: $breakpoint-small-max) {
							width: 13px;
							height: 13px;
						}
					}
				}

				&:not(.selected):hover {
					border: 1px solid #efefef;
					background-color: get-color('wolf-red');
					color: get-color('true-white');

					@media (max-width: $breakpoint-small-max) {
						border: 2px solid #86a7aa;
						background-color: transparent;
						color: get-color('wolf-black');
					}
				}

				&.selected {
					border: 1px solid #efefef;
					background-color: get-color('wolf-red');
					color: get-color('true-white');

					.a-button__text {
						@media (max-width: $breakpoint-small-max) {
							opacity: 1;
						}
					}
				}

				&.selected:hover {
					border: 1px solid #efefef;
					background-color: get-color('wolf-red');
					color: get-color('true-white');
				}
			}

			#{$name}__icon {
				width: 90px;
				height: 90px;
				background-color: #25ae88;
				padding: 15px;
				border-radius: 50%;

				svg {
					padding: 15px;
					color: white;
				}
			}

			#{$name}__text {
				@include font-inter-600();
				font-size: 34px;
				line-height: 40px;
				color: #c2c3c5;
				margin: 50px 10px;

				@media (max-width: $breakpoint-small-max) {
					width: 100%;
					text-align: center;
					font-size: 30px;
					line-height: 32px;
					color: get-color('wolf-black');
				}
			}
		}

		#{$name}__back {
			max-width: 10%;
			display: inline-flex;

			.a-button {
				background-color: transparent;
				color: #cbcbcb;
				border: 2px solid #cbcbcb;
				border-radius: 50%;

				&.hover {
					color: get-color('wolf-red');
					border: 2px solid get-color('wolf-red');
				}

				.a-button__icon-wrapper {
					margin: auto;
				}
			}
		}

		#{$name}__download {
			display: none !important;
			max-width: 10%;
			display: inline-flex;

			.a-button {
				background-color: transparent;
				color: get-color('wolf-black');

				svg {
					width: 66px;
					height: auto;
				}

				&.hover {
					color: get-color('wolf-red');
					border: 2px solid get-color('wolf-red');
				}
			}
		}

		&__buttons-mobile {
			display: flex;
			flex-direction: column;
			width: 100%;

			.next {
				.a-button {
					width: 100%;
					background-color: get-color('wolf-black');
					color: get-color('true-white');
					@include font-inter-600();
					font-size: 14px;
					line-height: 20px;
					border: 1px solid #707070;
					justify-content: center;
				}
			}

			.show {
				margin-top: 10px;

				.a-button {
					width: 100%;
					color: get-color('wolf-black');
					background-color: get-color('true-white');
					@include font-inter-600();
					font-size: 14px;
					line-height: 20px;
					border: 1px solid #707070;
					justify-content: center;
				}
			}
		}

		&__back-mobile {
			z-index: 811;

			.a-button {
				background: transparent;
				color: #85a6a9;
				position: absolute;
				top: 20px;
				left: 25px;
				border: 0px;
			}

			.a-button__icon-wrapper {
				padding: 0px;
				width: 30px;

				> svg {
					width: 30px;
					height: 25px;
				}
			}
		}
	}
}

@include wolf-m-finder-select-step();
