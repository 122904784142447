@mixin wolf-m-breadcrumb($name: '.m-breadcrumb') {
	#{$name} {
		padding: 32px 0 32px 90px;
		box-sizing: border-box;

		@media (max-width: $breakpoint-medium) {
			padding: 20px 0px;
		}

		&__breadcrumb-container {
			background: none !important;
		}

		.dropdown-container {
			position: relative;
		}

		.uk-navbar-nav {
			display: flex;
			flex-wrap: wrap;
			row-gap: 20px;

			#{$name}__breadcrumb:first-child a {
				padding-left: 0;
				padding-right: 0;
			}

			> li {
				display: flex;
				&:not(:nth-last-of-type(-n + 3)) {
					@media (max-width: $breakpoint-medium) {
						display: none;
					}
				}

				> a {
					min-height: auto;
				}
			}
		}

		.uk-navbar-dropdown {
			background-color: map-get($colors, 'true-white') !important;
			box-shadow: 0px 2px 5px 0px rgba(158, 158, 158, 1);
			max-width: 250px;
			top: 35px !important;
			left: -15px !important;
			font-size: get-fontsize(m, small) !important;
			z-index: 99;
			position: absolute;
			@media (max-width: $breakpoint-medium) {
				max-width: 150px;
			}
		}

		.uk-navbar-dropdown,
		&__breadcrumb {
			a {
				cursor: pointer;
			}
			a,
			p {
				color: map-get($colors, 'dark-grey') !important;
				font-size: get-fontsize(m, small) !important;
				@media (max-width: $breakpoint-medium) {
					color: map-get($colors, 'true-white') !important;
					font-size: 12px !important;
				}
			}
			p {
				margin: auto auto 0px;
				text-decoration: none;
			}
		}

		&__separator {
			padding: 0 15px;
			column-gap: 0.25em;
			color: map-get($colors, 'wolf-red') !important;
			font-size: get-fontsize(m, small) !important;

			@media (max-width: $breakpoint-medium) {
				font-size: 12px !important;
			}
		}

		&__breadcrumb p,
		&__separator {
			display: flex;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;
		}

		.uk-open #{$name}__breadcrumb a {
			font-size: get-fontsize(m, default);
		}

		.uk-icon {
			color: map-get($colors, 'dark-grey') !important;
			margin-top: 2px;

			@media (max-width: $breakpoint-medium) {
				color: map-get($colors, 'true-white') !important;
			}

			svg {
				height: 15px;
				width: 15px;
				margin-left: 15px;

				@media (max-width: $breakpoint-medium) {
					height: 10px !important;
					width: 10px !important;
				}
			}
		}
	}
}

@include wolf-m-breadcrumb();
