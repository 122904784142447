&.new-design {
	@media (max-width: $breakpoint-medium) {
		padding: 21px 10px;

		.m-nav-bar__container-left {
			.a-button {
				.a-button__icon-wrapper > svg {
					width: 24px;
					height: 24px;
				}
			}
		}
	}

	.uk-navbar-nav {
		> li {
			> .a-button {
				padding: 16px 20px;

				@media (max-width: 1690px) {
					padding: 16px 8px;
				}

				@media (max-width: 1390px) {
					padding: 16px 4px;
					font-size: 14px;
				}

				&.uk-flex-row-reverse:not(.only-icon) .a-button__icon-wrapper {
					padding-left: 5px;

					@media (max-width: 1690px) {
						margin-right: -5px;
					}
				}
			}
		}

		li {
			padding: 0;
		}
	}

	.m-nav-bar {
		&__container-right.uk-navbar-right {
			padding-right: 30px;

			@media (max-width: $breakpoint-small-max) {
				padding-right: 14px;
			}
		}
		&__container-right {
			@media (max-width: $breakpoint-small-max) {
				padding-right: 14px;
			}
		}

		&__logo {
			margin: auto 30px;
			min-width: auto;

			@media (max-width: 1570px) {
				margin: auto 14px;
			}

			@media (max-width: $breakpoint-medium) {
				margin-top: 3px;
			}

			> svg {
				height: 48px;
			}
		}
	}

	.m-navigation-switcher {
		.a-button {
			@media (max-width: 1570px) {
				padding: 12px 22px;
			}
		}
	}

	.login-btn {
		&.left-side {
			display: none;

			@media (max-width: 1540px) {
				display: inline-flex;
			}
		}

		&.right-side {
			@media (max-width: 1540px) {
				display: none;
			}
		}
	}
}
