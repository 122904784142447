$o-kb-startpage-stage-search-button-height: 58px;

@mixin wolf-o-kb-startpage-stage($name: '.o-kb-startpage-stage') {
	#{$name} {
		color: white !important;
		hyphens: auto;

		&__background--image {
			max-width: 100%;
			padding: 49px 0px;
			min-height: 1620px;
			height: auto;
			background-image: url('./../../../global/assets/images/library-wolf.jpg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;

			@media (max-width: $breakpoint-small-max) {
				min-height: auto;
				background-attachment: fixed; // prevent jumping on mobile when opening breadcrumbs etc
			}
		}

		&__heading {
			text-align: center;
			color: map-get($colors, 'true-white');
			margin: auto;
			margin-top: 270px;

			h1 {
				color: map-get($colors, 'true-white') !important;
				margin: 30px 0 0 0;
			}

			@media (max-width: $breakpoint-medium) {
				margin-top: 145px;
				word-break: break-all;
			}

			@media (min-width: $breakpoint-small-max) and (max-height: 755px) {
				margin-top: 200px;
			}

			@media (min-width: $breakpoint-small-max) and (max-height: 690px) {
				margin-top: 150px;
			}

			@media (min-width: $breakpoint-small-max) and (max-height: 640px) {
				margin-top: 100px;
			}

			@media (max-width: $breakpoint-medium) and (max-height: 650px) {
				margin-top: 50px;
			}
		}

		&__subtitle {
			margin: 0;
			color: map-get($colors, 'true-white');
		}

		&__input {
			background-color: transparent;
			border: none;
			border-bottom: 1px solid map-get($colors, 'wolf-red');
			height: $o-kb-startpage-stage-search-button-height !important; // see search-button
			font-size: get-fontsize(m, small) !important;
			line-height: normal;
			width: 100%;
			padding-left: 10px;
			padding-right: 10px;

			color: map-get($colors, 'true-white');

			&:focus {
				background-color: rgba(0, 0, 0, 0.33);
				color: map-get($colors, 'true-white');
			}
		}

		&__input::placeholder {
			color: map-get($colors, 'true-white');
		}

		&__form {
			margin-left: auto;
			margin-right: auto;
			max-width: 780px;
		}

		&__search {
			display: flex;
			margin-bottom: 0px;
			margin-top: 70px;
		}

		&__query {
			// width: 100%;
			flex: 1 0 620px;
			// max-width: 785px;
			max-width: 620px;
			min-width: 620px;

			@media (max-width: $breakpoint-medium-max) {
				// padding: 1rem 1rem 4rem 1rem;
				max-width: none;
				min-width: 70%;
				flex: 1;
			}
		}

		// &__search-button {
		// 	height: $o-kb-startpage-stage-search-button-height !important; // see input
		// 	span {
		// 		font-size: get-fontsize(m, small);
		// 		@include font-inter-500;
		// 	}
		// 	border: 0px;
		// }

		&__submit {
			flex-grow: 1;
		}

		form input[type='submit'] {
			// see: @include wolf-submit-button();
			@include font-inter-600();
			width: 100%;
			height: $o-kb-startpage-stage-search-button-height !important; // see input

			font-size: 22px;
			line-height: 22px;

			@media (max-width: $breakpoint-medium-max) {
				max-width: 100%;
				min-width: 100%;
			}
		}

		// &__submitButton {
		// }

		&__results {
			// this will contain the typeahead results
			min-height: 284px;

			@media (min-width: $breakpoint-small-max) and (max-height: 1020px) {
				min-height: 230px;
			}

			@media (min-width: $breakpoint-small-max) and (max-height: 965px) {
				min-height: 200px;
			}

			@media (min-width: $breakpoint-small-max) and (max-height: 935px) {
				min-height: 170px;
			}

			@media (min-width: $breakpoint-small-max) and (max-height: 905px) {
				min-height: 140px;
			}

			@media (min-width: $breakpoint-small-max) and (max-height: 885px) {
				min-height: 110px;
			}

			@media (min-width: $breakpoint-small-max) and (max-height: 855px) {
				min-height: 80px;
			}

			@media (min-width: $breakpoint-small-max) and (max-height: 825px) {
				min-height: 50px;
			}

			@media (min-width: $breakpoint-small-max) and (max-height: 795px) {
				min-height: 20px;
			}

			@media (max-width: $breakpoint-medium) and (max-height: 820px) {
				min-height: 30px;
			}
		}

		&__anchor {
			text-align: center;
			color: map-get($colors, 'true-white');
			margin: auto;

			margin-top: 54px;
			//margin-top: 300px; // this allows for the typeahead overlay if using pos abs

			@media (max-width: $breakpoint-medium) {
				margin-top: 16px;
			}

			@media (min-width: $breakpoint-small-max) and (max-height: 1076px) {
				margin-top: 16px;
			}

			@media (min-width: $breakpoint-small-max) and (max-height: 1040px) {
				margin-top: 0px;
			}
		}

		&__anchor-text {
			font-size: get-fontsize(m, small) !important;
		}

		&__arrow-down {
			border: 1px solid map-get($colors, 'true-white');
			margin: 0 auto;
			width: 44px;
			height: 44px;

			.uk-icon {
				padding: 0.5rem;

				@media (max-width: $breakpoint-medium) {
					padding: 0.8rem;
				}
			}
		}

		&__secondary-nav {
			color: map-get($colors, 'true-white') !important;
			margin-top: 125px;

			@media (max-width: $breakpoint-medium) {
				margin-top: 85px;
			}

			h3 {
				color: map-get($colors, 'true-white') !important;
				margin-bottom: 0px;
				white-space: nowrap;

				@media (max-width: $breakpoint-medium) {
					margin-bottom: 40px;
				}
			}

			.m-breadcrumb {
				margin-top: 50px;
				padding: 0;
			}

			.m-breadcrumb__breadcrumb a,
			.m-breadcrumb .uk-navbar-dropdown,
			.m-breadcrumb__breadcrumb p {
				font-size: get-fontsize(m, default) !important;
				padding: 0px;
				color: map-get($colors, 'true-white') !important;
				min-width: 100%;
			}

			.uk-grid {
				@media (max-width: $breakpoint-medium) {
					margin-left: 0px;
				}
			}

			.m-breadcrumb .m-breadcrumb__icon-svg {
				color: map-get($colors, 'true-white') !important;

				svg {
					height: 12px !important;
					width: 65px !important;
					margin-left: 0;
				}
			}

			.uk-navbar-dropdown {
				//backdrop-filter: blur(5px);
				//background-color: rgba(0, 0, 0, 0.9) !important;
				background-color: rgba(0, 0, 0, 1) !important;
				padding: 0 15px !important;
				box-shadow: none;
				//top: initial !important;
				width: fit-content;
				width: -moz-fit-content;

				a,
				a:hover {
					color: get-colors('true-white');
					color: red;
				}
			}

			.uk-navbar-nav {
				flex-wrap: wrap;
			}

			.uk-navbar-dropdown-nav li:first-child {
				margin-top: 1rem;
			}

			.uk-navbar-dropdown-nav li:last-child {
				margin-bottom: 1rem;
			}

			.uk-navbar-dropdown-nav li {
				margin-bottom: 0.5rem;
			}
		}

		&__nav--padding {
			@media (max-width: $breakpoint-medium) {
				padding: 0;
			}
		}

		&__wrapper {
			width: 100%;
			overflow: hidden;

			@media (min-width: 1935px) {
				position: relative;
				left: 0;
				right: 0;
				margin: 0 auto;
				overflow: hidden;
			}
		}

		/*Width changes */
		&__background {
			max-width: 1920px;
			width: 100%;
			margin: auto;
			padding: 49px 0;
			background: none;
		}
	}
}

@include wolf-o-kb-startpage-stage();
