@mixin wolf-m-professional-anchor-navigation($name: '.m-professional-anchor-navigation') {
	#{$name} {
		background-color: get-color('accent');
		padding: 45px 0 30px;
		@media (max-width: $breakpoint-medium-max) {
			padding: 12px 0;
		}
		@media (max-width: $breakpoint-xsmall-max) {
			padding: 0 0 12px;
		}

		&__navbar-content-wrapper {
			width: 100%;
			max-width: 1920px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			align-items: center;

			.uk-navbar-center {
				@media (max-width: $breakpoint-medium-max) {
					flex-wrap: nowrap;
					overflow-x: auto;

					&::-webkit-scrollbar {
						width: 6px;
						height: 6px;
					}

					&::-webkit-scrollbar-track {
						background-color: #81adc8;
					}

					&::-webkit-scrollbar-thumb {
						background-color: #8ebbd1;
						outline: none;
						border-radius: 3px;
					}
				}
				@media (max-width: $breakpoint-xsmall-max) {
					&::-webkit-scrollbar {
						width: 0;
						height: 0;
					}

					&::-webkit-scrollbar-track {
						background-color: transparent;
					}
				}

				&:not(:only-child) {
					position: relative;
					top: unset;
					left: unset;
					transform: unset;
					margin: 0 auto;
				}
			}

			.uk-navbar-right {
				@media (max-width: $breakpoint-medium-max) {
					padding-top: 12px;
				}
				@media (max-width: $breakpoint-small-max) {
					display: flex !important;
					flex-wrap: wrap;
					justify-content: flex-end;
					gap: 12px;
				}

				.uk-nav.uk-navbar-dropdown-nav {
					max-height: 65vh;
					overflow-y: auto;
				}
			}
		}

		.a-headline {
			color: get-color('true-white');
		}

		&__navbar-wrapper {
			&.uk-navbar-container {
				background-color: get-color('accent');
				padding: 0;
				transition: transform 200ms ease;

				@media (max-width: $breakpoint-medium-max) {
					padding: 10px 2px 10px;
				}

				@media (max-width: $breakpoint-xsmall-max) {
					padding: 10px 20px;
				}

				.uk-navbar {
					&-left {
						.a-headline {
							color: get-color('true-white');
							margin-bottom: 5px;
							margin-top: 5px;
							padding-inline: 8px;
						}
					}

					&-right {
						.a-button {
							&.uk-button-primary-blue {
								background-color: get-color('true-white');
								color: get-color('accent');
								border: none;
								border-radius: 22px;
								padding: 9px 30px;
							}
						}
					}

					&-left,
					&-right {
						display: none;

						@media (max-width: $breakpoint-xsmall-max) {
							display: block;
						}

						#{$name}__mobile-button {
							color: get-color('true-white');
							padding: 10px 25px;
							background-color: rgba(get-color('true-white'), 0.2);
							border-radius: 22px;
							line-height: 16px;
							cursor: pointer;
							display: flex;
							align-items: center;
							min-height: unset;
							font-size: 14px;
							@include font-inter-500;

							.uk-icon {
								width: 10px;
								margin-left: 10px;
								transform: rotate(180deg);
								transition: transform 200ms ease;
							}

							&.is-active {
								.uk-icon {
									transform: rotate(0deg);
								}
							}
						}

						.uk-navbar-dropdown {
							width: 100%;
							background-color: get-color('accent');
							border-top: 1px solid get-color('true-white');

							li {
								> .uk-button-default {
									border: none;
									background-color: transparent;
									color: get-color('true-white');
									text-align: left !important;
									line-height: 35px;

									.a-button__text {
										margin: 0;
									}
								}
							}
						}
					}

					&-center {
						#{$name}__links-container {
							margin-bottom: 0;
							display: flex;
							padding-left: 0;
							flex-wrap: nowrap;

							li {
								list-style: none;
								display: flex;

								.a-button {
									&.uk-button-default {
										background-color: transparent;
										box-shadow: none;
										border: none;
										padding: 16px 20px;

										@media (max-width: $breakpoint-medium-max) {
											white-space: nowrap;
											padding: 5px 20px;
										}

										&:hover {
											text-decoration: underline;
											opacity: 1;
										}
									}
								}

								&.uk-active {
									.a-button {
										&.uk-button-default {
											text-decoration: underline;
										}
									}
								}
							}
						}
					}
				}

				&.uk-sticky-fixed {
					.uk-navbar {
						&-left,
						&-right {
							display: block;
						}

						&-center {
							#{$name}__links-container {
								li:not(.uk-active) {
									.a-button {
										&.uk-button-default {
											opacity: 0.5;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.uk-sticky-placeholder {
			&:empty {
				display: none;
			}
		}
	}
}

@include wolf-m-professional-anchor-navigation();
