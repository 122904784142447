@mixin wolf-m-shopping-cart-item($name: '.m-shopping-cart-item') {
	#{$name} {
		color: get-color(true-white);

		&__wrapper {
			// padding: 12px 0;
		}
		&__cols {
			display: flex;
			flex-direction: column;
			@media (min-width: $breakpoint-small) {
				flex-direction: row;
				justify-content: space-between;
			}
			> div {
				flex-grow: 3;
				+ div {
					flex-grow: 1;
				}
			}
		}

		&__image {
			text-align: left;

			img {
				margin: auto;
				max-width: 77px;
				@media (min-width: $breakpoint-small) {
					max-width: 112px;
				}
			}
		}
		&__details {
			@media (min-width: $breakpoint-small) {
				padding-left: 30px;
			}
		}
		&__product {
			@include font-saira-700();
			font-size: 32px;
			line-height: 40px;
			margin-bottom: 6px;
		}
		&__description {
			@include font-inter-200();
			font-size: 16px;
			line-height: 28px;
			// margin-bottom: 88px;
			margin-bottom: 30px;
		}
		&__pricebox {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
		&__price {
			@include font-inter-600();
			font-size: 30px;
			line-height: 40px;
			text-align: right;
			white-space: nowrap;
			margin-bottom: 5px;
		}
	}
}

@include wolf-m-shopping-cart-item();
