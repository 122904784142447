@use '../../variables';
/* ============================ Spacings ========================= */
.padding-standard {
	padding-top: 110px;
	padding-bottom: 110px;
	@media (max-width: $breakpoint-small-max) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}

.padding-small {
	padding-top: 55px;
	padding-bottom: 55px;
	@media (max-width: $breakpoint-small-max) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}

.padding-small-top {
	padding-top: 55px;
	padding-bottom: 110px;
	@media (max-width: $breakpoint-small-max) {
		padding-top: 40px;
		padding-bottom: 80px;
	}
}

.padding-small-bottom {
	padding-top: 110px;
	padding-bottom: 55px;
	@media (max-width: $breakpoint-small-max) {
		padding-top: 80px;
		padding-bottom: 40px;
	}
}

.padding-big {
	padding-top: 220px;
	padding-bottom: 220px;
	@media (max-width: $breakpoint-small-max) {
		padding-top: 110px;
		padding-bottom: 110px;
	}
}

.padding-big-top {
	padding-top: 220px;
	padding-bottom: 110px;
	@media (max-width: $breakpoint-small-max) {
		padding-top: 110px;
		padding-bottom: 80px;
	}
}

.padding-big-bottom {
	padding-top: 110px;
	padding-bottom: 220px;
	@media (max-width: $breakpoint-small-max) {
		padding-top: 80px;
		padding-bottom: 110px;
	}
}

.padding-no-padding-bottom {
	padding-top: 55px;
	padding-bottom: 0;
	@media (max-width: $breakpoint-small-max) {
		padding-top: 40px;
		padding-bottom: 0;
	}
}

/* ============================  Colors ========================= */

$colorKeyList: map-keys($colors);

/* ============================ Text Colors ========================= */

/* Text color*/
//text-color-$textColor

@mixin text-colors($color) {
	.text-color-#{$color} {
		color: get-color(#{$color});
	}

	.text-color-#{$color}:not([class*='headline-color-']) {
		.a-headline {
			color: inherit;
		}
	}
}

/* Copy Text color*/
//copy-color-$copyColor .a-copy

@mixin copy-colors($color) {
	.copy-color-#{$color} .a-copy {
		color: get-color(#{$color});
	}
}

/* Headline Text color */
//headline-color-$headlineColor .a-headline
@mixin headline-colors($color) {
	.styleSettings.headline-color-#{$color} .a-headline {
		color: get-color(#{$color});
	}

	.styleSettings .styleSettings.headline-color-#{$color} .a-headline {
		color: get-color(#{$color});
	}
}

@mixin generate-colors-stylings() {
	@each $color in $colorKeyList {
		@include headline-colors($color);
		@include copy-colors($color);
		@include text-colors($color);
	}
}

@include generate-colors-stylings();
