@mixin wolf-m-professional-product-table-mobile($name: '.m-professional-product-table-mobile') {
	#{$name} {
		// stop the x-scroll due to the slider top
		// @todo - try a better fix
		overflow: hidden;

		&__subheadline {
			&.a-copy {
				margin-bottom: 20px;
			}
		}

		&__dots {
			margin-top: 28px;
			margin-bottom: 50px;

			&.uk-dotnav {
				> li {
					padding-left: 15px;
				}

				> * > * {
					// make the dots bigger
					width: 12px;
					height: 12px;
					background-color: rgb(210, 213, 217); // dark-blue @ 20%
				}

				.uk-active {
					> * {
						background-color: get-color('dark-blue');
					}
				}
			}
		}

		&__slider {
			li {
				&.uk-width-3-4.uk-width-custom {
					width: 240px;
				}

				.uk-card,
				.uk-card-body,
				.uk-card-title {
					user-select: none;
				}

				.uk-card {
					background-color: white;
					border: 1px solid get-color('dark-blue');
					transition: background-color 100ms ease-out;
					cursor: pointer;
				}

				.uk-card-body {
					box-sizing: border-box;
					height: 180px;

					display: flex;
					justify-content: center;
					align-items: center;
				}

				.uk-card-title {
					@include font-inter-600;
					color: get-color('dark-blue');
					font-size: 30px;
					text-align: center;
				}

				&.uk-active {
					.uk-card {
						background-color: get-color('dark-blue');
						transition: background-color 250ms ease-in;
						transition-delay: 200ms;
					}

					.uk-card-title {
						color: white;
					}
				}
			}
		}

		&__table {
			position: relative;
			overflow: hidden;

			margin: 30px 20px;

			@media (max-width: $breakpoint-small-max) {
				margin: 0;
			}

			&.truncated-y {
				&::before {
					// add a blur to the bottom of the table
					background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 40%, rgba(255, 255, 255, 1) 100%);

					position: absolute;
					top: initial;
					left: 0;
					right: 0;
					bottom: 0;
					height: 100px;
					content: '';
					z-index: 1;
				}
			}
		}

		&__tableInner {
			> .col {
				display: flex;
				flex: 1 0 auto;

				&__separator {
					.cell {
						border-left-color: transparent;
						border-right-color: transparent;
						background-color: transparent;

						padding-top: 36px;
						@include font-inter-600;
						font-size: 16px;
					}
				}

				&:last-child {
					.cell {
						border-bottom-color: #ddd;
					}
				}

				&--hidden {
					display: none;
				}

				// override - only hide cols which are not marked as shown
				&--shown {
					display: flex;
				}
			}

			.cell {
				flex: 1 0 40%;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				box-sizing: border-box;
				border: 1px solid #ddd;
				padding: 12px 15px 12px 15px;

				@include font-inter-400;
				font-size: 14px;
				text-align: left;
				color: get-color('wolf-black');
				letter-spacing: -0.4px;
				line-height: 18px;
				word-break: break-all;

				&--hidden {
					display: none;
				}

				&:not(.cell--first) {
					border-left-color: transparent;
					border-bottom-color: transparent;
				}

				&--first {
					flex-basis: 60%;
					background-color: #f3f3f3;
					border-right-color: transparent;
					border-bottom-color: transparent;
				}
			}
		}

		&__tableFooter {
			margin: 0px 20px;

			.mb-10 {
				margin-bottom: 10px;
			}

			#{$name} {
				&__button {
					&.a-button {
						@include font-inter-500();
						font-size: 16px;
						border-radius: 60px;
						border-width: 2px;
						padding: 11px 38px;

						width: 100%;
						justify-content: center;

						transition: opacity 150ms ease-in-out;

						&[disabled] {
							opacity: 0.25;
							user-select: none;
							pointer-events: none;
							cursor: default;
							transition: opacity 150ms ease-in-out;
						}
					}
				}
			}
		}
	}
}

@include wolf-m-professional-product-table-mobile();
