@mixin wolf-a-linklist($name: '.a-linklist') {
	#{$name} {
		width: fit-content;
		width: -moz-fit-content;
		max-width: 100%;

		&__list-wrapper {
			padding: 1rem;
			font-size: get-fontsize(m, default);
			color: map-get($colors, 'dark-grey');
			margin: 1rem;

			@media (max-width: $breakpoint-medium) {
				font-size: get-fontsize(s, default);
			}
		}

		&__list-wrapper > li {
			margin-top: 12px !important;
		}

		&__list-title {
			color: black;
			list-style: none;
		}

		&__link {
			color: map-get($colors, 'dark-grey-seo-light');
			text-decoration: none;
		}

		&__link:hover {
			color: map-get($colors, 'dark-grey-seo-light');
			text-decoration: none;
		}

		&__list-title-link,
		&__list-title-link a {
			color: black;
			list-style: none;
			text-decoration: none;
		}

		&__list-title-link:hover {
			color: map-get($colors, 'dark-grey-seo-light');
		}

		&__dark &__list-wrapper {
			background-color: map-get($colors, 'wolf-black');
			color: map-get($colors, 'black');
			border: none;

			@media (max-width: $breakpoint-medium) {
				background-color: map-get($colors, 'dark-blue');
			}

			&__link,
			&__link:hover,
			&__list-title-link:hover {
				color: map-get($colors, 'dark-grey-seo');
			}

			&__list-title-link,
			&__list-title-link a {
				color: map-get($colors, 'light-blue-90-white');
			}
		}

		&__dark &__list-title,
		&__dark &__list-title-link,
		&__dark &__list-title-link a {
			color: white;
			list-style: none;
		}
	}
}

@include wolf-a-linklist();
