@mixin wolf-m-form($name: '.m-form') {
	#{$name} {
		padding: 0;
		margin: 0;
		border: none;
	}
}

@include wolf-m-form();

form.hs-form {
	max-width: unquote('min(830px, calc(100vw - 38px))');
	margin: 0 auto;
	box-sizing: border-box;

	.hs-dependent-field {
		width: 100%;
		max-width: 100%;
	}

	fieldset {
		display: flex;
		gap: 15px;

		.field {
			width: 100%;
			margin-bottom: 20px;
		}
	}

	& > .field,
	fieldset {
		font-family: Inter;
		border: none;
		font-size: 16px;
		font-weight: 400;
		margin: 0 0 15px;
		width: 100%;
		max-width: 100%;
		min-width: unset;
		box-sizing: border-box;

		&.form-columns-2 .field {
			width: 50%;
		}

		label,
		legend {
			margin-bottom: 8px;
			display: block;

			&.hs-form-booleancheckbox-display {
				input[type='checkbox'] {
					margin-top: 3px;
				}
			}
		}
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		padding-right: 20px;
		background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%2311151c%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%2311151c%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A);
		background-repeat: no-repeat;
		background-position: 100% 50%;
		height: 48px;
		padding-left: 12px;
		border: 1px solid #11151c;
		outline-color: #11151c;
		font-size: inherit;
		max-width: 100%;
		width: 100% !important;

		option {
			font-size: inherit;
		}
	}

	input:not([type='checkbox']):not([type='radio']),
	textarea {
		line-height: normal;
		background-color: transparent;
		border: 1px solid #11151c;
		outline-color: #11151c;
		padding: 8px 16px;
		height: 48px;
		vertical-align: middle;
		display: inline-block;
		max-width: 100%;
		width: 100%;
		color: #11151c;
		box-sizing: border-box;
	}

	textarea.hs-input.hs-fieldtype-textarea {
		min-height: 18em;
		font-size: 16px;
		width: 100%;
	}

	input[type='checkbox'],
	input[type='radio'] {
		color: black;
		display: inline-block;
		height: 16px !important;
		width: 16px !important;
		overflow: hidden;
		vertical-align: middle;
		-webkit-appearance: none;
		-moz-appearance: none;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		cursor: pointer;
		border: 1px solid #11151c;

		&:checked {
			background-color: #e10000;
		}
	}

	input[type='checkbox'] {
		min-width: 1em;
		min-height: 1em;
		margin: -4px 5px 0px 0px;

		&:checked {
			background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A);
		}
	}

	input[type='radio'] {
		border-radius: 50%;
		margin-top: -4px;
		margin-left: 0px;

		&:checked {
			background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E);
		}
	}

	input[type='file'].hs-input {
		padding: 12px 16px;
	}

	input[type='submit'] {
		margin: 0px;
		width: unset !important;
		background-color: #e10000 !important;
		border-color: #e10000 !important;
		color: #ffffff !important;
		font-weight: 700;

		&:hover {
			background-color: #b70406 !important;
		}
	}

	.inputs-list {
		list-style: none;
		padding: 0;
	}

	.hs-fieldtype-intl-phone {
		display: flex;
		gap: 18px;

		&::after {
			content: none;
			display: none;
		}
		select {
			max-width: 40%;
		}
	}

	.hs-error-msgs {
		margin-top: 4px;
		color: #e10000;
	}

	fieldset label span:nth-child(1),
	fieldset label span.hs-form-required {
		font-weight: 500;
	}

	legend.hs-field-desc {
		font-size: 12px;
		color: #a5a5a5;
	}

	fieldset.form-columns-0 {
		margin-top: 50px;

		&:first {
			margin-top: 0;
		}
	}
}
