@mixin wolf-o-application-steps($name: '.o-application-steps') {
	#{$name} {
		&__wrapper {
			margin: 0 auto;
			width: calc(100vw - 64px);
			background-color: white;
			padding: 32px;
			@media (min-width: $breakpoint-medium) {
				max-width: 1520px;
			}
		}
		& .a-headline--m-application-step {
			margin: 150px 0 215px;
		}
	}
}

@include wolf-o-application-steps();
