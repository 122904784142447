@import '../../../../global/assets/fonts/font-mixin.scss';

$fontpath2: '../../../global/assets/fonts';
$fontFolder2: 'inter';
$fontFamily2: 'Inter';
$fontExtension2: 'woff2';
$fontWeights2: 100, 200, 300, 400, 500, 600, 700, 800, 900;

@each $fweight2 in $fontWeights2 {
	@include fontface($fontpath2, $fontFolder2, $fontFamily2, $fweight2, 'cyrillic-ext', $fontExtension2);
}

@each $fweight2 in $fontWeights2 {
	@include fontface($fontpath2, $fontFolder2, $fontFamily2, $fweight2, 'cyrillic', $fontExtension2);
}

@each $fweight2 in $fontWeights2 {
	@include fontface($fontpath2, $fontFolder2, $fontFamily2, $fweight2, 'greek-ext', $fontExtension2);
}

@each $fweight2 in $fontWeights2 {
	@include fontface($fontpath2, $fontFolder2, $fontFamily2, $fweight2, 'greek', $fontExtension2);
}

@each $fweight2 in $fontWeights2 {
	@include fontface($fontpath2, $fontFolder2, $fontFamily2, $fweight2, 'latin-ext', $fontExtension2);
}

@each $fweight2 in $fontWeights2 {
	@include fontface($fontpath2, $fontFolder2, $fontFamily2, $fweight2, 'latin', $fontExtension2);
}

// INTER
@mixin font-inter-100() {
	font-family: $fontFamily2, 'Inter-fallback', sans-serif;
	font-weight: 100;
}

@mixin font-inter-200() {
	font-family: $fontFamily2, 'Inter-fallback', sans-serif;
	font-weight: 200;
}

@mixin font-inter-300() {
	font-family: $fontFamily2, 'Inter-fallback', sans-serif;
	font-weight: 300;
}

@mixin font-inter-400() {
	font-family: $fontFamily2, 'Inter-fallback', sans-serif;
	font-weight: 400;
}

@mixin font-inter-500() {
	font-family: $fontFamily2, 'Inter-fallback', sans-serif;
	font-weight: 500;
}

@mixin font-inter-600() {
	font-family: $fontFamily2, 'Inter-fallback', sans-serif;
	font-weight: 600;
}

@mixin font-inter-700() {
	font-family: $fontFamily2, 'Inter-fallback', sans-serif;
	font-weight: 700;
}

@mixin font-inter-800() {
	font-family: $fontFamily2, 'Inter-fallback', sans-serif;
	font-weight: 800;
}

@mixin font-inter-900() {
	font-family: $fontFamily2, 'Inter-fallback', sans-serif;
	font-weight: 900;
}

//Original
//src: url("https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2") format('woff2');
//src: url("https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2") format('woff2');
//src: url("https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2") format('woff2');
//src: url("https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2") format('woff2');
//src: url("https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2") format('woff2');
//src: url("https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2") format('woff2');
//src: url("https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2") format('woff2');
