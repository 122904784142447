@mixin wolf-m-category-toggle-selector($name: '.m-category-toggle-selector') {
	#{$name} {
		&__categories-wrapper {
			padding: 10px 0;
			flex-wrap: nowrap;
			display: flex;
			width: calc(100% + 100px);
			transform: translate(-50px, 0px);
			padding-left: 50px;
			overflow-x: auto;

			.a-button {
				border-radius: 28px;
				margin-right: 16px;
				white-space: nowrap;

				&:not(.uk-active) {
					font-weight: 400;
					color: get-color('dark-blue-30-white');
					border-color: get-color('dark-blue-90-white');

					&:hover {
						color: get-color('true-white');
					}
				}

				&.uk-active {
					background-color: get-color('primary');
				}

				&:last-child {
					margin-right: 100px;
				}
			}
		}
		.mob {
			display: none;
			width: calc(100% - 100px);

			.m-filter-accordion-label {
				font-size: 16px;
			}
			.m-filter-accordion {
				.m-filter-accordion-head {
					.left-content {
						font-size: 16px;
					}
				}
				.right-content .checked-filters {
					display: none;
				}

				label .text {
					font-size: 16px;
				}
			}
		}
		@media (max-width: $breakpoint-small-max) {
			.mob {
				display: block;
			}
			.pc {
				display: none;
			}
		}
	}
}

@include wolf-m-category-toggle-selector();
