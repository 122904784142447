$wolf-o-flipcard-card-wrapper-width: 360px;
$wolf-o-flipcard-card-wrapper-height: 485px;
$wolf-o-flipcard-card-width: 360px;
$wolf-o-flipcard-card-height: 485px;

@mixin wolf-o-flipcard($name: '.o-flipcard') {
	#{$name} {
		&__top {
			.logo {
				text-align: center;

				@media (max-width: $breakpoint-medium-max) {
					height: fit-content;
					height: -moz-fit-content;
				}

				img {
					max-height: 150px;
				}

				.a-wolf-logo {
					svg {
						path {
							fill: get-color(true-white);

							+ path {
								fill: get-color(wolf-red);
							}
						}
					}
				}
			}

			.leadin {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-top: 100px;

				@media (max-width: $breakpoint-small-max) {
					margin-top: 30px;
				}

				div.a-headline {
					width: 35%;
					display: flex;
					justify-content: center;
					text-align: center;

					h1,
					h2,
					h3,
					h4,
					h5,
					h6 {
						text-align: inherit;
						margin-bottom: 0;
					}

					@media (max-width: $breakpoint-medium-max) {
						width: 85%;
						margin: 0 auto 40px;
					}

					@media (min-width: $breakpoint-large) and (max-width: 1450px) {
						width: 55%;
					}
				}

				.a-copy {
					width: 60%;
					display: flex;
					justify-content: center;
					text-align: center;
				}

				#{$name}__subtitle {
					margin-top: 25px;

					.a-copy {
						width: 55%;
					}
				}
			}
		}

		&__desktop {
			.card-wr {
				position: relative;
				box-sizing: border-box;
				width: 25%;
				min-height: $wolf-o-flipcard-card-wrapper-height;

				@media (max-width: $breakpoint-large-max) {
					width: 33.333%;

					.card {
						width: 100%;
					}
				}
			}

			.card {
				position: relative;
				margin: auto;
				cursor: pointer;

				width: $wolf-o-flipcard-card-width;
				min-height: $wolf-o-flipcard-card-height;
				perspective: 500px;

				&:hover {
					.content {
						// flip the card
						transform: rotateY(180deg);
						transition: transform 0.5s;
					}
				}

				.content {
					position: absolute;
					width: 100%;
					height: 100%;
					box-sizing: border-box;

					transform: rotateY(0deg);
					transition: transform 1s;
					transform-style: preserve-3d;
				}

				.front {
					transform: rotateY(0deg);
					display: flex;

					// style the inner component
					.m-flipcard {
						color: inherit;
						padding: 0;
						margin: auto;
						width: 100%;

						&__head-wrapper {
							width: 100%;
						}

						&__icon {
							color: inherit;
							width: 100px;
							margin-top: 120px;
							margin-bottom: 87px;
						}

						.a-headline {
							line-height: 38px;
							font-weight: normal;

							&__heading {
								margin-left: auto;
								margin-right: auto;
								max-width: 75%;
								min-height: 80px;
							}
						}
					}
				}

				.front,
				.back {
					box-sizing: border-box;
					position: absolute;
					height: 100%;
					width: 100%;
					background: transparent;
					text-align: center;

					backface-visibility: hidden;
					-webkit-backface-visibility: hidden;
					-moz-backface-visibility: hidden;
					-ms-backface-visibility: hidden;
				}

				.back {
					transform: rotateY(180deg);
					border: 1px solid rgba(255, 255, 255, 0.25);
					box-sizing: border-box;
					padding: 30px;
					text-align: left;

					#{$name} {
						&__icon {
							margin-bottom: 20px;
						}

						&__icon-label {
							margin: auto;
							width: 50px;

							> svg {
								width: 50px;
								min-width: 50px;
							}
						}

						&__title {
							h1,
							h2,
							h3,
							h4,
							h5,
							h6 {
								&.a-headline {
									@include font-inter-600();
									margin: 0 0 10px 0;
								}
							}
						}

						&__subtitle {
							h1,
							h2,
							h3,
							h4,
							h5,
							h6 {
								&.a-headline {
									@include font-inter-300();
									margin: 0 0 70px 0;
								}
							}
						}

						&__description {
							.a-copy {
								width: 80%;
								margin: 0;

								* {
									margin: 0;
								}

								> * + * {
									margin-top: 10px;
								}

								p {
									@include font-inter-300();
									font-size: 14px;
									line-height: 20px;
								}
							}

							a {
								margin-top: 23px;
								font-size: 12px;
								color: inherit;
								display: inline-flex;
								align-items: center;

								.a-button__text {
									margin-right: 15px;
									display: inline-flex;
								}
							}
						}

						&__content-wrapper {
							display: flex;
							flex-direction: column;
							height: 100%;
							max-height: 270px;
							width: calc(100% + 58px);
							margin-left: -30px;
							padding: 0 32px 0 30px;
							box-sizing: border-box;

							overflow: hidden;
							overflow-y: auto;

							&::-webkit-scrollbar {
								width: 6px;
								background-color: transparent;
							}

							&::-webkit-scrollbar-track {
								background-color: transparent;
							}

							&::-webkit-scrollbar-thumb {
								background-color: rgba(255, 255, 255, 0);
								outline: none;
								border-radius: 3px;
								transition: all 0.25s ease-out;
							}

							&:hover {
								&::-webkit-scrollbar-thumb {
									background-color: rgba(255, 255, 255, 0.2);
								}
							}
						}
					}
				}
			}
		}

		// Flipcard MOBILE
		&__mobile {
			.flipcard {
				min-width: 90%;
				max-width: 600px;

				.m-flipcard {
					align-items: flex-start;
					padding: 0px 60px 0 20px;

					&__icon {
						margin-bottom: 20px;

						span svg {
							width: 80px;
							height: 60px;
						}
					}

					&__subtitle {
						display: block;
					}

					&__description {
						@include font-inter-400();
						display: flex;
						flex-direction: column;
						font-size: 14px;
						line-height: 20px;
					}

					@media (max-width: $breakpoint-small-max) {
						&__head-wrapper {
							.a-headline {
								justify-content: flex-start;
							}

							* .a-headline {
								text-align: left;
							}
						}
					}
				}
			}

			// mobile scrolling
			div.uk-grid {
				flex-wrap: nowrap;
				overflow-x: scroll;
				width: 100%;
				padding-bottom: 50px;

				/*Scrollbar styling firefox*/
				scrollbar-color: #81adc8 transparent;

				&::-webkit-scrollbar {
					appearance: none;
					width: 4px !important;
				}

				&::-webkit-scrollbar:vertical {
					width: 20px;
				}

				&::-webkit-scrollbar:horizontal {
					height: 15px;
				}

				&::-webkit-scrollbar-thumb {
					background-color: transparent;
					border-radius: 20px;
					border: 3px solid #81adc8;
				}

				&::-webkit-scrollbar-track {
					margin-left: 20px;
				}
			}

			.outer {
				width: 85vw;
			}
		}

		.a-button {
			display: flex;
			margin-top: 24px;
			margin-bottom: 10px;

			&:not(.only-icon) .a-button__text + .a-button__icon-wrapper {
				padding-left: 15px;
			}
		}

		a.a-button {
			border: 0;
			padding-left: 0;
			&:hover {
				background-color: transparent !important;
				border: 0;
			}
			&:active,
			&.uk-active {
				background-color: transparent !important;
				border: 0;
				box-shadow: none;
			}
		}
	}
}

@include wolf-o-flipcard();

@keyframes wolf-o-flipcard-hover {
	/* You could think of as "step 1" */
	0% {
		opacity: 0;
	}

	25% {
		opacity: 0.25;
	}

	50% {
		opacity: 0.5;
	}

	75% {
		opacity: 0.75;
	}

	/* You could think of as "step 2" */
	100% {
		opacity: 1;
	}
}
