@keyframes draw {
	to {
		stroke-dashoffset: 0;
	}
}

@mixin wolf-o-cta($name: '.o-cta') {
	#{$name} {
		position: relative;
		overflow: hidden;

		@media (max-width: $breakpoint-small-max) {
			.padding-standard {
				padding-top: 33px;
				padding-bottom: 32px;
			}
		}

		.image-wrapper {
			vertical-align: unset;
			min-height: 100vh;
			top: 50%;
			transform: translateY(-50%);
			z-index: 0;

			> img {
				object-fit: cover;
				height: 100%;
			}

			@media (min-width: 1921px) and (hover: hover) {
				max-height: 929px;
			}

			@media (min-width: 1921px) and (hover: coarse) {
				max-height: 929px;
			}

			&::before {
				background: linear-gradient(90deg, rgba(33, 33, 44, 0.78) 0%, rgba(33, 33, 44, 0.68) 30%, rgba(255, 255, 255, 0) 70%);
			}
		}

		&.default-gradient .uk-container {
			position: relative;
			z-index: 2;
		}

		&.vertical-left div.uk-container,
		&.vertical-left.embed-element > div {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		&.vertical-right div.uk-container,
		&.vertical-right.embed-element > div {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}

		&.vertical-right #{$name}__wrapper {
			text-align: right;
			max-width: 45%;

			#{$name}__text {
				width: 100%;
				text-align: right;
				margin: 25px 0px 40px;
				overflow-wrap: break-word;
			}
		}

		&.vertical-left #{$name}__wrapper {
			text-align: left;
			max-width: 45%;

			#{$name}__text {
				width: 100%;
				text-align: left;
				margin: 25px 0px 40px;
				overflow-wrap: break-word;
			}

			@media (max-width: $breakpoint-small) {
				max-width: 100%;
			}
		}

		&__wrapper {
			text-align: center;
			position: relative;
			z-index: 2;

			.padding-enhancer {
				margin-top: 40px;

				@media (min-width: $breakpoint-medium) {
					margin-top: 100px;
				}
			}

			.a-button {
				&.uk-button-default {
					border: solid 1px get-color('true-white');
				}
			}
		}

		&__wrapper.vertical-left,
		&__wrapper.vertical-right {
			width: fit-content;
			width: -moz-fit-content;
		}

		&__icon {
			&-container--success-animated {
				display: flex;
				margin: 0 auto 47px;

				width: 65px;
				height: 65px;
				align-items: center;
				justify-content: center;

				border-radius: 50%;
				border: 5px solid get-color('wolf-red');

				@media (min-width: $breakpoint-medium) {
					width: 120px;
					height: 120px;
				}
			}

			&--success-animated {
				height: 80px;
				width: 80px;

				path {
					fill: none;
					stroke: get-color('wolf-red');
					stroke-width: 1.5;
					stroke-dasharray: 23;
					stroke-dashoffset: 23;
					animation: draw 0.5s linear forwards;
				}
			}
		}

		&__headline * {
			margin: 0px;
		}

		&__sub-headline.a-headline {
			margin: 48px 0 15px;

			@media (max-width: $breakpoint-small-max) {
				margin: 32px 0 15px;
			}
		}

		&__image-text-container {
			display: flex;
			margin-top: 75px;
			align-items: center;

			#{$name}__image {
				width: 50%;
				display: inline-flex;
				margin: auto;
				align-items: center;
				justify-content: center;
				> .image-wrapper {
					position: relative;
					min-height: auto;
					height: auto;
					top: unset;
					transform: translate(0, 0);
				}
			}

			#{$name}__text {
				width: calc(50% - 140px);
				text-align: left;
				margin: 0 0 0 140px;
			}

			@media (max-width: $breakpoint-xsmall-max) {
				flex-direction: column;
				margin-top: 60px;

				#{$name}__text {
					> .a-copy {
						margin-top: 40px;
					}

					width: 100%;
				}

				#{$name}__image {
					width: 100%;
					margin-left: 0;
					> .image-wrapper {
						position: relative;
					}
				}
			}
		}

		&__text {
			width: 60%;
			text-align: center;
			margin: 25px auto 40px;

			@media (max-width: $breakpoint-medium) {
				width: 100%;
				margin-top: 20px;
				margin-bottom: 24px;
			}

			&:not(.pure-wysiwyg) {
				@include font-inter-200();

				@media (max-width: $breakpoint-small-max) {
					font-size: 20px;
					line-height: 32px;
					margin: 0 auto;
					font-size: 12px;
				}
			}

			.enhance-width {
				width: 85%;
			}
		}

		&__button {
			.a-button {
				min-height: 57px;
			}
		}

		&__horizontal {
			.a-headline {
				text-align: left;
				&.o-cta__sub-headline {
					margin: 0;
				}
			}

			@media (min-width: $breakpoint-medium) {
				padding-right: 30px;
			}

			@media (max-width: $breakpoint-small-max) {
				align-items: center;
			}
		}

		&__horizontal-buttons {
			@media (max-width: $breakpoint-medium-max) {
				align-items: center;
			}

			.a-button {
				margin-right: 16px;
				margin-left: 16px;
			}
		}

		&__horizontal-buttons-wrapper {
			@media (max-width: $breakpoint-small-max) {
				justify-content: center;
				margin-top: 30px;
			}
		}

		&__horizontal-headline {
			@media (max-width: $breakpoint-small-max) {
				align-items: center;
			}

			* .a-headline {
				text-align: left;

				@media (max-width: $breakpoint-small-max) {
					text-align: center;
				}
			}

			* .a-copy {
				text-align: left;

				@media (max-width: $breakpoint-small-max) {
					text-align: center;
				}
			}
		}

		&__horizontal-headline-right {
			padding-left: 30px;

			.a-headline {
				text-align: left;
			}
		}

		&__horizontal-text {
			margin-top: 25px;
			margin-bottom: 40px;
			text-align: left;

			@media (max-width: $breakpoint-small-max) {
				margin-top: 20px;
				margin-bottom: 24px;
			}

			* {
				@include font-inter-200();
				font-size: 20px;
				line-height: 32px;
				margin: 0px;

				@media (max-width: $breakpoint-small-max) {
					font-size: 12px;
				}
			}
		}
	}
}

@include wolf-o-cta();
