@mixin wolf-o-teaser-grid($name: '.o-teaser-grid') {
	#{$name} {
		.hastext {
			&__headline > * {
				margin: 0px;
			}
		}
		div:not(.hastext) {
			&__headline > * {
				margin-bottom: 50px;
			}
		}
		&.teaser-boxed {
			@media (max-width: $breakpoint-small-max) {
				.padding-small-bottom {
					padding-top: 30px;
					padding-bottom: 0px;
				}
			}

			.uk-container {
				position: relative;
				@media (max-width: $breakpoint-small-max) {
					.m-teaser-boxed {
						padding: 16px 10px;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						position: relative;

						.m-teaser-boxed {
							&__label-container {
								position: absolute;
								top: 15px;
								right: 15px;
								max-width: calc(100% - 24px);
								z-index: 5;
							}

							&__image-container {
								margin: 0 0 15px;
							}

							&__content-wrapper {
								margin-top: 36px;

								.button {
									.a-button {
										padding: 0;
										font-size: 16px;
										font-weight: 400;
										margin-bottom: -6px;
									}
								}
							}
						}

						.a-headline {
							margin-bottom: 6px;
							font-size: 4vw;
						}
					}
				}

				@media (max-width: 540px) {
					.m-teaser-boxed {
						.m-teaser-boxed__content-wrapper {
							.button {
								.a-button {
									.a-button__icon-wrapper {
										width: 0;
										overflow: hidden;
										padding-inline: 0;
									}
								}
							}
						}
					}
				}
			}
		}

		&.finder-result-project {
			@media (max-width: $breakpoint-small-max) {
				.uk-grid > div {
					> .m-finder-result {
						.m-finder-result__content-wrapper {
							max-width: 100%;
							position: relative;
						}

						.m-finder-result__headline-link {
							top: unset;
							bottom: 10px;
							&.with-button {
								bottom: 70px;
							}
						}

						> .m-finder-result__image {
							.image-wrapper {
								position: relative;

								&::before {
									background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 40%, rgba(33, 33, 44, 0.68) 70%, rgba(33, 33, 44, 0.78));
								}
							}
						}
					}
				}
			}

			@media (max-width: $breakpoint-xsmall-max) {
				.uk-grid > div {
					.m-finder-result-reference-mobile__content & {
						width: 100%;
					}

					> .m-finder-result {
						.m-finder-result__content-wrapper {
							max-width: 100%;
							position: relative;
						}

						.m-finder-result__headline-link {
							top: unset;
							bottom: 10px;
							&.with-button {
								bottom: 60px;
							}

							.a-headline {
								font-size: 5vw;
							}
						}
					}

					.m-finder-result-teasers {
						.m-finder-result-teasers__item-container {
							width: 50%;
						}
					}
				}
			}
		}

		&__headline {
			.a-headline {
				margin-bottom: 30px;
			}
		}

		&__links {
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
			@include font-inter-200();
			font-size: 24px;
			line-height: 28px;
			padding-top: 15px;
			padding-right: 30px;

			@media (max-width: $breakpoint-medium-max) {
				justify-content: flex-start;
				margin-bottom: 50px;
			}

			@media (max-width: $breakpoint-small-max) {
				margin-top: 0 !important;
				margin-bottom: 15px;
			}

			@media (max-width: $breakpoint-xsmall-max) {
				padding-top: 0;

				p {
					font-size: 18px;

					> .anouncement {
						display: block;
					}

					> a {
						margin-left: 0;
					}
				}
			}

			a {
				margin-left: 25px;
				font-weight: 400;
				text-decoration-color: get-color('true-black');
			}
		}

		&__text {
			margin-top: 50px;
			margin-bottom: 100px;

			@media (max-width: $breakpoint-small-max) {
				margin-top: 15px;
				margin-bottom: 31px;
			}

			p {
				margin-bottom: 0px;
			}
		}

		&__textBottom {
			@include font-inter-400();
			font-size: 18px;
			line-height: 1.5em;
			letter-spacing: 0.4px;

			&:not(.styled) p,
			&:not(.styled) li,
			&:not(.styled) ul,
			&:not(.styled) ol {
				@include font-inter-400();
				font-size: 18px;
				line-height: 1.5em;
				letter-spacing: 0.4px;
			}

			width: 80%;
			margin-top: 80px;

			@media (max-width: 650px) {
				width: 100%;
			}
		}

		&__buttonBottom {
			display: flex;
			width: 100%;
			margin-top: 40px;

			&--left {
				justify-content: flex-start;
			}

			&--center {
				justify-content: center;
			}

			&--right {
				justify-content: flex-end;
			}

			&--noText {
				margin-top: 40px;
			}
		}

		&--odd-container {
			.o-gridcontainer {
				> .uk-first-column:nth-last-of-type(2) {
					//margin-left: calc(100% * 1 / 6.001);
					margin-left: 0;

					@media (max-width: $breakpoint-xsmall-max) {
						//margin-left: 0;
					}
				}
			}

			.o-grid-container {
				&.oneRow__column-width-- {
					.o-grid-container {
						> div {
							@media (max-width: $breakpoint-xsmall-max) {
								.m-teaser-bg__headline .a-headline {
									font-size: 28px;
								}
							}
						}
					}
				}
			}
		}

		.no-scrollbar {
			&.hastext {
				margin: 0px;

				#{$name}__scrollbar-nav {
					width: 20%;

					@media (max-width: $breakpoint-small-max) {
						width: 100%;
					}
				}
			}

			&:not(.hastext) {
				#{$name}__scrollbar-nav {
					width: 100%;
				}
			}

			#{$name}__text {
				display: inline-flex;
				width: 80%;
				margin-bottom: 20px;

				@media (max-width: $breakpoint-small-max) {
					margin-top: 0px;
				}
			}

			#{$name}__scrollbar-nav {
				display: inline-flex;
				z-index: 0;
				justify-content: flex-end;
				margin-bottom: 15px;

				@media (max-width: $breakpoint-small-max) {
					width: 100%;
					justify-content: flex-start;
					margin-top: 0px;
				}

				span {
					height: 66px;
					width: 66px;
					border: 1px solid black;
					border-radius: 50%;
					cursor: pointer;
					z-index: 99;

					@media (max-width: $breakpoint-medium-max) {
						height: 44px;
						width: 44px;
					}

					svg {
						padding: 25px;

						@media (max-width: $breakpoint-medium-max) {
							padding: 15px;
						}

						pointer-events: none;
					}

					&:first-child {
						margin-right: 20px;
					}

					&:last-child {
						margin-right: 30px;
					}

					&:not([disabled]):hover {
						background-color: #060505;
						border-color: #060505;
						color: get-color('true-white');
					}

					&[disabled] {
						border: 1px solid get-color('silver-grey');

						&:hover {
							cursor: default;
							background-color: none;
							pointer-events: false;
						}

						svg {
							color: get-color('silver-grey');
						}
					}

					&[disabled] {
						opacity: 0.3;
						cursor: default;
					}
				}
			}
		}

		.o-gridcontainer {
			&:empty {
				display: none;
			}
		}

		&.teaser-default {
			.padding-standard {
				@media (max-width: $breakpoint-small-max) {
					padding-top: 24px;
					padding-bottom: 24px;
				}
			}
		}
	}
}

@include wolf-o-teaser-grid();
