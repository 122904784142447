@mixin wolf-m-popup-interview($name: '.m-popup-interview') {
	#{$name} {
		position: relative;
		.uk-modal-close-default {
			position: absolute;
			top: 40px;
			left: 40px;
			text-align: right;

			@media (max-width: $breakpoint-small-max) {
				top: 25px;
				left: 20px;
			}

			svg {
				width: 20px;
				height: 20px;
			}

			&:hover {
				color: get-color('true-black');
				font-weight: bold;
			}
		}

		&__contained-layout {
			> div {
				padding: 64px 8%;
				@media (min-width: $breakpoint-medium) {
					padding: 152px 8%;
				}
			}
		}

		&__image {
			&--full-width {
				height: 226px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				background-repeat: no-repeat;
				background-size: cover;
				@media (min-width: $breakpoint-medium) {
					height: 400px;
				}
			}
			&--contained {
				width: calc(100vw - 16%);
				height: calc(100vw - 16%);
				border-radius: 20px;
				@media (min-width: $breakpoint-medium) {
					width: 400px;
					min-width: 400px;
					height: 400px;
					min-height: 400px;
					border-radius: 10px;
				}
			}
		}

		&__top-content-wrapper {
			&--contained {
				display: flex;
				margin-bottom: 32px;
				flex-direction: column;
				@media (min-width: $breakpoint-medium) {
					flex-direction: row;
					margin-bottom: 96px;
				}
			}
		}

		&__title-wrapper {
			&--full-width {
				display: flex;
				flex-direction: column-reverse;
				padding-right: 20%;
				padding-top: 64px;
				@media (min-width: $breakpoint-medium) {
					flex-direction: column;
					padding-top: 0;
				}
			}
			&--contained {
				display: flex;
				flex-direction: column;
				margin-left: 0;
				margin-top: 32px;
				@media (min-width: $breakpoint-medium) {
					margin-left: 68px;
					margin-top: 0;
				}
			}
		}

		&__kicker {
			&--full-width {
				font-size: 20px;
				margin: 0 0 20px;
				@media (min-width: $breakpoint-medium) {
					font-size: 32px;
				}
			}
			&--contained {
				font-size: 20px;
				margin: 0 0 20px;
				@media (min-width: $breakpoint-medium) {
					font-size: 32px;
				}
			}
		}

		&__headline {
			> .a-headline {
				margin: 0 0 10px -1px;
				@media (min-width: $breakpoint-medium) {
					margin: 0 0 40px -4px;
				}
			}
			&--contained {
				> .a-headline {
					font-size: 32px;
					@media (min-width: $breakpoint-medium) {
						font-size: 70px;
					}
				}
			}
		}

		&__shortDescription {
			font-size: 14px;
			line-height: 36px;
			display: none;
			@media (min-width: $breakpoint-medium) {
				font-size: 20px;
				display: block;
			}
		}

		&__article {
			&--full-width {
				padding: 15%;
				@media (min-width: $breakpoint-medium) {
					padding: 78px 204px;
				}
			}
		}
	}
}

@include wolf-m-popup-interview();
