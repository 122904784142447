@mixin wolf-a-navlink($name: '.a-navlink') {
	#{$name} {
		padding: $global-small-gutter;
		color: map-get($colors, 'true-white');
		text-decoration: none;
		margin: 0 5px;
		font-size: $global-xsmall-font-size;

		&:hover {
			color: map-get($colors, 'true-white');
			text-decoration: none;
		}

		&--isButton {
			padding: 1rem 2rem;
			transition: all 0.2s ease-out;
			white-space: nowrap;
			background-color: map-get($colors, 'dark-blue');

			&:hover {
				background-color: map-get($colors, 'wolf-black');
				transition: all 0.2s ease-in;
			}
		}

		&--isLightTheme {
			background-color: map-get($colors, 'transparent');
			color: map-get($colors, 'true-black');
			transition: all 0.2s ease-out;

			&:hover {
				background-color: map-get($colors, 'transparent');
				color: map-get($colors, 'wolf-black');
				opacity: 0.8;
				transition: all 0.2s ease-in;
			}
		}

		.uk-badge {
			background-color: map-get($colors, 'wolf-red');
			color: map-get($colors, 'true-white');
			font-size: 12px;
			position: relative;
			top: -13px;
			right: 6px;
			min-width: 16px;
			height: 16px;

			&:hover {
				color: map-get($colors, 'true-white');
			}
		}
	}
}

@include wolf-a-navlink();
