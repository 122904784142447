@mixin wolf-m-formstep-step-title($name: '.m-formstep-step-title') {
	#{$name} {
		width: 100%;

		&__head {
			display: flex;
			justify-content: space-between;
			margin-bottom: 43px;
		}

		&__headline {
			.a-headline {
				width: 100%;
				margin: 0;
			}

			@media (min-width: $breakpoint-xsmall-max) {
				> .a-headline {
					> .a-headline {
						word-break: break-all;
					}
				}
			}

			.a-copy {
				margin-top: 20px !important;
				margin: 0;

				@media (min-width: $breakpoint-medium-max) {
					margin-top: 8px;
					font-size: 20px;
					line-height: 20px;
				}
			}

			#{$name}__more-info {
				margin-top: 8px;
				display: flex;
				align-items: flex-end;

				& .a-button {
					&__extraInfo {
						left: -135px;
						top: 50px;
						width: 300px;
						text-align: center;
					}
				}

				@media (max-width: calc(#{$breakpoint-large} + 30px)) {
					& .a-button {
						&__extraInfo {
							left: -135px;
							top: 35px;
							width: 200px;
							text-align: left;
						}
					}
				}
			}
		}

		&__step {
			display: none;
			padding-right: 37px;
			font-size: 18px;

			@media (min-width: $breakpoint-medium-max) {
				display: block;
			}
		}
	}
}

@include wolf-m-formstep-step-title();
