@mixin wolf-o-default-stage($name: '.o-default-stage') {
	#{$name} {
		&__background {
			height: auto;
			min-height: 470px;
			max-width: 1920px;
			width: 100%;
			margin: auto;
			padding-left: 0;
			padding-right: 0;
			background: none;

			@media (max-width: $breakpoint-medium) {
				height: auto;
				box-sizing: border-box;
				padding-left: 24px;
				padding-right: 24px;
			}

			&.padding-standard {
				padding-top: 110px;
				padding-bottom: 110px;

				@media (max-width: $breakpoint-small-max) {
					padding-top: 40px;
					padding-bottom: 40px;
				}
			}

			&.padding-small {
				padding-top: 55px;
				padding-bottom: 55px;

				@media (max-width: $breakpoint-small-max) {
					padding-top: 40px;
					padding-bottom: 40px;
				}
			}

			&.padding-small-top {
				padding-top: 55px;
				padding-bottom: 110px;

				@media (max-width: $breakpoint-small-max) {
					padding-top: 40px;
					padding-bottom: 80px;
				}
			}

			&.padding-small-bottom {
				padding-top: 110px;
				padding-bottom: 55px;

				@media (max-width: $breakpoint-small-max) {
					padding-top: 80px;
					padding-bottom: 40px;
				}
			}

			&.padding-big {
				padding-top: 220px;
				padding-bottom: 220px;

				@media (max-width: $breakpoint-small-max) {
					padding-top: 110px;
					padding-bottom: 110px;
				}
			}

			&.padding-big-top {
				padding-top: 220px;
				padding-bottom: 110px;

				@media (max-width: $breakpoint-small-max) {
					padding-top: 110px;
					padding-bottom: 80px;
				}
			}

			&.padding-big-bottom {
				padding-top: 110px;
				padding-bottom: 220px;

				@media (max-width: $breakpoint-small-max) {
					padding-top: 80px;
					padding-bottom: 110px;
				}
			}

			&.padding-no-padding-bottom {
				padding-top: 55px;
				padding-bottom: 0;

				@media (max-width: $breakpoint-small-max) {
					padding-top: 40px;
					padding-bottom: 0;
				}
			}

			&.padding-standard,
			&.padding-small,
			&.padding-small-top,
			&.padding-small-bottom,
			&.padding-big,
			&.padding-big-top,
			&.padding-big-bottom,
			&.padding-no-padding-bottom {
				@media (max-width: $breakpoint-small-max) {
					#{$name}__header {
						margin-top: 50px;
						margin-bottom: 0;
					}
				}
			}
		}

		&--light {
			#{$name}__wrapper {
				background-color: get-color(true-white);
			}
		}

		&--dark {
			#{$name}__wrapper {
				background-color: get-color(wolf-black);
			}
		}

		&--has-background-image {
			#{$name}__wrapper {
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
			}
		}

		&__container {
			width: 100%;
			margin: auto;
		}

		&__header {
			position: relative;
			margin-top: 120px;
			margin-bottom: 200px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			/*Width changes*/
			padding-left: 0;
			padding-right: 0;

			@media (max-width: $breakpoint-small-max) {
				margin-bottom: 120px;
				// padding-right: 7%;
			}
		}

		&__breadcrumbs {
			color: white;
		}

		@media (max-width: $breakpoint-medium) {
			& .uk-container {
				padding-left: 0;
			}
		}

		&__wrapper {
			width: 100%;
			overflow: hidden;

			@media (min-width: 1935px) {
				position: relative;
				left: 0;
				right: 0;
				margin: 0 auto;
				overflow: hidden;
			}

			.image-wrapper {
				> img {
					object-fit: cover;
					height: 100%;
					max-width: none;
				}
			}
		}

		&___button-back {
			left: inherit;

			@media (max-width: 1919px) {
				left: 2%;
			}

			@media (max-width: 1739px) {
				left: 5%;
			}

			@media (max-width: 1634px) {
				left: 8%;
			}
		}

		& .m-breadcrumb {
			padding: 32px 0 32px 10%;
			max-width: $global-max-width;
			margin: 0 auto;
			background-color: get-color('true-white');
		}
	}
}

@include wolf-o-default-stage();
