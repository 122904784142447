@mixin wolf-o-article($name: '.o-article') {
	#{$name} {
		margin-top: 2rem;
		display: flex;
		max-width: $global-max-width;
		justify-content: center;
		margin: 0 auto;

		* + ul {
			margin: 0 0 16px 0;
		}

		p {
			margin-top: 16px;
		}

		* + p {
			margin-bottom: 16px;
		}

		ul p,
		ol p {
			margin: 0px;
		}

		&__index-nav-wrapper {
			width: 0;
			margin-top: 40px;
			margin-left: 20px;
		}
		&__article {
			& h5 {
				color: map-get($colors, 'wolf-black');
				@media (max-width: $breakpoint-medium) {
					margin-top: 1rem;
				}
				margin-bottom: 14px;
			}

			& img {
				width: 100%;
			}
		}

		&__article-wrapper {
			margin-bottom: 130px;
			@media (max-width: $breakpoint-medium) {
				margin-bottom: 52px;
			}
		}

		&__container {
			max-width: 1430px;
			overflow: hidden;
			padding: 3rem 1rem;

			@media (max-width: $breakpoint-medium) {
				margin-left: 0;
				padding: 1rem 30px;
			}
		}

		&__anchor {
			position: relative;
			top: -75px;
		}
	}
}

@include wolf-o-article();
