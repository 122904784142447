@mixin wolf-m-teaser-square-icon($name: '.m-teaser-square-icon') {
	#{$name} {
		&__inner {
			padding: 30px;
		}

		&__icon {
			margin: auto;
			width: 140px;
			height: 140px;
			margin-bottom: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__headline {
			.a-headline {
				color: get-color('wolf-black');
				margin-bottom: 50px;
			}
		}

		&__link {
			a {
				@include font-inter-400();
				color: get-color('wolf-red');
				font-size: 16px;
				text-decoration: none;
			}
		}

		&__span {
			> svg {
				width: 140px;
				min-width: 140px;
			}
		}

		&.styleSettings {
			#{$name}__link {
				a {
					color: inherit;
				}
			}

			& > div {
				display: flex;
				flex-direction: column;
				min-height: 100%;

				.uk-container {
					display: flex;
					flex-direction: row;
					flex: 1;
					min-width: 100%;

					#{$name}__inner {
						align-items: flex-start;
						flex-direction: column;
						width: 100%;
						margin-bottom: auto;

						&__icon {
							margin-top: 30px;
						}
					}
				}
			}
		}

		&:not(.styleSettings) {
			&__inner {
				background-color: get-color('true-white');
			}
		}
	}
}

@include wolf-m-teaser-square-icon();
