@mixin wolf-m-step-navigation($name: '.m-step-navigation') {
	#{$name} {
		display: flex;
		margin-top: 75px;
		margin-left: -10px; //Counting for offset on first element center, moving full molecule to the left to offset it.

		@media (max-width: $breakpoint-medium) {
			margin-top: 60px;
			justify-content: center;
		}

		&__step-wrap {
			&:not(:first-child) {
				margin-left: 31px;
			}

			min-width: 80px;
			max-width: 80px;
			text-align: center;
		}

		&__step--done {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;

			a {
				> span {
					display: inline-block;
					position: absolute;

					&:before {
						width: 58px;
						content: '';
						height: 1px;
						display: inline-block;
						background: get-color('accent');
						position: relative;
						top: 12px;
						right: 84px;
					}
				}
			}

			div {
				width: 21px;
				height: 21px;
				padding: 15px;
				border: solid 1px get-color('accent');
				border-radius: 50px;
				position: relative;

				span {
					width: 16px;
					height: 16px;
					color: get-color('accent');
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					display: inline-flex;
					align-items: center;
					justify-content: center;
				}
			}
		}

		&__step--current {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;

			> span {
				display: inline-block;
				position: absolute;

				&:before {
					width: 58px;
					content: '';
					height: 1px;
					display: inline-block;
					background: get-color('wolf-red');
					position: relative;
					top: 12px;
					right: 55px;
				}
			}

			div {
				width: 21px;
				height: 21px;
				padding: 15px;
				border: solid 1px get-color('wolf-red');
				border-radius: 50px;
				position: relative;
				// display: grid;
				// place-items: center;

				span {
					font-weight: bold;
					color: get-color('wolf-red');
					font-size: 18px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}

		&__step--open {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;

			> span {
				display: inline-block;
				position: absolute;

				&:before {
					width: 58px;
					content: '';
					height: 1px;
					display: inline-block;
					background: get-color('silver-grey');
					position: relative;
					top: 12px;
					right: 55px;
				}
			}

			div {
				width: 21px;
				height: 21px;
				padding: 15px;
				border: solid 1px get-color('silver-grey');
				border-radius: 50%;
				position: relative;

				span {
					color: get-color('dark-grey');
					font-size: 18px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}

		&__label {
			margin-top: 12px;
			color: get-color('dark-grey');
		}

		&__label--done {
			margin-top: 12px;
			color: get-color('accent');
		}
	}
}

@include wolf-m-step-navigation();
