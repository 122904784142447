@mixin wolf-m-application-step($name: '.m-application-step') {
	#{$name} {
		&__step-content {
			padding: 0;

			@media (min-width: $breakpoint-medium) {
				padding: 70px;
			}
		}

		&__index-line {
			display: flex;
			align-items: center;

			&-number {
				width: 28px;
				min-width: 28px;
				height: 28px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				background-color: get-color(true-black);
				color: white;

				@media (min-width: $breakpoint-medium) {
					width: 42px;
					min-width: 42px;
					height: 42px;
				}
			}

			&-stroke {
				height: 1px;
				width: calc(100% - 16px);
				background-color: get-color(true-black);
				margin-left: 16px;

				@media (min-width: $breakpoint-medium) {
					margin-left: 0;
				}
			}
		}

		&__main-content {
			display: flex;
			padding-left: 64px;
			max-width: 100%;

			&-title {
				font-size: 14px;
				font-weight: bold;
				margin: 0 0 32px 0;

				@media (min-width: $breakpoint-medium) {
					font-size: 28px;
				}
			}

			&-description {
				font-size: 14px;
				align-self: flex-start;

				@media (min-width: $breakpoint-medium) {
					font-size: 24px;
				}
			}

			&-extra-info-modal-trigger {
				color: get-color(light-blue);
				font-weight: 600;
				margin: 32px 0;
			}

			& .a-copy:not(:first-child) {
				margin: 0;
			}

			@media (min-width: $breakpoint-medium) {
				width: 40%;
				max-width: 40%;
				padding-right: 64px;
				padding-left: 96px;
			}
		}

		&__questions-wrapper {
			display: flex;
			flex-direction: column;

			@media (min-width: $breakpoint-medium) {
				width: 25%;
				max-width: 25%;
				padding-right: 64px;
			}
		}

		&__answers-title {
			font-size: 16px;
			font-weight: bold;
			margin: 0;
		}

		&__questions-list {
			font-size: 14px;
			max-width: 100%;
			padding: 0;

			& :not(.uk-open) {
				a {
					color: get-color(dark-grey);
				}
			}
		}

		&__question {
			font-size: 14px;
			@include font-inter-700;
			text-decoration: unset;
			margin: 30px 0 0;

			@media (min-width: $breakpoint-medium) {
				font-size: 16px;
				margin: 0 0 30px 0;
			}
		}

		&__answer {
			font-size: 14px;
			color: get-color(dark-grey);
			line-height: 28px;

			@media (min-width: $breakpoint-medium) {
				font-size: 16px;
			}
		}

		&__tip-container {
			margin-top: 32px;
			padding-top: 0px;
			border-top: 1px solid get-color(light-blue-seo);
		}

		&__tip {
			max-width: 100%;
			font-size: 16px;
			color: get-color(light-blue-seo);

			&-icon {
				text-align: left;
				margin: 32px 0 32px 0;

				& svg {
					height: 40px;
					width: 40px;
				}
			}

			&-title {
				text-align: left;
				@include font-saira-700;
				margin-top: 0;
				margin-bottom: 0;
				color: get-color(light-blue-seo);
				margin: 40px 0 32px 0;
				font-size: 24px;
			}

			&-content {
				color: get-color(light-blue-seo);
				margin: 0;
				line-height: 28px;
			}

			@media (min-width: $breakpoint-medium) {
				width: 15%;
				max-width: 15%;

				&-icon,
				&-title {
					margin-top: 0;
					font-size: 28px;
				}
			}
		}

		&__modal {
			padding: 44px 0px 0px 0px;

			& .uk-modal-close-default {
				left: 20px;
				position: absolute;
			}

			&-body {
				position: absolute;
				overflow-y: scroll;
				max-height: 80%;
				bottom: 0;
				width: 100%;
				margin: 0px auto;
				padding: 32px 55px;
				border-top-right-radius: 15px;
				border-top-left-radius: 15px;
			}
		}
	}
}

@include wolf-m-application-step();
