@mixin wolf-m-headline-stage($name: '.m-headline-stage') {
	#{$name} {
		position: relative;
		height: 100vh;

		color: get-color('true-white');
		margin-bottom: 20px;

		@include min-md {
			margin-bottom: 40px;
		}

		@include min-lg {
			margin-bottom: 95px;
		}

		@media (min-width: 1921px) and (-webkit-max-device-pixel-ratio: 1) {
			max-height: 929px;
		}

		&::after {
			content: '';
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			background-color: rgba(0, 0, 0, 0.5);
		}

		.image-wrapper {
			position: absolute;
			overflow: hidden;
			width: 100%;
			height: 100%;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			object-fit: cover;
			> img {
				@media (max-width: $breakpoint-medium-max) {
					height: 100%;
					max-width: none;
				}
			}
		}

		.uk-container {
			position: relative;
			z-index: 2;
			padding: 0 $mobileGutter * 2;
			width: 100%;

			@include min-md {
				padding: 0 $gutter * 2;
			}

			@include min-lg {
				padding: 0 $gutter;
			}
		}

		.left-side {
			@include only-mobile {
				@media (orientation: landscape) {
					padding-top: 24px;
				}
			}

			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			div {
				&.a-headline {
					margin: 0 0 9vh;
					line-height: 1;
					color: get-color('true-white');

					@include only-mobile {
						@media (orientation: landscape) {
							margin: 0 0 6vh;
						}
					}

					@include min-md {
						margin: 0;
					}
				}
			}

			h1.a-headline {
				margin-top: 0;
			}

			h2.a-headline {
				margin-top: 2px;
			}

			h3.a-headline {
				margin-top: 5px;
			}
		}

		.right-side {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			div {
				&.a-headline {
					margin: 0 0 15px;
					line-height: 1;
					color: get-color('true-white');

					@include min-md {
						margin: 0 0 30px;
					}

					@include min-lg {
						margin: 0 0 60px;
					}
				}
			}

			h1.a-headline {
				margin-top: 0;
			}

			h2.a-headline {
				margin-top: 2px;
			}

			h3.a-headline {
				margin-top: 5px;
			}
		}

		.content {
			margin-bottom: 80px;

			@include only-mobile {
				@media (orientation: landscape) {
					margin: 0 0 50px;
				}
			}

			@include min-md {
				margin-bottom: unset;
			}
		}

		p {
			font-family: 'Inter';
			font-weight: 400;
			font-size: 18px;
			line-height: 1.5em;
			letter-spacing: 0.4px;

			@include min-md {
				font-size: 18px;
			}

			@include min-lg {
				font-size: 20px;
			}
		}
	}
}

@include wolf-m-headline-stage();
