@mixin wolf-o-checklist($name: '.o-checklist') {
	#{$name} {
		> * {
			padding-left: 200px;
			padding-right: 200px;

			@media (max-width: 1600px) {
				padding-left: 100px;
				padding-right: 100px;
			}

			@media (max-width: 1200px) {
				padding-left: 50px;
				padding-right: 50px;
			}

			@media (max-width: 700px) {
				padding-left: 25px;
				padding-right: 25px;
			}
		}

		&.default-gradient {
			position: relative;

			.uk-container {
				position: relative;
			}
		}

		.image-wrapper {
			&::before {
				background: linear-gradient(90deg, rgba(33, 33, 44, 0.78) 0%, rgba(33, 33, 44, 0.68) 30%, rgba(255, 255, 255, 0) 70%);
			}

			&.image-loader {
				&::before {
					content: none;
				}
			}
			@media (max-width: 768px) {
				> img {
					max-width: none;
					height: 100%;
				}
			}
		}

		.a-headline {
			width: 85%;

			@media (max-width: 700px) {
				width: 100%;
			}

			@media (max-width: 460px) {
				.a-headline {
					h1,
					h2,
					h3,
					h4,
					h5,
					h6 {
						font-size: 36px;
						margin: 0;
					}
				}
			}

			* {
				width: 100%;
			}
		}

		&__subheadline {
			@include font-inter-400();
			font-size: 26px;
			line-height: 28px;
			letter-spacing: -0.2px;
			width: 85%;
			margin-top: 0px;
			margin-bottom: 0px;

			@media (max-width: 700px) {
				width: 100%;
			}
		}

		.a-copy {
			width: 70%;
			margin: 75px 0px 0px 0px;

			@media (max-width: 700px) {
				width: 100%;
			}

			@media (max-width: 460px) {
				margin: 35px 0 0;
			}

			a {
				color: get-color('true-white');

				&:hover {
					color: get-color('wolf-red');
				}
			}
		}

		&__checklist {
			margin-top: 100px;

			.m-cb-checklist p {
				width: 85%;
			}
		}

		.uk-grid-large > .uk-grid-margin {
			margin-top: 50px;
		}
	}
}

@include wolf-o-checklist();
