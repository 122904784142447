$bar-menu-top-padding: 19vh;
$custom-padding-left: 172px;

@mixin wolf-m-nav-burger-menu($name: '.m-nav-burger-menu') {
	#{$name} {
		display: flex;
		flex-direction: column;
		max-height: 100vh;
		margin-right: 12px;

		&__offcanvas {
			.m-nav-offcanvas__canvas {
				justify-content: flex-start;
				padding: $bar-menu-top-padding 0 0 $custom-padding-left;
			}
		}

		&__language-switcher {
			min-height: 48px;
			position: absolute;
			left: $custom-padding-left;
			top: 26px;
			display: flex;
			align-items: center;
			margin: 0;
			@include font-inter-400;
			font-size: 20px;
			text-decoration: none;

			&:hover {
				text-decoration: none;
			}

			svg {
				fill: get-color('true-white');
				width: 12px;
				margin-left: 6px;
			}
		}

		&__back-link {
			@include font-inter-400;
			font-size: 20px;
			background-color: transparent !important;
			border: none;
			color: get-color('true-white') !important;

			position: absolute;
			top: 25px;
			padding: 0;
			align-items: center;
			margin-left: 10vw;

			.uk-icon {
				padding: 0 10px;
			}

			&:hover {
				opacity: 0.6;
			}
		}

		&__menu {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			height: 100%;

			&-2 {
				margin-top: 13vh;
			}

			&--items {
				li.uk-parent,
				li.list-title-no-child {
					height: 80px;
					display: flex;
					align-items: baseline;
					width: 300px;
					transition: width 0.2s ease-in-out;

					> a {
						padding: 0;
						color: get-color('true-white');

						> span {
							@include font-inter-400;
							font-size: 40px;
						}
					}

					&.uk-open {
						width: 860px;
						min-width: 860px;

						a > span {
							color: get-color('true-white');
						}
					}
				}
			}

			&--sub-items {
				position: absolute;
				left: 568px;
				top: $bar-menu-top-padding;

				padding: 10px 0 5px 15px !important;

				li {
					height: 79px;
					display: flex;
					align-items: baseline;

					> a {
						> span {
							@include font-inter-400;
							color: get-color('true-white');
							white-space: nowrap;
							font-size: 28px;
						}
					}
				}
			}

			&--additional-items {
				@include font-inter-400;
				font-size: 20px;
				color: get-color('true-white');
				width: fit-content;
				margin-top: 20px;
				padding-top: 16px;
				border-top: 1px solid get-color('true-white');

				@media (max-width: $breakpoint-xlarge) {
					bottom: 18vh;
				}
			}

			&--social-media {
				margin-top: 10.6vh;
				margin-bottom: 40px;

				&.a-socialmedia {
					gap: 0;
					> a {
						color: get-color('true-white');
						width: 48px;
						padding: 10px;
						box-sizing: border-box;
					}
					svg {
						color: get-color('true-white');
						min-width: 28px;
						width: 28px;
						opacity: 1;
					}
				}
				a {
					&:not(:last-child) {
						margin-right: 0;
					}
				}

				svg {
					fill: get-color('true-white');
					width: 30px;
				}
			}

			span.uk-icon {
				max-width: 20px;
			}
		}
	}
}

@include wolf-m-nav-burger-menu();
