@mixin display($val) {
	#{$val} {
		&-flex {
			display: flex !important;
		}

		&-inline-flex {
			display: inline-flex !important;
		}

		&-block {
			display: block !important;
		}

		&-inline-block {
			display: inline-block !important;
		}

		&-table {
			display: table !important;
		}

		&-table-row {
			display: table-row !important;
		}

		&-table-cell {
			display: table-cell !important;
		}

		&-none {
			display: none !important;
		}

		&-flex {
			&-row {
				flex-direction: row !important;
			}

			&-row-reverse {
				flex-direction: row-reverse !important;
			}

			&-column {
				flex-direction: column !important;
			}

			&-column-reverse {
				flex-direction: column-reverse !important;
			}

			&-wrap {
				flex-wrap: wrap !important;
			}

			&-nowrap {
				flex-wrap: nowrap !important;
			}
		}
	}
}
