@mixin wolf-m-product-gallery-details($name: '.m-product-gallery-details') {
	#{$name} {
		&__headline {
			> h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				margin: 0 auto 20px;
			}
		}
		&__subHeadline {
			> h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				padding-bottom: 20px;
				margin: 0 auto 45px;
				border-bottom: 1px solid #eaeaea;
			}
		}
		&__claim {
			> h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				margin: 0px auto 20px 0;
			}
		}
		&__description {
			font-weight: 300;
			line-height: 1.5;
		}

		&__attributes {
			display: grid;
			grid-template-columns: 1fr;
			column-gap: 20px;
			row-gap: 0px;
			position: relative;

			&.truncated {
				&::after {
					background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 65%, rgba(255, 255, 255, 1) 100%);
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					height: 50px;
					content: '';
					z-index: 1;
				}
			}

			.item {
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid #eaeaea;
				padding: 12px 0;
				font-weight: 300;

				span.label {
					@include font-inter-400;
				}
				span.value {
					@include font-inter-300;
				}
			}
		}

		&__toggler {
			margin: 20px 0 50px 0;
			text-align: center;

			.toggle-button {
				@include font-inter-500;
				font-size: 16px;
				line-height: normal;
				display: inline-block;
				padding: 12px 50px;
				border-radius: 22px;
				background-color: #efefef;
				color: #5e6673;
				text-decoration: none;
				&:hover {
					text-decoration: none;
					background-color: darken(#efefef, 5%);
				}
			}
		}

		&__bottomButtonWrapper {
			margin: 32px 0;
		}
		&__bottomButton {
			&.a-button {
				width: 100%;
				display: flex;
				justify-content: center;
			}
		}
		&__bottomInfoText {
			> * {
				font-weight: 300;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		&__bottomLink {
			> * {
				&:last-child {
					margin-bottom: 0;
				}
			}
			> a {
				@include font-inter-600;
				font-size: 20px;
				line-height: normal;
				color: get-color('wolf-black');
			}
		}
		&__bottomCopyWrapper {
			margin: 32px 0 0;
		}
		&__bottomCopy {
			font-weight: 300;
		}
	}
}

@include wolf-m-product-gallery-details();
