@mixin wolf-o-text-image-scrollable($name: '.o-text-image-scrollable') {
	#{$name} {
		&__left-container {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			@media (max-width: $breakpoint-small-max) {
				max-height: fit-content;
			}

			@media (min-width: $breakpoint-medium) {
				padding-top: 30vh;
				position: sticky;
				top: 0;
				min-height: 600px;
				max-height: 100vh;
			}

			> div {
				display: flex;
				align-items: left;
				margin-top: 0px;
				max-width: 630px;
			}

			.a-headline {
				margin: 0px;
				@media (max-width: $breakpoint-small-max) {
					padding-left: 15px;
					padding-right: 15px;
					body.page-karriere & {
						padding-top: 24px;
						padding-inline: 15px;
					}
				}
			}

			.a-copy {
				@include font-inter-400();
				font-size: 32px;
				line-height: 47px;
				margin: 95px 0px 0px 0px;
				@media (max-width: $breakpoint-small-max) {
					font-size: 20px;
					line-height: 28px;
					margin-top: 24px;
					body.page-karriere & {
						padding-inline: 14px;
					}
					p {
						margin-bottom: 14px;
					}
				}
			}
		}

		&__right-container {
			height: 100%;
			display: flex;
			flex-direction: column;
			&::-webkit-scrollbar {
				display: none;
			}
			@media (max-width: $breakpoint-small-max) {
				flex-direction: row;
				overflow-x: scroll;
				scroll-snap-type: x mandatory;
				scroll-snap-align: start;
			}
			.teaser {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding: 145px 130px 20px;
				@media (max-width: $breakpoint-large-max) {
					padding: 130px 10% 20px;
				}
				@media (max-width: $breakpoint-medium-max) {
					padding: 130px 5% 20px;
				}
				@media (max-width: $breakpoint-small-max) {
					padding: 30px 15px 35px;
					min-width: 300px;
					&:first-child {
						padding-left: 15px;
					}
				}

				.image-wrapper {
					position: relative;
					@media (max-width: $breakpoint-small-max) {
						max-height: 220px;
					}
				}

				.numering {
					@include font-inter-400();
					font-size: 22px;
					line-height: 22px;
					margin: 40px 0 0 0;
				}

				> .a-headline {
					align-items: flex-start;
					margin: 20px 0 0 0;
					width: 80%;
					font-weight: 400;
					@media (max-width: $breakpoint-small-max) {
						width: 100%;
						margin-top: 15px;
					}
				}

				.a-copy {
					align-items: flex-start;
					margin: 45px 0 0 0;
					width: 80%;
					@media (max-width: $breakpoint-large-max) {
						width: 100%;
					}
					@media (max-width: $breakpoint-small-max) {
						margin-top: 15px;
						font-weight: 400;
						margin-bottom: auto;
						p {
							&:empty {
								display: none;
								height: 0;
							}
						}
					}
				}
			}
		}

		@media (max-width: $breakpoint-small-max) {
			> .padding-small-top {
				padding-top: 24px;
				padding-bottom: 0;
			}
			> .padding-standard {
				padding-top: 0;
				padding-bottom: 0;
				> .uk-container {
					padding-left: 0;
					padding-right: 0;
				}
			}
			border-bottom: solid 1px #dddddd;
		}
		~ #{$name} {
			border-top: solid 1px #dddddd;
			padding-top: 24px;
			margin-top: -1px;
		}
	}
}

@include wolf-o-text-image-scrollable();
