@mixin wolf-o-text-media($name: '.o-text-media') {
	#{$name} {
		&__image-circle {
			display: flex;
			flex: none !important;
			align-items: center;

			border: 1px solid get-color(wolf-red);
			object-fit: cover;

			background: center center no-repeat scroll;
			background-size: cover;

			border-radius: 50%;
			overflow: hidden;

			margin: 0 auto;
			width: 190px !important;
			height: 190px;

			@media (max-width: $breakpoint-medium) {
				width: 150px !important;
				height: 150px;
				margin-top: 0;
			}

			.image-wrapper {
				position: relative;
			}
		}

		&__topline {
			margin-bottom: 15px;
			color: get-color(dark-blue-30-white);
		}

		& .a-copy {
			margin-bottom: 40px;
		}

		&__circle-icon {
			border: 1px solid get-color(dark-blue-30-white);
			border-radius: 50%;
			height: 45px;
			width: 45px;
			margin-right: 30px;

			svg {
				height: 25px;
				width: 25px;
				color: get-color(wolf-red);
				margin: 10px;
			}
		}

		& .a-button {
			padding: 10px 20px;

			&.has-icon:not(.only-icon) {
				padding: 10px 20px;
			}

			.a-button-text {
				margin: 0px;
			}

			.a-button__icon-wrapper {
				padding: 0px 15px;
			}
		}

		&__text-wrapper {
			@media (max-width: $breakpoint-medium) {
				padding: 0 25px;
				text-align: center;
			}
		}
	}

	.padding-background-both {
		padding-bottom: 170px;
		padding-top: 170px;

		@media (max-width: $breakpoint-small-max) {
			padding-bottom: 50px;
			padding-top: 50px;
		}
	}
}

@include wolf-o-text-media();
