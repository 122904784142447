@mixin wolf-o-pdf-list($name: '.o-pdf-list') {
	#{$name} {
		&__listing {
			margin: 0 0 50px;

			@media (max-width: $breakpoint-medium-max) {
				:first-child() {
					border-top: 0px;
				}
			}
		}
	}
}

@include wolf-o-pdf-list();
