@mixin wolf-m-formstep-warranty-step-3($name: '.m-formstep-warranty-step-3') {
	#{$name} {
		& > div:first-of-type {
			max-width: 1000px;
		}

		&__formstep-3-wrapper {
			max-width: 869px;
			max-height: 597px;
			padding: 0 150px 30px 0;
			overflow-y: scroll;

			@media (max-width: $breakpoint-medium-max) {
				padding-right: 0;
			}

			#{$name}__input-wrapper {
				margin-top: 33px;

				.uk-input,
				.uk-select {
					background-color: transparent;
					border: 1px solid #62677e;

					&::placeholder {
						@include font-inter-300;
						color: get-color('silver-grey');
						opacity: 0.2;
					}
					&:focus,
					&:active {
						background-color: 'transparent';
					}
				}
			}
		}
	}
}

@include wolf-m-formstep-warranty-step-3();
