// Name:            Base
// Description:     Default values for HTML elements
//
// Component:       `uk-link`
//                  `uk-h1`, `uk-h2`, `uk-h3`, `uk-h4`, `uk-h5`, `uk-h6`
//                  `uk-hr`
//
// ========================================================================

@mixin hook-base-body() {
	font-size: get-fontsize(s, default);

	@media (min-width: $breakpoint-medium) {
		font-size: $base-body-font-size;
	}
}
// @mixin hook-base-link(){}
// @mixin hook-base-link-hover(){}
// @mixin hook-base-code(){}
// @mixin hook-base-heading(){}
@mixin hook-base-h1() {
	@include font-saira-500();
	font-size: $base-h1-font-size-m;
	line-height: $base-h1-line-height-m;
	letter-spacing: $base-h1-letter-spacing-m;

	@media (min-width: $breakpoint-medium) {
		font-size: $base-h1-font-size;
		line-height: $base-h1-line-height;
		letter-spacing: $base-h1-letter-spacing;
	}
}
@mixin hook-base-h2() {
	@include font-saira-500();
	font-size: $base-h2-font-size-m;
	line-height: $base-h2-line-height-m;
	letter-spacing: $base-h2-letter-spacing-m;

	@media (min-width: $breakpoint-medium) {
		font-size: $base-h2-font-size;
		line-height: $base-h2-line-height;
		letter-spacing: $base-h2-letter-spacing;
	}
}
@mixin hook-base-h3() {
	@include font-saira-500();
	font-size: $base-h3-font-size-m;
	line-height: $base-h3-line-height-m;
	letter-spacing: $base-h3-letter-spacing-m;

	@media (min-width: $breakpoint-medium) {
		font-size: $base-h3-font-size;
		line-height: $base-h3-line-height;
		letter-spacing: $base-h3-letter-spacing;
	}
}
@mixin hook-base-h4() {
	@include font-saira-500();
	font-size: $base-h4-font-size-m;
	line-height: $base-h4-line-height-m;
	letter-spacing: $base-h4-letter-spacing-m;

	@media (min-width: $breakpoint-medium) {
		font-size: $base-h4-font-size;
		line-height: $base-h4-line-height;
		letter-spacing: $base-h4-letter-spacing;
	}
}
@mixin hook-base-h5() {
	@include font-saira-500();
	font-size: $base-h5-font-size-m;
	line-height: $base-h5-line-height-m;
	letter-spacing: $base-h5-letter-spacing-m;

	@media (min-width: $breakpoint-medium) {
		font-size: $base-h5-font-size;
		line-height: $base-h5-line-height;
		letter-spacing: $base-h5-letter-spacing;
	}
}
@mixin hook-base-h6() {
	@include font-saira-500();
	font-size: $base-h6-font-size-m;
	line-height: $base-h6-line-height-m;
	letter-spacing: $base-h6-letter-spacing-m;

	@media (min-width: $breakpoint-medium) {
		font-size: $base-h6-font-size;
		line-height: $base-h6-line-height;
		letter-spacing: $base-h6-letter-spacing;
	}
}

@mixin hook-inter-h1() {
	@include font-inter-600();
	font-size: $inter-h1-font-size-m;
	line-height: $inter-h1-line-height-m;
	letter-spacing: $inter-h1-letter-spacing-m;

	@media (min-width: $breakpoint-medium) {
		font-size: $inter-h1-font-size;
		line-height: $inter-h1-line-height;
		letter-spacing: $inter-h1-letter-spacing;
	}
}
@mixin hook-inter-h2() {
	@include font-inter-600();
	font-size: $inter-h2-font-size-m;
	line-height: $inter-h2-line-height-m;
	letter-spacing: $inter-h2-letter-spacing-m;

	@media (min-width: $breakpoint-medium) {
		font-size: $inter-h2-font-size;
		line-height: $inter-h2-line-height;
		letter-spacing: $inter-h2-letter-spacing;
	}
}
@mixin hook-inter-h3() {
	@include font-inter-600();
	font-size: $inter-h3-font-size-m;
	line-height: $inter-h3-line-height-m;
	letter-spacing: $inter-h3-letter-spacing-m;

	@media (min-width: $breakpoint-medium) {
		font-size: $inter-h3-font-size;
		line-height: $inter-h3-line-height;
		letter-spacing: $inter-h3-letter-spacing;
	}
}
@mixin hook-inter-h4() {
	@include font-inter-600();
	font-size: $inter-h4-font-size-m;
	line-height: $inter-h4-line-height-m;
	letter-spacing: $inter-h4-letter-spacing-m;

	@media (min-width: $breakpoint-medium) {
		font-size: $inter-h4-font-size;
		line-height: $inter-h4-line-height;
		letter-spacing: $inter-h4-letter-spacing;
	}
}
@mixin hook-inter-h5() {
	@include font-inter-500();
	font-size: $inter-h5-font-size-m;
	line-height: $inter-h5-line-height-m;
	letter-spacing: $inter-h5-letter-spacing-m;

	@media (min-width: $breakpoint-medium) {
		font-size: $inter-h5-font-size;
		line-height: $inter-h5-line-height;
		letter-spacing: $inter-h5-letter-spacing;
	}
}
@mixin hook-inter-h6() {
	@include font-inter-600();
	font-size: $inter-h6-font-size-m;
	line-height: $inter-h6-line-height-m;
	letter-spacing: $inter-h6-letter-spacing-m;

	@media (min-width: $breakpoint-medium) {
		font-size: $inter-h6-font-size;
		line-height: $inter-h6-line-height;
		letter-spacing: $inter-h6-letter-spacing;
	}
}
// @mixin hook-base-hr(){}
// @mixin hook-base-blockquote(){}
// @mixin hook-base-blockquote-footer(){}
// @mixin hook-base-pre(){}
// @mixin hook-base-misc(){}

// @mixin hook-inverse-base-link(){}
// @mixin hook-inverse-base-link-hover(){}
// @mixin hook-inverse-base-code(){}
// @mixin hook-inverse-base-heading(){}
// @mixin hook-inverse-base-h1(){}
// @mixin hook-inverse-base-h2(){}
// @mixin hook-inverse-base-h3(){}
// @mixin hook-inverse-base-h4(){}
// @mixin hook-inverse-base-h5(){}
// @mixin hook-inverse-base-h6(){}
// @mixin hook-inverse-base-blockquote(){}
// @mixin hook-inverse-base-blockquote-footer(){}
// @mixin hook-inverse-base-hr(){}
