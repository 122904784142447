@mixin wolf-m-job-offer-area-content($name: '.m-job-offer-area-content') {
	#{$name} {
		&__jobs-wrapper {
			& .m-job-offer-item:first-of-type {
				.m-job-offer-item__divider:first-of-type {
					@media (max-width: $breakpoint-xsmall-max) {
						display: block !important;
					}
				}
			}
		}

		&__load-more {
			display: grid;
			place-content: center;
			margin-top: 65px;

			.a-button {
				@include font-inter-400;
				border: none;
				padding: 0;
				text-decoration: underline;
				// margin-bottom: 90px;

				&:hover,
				&:active,
				&:focus {
					outline: none;
					background-color: transparent;
					color: get-color('primary');
				}
			}
		}
	}
}

@include wolf-m-job-offer-area-content();
