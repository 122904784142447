// Name:            Button
// Description:     Styles for buttons
//
// Component:       `uk-button`
//
// Sub-objects:     `uk-button-group`
//
// Modifiers:       `uk-button-default`
//                  `uk-button-primary`
//                  `uk-button-secondary`
//                  `uk-button-danger`
//                  `uk-button-text`
//                  `uk-button-link`
//                  `uk-button-small`
//                  `uk-button-large`
//
// States:          `uk-active`
//
// ========================================================================

// Hooks
// ========================================================================

@mixin hook-button() {
	transition: all 100ms ease-out;
}

// @mixin hook-button-hover(){}

@mixin hook-button-focus() {
	&:not(:active) {
		outline: 1px solid get-color(secondary);
		outline-offset: 3px;
	}
}

// @mixin hook-button-active(){}

@mixin hook-button-default() {
	border: 1px solid $button-default-color;
}

@mixin hook-button-default-hover() {
	border-color: $button-default-hover-color;
}

@mixin hook-button-default-active() {
	box-shadow: inset 0 0 0 1px $button-default-active-color;
}

@mixin hook-button-primary() {
	border: 1px solid $button-primary-color;
}
//@mixin hook-button-primary-hover(){}

@mixin hook-button-primary-active() {
	box-shadow: inset 0 0 0 2px $button-primary-hover-background;
}

// @mixin hook-button-secondary(){}
// @mixin hook-button-secondary-hover(){}

@mixin hook-button-secondary-active() {
	box-shadow: inset 0 0 0 2px $button-secondary-hover-background;
}

// @mixin hook-button-danger(){}
// @mixin hook-button-danger-hover(){}
// @mixin hook-button-danger-active(){}
// @mixin hook-button-disabled() {}
// @mixin hook-button-small(){}
// @mixin hook-button-large(){}
// @mixin hook-button-text(){}
// @mixin hook-button-text-hover(){}
// @mixin hook-button-text-disabled(){}
// @mixin hook-button-link(){}
// @mixin hook-button-misc(){}

@mixin hook-button-primary-blue() {
	border: 1px solid $button-primary-blue-color;
}

/*
 * -blue variants need additional .uk-button classs prefix to avoid specificity issues with the color attribute
 */

/*
 * Default-blue
 */

.uk-button.uk-button-default-blue {
	background-color: $button-default-blue-background;
	color: $button-default-blue-color;
	@if (mixin-exists(hook-button-default-blue)) {
		@include hook-button-default-blue();
	}
}

/* Hover + Focus */
.uk-button.uk-button-default-blue:hover,
.uk-button.uk-button-default-blue:focus {
	background-color: $button-default-blue-hover-background;
	color: $button-default-blue-hover-color;
	@if (mixin-exists(hook-button-default-blue-hover)) {
		@include hook-button-default-blue-hover();
	}
}

/* OnClick + Active */
.uk-button.uk-button-default-blue:active,
.uk-button.uk-button-default-blue.uk-active {
	background-color: $button-default-blue-active-background;
	color: $button-default-blue-active-color;
	@if (mixin-exists(hook-button-default-blue-active)) {
		@include hook-button-default-blue-active();
	}
}

/*
 * Primary
 */

.uk-button.uk-button-primary-blue {
	background-color: $button-primary-blue-background;
	color: $button-primary-blue-color;
	@if (mixin-exists(hook-button-primary-blue)) {
		@include hook-button-primary-blue();
	}
}

/* Hover + Focus */
.uk-button.uk-button-primary-blue:hover,
.uk-button.uk-button-primary-blue:focus {
	background-color: $button-primary-blue-hover-background;
	color: $button-primary-blue-hover-color;
	@if (mixin-exists(hook-button-primary-blue-hover)) {
		@include hook-button-primary-blue-hover();
	}
}

/* OnClick + Active */
.uk-button.uk-button-primary-blue:active,
.uk-button.uk-button-primary-blue.uk-active {
	background-color: $button-primary-blue-active-background;
	color: $button-primary-blue-active-color;
	@if (mixin-exists(hook-button-primary-blue-active)) {
		@include hook-button-primary-blue-active();
	}
}

/*
 * Secondary-blue
 */

.uk-button.uk-button-secondary-blue {
	background-color: $button-secondary-blue-background;
	color: $button-secondary-blue-color;
	@if (mixin-exists(hook-button-secondary-blue)) {
		@include hook-button-secondary-blue();
	}
}

/* Hover + Focus */
.uk-button.uk-button-secondary-blue:hover,
.uk-button.uk-button-secondary-blue:focus {
	background-color: $button-secondary-blue-hover-background;
	color: $button-secondary-blue-hover-color;
	@if (mixin-exists(hook-button-secondary-blue-hover)) {
		@include hook-button-secondary-blue-hover();
	}
}

/* OnClick + Active */
.uk-button.uk-button-secondary-blue:active,
.uk-button.uk-button-secondary-blue.uk-active {
	background-color: $button-secondary-blue-active-background;
	color: $button-secondary-blue-active-color;
	@if (mixin-exists(hook-button-secondary-blue-active)) {
		@include hook-button-secondary-blue-active();
	}
}

/*
 * Tertiary
 */

.uk-button.uk-button-tertiary {
	background-color: transparent;
	color: get-color(true-white);
	border: solid 1px get-color(true-white);
	@if (mixin-exists(hook-button-tertiary)) {
		@include hook-button-secondary-blue();
	}
}

/* Hover + Focus */
.uk-button.uk-button-tertiary:hover,
.uk-button.uk-button-tertiary:focus {
	background-color: get-color(true-white);
	color: get-color(wolf-black);
	@if (mixin-exists(hook-button-tertiary-hover)) {
		@include hook-button-tertiary-hover();
	}
}

/* OnClick + Active */
.uk-button.uk-button-tertiary:active,
.uk-button.uk-button-tertiary.uk-active {
	background-color: get-color(true-white);
	color: get-color(wolf-black);
	@if (mixin-exists(hook-button-tertiary-active)) {
		@include hook-button-tertiary-active();
	}
}
