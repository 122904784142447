@mixin wolf-o-finder($name: '.o-finder') {
	#{$name} {
		& > .uk-container {
			max-width: 1682px;
		}

		.m-finder-select-step {
			@media (max-width: $breakpoint-small-max) {
				margin-top: 0;
			}
		}

		&__select-step-container {
			width: 100%;
			max-width: 90%;
			margin: auto;
			margin-bottom: 50px;

			@media (max-width: $breakpoint-small-max) {
				max-width: 100%;
				margin: 0;
			}
		}

		&.styleSettings.headline-color-true-white {
			.m-finder-popup-form__content {
				.m-finder-popup-form__head .m-finder-popup-form__headline > .a-headline {
					color: get-color('wolf-black');
					@media (max-width: $breakpoint-small-max) {
						color: #0d5055;
					}
				}

				form.hs-form > .field label,
				form.hs-form > .field legend,
				form.hs-form fieldset label,
				form.hs-form fieldset legend {
					color: get-color('wolf-black');
				}
			}
		}

		&__wrapper {
			position: relative;
			z-index: 1;
		}

		&.negative-overlap {
			margin-top: -100px;
			position: relative;

			@media (max-width: $breakpoint-small-max) {
				margin-top: 0;
				position: static;
			}
		}

		.image-wrapper {
			position: relative;
		}

		.o-teaser-grid {
			&--odd-container {
				.o-gridcontainer > .uk-first-column:nth-last-of-type(2) {
					margin-left: 0;
				}
			}

			.m-teaser-bg {
				> div > .uk-container {
					height: 100%;
				}
			}
		}

		.m-pagination {
			margin-top: 62px;

			.uk-pagination {
				justify-content: center;
			}

			&__current-page {
				a {
					background-color: get-color('primary') !important;
				}
			}
		}
	}
}

@include wolf-o-finder();
