@mixin wolf-m-formstep-select-icon($name: '.m-formstep-select-icon') {
	// @todo - refactor and clean this up...
	#{$name} {
		&__head {
			display: inline-flex;
			width: 100%;

			margin-bottom: 24px;

			#{$name}__headline {
				.a-headline {
					font-weight: 600;
					margin: 0px;
					font-size: 30px;

					@media (max-width: $breakpoint-medium-max) {
						font-size: 40px;
						line-height: 40px;
						font-weight: 400;
					}
				}

				.a-copy {
					margin-top: 8px;
					@media (max-width: $breakpoint-medium-max) {
						margin-top: 20px;
					}

					* {
						@media (max-width: $breakpoint-medium-max) {
							font-size: 12px;
							line-height: 12px;
						}
					}
				}
			}

			#{$name}__stepCounter {
				display: inline-flex;
				align-items: center;
				flex-grow: 1;
				justify-content: flex-end;
				padding-right: 35px;
				font-size: 18px;

				@media (max-width: $breakpoint-medium-max) {
					display: none;
				}
			}
		}

		&__selection-wrapper {
			max-height: 584px;
			overflow-y: scroll;

			@media (max-width: $breakpoint-medium-max) {
				overflow: -moz-scrollbars-none;
				-ms-overflow-style: none;
			}

			&::-webkit-scrollbar {
				width: 14px;
				-webkit-appearance: none;
				background-color: transparent;

				@media (max-width: $breakpoint-medium-max) {
					width: 0;
				}
			}

			&::-webkit-scrollbar-track,
			&::-webkit-scrollbar-thumb {
				background-clip: padding-box;
			}

			&::-webkit-scrollbar-track {
				border-top: 18px solid transparent;
				border-bottom: 18px solid transparent;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				background-color: rgb(247, 247, 247);
			}

			&::-webkit-scrollbar-thumb {
				border-top: 18px solid transparent;
				border-bottom: 18px solid transparent;
				border-right: 5px solid transparent;
				border-left: 5px solid transparent;
				background-color: rgb(106, 110, 112);
			}

			.iconSelector {
				width: 100%;
				max-width: 786px;
				border: 1px solid #efefef;
				border-radius: 11px;
				flex: 1;
				margin-bottom: 20px;

				background-image: linear-gradient(#212d40, #212d40);
				background-position: 50% 50%;
				background-repeat: no-repeat;
				background-size: 0% 100%;
				transition: background-size 0.5s, color 0.5s;

				position: relative;
				z-index: 99;

				.icon {
					width: 25px;
					height: 25px;
					padding: 10px;
					background-color: #f7f5f5;
					border-radius: 50%;
					margin: 15px 20px 15px 20px;
					pointer-events: none;
				}

				.text {
					@include font-inter-600();
					font-size: 17px;
					line-height: 22px;
					display: inline-flex;
					align-items: center;
					pointer-events: none;
				}

				@media (min-width: $breakpoint-medium-max) {
					margin-bottom: 40px;
					.text {
						font-size: 22px;
					}
					.icon {
						width: 34px;
						height: 34px;
						padding: 15px;
						margin: 20px 35px 20px 25px;
					}
				}
			}

			.selectionIcon {
				display: inline-flex;
				width: 100%;
			}

			.icon-selected {
				visibility: hidden;
				height: 100%;
				pointer-events: none;
				margin: 35px 25px;
				display: inline-flex;
				transition: visibility 0s, opacity 0.5s linear;
				opacity: 0;

				@media (max-width: $breakpoint-medium-max) {
					display: none;
				}
			}

			.iconInfo {
				position: absolute;
				top: 14px;
				right: 14px;
				width: 22px;
				height: 22px;
				cursor: pointer;
				color: #aba8a8;
				z-index: 101;

				&:hover {
					~ .extraInfo {
						display: inline-flex;
					}

					~ .text {
						display: none;
					}
				}
			}

			.extraInfo {
				@include font-inter-600();
				font-size: 17px;
				line-height: 22px;
				display: none;
				align-items: flex-start;
				padding: 15px 0;
				pointer-events: none;
				max-width: 70%;
				width: 100%;

				@media (max-width: $breakpoint-medium-max) {
					font-size: 15px;
					line-height: 17px;
					padding: 10px 0;
				}
			}
		}

		.selected {
			.iconSelector {
				cursor: pointer;
				background-size: 100% 100%;
				.text,
				.extraInfo,
				.iconInfo {
					color: get-color('true-white');
				}
			}

			.icon-selected {
				height: 100%;
				pointer-events: none;
				margin: 35px 25px;
				display: inline-flex;
				visibility: visible;
				transition: visibility 0s, opacity 0.5s linear;
				opacity: 1;

				span {
					height: 35px;
					width: 35px;
					color: #212d40;
				}

				@media (max-width: $breakpoint-medium-max) {
					display: none;
				}
			}
		}
	}
}

@include wolf-m-formstep-select-icon();
