// Name:            Card
// Description:     Component to create boxed content containers
//
// Component:       `uk-card`
//
// Sub-objects:     `uk-card-body`
//                  `uk-card-header`
//                  `uk-card-footer`
//                  `uk-card-media-*`
//                  `uk-card-title`
//                  `uk-card-badge`
//
// Modifiers:       `uk-card-hover`
//                  `uk-card-default`
//                  `uk-card-primary`
//                  `uk-card-secondary`
//                  `uk-card-small`
//                  `uk-card-large`
//
// Uses:            `uk-grid-stack`
//
// ========================================================================

// Variables
// ========================================================================

$card-body-padding-horizontal: $global-gutter;
$card-body-padding-vertical: $global-gutter;

$card-body-padding-horizontal-l: $global-medium-gutter;
$card-body-padding-vertical-l: $global-medium-gutter;

$card-header-padding-horizontal: $global-gutter;
$card-header-padding-vertical: round($global-gutter / 2);

$card-header-padding-horizontal-l: $global-medium-gutter;
$card-header-padding-vertical-l: round($global-medium-gutter / 2);

$card-footer-padding-horizontal: $global-gutter;
$card-footer-padding-vertical: ($global-gutter / 2);

$card-footer-padding-horizontal-l: $global-medium-gutter;
$card-footer-padding-vertical-l: round($global-medium-gutter / 2);

$card-title-font-size: $global-large-font-size;
$card-title-line-height: 1.4;

$card-badge-top: $global-gutter;
$card-badge-right: $card-badge-top;

$card-hover-background: $global-muted-background;

$card-default-background: $global-muted-background;
$card-default-color: $global-color;
$card-default-title-color: $global-emphasis-color;
$card-default-hover-background: darken($card-default-background, 5%);

$card-primary-background: $global-primary-background;
$card-primary-color: $global-inverse-color;
$card-primary-title-color: $card-primary-color;
$card-primary-hover-background: darken($card-primary-background, 5%);
$card-primary-color-mode: light;

$card-secondary-background: $global-secondary-background;
$card-secondary-color: $global-inverse-color;
$card-secondary-title-color: $card-secondary-color;
$card-secondary-hover-background: darken($card-secondary-background, 5%);
$card-secondary-color-mode: light;

$card-small-body-padding-horizontal: $global-margin;
$card-small-body-padding-vertical: $global-margin;
$card-small-header-padding-horizontal: $global-margin;
$card-small-header-padding-vertical: round($global-margin / 1.5);
$card-small-footer-padding-horizontal: $global-margin;
$card-small-footer-padding-vertical: round($global-margin / 1.5);

$card-large-body-padding-horizontal-l: $global-large-gutter;
$card-large-body-padding-vertical-l: $global-large-gutter;
$card-large-header-padding-horizontal-l: $global-large-gutter;
$card-large-header-padding-vertical-l: round($global-large-gutter / 2);
$card-large-footer-padding-horizontal-l: $global-large-gutter;
$card-large-footer-padding-vertical-l: round($global-large-gutter / 2);
