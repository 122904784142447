@mixin wolf-m-stage-fullscreen($name: '.m-stage-fullscreen') {
	#{$name} {
		height: 100vh;
		hyphens: auto;

		&.default-gradient {
			position: relative;
		}

		&.default-gradient > div:before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: linear-gradient(90deg, rgba(33, 33, 44, 0.78) 0%, rgba(33, 33, 44, 0.68) 30%, rgba(255, 255, 255, 0) 70%);
		}

		&.default-gradient .uk-container {
			position: relative;
		}

		&.uk-text-left,
		&.uk-text-right {
			@media (max-width: $breakpoint-xsmall-max) {
				text-align: center !important;
			}
		}

		.image-wrapper {
			position: relative;
		}

		> div {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			width: 100%;

			> .uk-container {
				width: 100%;
				margin-right: 0px;
				margin-left: 0px;
			}

			@media (min-width: 1620px) {
				> .uk-container {
					min-width: 1520px;
					margin: auto;
					margin: auto;
				}
			}

			.a-headline {
				width: 100%;
			}

			.a-button {
				margin-top: 175px;
				border: 0px;
				padding: 26px 58px;

				@include font-inter-600();
				line-height: 16px;
				font-size: 16px;

				&.minimized {
					margin-top: 94px;
				}

				.a-button__text {
					margin: 0px;
					padding: 0px 20px;
				}

				&.only-icon {
					padding: 10px;
					border-radius: 50px;
					background-color: rgba(get-color('mid-grey'), 0.9);
				}
			}

			.a-copy {
				margin-top: 8px;

				a {
					color: inherit;
					text-decoration: underline;
					text-decoration-color: white;
				}

				a:after {
					display: none;
				}
			}
		}

		&[class*='uk-text-'] {
			> div {
				text-align: inherit;
				.uk-container {
					text-align: inherit;
				}
			}
		}
	}
	main > #{$name}.styleSettings .image-wrapper {
		position: absolute;
		z-index: -1;
	}
}

@include wolf-m-stage-fullscreen();
