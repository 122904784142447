@mixin wolf-m-teaser-list-item($name: '.m-teaser-list-item') {
	#{$name} {
		color: map-get($colors, 'wolf-black');
		margin: auto auto 90px;
		padding: 20px 0;
		border-top: 1px solid map-get($colors, 'wolf-black');
		.image-wrapper {
			position: relative;
		}
		> div {
			margin: 0;
			> div {
				padding: 0;
			}
		}
		@media (max-width: $breakpoint-medium) {
			margin-bottom: 20px;
		}
		.clickable {
			cursor: pointer;
			&:hover {
				#{$name}__title,
				#{$name}__icon-link {
					color: map-get($colors, 'wolf-red');
					transition: all 0.2s ease;
				}
			}
		}
		&__category {
			font-size: 12px;
			text-transform: uppercase;
			color: map-get($colors, 'wolf-black');
		}
		&__date {
			font-size: 12px;
			text-transform: uppercase;
			color: map-get($colors, 'dark-grey');
			@media (max-width: $breakpoint-medium) {
				text-align: right;
				color: #9b9b9b;
			}
		}
		&__title {
			@include font-inter-500;
			@extend %hyphenate;
			color: map-get($colors, 'wolf-black');
			font-size: $global-large-font-size;
			line-height: 40px;
			@media (max-width: $breakpoint-medium) {
				font-size: 25px;
				line-height: 35px;
			}
			&:hover {
				color: map-get($colors, 'wolf-red');
				transition: all 0.2s ease;
			}
		}
		&__image {
			max-width: 100%;
		}
		.image-wr {
			@media (min-width: $breakpoint-medium) {
				max-width: 66.66%;
			}
		}
		&__icon-link {
			> a,
			> span {
				width: 32px;
				svg {
					width: 32px;
					min-width: 32px;
				}
			}
		}
		&__icon-bookmark {
			> a,
			> span {
				width: 22px;
				height: auto;
			}
		}
		&__content {
			color: map-get($colors, 'wolf-black');
			font-size: $global-xsmall-font-size;
			line-height: 32px;
			@include line-clamp(4);
			> *:last-child {
				margin-bottom: 0;
			}
		}
		&__item--margin {
			margin-top: 45px !important;
			@media (max-width: $breakpoint-medium) {
				margin-top: 24px !important;
				margin-left: 0 !important;
				padding: 0 !important;
				> div {
					padding-left: 0;
				}
			}
		}
	}
}

@include wolf-m-teaser-list-item();
