svg {
	vertical-align: unset;
}

.image-wrapper {
	display: flex;
	position: absolute;
	z-index: 0;
	height: 100%;
	top: 0;
	width: 100%;
	left: 0;
	vertical-align: unset;

	> img {
		object-fit: cover;
		width: 100%;
	}
}

// Cookie Popup Overwrite
html {
	#onetrust-consent-sdk #onetrust-accept-btn-handler,
	#onetrust-banner-sdk #onetrust-reject-all-handler {
		background-color: #00681b;
		border-color: #00681b;
		color: #ffffff;
	}

	.uk-navbar-nav > li:hover > a,
	.uk-navbar-nav > li > a:focus,
	.uk-navbar-nav > li > a[aria-expanded='true'] {
		color: #ffffff;
		outline: none;
	}

	.uk-navbar-nav > li {
		&:hover,
		&:focus,
		&.a-button--active {
			opacity: 1;
			outline: none;

			> .a-button {
				.a-button__text {
					text-decoration: underline;
					text-underline-offset: 5px;
					text-decoration-color: get-color('primary');
				}
			}
		}
	}

	.m-nav-flyout__column-links {
		margin: 0;
		padding-top: 0;

		> li.m-nav-flyout__column-list-item {
			padding-left: 0;

			.a-button {
				padding: 0 0 24px;
				text-align: left;
				justify-content: flex-start;
				font-family: 'Inter';
				font-weight: 300;
				font-size: 16px;
				line-height: 21px;

				.a-button__text {
					font-family: 'Inter';
					font-weight: 300;
					font-size: 16px;
					line-height: 21px;
					margin: 0;
				}
			}

			&:hover,
			&:focus,
			&.a-button--active {
				opacity: 1;
				outline: none;

				> .a-button {
					.a-button__text {
						text-decoration: underline;
						text-underline-offset: 5px;
						text-decoration-color: get-color('primary');
					}
				}
			}
		}
	}

	body {
		&[data-theme^='proklima '] {
			.uk-navbar-nav > li {
				&:hover,
				&:focus,
				&.a-button--active {
					> .a-button {
						.a-button__text {
							text-decoration-color: get-color('true-white');
						}
					}
				}
			}

			.m-nav-flyout__column-links {
				> li.m-nav-flyout__column-list-item {
					&:hover,
					&:focus,
					&.a-button--active {
						> .a-button {
							.a-button__text {
								text-decoration-color: get-color('true-white');
							}
						}
					}
				}
			}
		}
	}
}

img {
	object-fit: cover;
}

section {
	position: relative;
}

html,
body {
	scroll-behavior: smooth;
}

html {
	position: relative;

	&.uk-offcanvas-page {
		#o-fab-section {
			display: none;
		}
	}
}

body {
	overflow-x: hidden;
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	> main {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
}

.uk-input::placeholder {
	color: #1e293a50;
}

.double-optin-button-wrapper {
	padding-top: 24px;
	padding-bottom: 24px;
	width: 100%;
}
