@mixin wolf-m-cardimage($name: '.m-cardimage') {
	#{$name} {
		max-width: 100%;
		padding: 1rem;

		&__column-text-wrapper {
			padding: 0;
			text-align: left;
		}

		&__text-wrapper {
			flex-direction: column;
			width: 100%;
		}

		&__title {
			font-size: get-fontsize(m, small);
		}

		&__cardimage-text {
			font-size: get-fontsize(m, default);

			@media (max-width: $breakpoint-medium) {
				font-size: get-fontsize(s, xxsmall);
			}
		}

		&__title,
		&__cardimage-text {
			margin: 0;
			word-wrap: normal;
			max-width: 75%;

			@media (max-width: $breakpoint-medium) {
				max-width: 80%;
			}
		}

		&__title {
			color: map-get($colors, 'true-black');
		}

		&__cardimage-text {
			color: map-get($colors, 'dark-grey-seo');
			padding-top: 0.5rem;
		}

		&__contact-image {
			width: 112px;
			height: 112px;
			min-width: 112px;
			background-size: cover;
			background-position: top center;
			border-radius: 50%;

			@media (max-width: $breakpoint-large) {
				width: 70px;
				height: 70px;
				min-width: 70px;
			}
		}

		&__wrapper {
			text-align: right;
			display: flex;
			justify-content: flex-end;
			padding-right: 1rem;
		}

		&__button-wrapper {
			justify-content: center;
			margin-top: 1rem;
			white-space: nowrap;
		}

		&__button-footer {
			border: 1px solid map-get($colors, 'true-black');
			color: map-get($colors, 'true-black');
			background: transparent;
			font-size: get-fontsize(m, default);
			padding: 0px 16px;
		}

		&:not(#{$name}--dark) {
			.a-button {
				&.uk-button {
					&-tertiary {
						border: 1px solid map-get($colors, 'true-black');
						color: map-get($colors, 'true-black');
					}
				}
			}
		}

		&--dark {
			background-color: map-get($colors, 'true-black');
		}

		&--dark &__title {
			color: map-get($colors, 'true-white');
		}

		&--dark &__button-footer {
			border: 1px solid map-get($colors, 'true-white');
			font-weight: 400;
			color: map-get($colors, 'true-white');
		}

		&--right &__wrapper {
			flex-direction: row-reverse;
		}

		&--right &__button-wrapper {
			justify-content: left;
		}

		&--right &__column-text-wrapper {
			text-align: left;
			padding-left: 30px;
		}

		&--noImg &__contact-image,
		&--noBtn &__button-wrapper {
			display: none;
		}

		&--noImg &__contact-image {
			justify-content: left;
			margin-top: 1rem;
		}

		&--noImg &__text-wrapper,
		&--right &__text-wrapper {
			padding-left: 0rem;
		}
	}
}

@include wolf-m-cardimage();
