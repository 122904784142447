@mixin wolf-o-mediacenter-list($name: '.o-mediacenter-list') {
	$mobileSize: 48px;

	#{$name} {
		position: relative;
		padding-bottom: 230px;

		@include only-mobile {
			padding-inline: 3px;
		}

		&.main-last-item {
			@include only-mobile {
				padding-bottom: 0;
			}
		}

		> .container {
			> .row {
				> .container {
					padding-inline: 5px;

					> .row {
						margin-left: 0;
						margin-right: 0;
					}
				}
			}
		}

		:not(#{$name}-header) {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				&.a-headline {
					@include only-mobile {
						font-family: 'Saira';
						font-size: 21px;
						font-weight: 500;
						line-height: 1.19;
						letter-spacing: -0.84px;
						text-align: left;
						color: get-color(true-black);
						margin-bottom: 20px;
					}
				}
			}
		}

		&-filters {
			display: flex;
			background-color: transparent;
			margin-bottom: 20px;
			gap: 20px;

			@include min-md {
				min-height: 88px;
				border: solid 1px #d2d2d2;
				margin-bottom: 30px;
				gap: 0;
			}

			svg {
				height: 24px;
				@include only-mobile {
					height: 20px;
				}
			}

			&-selected {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: 30px;
				align-items: flex-start;
				justify-content: flex-start;
				flex-direction: column;

				@include min-md {
					flex-direction: row;
				}

				&:not(:empty) {
					min-height: 30px;
					margin-bottom: 50px;

					@include min-md {
						min-height: 40px;
						margin-bottom: 80px;
					}
				}

				.selected-label {
					display: flex;
					padding-right: 30px;
					font-family: 'Inter';
					font-size: 14px;
					font-weight: 600;
					font-stretch: normal;
					font-style: normal;
					line-height: 1;
					letter-spacing: -0.72px;
					margin-bottom: 10px;

					@include min-md {
						font-size: 24px;
						line-height: 40px;
						margin-bottom: auto;
					}
				}

				.selected-items {
					display: flex;
					flex-grow: 1;
					flex-wrap: wrap;
					gap: 10px;
				}
			}

			#{$name} {
				&-search-wrapper {
					display: flex;
					flex-grow: 1;
					position: relative;

					#{$name} {
						&-search {
							&-buttons {
								position: absolute;
								top: 0;
								right: 0;
								display: flex;
								align-items: center;
								justify-content: center;
								bottom: 0;
								gap: 4px;

								&[data-show-buttons='false'] {
									display: none;

									> button {
										pointer-events: none;
									}
								}

								> button {
									display: flex;
									border-radius: 0;
									align-items: center;
									justify-content: center;
									border: 0;
									outline: none;
									min-width: 88px;
									height: 88px;
									padding: 0;
									cursor: pointer;

									@include only-mobile {
										min-width: $mobileSize;
										height: $mobileSize;
									}
								}
							}

							&-input {
								width: 100%;
								border: 0;
								outline: none;
								padding: 30px 220px 30px 30px;
								font-size: 24px;
								font-weight: 500;
								font-family: 'Inter';
								line-height: 1;
								color: #161717;

								@include only-mobile {
									border: solid 1px #d2d2d2;
									height: $mobileSize;
									padding: 0 50px 0 15px;
									box-sizing: border-box;
									font-size: 16px;
								}

								&::placeholder {
									color: #16171750;
								}
							}

							&-clear {
								background-color: transparent;
								color: get-color(wolf-black);
							}

							&-submit {
								background-color: get-color(wolf-red);
								color: get-color(true-white);

								svg {
									width: 38px;
									height: 38px;
									@include only-mobile {
										width: 26px;
										height: 26px;
									}
								}
							}
						}
					}
				}

				&-filter-toggle {
					display: flex;
					min-width: 272px;
					background-color: transparent;
					border: 0;
					outline: none;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					position: relative;
					font-family: 'Inter';
					font-size: 24px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1;
					letter-spacing: -0.72px;
					color: get-color(wolf-black);
					box-sizing: border-box;
					align-items: center;
					justify-content: center;

					@include only-mobile {
						min-width: $mobileSize;
						width: $mobileSize;
						height: $mobileSize;
						border-radius: 50%;
						border: solid 1px #d2d2d2;
						padding: 12px;
					}

					&::before {
						content: '';
						position: absolute;
						top: 10px;
						bottom: 10px;
						left: 0;
						background-color: #d2d2d2;
						width: 1px;
						height: calc(100% - 20px);

						@include only-mobile {
							content: unset;
						}
					}

					svg {
						height: 16px;

						@include only-mobile {
							height: 14px;
							width: 14px;
							color: #62677e;
							opacity: 1;
							display: inline-flex;
						}

						@include min-md {
							margin-left: 20px;
						}
					}

					.filter-toggle-label {
						@include only-mobile {
							display: none;
						}
					}

					.icon {
						&-desktop {
							display: none;

							@include min-lg {
								display: inline-flex;
							}
						}

						&-mobile {
							display: inline-flex;

							@include min-lg {
								display: none;
							}

							@include only-mobile {
								height: 14px;
								width: 14px;
							}
						}
					}
				}
			}
		}

		.m-card {
			margin-bottom: 18px;
		}

		&-pagination-wrapper {
			margin-top: 60px;

			@include only-mobile {
				margin-top: 12px;
				margin-bottom: 48px;
			}

			.a-button {
				&.loading-in-progress {
					overflow: hidden;
					position: relative;
					pointer-events: none;

					color: transparent;
					position: relative;

					&::after {
						content: '';
						width: 24px;
						height: 24px;
						border-radius: 50%;
						overflow: hidden;
						border-width: 3px;
						border-style: solid;
						position: absolute;
						z-index: 1;
						top: 50%;
						left: 50%;
						margin: -14px 0 0 -12px;
						transform-origin: center;
						animation: loader-rotate 0.6s linear infinite;
					}

					&.uk-button-default {
						&::after {
							border-color: get-color(true-white) get-color(true-white) transparent;
						}
					}

					&:not(.uk-button-default) {
						&::after {
							border-color: get-color(wolf-red) get-color(wolf-red) transparent;
						}
					}
				}
			}
		}

		&-search-list {
			[data-counter] {
				.m-card-delete {
					display: none;
				}
			}
			&.loading-in-progress {
				&::before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					transition: all 0.15s ease-out 0s;
					z-index: 4;
					background-color: rgba(17, 21, 28, 0.596);
					backdrop-filter: blur(4px);
				}

				&::after {
					content: '';
					position: absolute;
					z-index: 5;
					background: transparent;
					top: 300px;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 30px;
					height: 30px;
					border-radius: 50%;
					border-style: solid;
					border-width: 2px;
					border-color: #666 #f9f9f9 #f9f9f9;
					animation: roating 0.5s linear infinite;
				}
			}
		}

		&-container-wrapper {
			position: fixed;
			z-index: 1;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;

			.filters-opened & {
				z-index: 1000;
				pointer-events: all;
			}

			&[data-open='false'] {
				#{$name} {
					&-backdrop {
						opacity: 0;
						pointer-events: none;
						backdrop-filter: blur(0);
					}

					&-container {
						opacity: 0;
						pointer-events: none;
						transform: translateY(100%);

						@media (min-width: 691px) {
							transform: translateX(100%);
						}
					}
				}

				.m-card.list-view {
					opacity: 0;
					margin-bottom: 20px;
					transition: all 0.25s ease-out;
				}
			}

			&[data-open='true'] {
				.m-card.list-view {
					opacity: 1;
					margin-bottom: 0;
					transition: all 0.25s ease-out;
				}
			}
		}

		&-backdrop {
			position: absolute;
			z-index: 2;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #11151c98;
			backdrop-filter: blur(4px);
			transition: all 0.15s ease-out;
		}

		&-container {
			position: absolute;
			z-index: 3;
			top: 50px;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			max-width: 100%;
			height: calc(100% - 50px);
			background-color: get-color(true-white);
			padding: 0;
			display: flex;
			flex-direction: column;
			border-radius: 10px 10px 0 0;
			transform: translateY(0);
			transition: all 0.15s ease-out;

			@media (min-width: 691px) {
				transform: translateX(0);
				top: 0;
				left: unset;
				height: 100%;
				max-width: 690px;
				border-radius: 0;
			}
		}

		&-header {
			display: flex;
			flex-wrap: wrap;
			gap: 16px;
			justify-content: space-between;
			align-items: center;
			padding: 18px 24px;
			flex-direction: row-reverse;

			@media (min-width: 691px) {
				padding: 30px;
				flex-direction: row;
			}

			> .a-headline {
				display: flex;

				flex-grow: 1;
				justify-content: center;
				padding-right: 20px;

				@media (min-width: 691px) {
					justify-content: flex-start;
					padding-right: 0;
				}

				h1,
				h2,
				h3,
				h4,
				h5,
				h6,
				div {
					font-family: 'Inter';
					font-size: 20px;
					color: #0d505575 !important;
					font-weight: bold;
					line-height: 1;
					letter-spacing: normal;
					text-align: left;
					margin: 0 !important;
					padding: 0;

					@media (min-width: 691px) {
						padding: 4px 0 4px 30px;
						font-family: 'Saira';
						font-size: 35px;
						color: get-color(wolf-black) !important;
						letter-spacing: -1.4px;
						font-weight: 500;
					}
				}
			}

			#{$name}-close {
				background-color: transparent;
				border: 0;
				outline: none;
				cursor: pointer;
				width: 46px;
				height: 46px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: -9px;

				@media (min-width: 691px) {
					width: 62px;
					height: 62px;
				}

				svg {
					width: 20px;
					height: 20px;
					display: flex;
					color: #85a6a9;
					margin: 0;

					@media (min-width: 691px) {
						color: #85a6a9;
					}
				}
			}
		}

		&-filter-wrap {
			display: flex;
			flex-grow: 1;
			flex-direction: column;
			padding: 20px 24px;
			overflow: hidden auto;
			gap: 20px;

			@media (min-width: 691px) {
				padding: 34px 60px;
			}

			&::-webkit-scrollbar {
				width: 8px;
			}

			&::-webkit-scrollbar-track {
				background-color: get-color(true-white);

				@media (min-width: 691px) {
					background-color: #f0f0f0;
				}
			}

			&::-webkit-scrollbar-thumb {
				background-color: get-color(true-white);
				outline: none;

				@media (min-width: 691px) {
					background-color: get-color(wolf-black);
				}
			}
		}

		&-buttons {
			box-sizing: border-box;
			background-color: #f0f0f0;
			display: flex;
			flex-wrap: wrap;
			gap: 12px;
			justify-content: flex-end;
			align-items: center;
			padding: 20px 24px;
			width: 100%;

			@include min-lg {
				padding: 30px 60px;
			}

			@media (max-width: 530px) {
				flex-direction: column-reverse;
				gap: 1px;
			}

			.a-button {
				@media (max-width: 530px) {
					width: 100%;

					&.uk-button-secondary {
						flex-grow: 1;

						.a-button__text {
							margin: auto;
						}
					}
				}

				&.uk-button-default {
					flex-grow: 1;

					.a-button__text {
						margin: auto;
					}
				}

				&:first-of-type {
					&:last-of-type {
						@include min-md {
							max-width: 328px;
							margin-inline: auto;
						}
					}
				}
			}
		}

		.no-result {
			font-size: 24px;
			font-weight: 500;
			font-family: 'Inter';
			line-height: 1;
			color: get-color(wolf-black);

			@include min-lg {
				font-size: 36px;
				font-weight: 600;
			}
		}
	}

	@keyframes loader-rotate {
		from {
			transform: rotateZ(0deg);
		}

		to {
			transform: rotateZ(359deg);
		}
	}

	body.filters-opened {
		overflow: hidden;

		@include min-md {
			padding-right: 17px !important;
		}
	}
}

@include wolf-o-mediacenter-list();
