@mixin wolf-m-fhw-stage($name: '.m-fhw-stage') {
	#{$name} {
		hyphens: auto;
		&__stage-wrapper {
			position: relative;
			height: 100vh;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			overflow: hidden;

			@media (max-width: $breakpoint-medium-max) {
				margin-bottom: 0px !important;
			}

			@media (max-width: $breakpoint-small-max) {
				height: 730px;
			}
		}

		.uk-container {
			width: 100%;

			z-index: 1;
			display: flex;
			align-items: center;

			@media (max-width: $breakpoint-small-max) {
				display: flex;
				flex-direction: column;
				margin-top: 0px;
				height: 100%;
			}

			#{$name}__left-container {
				display: flex;
				flex: 1;
				justify-content: flex-start;

				.intro-text {
					max-width: 60%;

					@media (max-width: $breakpoint-small-max) {
						display: flex;
						flex-direction: column;
						height: 100%;
						justify-content: center;
					}

					@media (max-width: 1250px) {
						max-width: 70%;
					}

					* {
						color: get-color('true-white');
					}

					h1 {
						margin-bottom: 90px;
						@media (max-width: $breakpoint-small-max) {
							margin-bottom: 20px;
						}
					}

					p {
						font-size: 16px;
						font-weight: 200;
						line-height: 30px;
						@media (max-width: $breakpoint-small-max) {
							font-size: 12px;
							line-height: 20px;
							margin-bottom: 0px;
						}
					}
				}
			}
		}
	}
}

@include wolf-m-fhw-stage();
