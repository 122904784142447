@mixin wolf-m-finder-result-reference-mobile($name: '.m-finder-result-reference-mobile') {
	#{$name} {
		&__container {
			z-index: 999; // 812  Minimum value to stay over the navigation
			padding: 50px 0 0;
			overflow: hidden;

			&.uk-open {
				#{$name} {
					&__content-wrapper {
						transform: translateY(0);
					}
				}
			}
		}

		&__content-wrapper {
			overflow: scroll;
			padding: 0 16px 16px;
			height: 100%;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			background-color: get-color('true-white');
			transform-origin: bottom;
			transform: translateY(100%);
			transition: transform 0.3s ease-in-out;

			.uk-close {
				color: #86a7aa;
				width: 20px;
				height: 20px;
				margin-inline: 5px;
				&:empty() {
					&::before {
						content: '';
						width: 20px;
						height: 20px;
						background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAOtJREFUOE+l1DEOwjAMBdDkDN0ACTYGboCYkLgAF+B8DHAHVGBnQCoDINpSpp7ByJESpSF20pAtavpk9zuV11cJRVmJ9WIu/lm3+i0uj6eQu/wEuJmOhskovr8/nlU9EgAAN6mojWGXCkQ5BXUx7NKAfVEfZlq2w4iplMK8YKhSDiNBCg1hLOiis8nYjAamiQH4VicU3wH9TfUzDgtWiAfsNgdZJjarJXuh2ApdrGnb4I0iQV8AMSPlBbk0Q+gPGDMaHNoBYzCdCIUasA/GoQpMwShUFlWt/oe4QkNLDaDdvtwecrg3n2TMrfQL/CMdktY7xYoAAAAASUVORK5CYII=');
						background-repeat: no-repeat;
						display: flex;
					}
				}
				svg {
					width: 20px;
					height: 20px;
				}
			}
		}

		&__header {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: sticky;
			padding: 16px 30px 16px 0;
			top: 0;
			background-color: get-color('true-white');
			z-index: 6;
			color: #86a7aa;
			box-sizing: border-box;

			.a-headline {
				margin-bottom: 0;
				color: #86a7aa;
				font-size: 40px;

				@media (max-width: $breakpoint-small-max) {
					font-size: 24px;
					margin-block: 0;
				}
			}

			.right-icon {
				.uk-icon {
					width: 17px;
					transform: rotate(-90deg);
					color: #86a7aa;
					padding: 0;
				}
			}
		}

		&__content {
			padding-bottom: 40px;

			@media (max-width: $breakpoint-small-max) {
				.uk-grid {
					margin-left: -10px;

					> * {
						padding-left: 10px;
					}

					> div {
						display: flex;
						width: 50%;

						.uk-container {
							.m-teaser-bg__inner {
								padding: 0 10px 26px;
							}
						}
					}

					> .uk-grid-margin {
						margin-top: 9px;
					}

					.o-grid-container__gridElement {
						width: 100%;

						.m-finder-contact {
							.a-headline {
								color: #86a7aa;
								opacity: 1;
								font-size: 21px;
								line-height: 27px;
							}

							.a-copy {
								&:empty() {
									display: none;
								}
							}

							.m-finder-contact__link {
								font-size: 17px;

								&::after {
									content: '→';
									font-size: 26px;
									font-weight: 700;
									margin-left: 10px;
								}
							}
						}
					}
				}
			}

			.m-finder-contact {
				margin-bottom: 16px;
			}
		}
	}
}

@include wolf-m-finder-result-reference-mobile();
