@mixin wolf-o-teaser-row($name: '.o-teaser-row') {
	#{$name} {
		&__headline {
			display: flex;
			justify-content: center;

			margin-bottom: 96px;

			* {
				margin: 0px;
			}

			@media (max-width: $breakpoint-medium) {
				justify-content: flex-start;
				margin-bottom: 32px;
				padding-top: 40px;
			}
		}

		&__teasers {
			display: flex;
			flex-wrap: nowrap;
			overflow-x: auto;
			cursor: grab;

			/*Scrollbar styling firefox*/
			scrollbar-color: #8f8f8f transparent;
			scrollbar-width: thin;

			.m-teaser-default {
				width: 25%;
				max-width: 400px;
				margin-right: 5%;
				flex: 0 0 auto;

				@media (max-width: $breakpoint-medium) {
					width: 80%;
					max-width: 254px;
					margin-right: 10%;
				}
			}
		}

		&__teasers:before {
			@media (min-width: $breakpoint-medium) {
				content: '';
				width: 20%;
				height: 500px;
				position: absolute;
				right: 0;

				background: linear-gradient(to right, rgba(255, 255, 255, 0) 20%, white);
			}
		}

		&__teasers::-webkit-scrollbar {
			-webkit-appearance: none;
			height: 155px;
			width: 12px;
			background-color: rgba(255, 255, 255, 0);

			@media (max-width: $breakpoint-small-max) {
				height: 50px;
			}
		}

		&__teasers::-webkit-scrollbar-track,
		&__teasers::-webkit-scrollbar-thumb {
			background-clip: padding-box;
		}

		&__teasers::-webkit-scrollbar-track {
			border-top: 149px solid rgba(255, 255, 255, 0);
			border-bottom: 1px solid rgba(255, 255, 255, 0);
			background-color: #e8e9eb;

			@media (max-width: $breakpoint-small-max) {
				border-top: 49px solid rgba(255, 255, 255, 0);
				border-bottom: 5px solid rgba(255, 255, 255, 0);
			}
		}

		&__teasers::-webkit-scrollbar-thumb {
			border-top: 148px solid rgba(255, 255, 255, 0);
			background-color: #8f8f8f;

			@media (max-width: $breakpoint-small-max) {
				border-top: 44px solid rgba(255, 255, 255, 0);
			}
		}

		&__button {
			width: 100%;
			display: flex;
			justify-content: center;
			margin-top: 50px;
		}
	}
}

@include wolf-o-teaser-row();
