@mixin wolf-m-teaser-with-modal($name: '.m-teaser-with-modal') {
	#{$name} {
		max-width: 410px;
		//max-height: 600px;

		@media (max-width: $breakpoint-small-max) {
			max-width: 286px;
			max-height: 330px;
		}

		&__image {
			position: relative;

			.a-image {
				position: relative;
				display: inline-block;

				img {
					border-radius: 20px;
					width: 100%;
					display: block;
				}

				&:after {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					display: inline-block;
					background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.6) 100%);
					background: -webkit-gradient(180deg, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.6) 100%);
					background: -o-linear-gradient(180deg, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.6) 100%);
					background: -ms-linear-gradient(180deg, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.6) 100%);
					background: linear-gradient(180deg, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.6) 100%);
					border-radius: 20px;
				}
			}

			.icon-modal {
				position: absolute;
				bottom: 35px;
				right: 40px;

				border: 1px solid get-color('true-white');
				border-radius: 50%;
				width: 48px;
				height: 48px;
				display: inline-flex;
				align-items: center;
				justify-content: center;

				> span {
					display: inline-flex;
					align-items: center;
					justify-content: center;
				}

				svg {
					color: get-color('true-white');
					width: 20px;
					min-width: 20px;
				}

				&:hover + p.icon-description {
					visibility: visible;
					opacity: 1;
				}
			}

			.icon-description {
				position: absolute;
				bottom: 40px;
				left: 20px;
				margin: 0px;

				display: flex;
				justify-content: flex-end;
				align-items: center;
				height: 47px;

				width: calc(100% - 120px);

				color: get-color(true-white);

				text-overflow: ellipsis;
				-webkit-line-clamp: 2;
				overflow: hidden;

				visibility: hidden;
				opacity: 0;
				transition: visibility 0s, opacity 0.3s linear;
			}
		}

		&__description {
			margin-top: 30px;

			@media (max-width: $breakpoint-small-max) {
				display: flex;
				flex-direction: column;
				margin-top: 20px;
			}

			.a-copy {
				@media (max-width: $breakpoint-small-max) {
					font-size: 14px;
					line-height: 24px;
					order: 3;
					margin-top: 30px;
				}

				* {
					margin: 0px;
				}
			}

			.name {
				margin: 22px 0px 0px 0px;

				@include font-inter-500();
				font-size: 28px;
				line-height: 35px;

				@media (max-width: $breakpoint-small-max) {
					font-size: 25px;
					line-height: 32px;
					order: 1;
					margin: 0px;
				}
			}

			.role {
				margin: 0px;

				@include font-inter-400();
				font-size: 24px;
				line-height: 24px;

				@media (max-width: $breakpoint-small-max) {
					font-size: 20px;
					line-height: 20px;
					order: 2;
				}
			}
		}

		&__modal {
			.m-popup-interview {
				max-width: 1163px;
				max-height: 100%;
				margin: auto;
				overflow: auto;
			}
		}

		&.staggered {
			margin-top: 75px;

			@media (max-width: $breakpoint-small-max) {
				margin-top: 0px;
			}
		}
	}
}

@include wolf-m-teaser-with-modal();
