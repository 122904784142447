@mixin wolf-m-fhw-profile-header($name: '.m-fhw-profile-header') {
	#{$name} {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width: $breakpoint-medium) {
			flex-wrap: wrap;
		}

		&__text-container {
			display: flex;
			width: 100%;
			flex-grow: 1;
			flex-direction: column;
			align-items: flex-start;
		}

		&__company-content {
			align-items: center;
			display: flex;
			width: 100%;

			@media (max-width: $breakpoint-medium) {
				margin-bottom: 24px;
			}

			@media (max-width: $breakpoint-xsmall-max) {
				flex-direction: column;

				.m-fhw-profile-header__text-container {
					display: flex;
					align-items: center;
				}
			}
		}

		&__partner-section {
			display: flex;
			gap: 20px;
			width: 100%;
			justify-content: flex-end;
			width: auto;
			min-width: 200px;

			@media (max-width: $breakpoint-xsmall-max) {
				width: 100%;
				justify-content: center;
				margin-bottom: 24px;
			}
		}

		&__share-section {
			display: flex;
			column-gap: 10px;
			align-items: center;
			width: auto;
			min-width: 230px;
			justify-content: flex-end;

			@media (max-width: $breakpoint-xsmall-max) {
				width: 100%;
				justify-content: center;
				margin-bottom: 24px;
			}

			button {
				&.uk-button {
					border: 1px solid get-color(wolf-red);
					background-color: get-color(true-white);
					color: get-color(wolf-red);
					border-radius: 0;
					outline: none;
					padding: 0 24px;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 15px;

					@media (max-width: $breakpoint-small-max) {
						min-width: 80%;
					}

					&:hover {
						background-color: get-color(wolf-red);
						color: get-color(true-white);
					}
				}
			}
		}

		.image-wrapper {
			position: relative;
			margin-right: 20px;
			width: auto;

			@media (max-width: $breakpoint-xsmall-max) {
				margin-right: 0;
				margin-bottom: 24px;
				padding: 1px;
				border: solid 1px #adadad;
			}

			img {
				object-fit: cover;
				width: 200px;
				height: 200px;

				@media (min-width: $breakpoint-xsmall-max) {
					margin-right: 40px;
					width: 152px;
					height: 152px;
				}
			}
		}

		.a-headline {
			margin-bottom: 0;

			h5.a-headline {
				opacity: 0.6;
			}

			@media (max-width: $breakpoint-xsmall-max) {
				h5.a-headline {
					color: #0d5055;
					opacity: 0.5;
				}
			}
		}

		.image-top-partner {
			align-self: flex-start;
		}

		.partner-placeholder-image {
			color: get-color(wolf-red);
			fill: currentColor;
			margin-right: 20px;

			@media (min-width: $breakpoint-xsmall-max) {
				margin-right: 40px;
			}
		}
	}
}

@include wolf-m-fhw-profile-header();
