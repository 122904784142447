@mixin wolf-m-fhw-profile-services-list($name: '.m-fhw-profile-services-list') {
	#{$name} {
		&__services-grid {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 10px;

			@media (max-width: $breakpoint-xsmall-max) {
				display: flex;
				flex-flow: row nowrap;
				width: 100%;
				overflow-x: scroll;
				-ms-overflow-style: none;
				scrollbar-width: none;

				&::-webkit-scrollbar {
					display: none;
				}
			}
		}

		&__service-item {
			display: flex;
			align-items: center;

			& .icon {
				color: get-color('primary');
				width: 70px;
				height: 70px;
				border: 1px solid rgba(#707070, 0.5);
				border-radius: 100%;
				padding: 15px;
				margin-right: 20px;
				box-sizing: border-box;
				display: grid;
				place-content: center;

				> svg {
					min-width: 24px;
				}
			}

			& .text {
				color: get-color('text');

				@media (max-width: $breakpoint-xsmall-max) {
					min-height: 40px;
					display: flex;
					align-items: center;
				}
			}

			@media (max-width: $breakpoint-xsmall-max) {
				border: thin solid #d9d9d9;
				border-radius: 5px;
				flex-direction: column;
				justify-content: center;
				box-sizing: border-box;
				padding: 25px 25px 5px;
				min-width: 150px;

				& .icon {
					margin-right: 0;
				}

				& .text {
					font-size: 14px;
					margin-bottom: 10px;
				}
			}
		}
	}
}

@include wolf-m-fhw-profile-services-list();
