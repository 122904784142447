@mixin wolf-m-ahu-hero($name: '.m-ahu-hero') {
	#{$name} {
		display: flex;
		justify-content: center;
		position: relative;
		overflow-y: hidden;
		overflow-y: clip;

		&.default-gradient {
			> div {
				position: relative;
				.image-wrapper {
					&::before {
						background: linear-gradient(270deg, rgba(255, 255, 255, 0) 30%, rgba(33, 33, 44, 0.68) 60%, rgba(33, 33, 44, 0.78) 100%);
					}
					> img {
						object-fit: cover;
						max-height: 100%;
					}
				}
			}
		}

		.uk-container {
			position: relative;
			padding-left: 0px;
			padding-right: 0px;
			height: 100%;
			width: 100%;
			.hero-text {
				z-index: 99;
			}
		}

		&.styleSettings > div {
			max-width: 1520px;
			width: 100%;
		}

		&.no-default-back {
			width: 100%;
			display: flex;
			min-height: 566px;
			height: auto;
			overflow-y: hidden;
			justify-content: flex-start;
			margin: 0 auto;

			@media (max-width: $breakpoint-medium) {
				overflow-y: initial;
				height: fit-content;
			}

			.hero-text {
				padding: 5rem 0px;
				@media (max-width: $breakpoint-medium) {
					padding: 40px 0px;
				}
			}

			> div {
				margin: 0 auto;
				position: relative;
			}
		}

		&__content-wrapper {
			display: flex;
			min-height: 566px;
			height: auto;
			max-width: 1320px;
			justify-content: flex-start;
			padding: 0px 100px;

			@media (max-width: $breakpoint-medium) {
				padding: 0px 10px;
			}

			@media (max-width: $breakpoint-medium) {
				overflow-y: initial;
				padding: 24px;
				height: fit-content;
				height: -moz-fit-content;
				position: relative;
			}

			.hero-text {
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				padding: 5rem 0;

				&.w-60 {
					width: 60%;
				}

				@media (max-width: $breakpoint-large) {
				}

				@media (max-width: $breakpoint-medium) {
					width: 90%;
					flex: 2;
					padding: 40px 0px;
				}

				.headline {
					display: block;
				}

				.a-copy,
				.a-copy p {
					margin: 9px 0 18px 0px;
					color: get-color('true-white');

					@media (max-width: $breakpoint-medium) {
						margin-top: 0;
						margin-bottom: 0;
					}
				}

				.buttons {
					white-space: wrap;
					margin-top: 30px;
					display: flex;
					gap: 16px 32px;

					.uk-button-primary {
						border: none;
						color: get-color('accent');
					}

					.uk-button-primary:hover,
					.uk-button-primary:focus {
						color: get-color('true-white');

						a {
							color: get-color('true-white');
						}
					}

					.a-button {
						font-size: 16px;

						@media (max-width: $breakpoint-medium) {
							font-size: 12px;
							justify-content: center;
						}

						&.uk-button-default {
							border: solid 1px get-color('true-white');
						}
					}

					@media (max-width: $breakpoint-xsmall-max) {
						flex-direction: column;

						a {
							width: fit-content;
							width: -moz-fit-content;
							justify-content: center;

							&:not(:last-of-type) {
								margin-bottom: 16px;
							}
						}
					}
				}
			}

			.hero-tablet {
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
				position: relative;
				width: fit-content;
				width: -moz-fit-content;
				margin-left: 5rem;

				@media (max-width: $breakpoint-large) {
					margin-right: 1rem;
					height: 110%;
				}

				@media (max-width: 1050px) {
					height: 105%;
				}

				@media (max-width: $breakpoint-medium) {
					margin-left: 0;
					margin-right: 0;
					padding-right: 0;
					position: relative;
					flex: 1;
				}

				img {
					@media (max-width: $breakpoint-medium) {
						align-items: flex-start;
						justify-content: flex-start;
						height: auto;
						position: relative;
						max-width: 80%;
						top: -50px;
					}
				}
			}
		}

		&:not([class^='text-color-']):not([class*='text-color-']):not([class^='copy-color-']):not([class*='copy-color-']) {
			.a-copy {
				color: get-color('true-white');
				* {
					color: get-color('true-white');
				}
			}
		}

		&:not([class^='headline-color-']):not([class*='headline-color-']) {
			.hero-text {
				h1,
				h2,
				h3,
				h4,
				h5 {
					color: white;
					&.headline {
						color: white;
					}
				}
			}
		}

		&:not(.m-ahu-background-image) {
			&:not(.with-style) {
				#{$name}__content-wrapper {
					background-color: get-color('accent');
				}
			}
			.hero-tablet {
				padding-right: 5rem;
				@media (max-width: $breakpoint-medium) {
					padding-right: 0px;
				}
			}
		}

		&--full-width {
			#{$name}__content-wrapper {
				height: auto;

				.hero-text {
					width: 100%;
					text-align: center;

					@media (max-width: $breakpoint-medium) {
						text-align: left;
					}

					.headline {
						display: flex;
						justify-content: center;
						width: 80%;
						@media (max-width: $breakpoint-medium) {
							width: 100%;
							justify-content: flex-start;
						}
					}

					.buttons {
						.a-button {
							@media (max-width: $breakpoint-medium) {
								width: 100%;
							}
						}
					}
				}
			}
		}

		&.bg-overlap {
			background: linear-gradient(180deg, transparent 200px, get-color('wolf-black') 200px, get-color('wolf-black') 100%);
			@import './color-themes/pro-klima-blue.scss';
		}

		&.main-last-item {
			margin-top: auto;
			margin-bottom: -1px;
			background: linear-gradient(180deg, transparent 200px, get-color('wolf-black') 200px, get-color('wolf-black') 100%);
			@import './color-themes/pro-klima-blue.scss';

			@media (max-width: 1200px) {
				.image-wrapper {
					height: 100% !important;
				}
			}
			.m-ahu-hero__content-wrapper .hero-text .buttons .a-button {
				&.uk-flex-row-reverse:not(.only-icon) {
					.a-button__icon-wrapper {
						padding: 15px 5px 15px 30px;
					}
				}

				&:not(.uk-flex-row-reverse:not(.only-icon)) {
					.a-button__icon-wrapper {
						padding: 15px 30px 15px 5px;
					}
				}
			}
		}
	}
}

@include wolf-m-ahu-hero();
