/**
 * get color from global $colors sass-map
 *
 * @param   {string}  $color  	color name
 * @param   {string}  $variant  color variation name
 *
 * @return  {color}             return hex value
 */
@function get-color($color, $lightness: false) {
	$base-color: map-get($colors, $color);

	@if $lightness {
		@return scale-color($base-color, $lightness: $lightness);
	} @else {
		@return $base-color;
	}
}

/**
 * get fontweight from global $fontweights sass-map by font name
 *
 * @param   {string}  $name        font name
 *
 * @return  {fontweight}           return fontweight
 */
@function get-fontweight($name: 'light') {
	@return map-get($fontweights, $name);
}

/**
 * get fontsize from global $fontsizes sass-map
 *
 * @param   {string}  $breakpoint  	s | m
 * @param   {string}  $variant      fontsize variation name
 *
 * @return  {fontsize}             return fontsize in pixel
 */
@function get-fontsize($breakpoint: 'm', $variant: 'default') {
	@return map-get(map-get($fontsizes, $breakpoint), $variant);
}

@mixin container-dark-on-mobile($desktop-bg: get-color(true-white), $mobile-bg: #333942, $mobile-text: #d3dbe8) {
	background-color: $desktop-bg;

	@media (max-width: $breakpoint-medium-max) {
		background-color: $mobile-bg;

		input {
			border: 1px solid $mobile-text;
			color: $mobile-text;

			&::placeholder {
				color: $mobile-text;
			}

			&:focus {
				color: $mobile-text;
			}
		}

		.uk-select {
			color: $mobile-text;

			&::placeholder {
				color: $mobile-text;
			}

			&:focus {
				color: $mobile-text;
			}
		}
	}
}

@mixin text-clear-on-mobile($mobile-color: get-color(true-white), $desktop-color: get-color(wolf-black)) {
	color: $mobile-color;

	.a-headline,
	.a-copy,
	*[class*='text'],
	.uk-select {
		color: $mobile-color;
	}

	*[class*='’label’'] {
		color: $mobile-color;
	}

	@media (min-width: $breakpoint-medium-max) {
		color: $desktop-color;

		.a-headline,
		.a-copy,
		*[class*='text'] {
			color: $desktop-color;
		}
	}
}

@mixin right-scrollbar() {
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 14px;
		appearance: none;
		background-color: transparent;

		@media (max-width: $breakpoint-medium-max) {
			width: 0;
		}
	}

	&::-webkit-scrollbar-track,
	&::-webkit-scrollbar-thumb {
		background-clip: padding-box;
	}

	&::-webkit-scrollbar-track {
		border-top: 18px solid transparent;
		border-bottom: 18px solid transparent;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		background-color: rgb(247, 247, 247);
	}

	&::-webkit-scrollbar-thumb {
		border-top: 18px solid transparent;
		border-bottom: 18px solid transparent;
		border-right: 5px solid transparent;
		border-left: 5px solid transparent;
		background-color: rgb(106, 110, 112);
	}
}
