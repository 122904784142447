@mixin wolf-m-formstep-main-part-component($name: '.m-formstep-main-part-component') {
	#{$name} {
		border: 0;
		padding: 0;
		margin: 0;
		max-width: 869px;
		min-height: 130px;

		&__part-box {
			@include container-dark-on-mobile(get-color(faded-grey), #101620);
			@include text-clear-on-mobile(#d2d2d2);

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			border-radius: 20px;
			padding: 40px 0;

			&-left-content {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				width: 100%;
				max-width: 869px;

				@media (min-width: $breakpoint-medium) {
					justify-content: center;
					min-height: 130px;
					border-right: 1px solid #e8e8e8;
					padding: 0 40px;
					width: calc(100% - 58px);
				}
			}
			@media (min-width: $breakpoint-medium) {
				flex-direction: row;
				padding: 0;
			}
		}

		&__text {
			font-size: 22px;
			font-weight: 700;
			line-height: 22px;
			margin: 0;
		}

		&__input-group {
			width: 100%;
			max-width: 869px;
			margin-top: 20px;

			@media (min-width: $breakpoint-medium) {
				width: calc(100% - 58px);
				min-width: 30%;
				max-width: 284px;
				margin: 0 32px;
			}
		}

		&__input {
			background-color: inherit;
			border: 1px solid get-color(form-grey);
			&.uk-form-danger {
				padding: 0 10px !important;
				font-size: 16px !important;
			}
		}

		&__label {
			font-size: 12px;
			background-color: inherit;
			margin: 0;
			//margin-top: 20px;
			margin-bottom: 6px;
			&.uk-form-danger {
				color: get-color(wolf-red);
				font-size: 12px !important;
				margin: 0;
				padding: 0 !important;
				@media (min-width: $breakpoint-medium) {
					margin-top: 60px;
				}
			}
		}

		.uk-form-danger {
			color: get-color(wolf-red);
			font-size: 15px;
			padding: 10px 0;
		}
	}
}

@include wolf-m-formstep-main-part-component();
