@mixin wolf-o-product-details($name: '.o-product-details') {
	#{$name} {
		margin-top: 210px;

		&__pagewrap {
			margin-top: 60px;
			display: grid;
			grid-template-columns: 1fr 2fr;
			column-gap: 50px;

			@media (max-width: $breakpoint-large) {
				display: flex;
				flex-direction: column;
				padding: 0;
			}
		}

		&__images {
			margin: 20px 0;
		}

		&__default {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 3rem;

			@media (max-width: $breakpoint-large) {
				display: flex;
				flex-direction: column;
				// padding: 1em;
			}
		}

		&__introduction-title {
			margin: 0;
			margin-bottom: 10px;
			line-height: 1em;
		}

		&__headline {
			margin: 20px 0;
		}

		&__introduction-description {
			font-size: $global-xsmall-font-size;
			line-height: 30px;
			font-weight: 300;
			margin-top: 20px;
			margin-bottom: 20px;
		}

		&__specifications {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}

		&__specifications--bottom {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 100px;

			@media (max-width: $breakpoint-large) {
				display: block;
			}

			div {
				&:first-child,
				&:last-child {
					align-self: flex-end;
				}
			}
		}

		&__purchasing {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 1rem;

			.price {
				justify-self: flex-end;
			}

			.selector {
				align-self: center !important;
			}
		}

		&__cart-button {
			margin-top: 3rem;

			a {
				height: 85px;
				width: 100%;
				justify-content: center;
			}
		}

		&__sku {
			font-weight: 200;
			font-size: 10px;
		}

		&__delivery-time {
			font-weight: bold;
			margin: 24px 0;

			span {
				width: 20px;
				color: get-color('wolf-red');

				> svg {
					width: 20px;
					min-width: 20px;
				}
			}

			@media (max-width: $breakpoint-large) {
				margin: 1rem 0 4rem 0;
			}
		}

		&__downloads {
			margin: 3rem 0;
			font-weight: bold;

			a {
				display: block;
				margin-bottom: 10px;
				text-decoration: none;

				span {
					width: 20px;
					color: get-color('wolf-red');

					> svg {
						width: 20px;
						min-width: 20px;
					}
				}
			}
		}

		&__back-button {
			display: flex;
			justify-content: center;

			span {
				border: 1px solid #f0f0f0;
				height: 50px;
				width: 50px;
				display: flex;
				align-items: center;
				justify-content: center;

				svg {
					height: 15px;
					width: 20px;
					margin: 0 auto;
				}
			}
		}
	}
}

@include wolf-o-product-details();
