@mixin wolf-o-shop-filter-stage($name: '.o-shop-filter-stage') {
	#{$name} {
		margin-bottom: 80px;

		&__header .m-heading__headline {
			color: map-get($colors, 'wolf-black');
			font-weight: normal;
			margin-top: 235px;

			@media (max-width: $breakpoint-small) {
				margin-top: 115px;
			}
		}

		&__select {
			select {
				&.uk-select {
					margin-top: 64px;
					margin-bottom: 35px;

					font-size: 16px; // global?
					font-weight: 400;
					line-height: normal;
					background-color: transparent;

					border: 1px solid #d6d6d6;
					outline-color: map-get($colors, 'wolf-black');

					width: 496px;
					max-width: 100%;
					height: 68px !important;

					padding-left: 16px;
					padding-right: 16px;
					// max-width: 60%;

					&:focus {
						// border-width: 4px;
						outline: 1px solid;
						background-color: transparent;
					}
				}
			}
		}

		&__copy {
			div.a-copy {
				width: 496px;
				max-width: 100%;
				margin: auto;
			}
		}
	}
}

@include wolf-o-shop-filter-stage();
