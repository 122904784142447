$wolf-m-nav-mobile-width: 100vw;
$nav-item-px: 22px;
$bg-gradient-customer: transparent linear-gradient(155deg, #e84b4c 0%, get-color('secondary') 100%) 0% 0% no-repeat padding-box;
$bg-gradient-professional: transparent linear-gradient(155deg, get-color('light-blue') 0%, get-color('secondary') 100%) 0% 0% no-repeat padding-box;
$bg-gradient-proklima-blue: transparent linear-gradient(155deg, get-color('pro-klima-blue') 0%, get-color('pro-klima-blue-dark') 100%) 0% 0% no-repeat
	padding-box;
$bg-gradient-proklima-grey: transparent linear-gradient(155deg, get-color('pro-klima-grey') 0%, get-color('pro-klima-grey-dark') 100%) 0% 0% no-repeat
	padding-box;

@mixin nav-accordion-icons {
	filter: invert(1);
	width: 21px;
	position: relative;
	top: 0;
}

@mixin nav-canvas-default {
	display: flex;
	flex-direction: column;

	width: $wolf-m-nav-mobile-width;
	padding: 0px;

	&.uk-open {
		> .uk-offcanvas-reveal {
			width: $wolf-m-nav-mobile-width;
		}
	}
}

@mixin white-stroke-on-hover {
	&:focus,
	&:hover {
		color: get-color('true-white') !important;

		svg {
			fill: get-color('true-white') !important;
		}
	}
}

@mixin icon-margin-by-position {
	.icon-margin-to-left:not(:empty) {
		margin-right: 8px;
	}

	.icon-margin-to-right:not(:empty) {
		margin-left: 8px;
	}
}

.border-b-white {
	border-bottom: 1px solid get-color('true-white');
}

.m-nav-mobile-menu__offcanvas {
	display: none;
}

@mixin wolf-m-nav-mobile-menu($name: '.m-nav-mobile-menu') {
	#{$name} {
		&__canvas--professional {
			@include nav-canvas-default();
			background: $bg-gradient-professional;
		}

		&__canvas--customer {
			@include nav-canvas-default();
			background: $bg-gradient-customer;
		}

		&__canvas--pro-klima-blue {
			@include nav-canvas-default();
			background: $bg-gradient-proklima-blue;
		}

		&__canvas--pro-klima-grey {
			@include nav-canvas-default();
			background: $bg-gradient-proklima-grey;
		}

		&__wrapper {
			height: 100vh;
			color: get-color('true-white');
		}

		&__canvas-header {
			display: flex;
			align-items: center;
			justify-content: center;

			height: 75px;
			width: 100%;

			#{$name}__language-switcher {
				@include font-inter-400;
				font-size: 16px;
				position: absolute;
				left: 22px;
				display: flex;
				align-items: center;
				text-decoration: none;
				margin: 0;
				color: get-color('true-white');

				&:hover {
					text-decoration: none;
				}

				& .uk-icon {
					width: 12px;
					margin-left: 6px;
				}

				span {
					margin-left: 6px;
					display: inline-flex;

					svg {
						width: 12px;
					}
				}
			}

			.a-wolf-logo {
				margin: 24px 0 $nav-item-px;

				svg {
					height: 24px;
				}

				+ a {
					position: absolute;
					right: $nav-item-px;
					top: 26px;
					width: 21px;
					color: get-color('true-white');

					svg {
						height: 21px;
					}
				}
			}
		}

		&__searchbox {
			width: 100%;
			box-sizing: border-box;

			> .a-button {
				width: 100%;
				padding: 15px 18px !important;
				box-sizing: border-box;
				justify-content: flex-end;
			}

			#{$name}__input.a-button {
				height: 68px;
				width: 100%;
				display: flex;
				justify-content: flex-end;
				align-items: center;

				&:active,
				&:focus {
					outline: none !important;
				}

				&__icon-wrapper {
					margin-right: 15px !important;
				}

				& .uk-icon {
					padding-right: 10px;
				}
			}

			.a-button {
				&.uk-flex-row-reverse {
					.a-button__icon-wrapper {
						padding: 0 15px 0 0;
					}
				}
			}
		}

		&__menu-items {
			display: flex;
			align-self: baseline;
			overflow-y: overlay;
			overflow-x: hidden;

			width: 100%;
			height: 60%;
			max-height: 60%;

			@media (max-height: 400px) {
				min-height: 150px;
			}

			.uk-nav-primary {
				width: 100%;
				height: max-content;

				.uk-open {
					background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
				}

				li {
					padding: 8px $nav-item-px 8px;

					&:first-of-type {
						padding: 14px $nav-item-px 8px;
					}

					&:last-of-type {
						padding: 8px $nav-item-px 14px;
					}

					&.list-item-title.additional {
						display: flex;
						align-items: flex-start;

						&:not(:last-of-type) {
							padding: 8px 22px 8px;
						}

						.a-button {
							width: 100%;
							display: flex;
							align-self: flex-start;
							justify-content: flex-end;

							&:hover,
							&:focus {
								background-color: transparent;
							}

							&:not(.uk-flex-row-reverse) {
								justify-content: space-between;
								padding: 0;

								.a-button__text {
									margin-left: 0;
								}
							}

							.a-button__icon-wrapper {
								padding: 5px 5px 5px 0;
							}
						}
					}

					a {
						@include font-inter-600();
						@include white-stroke-on-hover();
						@include icon-margin-by-position();

						color: get-color('true-white');
						font-size: 16px;

						.icon-with-text {
							display: flex;
							align-items: center;
						}
					}

					.uk-icon {
						width: 18px;
					}

					.uk-nav-sub {
						padding: 0;

						li > a {
							@include font-inter-400();
							@include icon-margin-by-position();

							svg {
								fill: rgba(255, 255, 255, 0.5);
								min-width: 20px;
								width: 20px;
								min-height: 20px;
								height: 20px;
							}
						}
					}
				}
			}

			.uk-nav-parent-icon {
				> .uk-parent > a:after {
					@include nav-accordion-icons();
					background-image: url('./../../../global/assets/icons/chevron-down.svg');
				}

				> .uk-parent.uk-open > a:after {
					@include nav-accordion-icons();
					background-image: url('./../../../global/assets/icons/chevron-up.svg');
				}
			}

			&::-webkit-scrollbar {
				width: 16px;
				-webkit-appearance: none;
				background-color: rgba(255, 255, 255, 0);
			}

			&::-webkit-scrollbar-track,
			&::-webkit-scrollbar-thumb {
				background-clip: padding-box;
			}

			&::-webkit-scrollbar-track {
				border-top: 18px solid rgba(255, 255, 255, 0);
				border-bottom: 18px solid rgba(255, 255, 255, 0);
				border-left: 5px solid rgba(255, 255, 255, 0);
				border-right: 5px solid rgba(255, 255, 255, 0);
				background-color: rgba(0, 0, 0, 0.2);
			}

			&::-webkit-scrollbar-thumb {
				border-top: 18px solid rgba(255, 255, 255, 0);
				border-bottom: 18px solid rgba(255, 255, 255, 0);
				border-right: 5px solid rgba(255, 255, 255, 0);
				border-left: 5px solid rgba(255, 255, 255, 0);
				background-color: rgba(0, 0, 0, 0.4);
			}
		}

		&__menu-bottom-items {
			padding: 4px $nav-item-px 8px $nav-item-px;

			li {
				padding-top: 12px;

				a {
					color: get-color('true-white');
				}
			}
		}

		&__menu-additional-items {
			align-self: flex-start;
			border-top: 1px solid get-color('true-white');
			padding-top: 8px;

			li {
				padding-top: 16px;
			}
		}

		@import './templates/new-design.scss';
	}
}

@include wolf-m-nav-mobile-menu();
