@mixin wolf-m-formstep-warranty-step-2($name: '.m-formstep-warranty-step-2') {
	#{$name} {
		& > div:first-of-type {
			max-width: 1000px;
		}

		&__formstep-2-wrapper {
			@include right-scrollbar();
			max-width: 869px;
			padding: 0 0px 30px 0;

			@media (min-width: $breakpoint-medium-max) {
				padding: 0 150px 30px 0;
			}

			#{$name}__input-wrapper {
				margin-top: 33px;
			}

			#{$name}__additional-form {
				margin-top: 36px;

				& .a-headline {
					margin-bottom: 0;
				}
			}
		}
	}
}

@include wolf-m-formstep-warranty-step-2();
