@mixin wolf-m-teaser-bg($name: '.m-teaser-bg') {
	#{$name} {
		height: 496px;
		position: relative;

		@media (max-width: $breakpoint-small-max) {
			height: auto;
		}

		.image-wrapper {
			position: relative;

			@media (max-width: $breakpoint-small-max) {
				height: 0;
				padding-top: 100%;
				overflow: hidden;
			}

			&::before {
				background: linear-gradient(180deg, rgba(255, 255, 255, 0) 40%, rgba(33, 33, 44, 0.68) 70%, rgba(33, 33, 44, 0.78) 100%);
			}

			> img {
				position: relative;
				top: 0;

				@media (max-width: $breakpoint-small-max) {
					position: absolute;
				}
			}
		}

		> div:not(.uk-container) {
			height: 100%;
			max-height: 496px;
		}

		&.default-gradient {
			position: relative;

			.uk-container {
				position: absolute;
				z-index: 2;
				padding-left: 0px;
				padding-right: 0px;
				height: 100%;
				top: 0;
				left: 0;
				right: 0;
			}
		}

		.uk-container {
			height: 100%;
		}

		&__outer {
			display: flex;
			justify-content: flex-end;
			flex-direction: column;
			height: 100%;
		}

		&__inner {
			margin-top: 50%;
			display: flex;
			flex: 1 0 50%;
			flex-direction: column;
			justify-content: flex-end;
			padding: 0px 34px 40px 34px;
			box-sizing: border-box;
			height: 100%;
		}

		&__headline {
			height: 82px; // this is approx 2 lines of text
			margin-bottom: 15px;

			@media (max-width: $breakpoint-small-max) {
				height: auto;
				margin-bottom: 6px;
			}

			&.not-link {
				height: 30%;
			}

			.a-headline {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;

				@media (max-width: $breakpoint-small-max) {
					font-size: 4vw;
				}

				@media (max-width: $breakpoint-xsmall-max) {
					font-size: 5vw;
				}

				> .a-headline {
					margin-bottom: 0;
				}
			}
		}

		&__link {
			margin-bottom: 6px;
			height: 5%;

			a,
			.a-button {
				@include font-inter-400();
				//color: get-color('light-blue');
				font-size: 16px;
				text-decoration: none;

				@media (max-width: $breakpoint-xsmall-max) {
					font-size: 14px;
				}
			}
		}
	}
}

@include wolf-m-teaser-bg();
