@mixin wolf-o-text($name: '.o-text') {
	#{$name} {
		& > div {
			width: 100%;
		}

		&.uk-flex-left {
			&.margin-0 .uk-container,
			&.margin-standard .uk-container {
				margin-left: auto;
			}

			&.margin-20 .uk-container {
				margin-left: 20%;
			}

			&.margin-30 .uk-container {
				margin-left: 30%;
			}

			.uk-container {
				display: flex;
				justify-content: flex-start;
				position: relative;
				z-index: 3;

				#{$name}__headline.head-100 {
					width: 100%;
				}

				#{$name}__headline.head-standard {
					width: 100%;

					@media (max-width: $breakpoint-small-max) {
						width: 100%;
					}
				}

				#{$name}__headline.head-70 {
					width: 70%;

					@media (max-width: $breakpoint-small-max) {
						width: 100%;
					}
				}
			}
		}

		&.uk-flex-right {
			&.margin-0 .uk-container {
				margin-right: auto;
			}

			&.margin-20 .uk-container,
			&.margin-standard .uk-container {
				margin-right: 20%;
			}

			&.margin-30 .uk-container {
				margin-right: 30%;
			}

			.uk-container {
				position: relative;
				z-index: 3;

				.o-text__headline,
				.a-headline {
					width: 100%;
					display: flex;
					justify-content: flex-end;
					text-align: end;
				}

				.o-text__button {
					display: flex;
					justify-content: flex-end;
				}

				.o-text__copytext {
					margin-left: 20%;
					text-align: right;
				}

				#{$name}__headline.head-100 div.a-headline {
					width: 100%;
				}

				#{$name}__headline.head-standard div.a-headline {
					width: 80%;
				}

				#{$name}__headline.head-70 div.a-headline {
					width: 70%;
				}
			}
		}

		&__text {
			flex-direction: column;
			justify-content: center;

			@media (max-width: $breakpoint-small-max) {
				width: 95%;
			}
		}

		&__headline {
			margin-bottom: 64px;
			width: 80%;

			@media (max-width: $breakpoint-small-max) {
				margin-bottom: 24px;
				width: 100%;
			}

			* {
				margin: 0px;
			}

			.a-headline:nth-child(2) {
				margin-top: 40px;

				@media (max-width: $breakpoint-small-max) {
					margin-top: 15px;
				}
			}
		}

		&__copytext {
			margin: 0px;
			width: 80%;
			@include font-inter-200();
			font-size: get-fontsize(m, default);
			letter-spacing: -0.32px;
			line-height: 28px;

			@media (max-width: $breakpoint-small-max) {
				width: 100%;

				p:empty {
					display: none;
				}
			}

			* {
				margin: 0px;
			}
		}

		&--big-text {
			#{$name}__copytext {
				font-size: 32px;
				line-height: 47px;

				@media (max-width: $breakpoint-small-max) {
					font-size: 20px;
					line-height: 36px;
				}
			}
		}

		&__button {
			margin-top: 64px;

			@media (max-width: $breakpoint-medium) {
				margin-top: 32px;
			}
		}

		&.padding-small-bottom {
			@media (max-width: $breakpoint-small-max) {
				padding-top: 15px;
				padding-bottom: 30px;

				+ #{$name}.padding-small-bottom {
					padding-top: 0;
					padding-bottom: 30px;
				}
			}
		}
	}

	.uk-flex-center .o-text__text {
		display: flex;
		align-items: center;
		text-align: center;

		.o-text__copytext {
			width: 100% !important;
		}

		.o-text__headline,
		.a-headline {
			width: 100%;
		}
	}

	@media (max-width: $breakpoint-small-max) {
		&.padding-standard {
			padding-top: 24px;
			padding-bottom: 24px;

			~ #{$name} {
				&.padding-standard {
					padding-top: 14px;
				}
			}
		}

		.padding-no-padding-bottom {
			padding-top: 14px;
			padding-bottom: 24px;
		}
	}
}

@include wolf-o-text();
