@mixin wolf-a-copyright($name: '.a-copyright') {
	#{$name} {
		&__copyright {
			color: map-get($colors, 'footer-copyright-seo');
			font-size: 12px;
			text-transform: uppercase;
			letter-spacing: 1.2px;
			line-height: 24px;
			white-space: nowrap;
			display: inline-block;
		}

		@media (max-width: $breakpoint-medium) {
			text-align: center;
		}
	}
}

@include wolf-a-copyright();
