// Name:            Base
// Description:     Default values for HTML elements
//
// Component:       `uk-link`
//                  `uk-h1`, `uk-h2`, `uk-h3`, `uk-h4`, `uk-h5`, `uk-h6`
//                  `uk-hr`
//
// ========================================================================

// Variables
// ========================================================================

//$base-body-background:                           $global-background;
//$base-body-font-family:                          $global-font-family;
$base-body-font-weight: get-fontweight(light);
//$base-body-font-size:                            $global-font-size;
//$base-body-line-height:                          $global-line-height;
//$base-body-color:                                $global-color;
//
//$base-link-color:                                $global-link-color;
//$base-link-text-decoration:                      none;
//$base-link-hover-color:                          $global-link-hover-color;
//$base-link-hover-text-decoration:                underline;
//
//$base-strong-font-weight:                        bolder;
//$base-code-font-size:                            $global-small-font-size;
//$base-code-font-family:                          Consolas, monaco, monospace;
//$base-code-color:                                $global-danger-background;
//$base-em-color:                                  $global-danger-background;
//$base-ins-background:                            #ffd;
//$base-ins-color:                                 $global-color;
//$base-mark-background:                           #ffd;
//$base-mark-color:                                $global-color;
//$base-quote-font-style:                          italic;
//$base-small-font-size:                           80%;
//
//$base-margin-vertical:                           $global-margin;
//
// $base-heading-font-family:                      $global-font-family;
$base-heading-font-weight: get-fontweight(medium);
//$base-heading-color:                             $global-emphasis-color;
//$base-heading-text-transform:                    none;
//$base-heading-margin-top:                        $global-medium-margin;

$base-h1-font-size-m: 40px;
$base-h1-font-size: 80px;
$base-h1-line-height-m: 1.04em;
$base-h1-line-height: 1em;
$base-h1-letter-spacing-m: -4px;
$base-h1-letter-spacing: -2px;

$base-h2-font-size-m: 35px;
$base-h2-font-size: 65px;
$base-h2-line-height-m: 1.04em;
$base-h2-line-height: 1em;
$base-h2-letter-spacing-m: -2px;
$base-h2-letter-spacing: -2px;

$base-h3-font-size-m: 30px;
$base-h3-font-size: 50px;
$base-h3-line-height-m: 1.17em;
$base-h3-line-height: 1em;
$base-h3-letter-spacing-m: -1.2px;
$base-h3-letter-spacing: -2px;

$base-h4-font-size-m: 21px;
$base-h4-font-size: 32px;
$base-h4-line-height-m: 1.19em;
$base-h4-line-height: 1em;
$base-h4-letter-spacing-m: -0.84px;
$base-h4-letter-spacing: -1.28px;

$base-h5-font-size-m: 21px;
$base-h5-font-size: 28px;
$base-h5-line-height-m: 1.19em;
$base-h5-line-height: 1em;
$base-h5-letter-spacing-m: -0.84px;
$base-h5-letter-spacing: -1.12px;

$base-h6-font-size-m: 12px;
$base-h6-font-size: 12px;
$base-h6-line-height-m: 1.25em;
$base-h6-line-height: 1.25em;
$base-h6-letter-spacing-m: -0.47px;
$base-h6-letter-spacing: -0.48px;

$inter-h1-font-size-m: 30px;
$inter-h1-font-size: 50px;
$inter-h1-line-height-m: 1.2em;
$inter-h1-line-height: 1.12em;
$inter-h1-letter-spacing-m: -0.6px;
$inter-h1-letter-spacing: -1px;

$inter-h2-font-size-m: 24px;
$inter-h2-font-size: 32px;
$inter-h2-line-height-m: 1.25em;
$inter-h2-line-height: 1.19em;
$inter-h2-letter-spacing-m: -0.48px;
$inter-h2-letter-spacing: -0.64px;

$inter-h3-font-size-m: 20px;
$inter-h3-font-size: 28px;
$inter-h3-line-height-m: 1.3em;
$inter-h3-line-height: 1.21em;
$inter-h3-letter-spacing-m: -0.4px;
$inter-h3-letter-spacing: -0.56px;

$inter-h4-font-size-m: 20px;
$inter-h4-font-size: 24px;
$inter-h4-line-height-m: 1.3em;
$inter-h4-line-height: 1.25em;
$inter-h4-letter-spacing-m: -0.42px;
$inter-h4-letter-spacing: -0.48px;

$inter-h5-font-size-m: 16px;
$inter-h5-font-size: 20px;
$inter-h5-line-height-m: 1.38em;
$inter-h5-line-height: 1.3em;
$inter-h5-letter-spacing-m: -0.32px;
$inter-h5-letter-spacing: -0.4px;

$inter-h6-font-size-m: 12px;
$inter-h6-font-size: 12px;
$inter-h6-line-height-m: 1.2em;
$inter-h6-line-height: 1.2em;
$inter-h6-letter-spacing-m: -0.3px;
$inter-h6-letter-spacing: -0.3px;

//$base-list-padding-left:                         30px;

//$base-hr-margin-vertical:                        $global-margin;
//$base-hr-border-width:                           $global-border-width;
//$base-hr-border:                                 $global-border;
//
//$base-blockquote-font-size:                      $global-medium-font-size;
//$base-blockquote-line-height:                    1.5;
//$base-blockquote-font-style:                     italic;
//$base-blockquote-margin-vertical:                $global-margin;
//$base-blockquote-footer-margin-top:              $global-small-margin;
//$base-blockquote-footer-font-size:               $global-small-font-size;
//$base-blockquote-footer-line-height:             1.5;
//
$base-pre-font-size: $global-xsmall-font-size;
//$base-pre-line-height:                           1.5;
//$base-pre-font-family:                           $base-code-font-family;
$base-pre-color: #efefef; // this is used on the docs show/hide twig code!
//
//$base-selection-background:                      #39f;
//$base-selection-color:                           $global-inverse-color;
