@mixin wolf-m-cb-checklist($name: '.m-cb-checklist') {
	#{$name} {
		display: flex;
		flex-direction: row;

		* {
			display: flex;
			vertical-align: top;
		}

		p {
			@include font-inter-200();
			font-size: 20px;
			line-height: 30px;
			letter-spacing: -0.1px;
			margin: 0px;
			display: flex;
			flex-grow: 1;
			word-break: break-word;
			padding-top: 4px;
			@media (max-width: $breakpoint-small) {
				font-size: 18px;
				line-height: 21px;
			}
		}

		.uk-icon,
		div {
			margin-right: 25px;

			@media (max-width: $breakpoint-small) {
				margin-right: 10px;
			}

			svg {
				max-height: 35px;
				max-width: 35px;

				@media (max-width: $breakpoint-small) {
					max-height: 25px;
					max-width: 25px;
				}

				path {
					stroke: currentColor;
					fill: transparent;
				}
			}
		}

		div > svg {
			width: 28px;
			min-width: 28px;
		}
	}
}

@include wolf-m-cb-checklist();
