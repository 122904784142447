@mixin wolf-m-pagination($name: '.m-pagination') {
	#{$name} {
		ul.uk-pagination {
			li {
				margin-left: 8px;
				margin-right: 8px;
				width: 50px;
				height: 50px;

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}

				.pagination-btn {
					text-decoration: none;
					color: map-get($colors, 'wolf-black');
					font-size: 25px;
					line-height: 50px;
					width: 50px;
					height: 50px;
					padding: 0;
					border-radius: 50%;
					background-color: transparent;
					border: 0;
					outline: none;
					cursor: pointer;
					display: grid;
					place-items: center;
					&:hover {
						text-decoration: none;
						background-color: rgba(0, 0, 0, 0.08);
					}
				}

				&#{$name}__current-page {
					.pagination-btn {
						background-color: map-get($colors, 'wolf-red');
						color: white;
						cursor: default;
					}
				}

				&#{$name}__chevron-next,
				&#{$name}__chevron-prev {
					&--nolink {
						pointer-events: none;

						.pagination-btn {
							svg {
								color: #c3c3c3;
							}
						}
					}
				}

				span.uk-pagination-next,
				span.uk-pagination-previous {
					> svg {
						width: 12px;
					}
				}
			}

			@media (max-width: $breakpoint-medium-max) {
				li {
					width: 32px;
					height: 32px;
					margin: 0 4px !important;

					a,
					.pagination-btn {
						line-height: 32px;
						font-size: 16px;
						width: 32px;
						height: 32px;
						min-width: 32px;
					}
				}
			}
		}
	}
}

@include wolf-m-pagination();
