// Name:            Navbar
// Description:     Component to create horizontal navigation bars
//
// Component:       `uk-navbar`
//
// Sub-objects:     `uk-navbar-container`
//                  `uk-navbar-left`
//                  `uk-navbar-right`
//                  `uk-navbar-center`
//                  `uk-navbar-center-left`
//                  `uk-navbar-center-right`
//                  `uk-navbar-nav`
//                  `uk-navbar-item`
//                  `uk-navbar-toggle`
//                  `uk-navbar-subtitle`
//                  `uk-navbar-dropbar`
//
// Adopted:         `uk-navbar-dropdown` + Modifiers
//                  `uk-navbar-dropdown-nav`
//                  `uk-navbar-dropdown-grid`
//                  `uk-navbar-toggle-icon`
//
// Modifiers:       `uk-navbar-primary`
//                  `uk-navbar-transparent`
//                  `uk-navbar-sticky`
//                  `uk-navbar-dropdown-stack`
//
// States:          `uk-active`
//                  `uk-parent`
//                  `uk-open`
//
//
// ========================================================================

// Variables
// ========================================================================

// $navbar-background:                              $global-muted-background;
// $navbar-color-mode:                              none;

// $navbar-nav-item-height:                         80px;
// $navbar-nav-item-padding-horizontal:             15px;
// $navbar-nav-item-color:                          $global-muted-color;
// $navbar-nav-item-font-size:                      $global-font-size;
// $navbar-nav-item-font-family:                    $global-font-family;
// $navbar-nav-item-hover-color:                    $global-color;
// $navbar-nav-item-onclick-color:                  $global-emphasis-color;
// $navbar-nav-item-active-color:                   $global-emphasis-color;

// $navbar-item-color:                              $global-color;

// $navbar-toggle-color:                            $global-muted-color;
// $navbar-toggle-hover-color:                      $global-color;

// $navbar-subtitle-font-size:                      $global-small-font-size;

// $navbar-dropdown-z-index:                        $global-z-index + 20;
$navbar-dropdown-width: 300px;
// $navbar-dropdown-margin:                         0;
$navbar-dropdown-padding: $global-small-gutter $global-gutter;
// $navbar-dropdown-background:                     $global-muted-background;
// $navbar-dropdown-color:                          $global-color;
// $navbar-dropdown-grid-gutter-horizontal:         $global-gutter;
// $navbar-dropdown-grid-gutter-vertical:           $navbar-dropdown-grid-gutter-horizontal;

// $navbar-dropdown-dropbar-margin-top:             0;
// $navbar-dropdown-dropbar-margin-bottom:          $navbar-dropdown-dropbar-margin-top;

// $navbar-dropdown-nav-item-color:                 $global-muted-color;
// $navbar-dropdown-nav-item-hover-color:           $global-color;
// $navbar-dropdown-nav-item-active-color:          $global-emphasis-color;
// $navbar-dropdown-nav-header-color:               $global-emphasis-color;
// $navbar-dropdown-nav-divider-border-width:       $global-border-width;
// $navbar-dropdown-nav-divider-border:             $global-border;
// $navbar-dropdown-nav-sublist-item-color:         $global-muted-color;
// $navbar-dropdown-nav-sublist-item-hover-color:   $global-color;
// $navbar-dropdown-nav-sublist-item-active-color:  $global-emphasis-color;

// $navbar-dropbar-background:                      $navbar-dropdown-background;
// $navbar-dropbar-z-index:                         $global-z-index - 20;
