@mixin wolf-m-finder-contact($name: '.m-finder-contact') {
	#{$name} {
		height: 100%;

		& > div {
			height: 100%;

			& > div.uk-container {
				height: 100%;
			}
		}

		&__container {
			font-size: 20px;
			padding: 48px;
			border: 1px solid get-color(silver-grey);
			height: 100%;
			box-sizing: border-box;

			@media (max-width: $breakpoint-small-max) {
				padding: 16px 16px 25px 16px;
				color: #0d5055;

				border: 2px solid get-color(silver-grey);
			}

			.a-headline {
				@media (max-width: $breakpoint-small-max) {
					margin-bottom: 12px;
					opacity: 0.7;
				}
			}
			.a-copy {
				margin-bottom: 16px;

				@media (max-width: $breakpoint-small-max) {
					@include font-inter-200();
					font-size: 14px;
					line-height: 24px;
				}
			}

			#{$name}__link {
				font-weight: bold;
				color: get-color(mid-grey);
				text-decoration-color: get-color(mid-grey);

				@media (max-width: $breakpoint-small-max) {
					@include font-inter-200();

					font-size: 14px;
					line-height: 24px;
					font-weight: bold;

					color: #0d5055;
					opacity: 0.5;
					text-decoration: none;
				}
			}
		}
	}
}

@include wolf-m-finder-contact();
