@mixin wolf-a-side-badge($name: '.a-side-badge') {
	#{$name} {
		border-radius: 50%;
		background-color: map-get($colors, 'wolf-red');
		position: fixed;
		z-index: 1001;
		bottom: 30px;
		right: 80px;
		height: 60px;
		width: 60px;
		display: flex;

		@media (max-width: $breakpoint-medium) {
			right: 15px;
			bottom: 15px;
		}
		a {
			width: 100%;
			height: 100%;
			display: flex;
		}

		span {
			width: 25px;
			height: 25px;
			color: map-get($colors, 'true-white');
			margin: 19px 18px auto auto;
		}
	}
}

@include wolf-a-side-badge();
