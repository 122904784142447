@mixin wolf-a-price($name: '.a-price') {
	#{$name} {
		&__price,
		&__currency {
			display: inline-block;
			text-decoration: inherit;
			> span {
				color: get-color(wolf-black);

				@media (min-width: $breakpoint-small) and (max-width: ($breakpoint-medium - 160px)) {
					font-size: 19px;
				}
			}
		}

		@include element-modifier('&--strikethru') {
			&__wrapper {
				color: get-color(wolf-red);
				text-decoration: line-through;
				white-space: nowrap;
			}
		}

		@include element-modifier('&--dark') {
			&__price,
			&__currency {
				> span {
					color: white;
				}
			}
		}
	}
}

@include wolf-a-price();
