@mixin wolf-m-accordion-content($name: '.m-accordion-content') {
	#{$name} {
		&__accordion-wrapper {
			margin-bottom: 0px;
			.uk-accordion-title {
				font-size: 28px;
				font-family: 'Inter';
				letter-spacing: -0.56px;
				text-decoration: none;
				display: flex;
				justify-content: space-between;
				align-items: center;
				text-align: left;
				width: 100%;
				border: 0;
				outline: none;
				background-color: transparent;
				cursor: pointer;
				padding-left: 0;

				@media (max-width: $breakpoint-medium) {
					align-items: center;
					max-width: 100vw;
					font-size: 18px;
					font-weight: 500;
					margin: 0 !important;
					min-height: 4rem;
					background-color: transparent;
					padding: 0 1rem;

					:last-child {
						margin: 0 10px;
					}
				}

				@media (max-width: $breakpoint-small-max) {
					padding: 1rem;
				}

				.title-element {
					> svg {
						min-width: 16px;
						height: 16.5px !important;
					}
				}

				.uk-icon {
					width: 26px;

					@media (max-width: $breakpoint-medium) {
						width: 19px;

						svg {
							width: 19px;
						}
					}
				}
			}

			.uk-accordion-content {
				font-size: 16px;
				font-family: 'Inter';
				line-height: 28px;
				letter-spacing: -0.32px;
				background-color: transparent;
				box-sizing: unset;

				.text-content {
					margin: 0 !important;
					@media (max-width: $breakpoint-medium) {
						font-size: 14px;
					}

					p {
						margin: 0 !important;

						@media (max-width: $breakpoint-medium) {
							font-size: 14px;
						}
					}
				}

				ul:not(:first-child) {
					margin-top: 30px;
				}

				@media (max-width: $breakpoint-medium) {
					font-size: 14px;
					line-height: 20px;
					padding: 0 1rem 30px;
					margin-top: 0;
				}

				@media (max-width: $breakpoint-small-max) {
					padding: 0 1rem 30px 6px;
				}

				.m-download li .m-download__icon {
					width: 10%;

					@media (max-width: $breakpoint-medium) {
						width: 20%;
					}
				}
			}

			.a-button[class*='uk-button']:after {
				margin-top: -1px;
			}

			.uk-open {
				.uk-accordion-title {
					.uk-icon:not(.title-element) {
						svg {
							transform: rotate(45deg);
						}
					}
				}
			}

			@media (max-width: $breakpoint-small-max) {
				> li {
					margin-top: 0 !important;
					border-top: solid 1px #c3c3c3;
				}
			}
		}

		&__download {
			margin-left: 100px;

			@media (max-width: 1050px) {
				margin-left: 50px;
			}

			@media (max-width: $breakpoint-medium) {
				margin-left: 0px;
			}
		}
	}
}

@include wolf-m-accordion-content();
