@mixin wolf-m-marker-image($name: '.m-marker-image') {
	#{$name} {
		&__container {
			position: relative;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			margin-top: 45px;

			.image-wrapper {
				position: relative;
			}

			.image-cover {
				visibility: hidden;
			}

			a {
				text-decoration: none;

				&:hover {
					color: get-color('wolf-black');

					.text {
						text-decoration: underline;
					}
				}
			}
		}

		&__marker {
			position: absolute;
			width: 42px;
			height: 42px;
			border-radius: 50%;
			border: 0px;
			background-color: rgba(17, 21, 28, 0.6);
			padding: 0;

			@media (max-width: $breakpoint-small-max) {
				display: none;
			}

			.uk-form-icon {
				position: unset;
				top: unset;
				left: unset;
				bottom: unset;
				width: fit-content;
				width: -moz-fit-content;
				height: 100%;

				svg {
					width: 10px;
					height: 10px;
					-webkit-box-shadow: 0px 0px 15px 7px rgba(0, 0, 0, 0.3);
					box-shadow: 0px 0px 15px 7px rgba(0, 0, 0, 0.3);
					border-radius: 50%;

					color: get-color('true-white');
				}
			}

			&:hover {
				cursor: pointer;
			}

			&.selected {
				background-color: get-color('true-white');

				+ #{$name}__tooltip {
					opacity: 1;
					transition-delay: 0.3s;
					pointer-events: all;
					margin: 0 !important;
				}

				.uk-form-icon {
					svg {
						width: 10px;
						color: get-color('true-black');
					}
				}
			}
		}

		&__tooltip {
			background-color: get-color('true-white');
			padding: 25px 30px 25px 25px;
			max-width: 265px;
			width: 100%;
			border-radius: 6px;
			// border: 1px solid #707070;
			z-index: 5;
			opacity: 0;
			transition-delay: 0s;
			transition: all 0.25s ease-out;
			pointer-events: none;
			margin: 0 0 0 15px !important;
			box-shadow: 0 2px 5px rgba(0, 0, 0, 0.35);

			.headline {
				@include font-inter-400();
				font-size: 20px;
				line-height: 26px;
				max-width: 230px;
				display: inline-flex;
				margin-bottom: 0;

				span {
					height: fit-content;
					height: --moz-fit-content;
					width: fit-content;
					width: --moz-fit-content;

					top: 29px;
					right: 30px;
					justify-content: flex-end;

					pointer-events: all;

					svg {
						width: 19px;
						height: 17px;
					}
				}
			}

			.text {
				margin: 0px;
				max-width: 260px;

				@include font-inter-200();
				font-size: 16px;
				line-height: 25px;

				margin-top: 20px;

				* {
					margin: 0px;
				}
			}
		}

		&__tooltip[data-show] {
			display: block;
		}
	}
}

@include wolf-m-marker-image();
