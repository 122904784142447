@mixin wolf-m-download($name: '.m-download') {
	#{$name} {
		li {
			> a.download {
				display: block;
				text-decoration: none;

				> div {
					display: inline-flex;
					vertical-align: middle;
					padding: 25px 0px;
				}
			}

			#{$name}__number {
				width: 10%;
				//padding-left: 2%;
				justify-content: flex-start;

				@include font-inter-400();
				font-size: 20px;
				letter-spacing: -0.2px;
				line-height: 25px;
				color: get-color('true-black');

				@media (max-width: $breakpoint-xsmall-max) {
					font-size: 14px;
				}
			}

			#{$name}__textblock {
				width: 60%;

				@media (max-width: $breakpoint-small-max) {
					width: 70%;
					//padding-left:2%;
				}

				p {
					margin: 0px;
				}
			}
			#{$name}__textblock.no_number {
				width: 70%;
				//padding-left:2%;

				@media (max-width: $breakpoint-small-max) {
					width: 80%;
				}
			}

			#{$name}__title {
				@include font-inter-400();
				font-size: 20px;
				letter-spacing: -0.2px;
				line-height: 25px;
				color: get-color('true-black');

				@media (max-width: $breakpoint-xsmall-max) {
					@include font-inter-600();
					font-size: 14px;
				}
			}

			#{$name}__underText {
				@include font-inter-400();
				font-size: 20px;
				letter-spacing: -0.2px;
				line-height: 25px;
				color: grey;
				margin-top: 5px;
			}

			#{$name}__typing {
				width: 20%;
			}

			#{$name}__icon {
				width: 8%;
				justify-content: flex-end;

				span {
					color: get-color('true-black');
					background: #f7f7f7;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;

					min-height: 44px;
					min-width: 44px;

					padding: 5px;

					svg {
						height: 22px;
						width: 22px;
					}
				}

				@media (max-width: $breakpoint-small-max) {
					width: 18%;

					span {
						min-width: 24px;
						min-height: 24px;

						svg {
							height: 12px;
							width: 12px;
						}
					}
				}
			}
		}

		/*Overriding uikit border list*/
		> :nth-child(n + 2) {
			margin: 0px;
			padding-top: 0px;
		}

		&.uk-list-divider > :first-child {
			border-top: 1px solid #e5e5e5;
		}

		&.uk-list-divider > :last-child {
			border-bottom: 1px solid #e5e5e5;
		}
	}
}

@include wolf-m-download();
