@mixin wolf-o-adviser($name: '.o-adviser') {
	#{$name} {
		&__headline {
			margin-bottom: 56px;

			@media (max-width: $breakpoint-small-max) {
				margin-bottom: 24px;
			}
		}

		.m-search-field__input {
			width: 676px;

			@media (max-width: calc(#{$breakpoint-medium} - 100px)) {
				width: 500px;
			}

			@media (max-width: $breakpoint-xsmall-max) {
				width: 100%;
			}
		}

		&__categories {
			margin-top: 24px;

			ul {
				padding: 0;
				display: flex;
				flex-wrap: wrap;
			}

			li {
				@include font-inter-300;
				color: get-color('dark-grey');
				font-size: 20px;
				list-style: none;
				margin-right: 33px;
				cursor: pointer;
				transition: color 0.2s ease-in-out;
				white-space: nowrap;

				span {
					color: get-color('stroke-grey');
				}

				&.active {
					color: get-color('primary');
				}

				&.disabled {
					color: get-color('stroke-grey');
				}
			}
		}

		@media (max-width: $breakpoint-small-max) {
			.padding-big-top {
				padding-top: 24px;
				padding-bottom: 24px;
			}
		}
	}
}

@include wolf-o-adviser();
