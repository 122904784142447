// Name:            Section
// Description:     Component to create horizontal layout section
//
// Component:       `uk-section`
//
// Modifiers:       `uk-section-xsmall`
//                  `uk-section-small`
//                  `uk-section-large`
//                  `uk-section-xlarge`
//                  `uk-section-default`
//                  `uk-section-muted`
//                  `uk-section-primary`
//                  `uk-section-secondary`
//                  `uk-section-overlap`
//
// States:          `uk-preserve-color`
//
// ========================================================================

// Hooks
// ========================================================================

// @mixin hook-section(){}
// @mixin hook-section-default(){}
// @mixin hook-section-muted(){}
// @mixin hook-section-secondary(){}
// @mixin hook-section-primary(){}
@mixin hook-section-overlap() {
	padding-top: 0;
}

@mixin hook-section-misc() {
	.uk-section-default + .uk-section-primary,
	.uk-section-default + .uk-section-secondary,
	.uk-section-default + .uk-section-muted {
		margin-top: $section-padding-vertical;

		@media (min-width: $breakpoint-medium) {
			margin-top: $section-padding-vertical-m;
		}
	}

	.uk-section-primary + .uk-section-default,
	.uk-section-secondary + .uk-section-default,
	.uk-section-muted + .uk-section-default {
		margin-top: $section-padding-vertical;

		@media (min-width: $breakpoint-medium) {
			margin-top: $section-padding-vertical-m;
		}
	}
}
