@mixin wolf-m-product-family($name: '.m-product-family') {
	#{$name} {
		&.styleSettings > div *:not(span):not(.standard-back) {
			background: inherit;
			color: inherit;
		}

		&__subheadline {
			position: relative;

			p.sub-headline {
				@include font-inter-400();
				font-size: 24px;
				line-height: 28px;
				display: inline-flex;
				margin-bottom: 48px;
				padding-right: 36px; // to give space for the tooltip "i" icon
			}

			span.tooltip-icon {
				position: absolute;
				top: 0;
				right: 4px; // allow the chevron on the bubble to not be to the right of the tooltip

				cursor: pointer;
				display: block;

				border: 2px solid #d2d2d2; // give the icon a circle outline
				border-radius: 50%;

				margin: 0;
				width: 23px;
				height: 23px;

				svg {
					width: 23px;
					height: 23px;
					color: #d2d2d2;
					padding: 5px;
				}

				// show the tooltip when the icon is hovered
				&:hover {
					+ .tooltip {
						display: block;
					}
				}
			}
		}

		// the contents of the tooltip
		&__tooltipPopup {
			display: none; // hide initially

			position: absolute;
			z-index: 2;

			// top:55%;
			top: 50px; // this is based on the font size of the headline at the moment ;o(
			right: 0;

			width: fit-content;

			color: get-color('true-black');
			background-color: get-color('true-white');

			border: 1px solid #d2d2d2;
			border-radius: 5px;

			padding: 10px 20px;

			@include font-inter-200();
			font-size: 20px;
			line-height: 36px;

			text-align: left;

			> * {
				// @todo - check html support to see if this is good
				margin: 0px;
			}

			&::before {
				content: '';
				width: 0px;
				height: 0px;
				position: absolute;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-top: 10px solid transparent;
				border-bottom: 10px solid #d2d2d2;
				left: initial;
				top: -20px;
				right: 7px;
			}
		}

		&__text {
			@include font-inter-200();
			font-size: 20px;
			line-height: 36px;
			margin-bottom: 70px;

			@media (max-width: $breakpoint-small-max) {
				margin-bottom: 24px;
			}
		}

		&__techData {
			div .a-headline {
				margin-bottom: 20px;
			}

			hr {
				margin: 0px;
			}

			p {
				@include font-inter-200();
				font-size: 20px;
				line-height: 30px;
			}
		}

		&__productImages {
			.m-gallery {
				height: 100%;

				&__highlighted {
					width: auto;
				}

				&.custom-gallery {
					// override gallery images to be full width
					picture.a-image {
						> img {
							min-width: 100%;
							height: auto;
							width: 100%;
						}
					}
				}
			}
		}

		// handle wrapper breakpoints
		#{$name}__wrapper {
			@media (max-width: $breakpoint-small-max) {
				flex-direction: column;
			}
		}

		// modifiers
		&--reverse {
			// flip the wrapper layout - make the image be on the left and the text on the right
			#{$name}__wrapper {
				flex-direction: row-reverse;
			}

			// make the text box align right, since it's 2-3 width
			#{$name}__productInner {
				justify-content: end;
			}
		}

		// style overrides for elements
		&.styleSettings {
			hr {
				border-top-color: currentColor;
			}

			#{$name}__tooltipPopup {
				&::before {
					border-bottom-color: currentColor;
				}
			}

			span.tooltip-icon {
				border-color: currentcolor;

				svg {
					color: currentColor;
				}

				&:hover + .tooltip {
					color: inherit;
					border-color: currentColor;
				}
			}
		}
	}
}

@include wolf-m-product-family();
