@mixin wolf-o-usp($name: '.o-usp') {
	#{$name} {
		&.default-gradient {
			position: relative;

			// >div:before {
			// 	content: '';
			// 	position: absolute;
			// 	left: 0;
			// 	right: 0;
			// 	top: 0;
			// 	bottom: 0;
			// 	background: linear-gradient(270deg, rgba(255, 255, 255, 0) 40%, rgba(33, 33, 44, 0.68) 70%, rgba(33, 33, 44, 0.78) 100%);
			// }
		}

		.image-wrapper {
			&::before {
				background: linear-gradient(270deg, rgba(255, 255, 255, 0) 40%, rgba(33, 33, 44, 0.68) 70%, rgba(33, 33, 44, 0.78) 100%);
			}
		}

		&__content-wrapper {
			position: relative;
			padding: 116px 0px 135px;
			max-width: 1250px;

			@media (max-width: $breakpoint-large-max) {
				padding: 70px 50px 100px;
			}

			@media (max-width: $breakpoint-xsmall-max) {
				padding: 40px 5px 60px;
			}
		}

		&__headline {
			margin-bottom: 50px;

			@media (max-width: $breakpoint-xsmall-max) {
				margin-bottom: 30px;
				width: 100%;

				.a-headline {
					letter-spacing: normal;
				}
			}
		}

		&__line {
			border-top-width: 2px;
			margin-bottom: 55px;

			@media (min-width: $breakpoint-medium) {
				width: 90%;
			}

			@media (max-width: $breakpoint-xsmall-max) {
				margin-bottom: 34px;
			}
		}

		&__subheadline {
			&-wrapper {
				@media (min-width: $breakpoint-medium) {
					width: 90%;
				}

				@media (max-width: $breakpoint-xsmall-max) {
					#{$name}__h4-headline .a-headline {
						margin-bottom: 24px;
					}
				}
			}

			&-text {
				font-size: 20px;
				line-height: 36px;

				@include font-inter-300;
			}

			@media (max-width: $breakpoint-xsmall-max) {
				&-text {
					font-size: 12px;
					line-height: 24px;
					margin-top: 0px;
				}

				&-block:not(:first-of-type) {
					margin-top: 35px;
				}
			}
		}
	}
}

@include wolf-o-usp();
