@mixin m-button-group($name: '.m-button-group') {
	#{$name} {
		&:not(.m-button-group-rounded) {
			margin-bottom: 40px;

			@include min-md {
				margin-bottom: 60px;
			}

			@include min-lg {
				margin-bottom: 80px;
			}
		}

		> .container {
			display: flex;
			flex-wrap: wrap;
			gap: 16px;

			@include only-mobile {
				> .a-button {
					width: 100%;
					justify-content: center;

					.a-button__text {
						flex: unset;
						justify-content: center;
					}
				}
			}

			.a-button[data-download='share'] {
				display: none;
			}
		}

		&#{$name} {
			&-share {
				.a-button[data-download='share'] {
					display: inline-flex;
				}
			}

			&-rounded {
				gap: 10px;

				.divider-wrapper {
					display: none;
				}

				.a-button {
					background-color: map-get($colors, 'true-white');
					color: map-get($colors, 'true-black');
					width: 41px;
					height: 41px;
					display: inline-flex;
					border-radius: 50%;
					overflow: hidden;
					padding: 5px;
					box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
					align-items: center;
					justify-content: center;
					text-decoration: none;
					box-sizing: border-box;
					font-weight: normal;
					cursor: pointer;
					border-color: map-get($colors, 'true-white');
					text-indent: -9999px;
					@include only-mobile {
						position: relative;
					}

					&.has-icon {
						padding: 0;
					}

					.a-button__icon-wrapper {
						padding: 0 1px 0 0;
						margin-right: 0;
						@include only-mobile {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							> svg {
								min-width: 26px;
							}
						}

						> svg {
							height: 26px;
							width: 26px;
						}
					}

					&[data-download='share'] {
						.a-button__icon-wrapper {
							padding: 0;
							margin-left: -2px;

							> svg {
								height: 20px;
								width: 20px;
							}
						}
					}

					&:disabled {
						background-color: red;
						color: white;
						cursor: default;

						> svg {
							width: 20px;
						}
					}
				}

				&.added-to-list {
					.a-button {
						&[data-download='add-to-downloadlist'] {
							background-color: #74b959;

							.a-button__icon-wrapper {
								> svg {
									height: 15px;
								}
							}
						}
					}
				}
			}
		}

		.divider {
			width: 100%;
			height: 1px;
			display: flex;
			background-color: #dddddd;
			margin: 30px 0 40px;

			@include min-md {
				margin: 30px 0 40px;
			}
		}
	}
}

@include m-button-group();
