@mixin wolf-m-ahu-navbar($name: '.m-ahu-navbar') {
	#{$name} {
		position: relative;

		&__wrapper {
			position: absolute;
			margin-top: $global-navbar-offset-top;
			width: 100%;
			display: grid;
			grid-template-columns: 2fr 1fr 2fr;
			background-color: transparent;
			z-index: 2;

			@media (min-width: $breakpoint-xlarge) {
				grid-template-columns: 3fr 1fr 3fr;
			}

			@media (max-width: $breakpoint-medium) {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 0;
			}
		}

		.stageless {
			position: initial;
			margin-top: 0;
			margin-bottom: 50px;
			padding: 20px 0;
		}

		&__left-links {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}

		&__right-links {
			display: flex;
			align-items: center;
		}

		&__logo {
			text-align: center;

			@media (max-width: $breakpoint-medium) {
				position: relative;
				left: 42%;
			}

			.a-wolf-logo {
				@media (max-width: $breakpoint-large) {
					width: 130px;
					margin: 10px 0;
				}
				@media (max-width: $breakpoint-small) {
					width: 84px;
					margin: 17px 0;
				}
			}
		}

		.navlink {
			a {
				border-bottom: solid 2px transparent;
				padding: 0;
				margin: 0 30px;
				transition: all 0.4s ease;
				opacity: 0.6;
				white-space: nowrap;

				&:hover {
					border-color: get-color('accent');
					transition: all 0.4s ease;
					opacity: 1;
				}
			}
		}

		.light {
			.a-navlink {
				color: get-color('true-black');
			}
		}

		.dark {
			.a-navlink {
				color: get-color('true-white');
			}
		}

		.uk-offcanvas-bar {
			background-color: map-get($colors, 'wolf-black');
			display: grid;
			width: 300px;
			place-items: center;
			grid-template-rows: 1fr 5fr;
		}

		#hamburger {
			margin: 20px;
		}

		.menu__wrap {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
}

@include wolf-m-ahu-navbar();
