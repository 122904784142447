@mixin wolf-m-fhw-profile-about($name: '.m-fhw-profile-about') {
	#{$name} {
		&__headline {
			max-width: 1256px;

			@media (max-width: $breakpoint-small-max) {
				margin-bottom: 16px;
			}
		}

		&__teasers-container {
			margin-bottom: 67px;
			display: flex;
			overflow: scroll;

			#{$name}__teaser {
				min-width: 40%;
				margin-right: 16px;

				@media (min-width: $breakpoint-large) {
					width: 33%;
					min-width: 30%;
				}

				border: thin solid #d9d9d9;
				border-radius: 5px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				padding: 20px;

				span {
					width: 110px;
					margin: 0 auto;
					margin-bottom: 25px;
					display: flex;
					justify-content: center;

					> svg {
						width: 32px;
						height: 32px;
					}
				}

				.a-headline {
					margin-bottom: 0;

					.prefix-anchor {
						&:empty {
							display: none;
						}
					}

					h1,
					h2,
					h3,
					h4,
					h5,
					h6 {
						text-align: center;
						color: get-color('dark-blue-30-white');
					}
				}
			}

			/* hide scrollbar but allow scrolling */
			-ms-overflow-style: none;
			/* for Internet Explorer, Edge */
			scrollbar-width: none;
			/* for Firefox */
			overflow-y: scroll;

			&::-webkit-scrollbar {
				display: none;
				/* for Chrome, Safari, and Opera */
			}
		}

		&__copy {
			@media (max-width: $breakpoint-large) {
				max-width: 1256px;
				margin-bottom: 32px;
			}
		}
	}
}

@include wolf-m-fhw-profile-about();
