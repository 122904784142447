@mixin wolf-o-finder-select-step-entrypoint($name: '.o-finder-select-step-entrypoint') {
	#{$name} {
		& div > .uk-container {
			max-width: 1682px;
		}

		.m-finder-select-step-static {
			position: relative;
			bottom: -110px;

			@media (max-width: $breakpoint-small-max) {
				position: inherit;
			}
		}

		@media (max-width: $breakpoint-small-max) {
			.a-headline,
			.a-copy {
				color: get-color('true-white');
			}
		}
	}

	#{$name}.styleSettings {
		.a-button {
			@media (max-width: $breakpoint-small-max) {
				color: get-color('true-white');
				border: 1px solid white;
			}
		}

		> div {
			@media (min-width: $breakpoint-medium) {
				// background-image: none !important;
			}
		}
	}

	.a-button:hover {
		@media (max-width: $breakpoint-small-max) {
			background-color: get-color(dark-blue);
			color: get-color(true-white);
		}
	}
}

@include wolf-o-finder-select-step-entrypoint();
