@mixin wolf-m-fab-drawer($name: '.m-fab-drawer') {
	#{$name} {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;

		.a-headline {
			@include font-saira-400();
			text-align: center;
		}

		div.a-headline {
			width: 100%;
			border-bottom: 1px solid #d3d3d3;
			margin-bottom: 20px;
		}

		.headline-text {
			width: 100%;
			text-align: center;
		}

		&__container {
			width: 100%;
			height: 100%;
			position: relative;
			padding-bottom: 75px;

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;

			.contact {
				width: 100%;

				.head {
					@include font-inter-400();
					font-size: 16px;
					line-height: 21px;
					color: #101620;
				}

				.phone {
					@include font-inter-500();
					text-decoration: none;
					font-size: 26px;
					line-height: 30px;
					letter-spacing: -1px;
					color: #101620;

					* {
						//removing underline on all browser-specific phone formating
						text-decoration: none;
					}
				}

				.hours {
					@include font-inter-400();
					font-size: 14px;
					line-height: 20px;
					color: #707070;
					margin: 0px;
				}
			}

			.contact-bottom {
				position: absolute;
				bottom: 20px;
				width: 100%;
				.a-button {
					width: 100%;
					display: flex;
					justify-content: center;
					&__text {
						font-size: 18px;
						line-height: 1.3;
					}
				}
			}
		}
	}
}

@include wolf-m-fab-drawer();
