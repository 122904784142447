@mixin wolf-o-two-column-text($name: '.o-two-column-text') {
	#{$name} {
		&.internal-container > * {
			box-sizing: content-box;
			max-width: 1520px;
			margin-left: auto;
			margin-right: auto;

			@media (min-width: 960px) {
				padding-left: 50px;
				padding-right: 50px;
			}

			@media (min-width: 640px) {
				padding-left: 16px;
				padding-right: 16px;
			}
		}

		.uk-grid-medium.uk-container {
			margin-left: auto;
			margin-right: auto;
		}

		.uk-child-width-1-1 .uk-flex-left {
			padding-left: 0px;
		}

		&__headline {
			flex-direction: column;

			.a-headline {
				max-width: 100%;
			}

			.a-copy {
				width: 90%;
				margin-left: 0px;
				@include font-inter-200();
				font-size: 32px;
				line-height: 40px;
			}
		}

		&__headline.headTop {
			.a-copy {
				margin-top: 0px;
			}
		}

		.uk-container > .a-headline {
			margin-bottom: 88px;
		}

		& .vertical {
			#{$name}__headline {
				margin-bottom: 85px;
				* {
					margin-bottom: 0px;
				}
			}
		}

		&__text {
			flex-direction: column;

			.a-copy {
				width: 100%;
				display: flex;
				align-items: inherit;
				justify-content: inherit;
				flex-direction: column;

				margin-top: 0px;

				@media (max-width: $breakpoint-small-max) {
					font-size: 14px;
				}
			}

			.a-button {
				width: fit-content;
				width: -moz-fit-content;
				margin-top: 50px;

				@media (max-width: $breakpoint-small-max) {
					justify-content: center;
					width: 100%;
					margin-top: 15px;
				}
			}
		}

		& .text-right {
			flex-direction: row-reverse;
		}

		& .headline-top {
			align-items: flex-start;
		}

		& .headline-middle {
			align-items: center;
		}

		& .headline-bottom {
			align-items: flex-end;
		}

		&__buttons {
			a {
				margin-right: 20px;
			}
		}
	}
}

@include wolf-o-two-column-text();
