$breakpoint-mobile-xs: 320px;

@mixin button-primary {
	background-color: get-color('wolf-red');
	color: get-color('true-white');
	border-color: get-color('wolf-red');
	&:hover {
		background-color: get-color('red-hover');
		border-color: get-color('red-hover');
		color: get-color('true-white');
	}

	@media (max-width: $breakpoint-xsmall-max) {
		margin-top: 16px;
	}

	@media (max-width: $breakpoint-mobile-xs) {
		margin-top: 0;
	}
}

@mixin wolf-m-login-form($name: '.m-login-form') {
	#{$name} {
		.m-nav-offcanvas__canvas {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		&__wrapper {
			max-width: 100%;
			height: max-content;
			min-height: 100%;
			padding-left: 0;

			@media (min-width: 340px) {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				min-height: auto;
			}

			@media (min-width: $breakpoint-medium) {
				width: 488px;
				padding-left: 140px;
			}
		}

		&__headline {
			h2.a-headline {
				margin-bottom: 46px;

				@media (max-width: $breakpoint-medium) {
					margin-top: 32px;
					margin-bottom: 0px;
				}
			}
		}

		&__text {
			@include font-inter-300;
			font-size: 12px;
			line-height: 22px;
			margin-top: 15px;

			@media (max-width: $breakpoint-medium) {
				font-size: 10px;
				line-height: 20px;
				margin-top: 25px;
				margin-bottom: 8px;
				&:last-of-type {
					padding-bottom: 20px;
				}
			}
		}

		&__input {
			display: flex;
			flex-direction: column;
			.password-toggler {
				span {
					width: 28px;
					padding: 0 16px;
					z-index: 1;
				}
			}
		}

		&__form {
			.uk-form-label,
			a:not(.uk-button) {
				font-size: 12px;
				line-height: 24px;
			}

			.uk-grid-small .my-dark-grey-text {
				font-size: 16px;
				line-height: 20px;
			}

			.uk-button-default {
				@include button-primary;
			}
		}

		&__first-button.uk-button-default {
			@include button-primary;
		}

		&__checkbox {
			margin-top: 20px;
			margin-bottom: 60px;

			@media (max-width: $breakpoint-small) {
				margin-top: -10px;
			}

			@media (max-width: $breakpoint-mobile-xs) {
				margin-top: 20px;
				margin-bottom: 20px;
				.uk-margin-medium-bottom {
					margin-bottom: 0px !important;
				}
			}
		}

		&__login-button,
		&__first-button,
		&__signup-button {
			width: 100%;
			border: unset;

			.a-button__text {
				margin: 0 auto;
			}
		}

		&__divider {
			display: flex;
			align-items: center;
			margin: 0 32px;

			hr {
				margin: 0;
			}
			p {
				margin: 16px 20px;
			}

			@media (max-width: $breakpoint-medium) {
				margin: 0 32px;
			}

			+ .a-button.uk-button.uk-flex-inline.uk-button-tertiary {
				min-height: 48px;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
			}
		}
	}
}

@include wolf-m-login-form();
