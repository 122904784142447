@mixin wolf-m-finder-result-teasers($name: '.m-finder-result-teasers') {
	#{$name} {
		max-width: 472px;

		@media (max-width: $breakpoint-small-max) {
			display: flex;
			flex-flow: row wrap;
			align-items: stretch;
			max-width: calc(100% + 16px);
		}

		&.single-item {
			@media (max-width: $breakpoint-small-max) {
				max-width: 100%;
				margin-left: 0;

				#{$name}__item-container {
					padding: 0;
					width: 100%;

					.content {
						height: 330px;
					}
				}
			}
		}

		&.multiple-items {
			#{$name}__item-container {
				.content {
					max-height: 220px;
					@media (max-width: $breakpoint-small-max) {
						height: auto;
						max-height: none;
						width: 100%;
					}

					.a-headline {
						word-break: break-word;
						font-size: 15px;
						@media (max-width: $breakpoint-small-max) {
							font-size: 3vw;
							margin-top: 10px;
							margin-bottom: 0;
						}
					}

					.a-image {
						margin-bottom: 16px;
						&__img {
							width: 90px;
						}
					}
				}
			}
		}

		&__item-container {
			@media (max-width: $breakpoint-small-max) {
				width: 50%;
			}

			.content {
				border: 1px solid get-color('dark-blue-90-white');
				padding: 12px;
				display: grid;
				place-items: center;
				text-decoration: none;
				box-sizing: border-box;
				height: 388px;

				.image-wrapper {
					position: relative;

					@media (max-width: $breakpoint-small-max) {
						height: auto;
					}
				}

				.a-image {
					margin-bottom: 0 auto 47px;

					&__img {
						object-fit: contain;
					}
				}

				@media (max-width: $breakpoint-small-max) {
					border: 1px solid #e8e9eb;
					background-color: #fcfcfc;
					height: auto;
					width: 100%;

					.a-headline {
						font-size: 3vw;
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

@include wolf-m-finder-result-teasers();
