$wolf-a-quantity-selector-button-width-large: 50px;
$wolf-a-quantity-selector-button-width-medium: 40px;
$wolf-a-quantity-selector-button-width-small: 30px;

@mixin wolf-a-quantity-selector($name: '.a-quantity-selector') {
	#{$name} {
		font-size: get-fontsize(m, large);
		display: flex;

		&__subtract,
		&__add,
		&__quantity {
			text-align: center;
			box-sizing: border-box;
			background-color: #fff;
		}

		&__subtract,
		&__add {
			> .uk-button {
				font-weight: 500;
				padding: 0px;
				border: 1px solid;
				user-select: none;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				&:focus {
					outline: none;
				}
			}
		}

		&__quantity {
			@include font-inter-600();
			border: 1px solid;
			border-left-width: 0;
			border-right-width: 0;
			user-select: none;
			cursor: text;
		}

		@media (max-width: $breakpoint-large-max) {
			font-size: get-fontsize(m, large) - 5;
		}

		@include element-modifier('&--small') {
			&__subtract,
			&__add,
			&__quantity {
				line-height: $wolf-a-quantity-selector-button-width-small;
				min-width: $wolf-a-quantity-selector-button-width-small;
				height: $wolf-a-quantity-selector-button-width-small;
			}
			&__subtract,
			&__add {
				flex: 0 0 $wolf-a-quantity-selector-button-width-small;
			}
			&__quantity {
				font-size: 18px;
			}
			> .uk-button {
				line-height: $wolf-a-quantity-selector-button-width-small;
				height: $wolf-a-quantity-selector-button-width-small;
				width: $wolf-a-quantity-selector-button-width-small;
				font-size: 18px;
			}
		}

		@include element-modifier('&--medium') {
			&__subtract,
			&__add,
			&__quantity {
				line-height: $wolf-a-quantity-selector-button-width-medium;
				min-width: $wolf-a-quantity-selector-button-width-medium;
				height: $wolf-a-quantity-selector-button-width-medium;

				@media (max-width: ($breakpoint-medium - 160px)) {
					line-height: $wolf-a-quantity-selector-button-width-small;
					min-width: $wolf-a-quantity-selector-button-width-small;
					height: $wolf-a-quantity-selector-button-width-small;
				}
			}
			&__subtract,
			&__add {
				flex: 0 0 $wolf-a-quantity-selector-button-width-medium;

				@media (max-width: ($breakpoint-medium - 160px)) {
					flex: 0 0 $wolf-a-quantity-selector-button-width-small;
				}
			}
			&__quantity {
				font-size: 24px;

				@media (max-width: ($breakpoint-medium - 160px)) {
					font-size: 18px;
				}
			}
			> .uk-button {
				line-height: $wolf-a-quantity-selector-button-width-medium;
				height: $wolf-a-quantity-selector-button-width-medium;
				width: $wolf-a-quantity-selector-button-width-medium;
				font-size: 24px;

				@media (max-width: 800px) {
					line-height: $wolf-a-quantity-selector-button-width-small;
					height: $wolf-a-quantity-selector-button-width-small;
					width: $wolf-a-quantity-selector-button-width-small;
					font-size: 18px;
				}
			}
		}

		@include element-modifier('&--large') {
			&__subtract,
			&__add,
			&__quantity {
				line-height: $wolf-a-quantity-selector-button-width-large;
				min-width: $wolf-a-quantity-selector-button-width-large;
				height: $wolf-a-quantity-selector-button-width-large;
			}
			&__subtract,
			&__add {
				flex: 0 0 $wolf-a-quantity-selector-button-width-large;
			}
			&__quantity {
				font-size: 24px;
			}
			> .uk-button {
				line-height: $wolf-a-quantity-selector-button-width-large;
				height: $wolf-a-quantity-selector-button-width-large;
				width: $wolf-a-quantity-selector-button-width-large;
				font-size: 24px;
			}
		}

		&--light {
			& .uk-button {
				color: get-color(true-black);
				border-color: #b5b5b5;
				&:focus {
					border-color: get-color(wolf-black);
				}
			}
		}

		&--dark {
			& .uk-button {
				color: get-color(true-white);
				border: 1px solid get-color(true-white);
				&:focus {
					border-color: darken(#fff, 20%);
				}
			}
		}

		@include element-modifier('&--light') {
			&__quantity {
				color: get-color(true-black);
				border-color: #eaeaea;
			}
		}

		@include element-modifier('&--dark') {
			&__quantity {
				color: get-color(true-white);
				border-color: rgba(255, 255, 255, 0.5);
			}
		}
	}
}

@include wolf-a-quantity-selector();
