@mixin wolf-o-nav($name: '.o-nav') {
	#{$name} {
		top: 0;
		width: 100%;
		left: 0;
		right: 0;
		margin: 0 auto;
		max-width: 1920px;
		min-height: 104px;
		z-index: 981;
		display: inline;
		@media (max-width: 1519px) {
			min-height: 100px;
		}
		@media (max-width: $breakpoint-medium) {
			min-height: 75px;
		}

		&__burger-icon {
			cursor: pointer;
			width: 32px;
			height: 32px;
			color: get-color('true-white');
			background-color: transparent;
			border: 0;
			outline: none;
			padding: 0;
		}

		nav {
			max-width: 1920px;
			margin: 0 auto;
		}

		&.overlap {
			position: absolute;
			top: 0;

			&.black-background {
				&.has-download-list {
					.m-nav-bar {
						&.on-top {
							background-color: get-color('wolf-bleck');
						}
					}
				}
			}
			&:not(.black-background) {
				.m-nav-bar {
					&.on-top:not(.dropdown-active):not(.hovering) {
						background-color: transparent !important;
					}
				}
			}

			.m-nav-bar {
				position: absolute;
				box-sizing: border-box;

				&__back-link {
					border: none;
					background-color: transparent;
					box-shadow: none;

					@media (max-width: $breakpoint-xsmall-max) {
						& .a-button__text {
							display: none;
						}
					}

					.uk-icon {
						padding: 0;
					}
				}
			}
		}

		&--burgerless {
			.m-nav-bar__wrapper.uk-navbar-container {
				padding: 16px;
			}
		}

		.o-nav__burger-icon {
			@media (max-width: 1199px) {
				position: absolute;
				top: 31px;
				right: 24px;
			}

			@media (max-width: 639px) {
				top: 21px;
			}
		}
	}
}

@include wolf-o-nav();
