@mixin wolf-m-flipcard($name: '.m-flipcard') {
	#{$name} {
		color: get-color('true-white');
		display: flex;
		flex-direction: column;
		align-items: center;

		padding: 110px 45px 64px 45px;

		&__head-wrapper {
			* {
				color: get-color('true-white');

				.a-headline {
					text-align: center;
				}
			}

			.a-headline {
				display: flex;
				justify-content: center;
			}
		}

		&__icon {
			span svg {
				width: 150px;
				height: 120px;
			}
		}

		&__head-subtitle {
			display: none;
			@include font-inter-200();

			.a-headline {
				width: 100%;
			}
		}

		&__description {
			display: none;
		}
	}
}

@include wolf-m-flipcard();
